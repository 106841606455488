export const normalizeManagerList = (value?: number[] | null) => {
  const stringResult = String(value || []);
  if (stringResult === '') {
    return undefined;
  } else if (stringResult === '0') {
    return [];
  } else {
    return value;
  }
};
