import { IGetBuildersResponse } from '../http';

type TNonNullableResponse = Required<{ [K in keyof IGetBuildersResponse]: NonNullable<IGetBuildersResponse[K]> }>;

export const getDataFromBuilderResponse = ({
  totalItems,
  totalPages,
  builderList,
}: IGetBuildersResponse): TNonNullableResponse => ({
  totalItems: totalItems || 0,
  totalPages: totalPages || 0,
  builderList: builderList || [],
});
