/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBuilderModel,
  IGetBuilderRequest,
  IMappers,
  IGetBuilderResponse,
  IGetBuilderResponseError,
  TGetBuilderResponse,
} from './types';

const defaultConfig: TGetBuilderModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v1/get-builder/',
  hostType: 'api',
} as TGetBuilderModel;

function createGetBuilderModel(parameters: IGetBuilderRequest): TGetBuilderModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBuilderOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBuilderRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBuilder<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBuilderOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetBuilderResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetBuilderModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBuilderResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBuilderResponseError);
    }
  }

  return { response, statusCode } as TGetBuilderResponse;
}

export { defaultConfig, createGetBuilderModel, fetchGetBuilder };
