import { IFatInfrastructureListItem } from '../types/fat';

const DISPLAY_TYPE_INFO = 'Блок с картинкой и расхлопом';
const DISPLAY_TYPE_IMAGE = 'Блок с крупной картинкой';

export const DISPLAY_TYPE_NAMES = {
  info: DISPLAY_TYPE_INFO,
  image: DISPLAY_TYPE_IMAGE,
};

export const DISPLAY_TYPE_DROPDOWN_VALUES = [
  { value: 'info', label: DISPLAY_TYPE_INFO },
  { value: 'image', label: DISPLAY_TYPE_IMAGE },
];

export const EMPTY_INFRASTRUCTURE: IFatInfrastructureListItem = {
  displayType: 'info',
  isEnabled: false,
};
