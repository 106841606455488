import { IThunkActionCreator } from '../../types/thunk';
import { IContractorCalltrackingListPageState } from '../../types/redux';
import { setContractorCallPopupStatus } from '../contractorCallPopup';
import { INITIAL_CONTRACTOR_POPUP, INITIAL_PLAYER_STATUS } from '../../constants';
import { setContractorPlayerStatus } from './actions';

// если открыли попап другого звонка со включенным аудио - сбрасываем в дефолт значения попапа и плеера
export function resetAfterSwitching(callId: number): IThunkActionCreator<IContractorCalltrackingListPageState> {
  return (dispatch, getState) => {
    const { callPopup, playerStatus } = getState();
    const isSwitched = callPopup.callId !== callId && !playerStatus.isPaused;

    if (isSwitched) {
      dispatch(setContractorCallPopupStatus(INITIAL_CONTRACTOR_POPUP));
      dispatch(setContractorPlayerStatus(INITIAL_PLAYER_STATUS));
    }
  };
}
