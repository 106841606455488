import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { EMPTY_INFRASTRUCTURE } from '../../../constants';

import { IFatIcon, IFatInfrastructureListItem } from '../../../types/fat';
import { FatInfrastructureModal } from '../../FatInfrastructureModal';
import { FatNewobjectCategorySetDataPreview } from '../../FatNewobjectCategorySetDataPreview';
import { ISortingItems, SortingComponent } from '../../SortingComponent';

import * as styles from './FatInfrastructureList.css';
import { Button } from 'react-bootstrap';
import { appendTemporaryKey } from 'shared/utils/sortable';
import { ISortable } from 'shared/types/sortable';

interface IFatInfrastructureListProps {
  onChange(infrastructureList: IFatInfrastructureListItem[]): void;
  list: IFatInfrastructureListItem[];
  fatIconList: IFatIcon[];
}

interface IFatInfrastructureListState {
  list: ISortable<IFatInfrastructureListItem>[];
  popupElementIndex?: number;
}

export class FatInfrastructureList extends React.Component<IFatInfrastructureListProps, IFatInfrastructureListState> {
  public state: IFatInfrastructureListState = {
    list: appendTemporaryKey(this.props.list || []),
  };

  public render() {
    const { fatIconList } = this.props;
    const { list, popupElementIndex } = this.state;

    const popupData = (typeof popupElementIndex === 'number' && list[popupElementIndex].item) || undefined;

    return (
      <div {...mergeStyles(styles['infrastructure'])}>
        <SortingComponent
          list={list}
          items={this.sortingItems}
          itemStyle={styles['infrastructure-wrapper']}
          onChange={this.onListChange}
        />
        <Button variant="success" type="button" onClick={this.addNewItem} disabled={list.length >= 6}>
          <i className="fas fa-plus" />
        </Button>
        {popupData && (
          <FatInfrastructureModal
            data={popupData}
            iconList={fatIconList}
            header="Блок супер премиум инфраструктуры"
            onClose={this.onClosePopup}
            onConfirm={this.onConfirmPopup}
            onRemove={this.onItemRemove}
            isInfrastructureList
          />
        )}
      </div>
    );
  }

  private onClosePopup = () => {
    this.setState({
      popupElementIndex: undefined,
    });
  };

  private onConfirmPopup = (data: IFatInfrastructureListItem) => {
    const { popupElementIndex, list } = this.state;
    if (typeof popupElementIndex !== 'number') {
      return;
    }

    const newList = list.slice();
    newList[popupElementIndex].item = data;

    this.setState({
      popupElementIndex: undefined,
    });
    this.onListChange(newList);
  };

  private onItemRemove = () => {
    const { popupElementIndex, list } = this.state;
    if (typeof popupElementIndex !== 'number') {
      return;
    }

    const currentElement = list[popupElementIndex];
    const newList = list.filter(el => el !== currentElement);

    this.setState({
      popupElementIndex: undefined,
    });

    this.onListChange(newList);
  };

  private addNewItem = () => {
    const { list } = this.state;

    const newList = [...list, ...appendTemporaryKey([EMPTY_INFRASTRUCTURE])];

    this.onListChange(newList);
  };

  private onItemClick = (index: number) => () => {
    this.setState({
      popupElementIndex: index,
    });
  };

  private onListChange = (data: ISortable<IFatInfrastructureListItem>[]) => {
    const sortedData = data.map((listItem, index) => ({
      ...listItem,
      item: { ...listItem.item, ordering: index },
    }));

    this.setState(
      {
        list: sortedData,
      },
      () => {
        this.props.onChange(sortedData.map(({ item }) => item));
      },
    );
  };

  private get sortingItems(): ISortingItems {
    const getFatIconUrl = (id?: number | null) => {
      const icon = this.props.fatIconList.find(i => i.id === id);

      return icon ? icon.url : undefined;
    };

    return this.state.list.reduce(
      (acc, { uuid, item }, index) => ({
        ...acc,
        [uuid]: (
          <FatNewobjectCategorySetDataPreview
            isInfrastructureList
            {...item}
            iconUrl={getFatIconUrl(item.iconId)}
            key={item.title || index}
            onClick={this.onItemClick(index)}
          />
        ),
      }),
      {} as ISortingItems,
    );
  }
}
