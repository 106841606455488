import { INewObjectCategorySet, IPhoto } from '../common/interfaces';

export function getFormattedNewobjectCategorySet(set: INewObjectCategorySet): INewObjectCategorySet {
  return Object.entries(set).reduce(
    (acc, [field, data]) => ({
      ...acc,
      [field]: data
        ? {
            ...data,
            gallery: {
              ...data.gallery,
              imageList: data.gallery.imageList.filter((image: IPhoto) => !!image.fullUrl),
            },
          }
        : null,
    }),
    {},
  );
}
