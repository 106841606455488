import {
  fetchUpdateVillage,
  IUpdateVillageRequest,
  IUpdateVillageResponse,
  IUpdateVillageResponseError,
} from '../repositories/countryside/v1/update-village';

import { createSimpleRequest } from './createSimpleRequest';

export const updateVillage = createSimpleRequest<
  IUpdateVillageRequest,
  IUpdateVillageResponse,
  IUpdateVillageResponseError
>('updateVillage', fetchUpdateVillage);
