// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IAdminGetConstructorCallHistoryRequest,
  TAdminGetConstructorCallHistoryModel,
  IMappers,
  TAdminGetConstructorCallHistoryResponse,
  IAdminGetConstructorCallHistoryResponse,
  IAdminGetConstructorCallHistoryResponseError,
} from './types';

export const defaultConfig: TAdminGetConstructorCallHistoryModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/get-constructor-call-history/',
} as TAdminGetConstructorCallHistoryModel;

export function createAdminGetConstructorCallHistoryModel(
  parameters: IAdminGetConstructorCallHistoryRequest,
): TAdminGetConstructorCallHistoryModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminGetConstructorCallHistoryOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IAdminGetConstructorCallHistoryRequest;
}

export async function fetchAdminGetConstructorCallHistory<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IAdminGetConstructorCallHistoryOptions<TResponse200, TResponse400>): Promise<
  TAdminGetConstructorCallHistoryResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createAdminGetConstructorCallHistoryModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminGetConstructorCallHistoryResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IAdminGetConstructorCallHistoryResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TAdminGetConstructorCallHistoryResponse;
}
