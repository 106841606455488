import * as React from 'react';
import { Form } from 'react-bootstrap';

export interface IDropdownValue<T = number | string> {
  value: T;
  label: string;
  muted?: boolean;
}

interface ISelectFieldProps {
  onChange(value: string | void): void;
  onClick?(e: React.MouseEvent): void;
  value: string | number;
  values: IDropdownValue[];
  className?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  canEmpty?: boolean;
  emptyText?: string;
  readOnly?: boolean;
  id?: string;
}

export const SelectField = ({
  onChange,
  onClick,
  value,
  values,
  className,
  label,
  required,
  disabled,
  canEmpty,
  emptyText,
  readOnly,
  id,
}: ISelectFieldProps) => (
  <Form.Group className={className}>
    <Form.Label>
      {label}&nbsp;
      {required && <span className="text-danger">*</span>}
    </Form.Label>
    <Form.Select
      disabled={disabled}
      value={value}
      onChange={e => !readOnly && onChange(e.currentTarget.value || undefined)}
      onClick={onClick}
      id={id}
    >
      {canEmpty && <option value="">{emptyText || 'не выбрано'}</option>}
      {values.map((selectValue, index) => (
        <option value={selectValue.value} key={index}>
          {selectValue.label}
        </option>
      ))}
    </Form.Select>
  </Form.Group>
);
