import {
  fetchGetBuilder,
  IGetBuilderRequest,
  IGetBuilderResponse,
  IGetBuilderResponseError,
} from '../../node/repositories/countryside/v1/get-builder';

import { createSimpleRequest } from './createSimpleRequest';

export const getBuilder = createSimpleRequest<IGetBuilderRequest, IGetBuilderResponse, IGetBuilderResponseError>(
  'getBuilder',
  fetchGetBuilder,
);
