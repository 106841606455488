import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  fetchGetRoads,
  IGetRoadsResponse,
  IGetRoadsResponseError,
  TGetRoadsResponse,
} from '../../node/repositories/countryside/v2/get-roads';
import { DC_REGIONS_IDS } from '../utils/geo';
import { getFormattedError } from '../utils';
import { isResponseContainsKey } from './helpers';

export async function getRoadList(httpApi: IHttpApi, logger: ILogger, regionId: number) {
  try {
    const parameters = { regionIds: [DC_REGIONS_IDS[regionId] || regionId] };
    const { response, statusCode }: TGetRoadsResponse = await fetchGetRoads({
      httpApi,
      parameters,
      config: {
        requestConfig: {
          headers: [['Content-Type', 'application/json']],
        },
      },
    });

    if (statusCode === 400 || isResponseContainsKey<IGetRoadsResponseError, IGetRoadsResponse>(response, 'errors')) {
      throw response;
    }

    return response.results;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getRoadList', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getRoadList' });

      throw formattedError;
    }

    throw error;
  }
}
