import { IConfig } from '@cian/config/shared';
import { mergePaths } from '@cian/http-api/shared';
import { ERoutes } from 'shared/common/types/routes';

export const fetchUrl = (url: string) => fetch(url, { method: 'GET', credentials: 'include', mode: 'no-cors' });

export const logOut = async (config?: IConfig, shouldReload: boolean = true) => {
  const authUrl = config && config.get<string>('countrysideLogoutUrl');
  const serviceAuthUrl = config && config.get<string>('countrysideServiceLogoutUrl');

  if (!authUrl || !serviceAuthUrl) {
    return;
  }
  const logoutCountryside = mergePaths(authUrl, '/api/users/logout');
  const logoutService = mergePaths(serviceAuthUrl, '/users/logoff');

  const logoutUrls = [logoutCountryside, logoutService];

  /* Получаем ссылки на логаут и разлогиниваемся */
  await Promise.all(logoutUrls.map(fetchUrl));

  if (shouldReload) {
    window.location.assign(ERoutes.Auth);
  }
};
