import { ICplBuilder, TCplListItem, TChangableCplField } from '../../../types';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { CplSettingsList } from '../CplSettingsList';

import * as styles from './CplSettingsInfo.css';

export interface ICplSettingsInfoProps {
  list: TCplListItem[];
  builder?: ICplBuilder;
  canEditBudget: boolean;
  canEditPlan: boolean;
  canEdit: boolean;
  period: IDatePeriod;
  managerId: number | undefined;
  onBlurField(id: number, name: keyof TChangableCplField, value: number | undefined): void;
}

export function CplSettingsInfo({
  list,
  builder,
  managerId,
  canEditBudget,
  canEditPlan,
  canEdit,
  period,
  onBlurField,
}: ICplSettingsInfoProps) {
  return (
    <div className={styles['container']}>
      <CplSettingsList
        list={list}
        canEditBudget={canEditBudget}
        canEditPlan={canEditPlan}
        canEdit={canEdit}
        onBlurField={onBlurField}
        period={period}
        builderId={builder ? builder.id : undefined}
        managerId={managerId}
      />
      {builder && builder.commentary && (
        <div className={styles['info']}>
          <div className={styles['info_item']}>
            <strong className={styles['info_item_title']}>Комментарий</strong>
            <p className={styles['info_item_description']}>{builder.commentary}</p>
          </div>
        </div>
      )}
    </div>
  );
}
