import { mergeStyles } from '@cian/utils';
import { MaskedTextField } from '../masked_text_field';
import * as styles from './PhoneInput.css';

export interface IPhoneInputProps {
  value?: string | null;
  label?: string;
  isMobile?: boolean;
  required?: boolean;
  invalid?: boolean;
  onChange(value: string): void;
  readOnly?: boolean;
  className?: string;
  htmlId?: string;
}

export function PhoneInput({
  value,
  label = 'Телефон',
  isMobile,
  required,
  invalid,
  onChange,
  readOnly,
  className,
  htmlId,
}: IPhoneInputProps) {
  const { className: containerClassName } = mergeStyles(
    styles['container'],
    isMobile && styles['container--mobile'],
    value && styles['container--dirty'],
    className,
  );

  return (
    <MaskedTextField
      value={(value || '').replace(/^7/, '+7')}
      label={label}
      mask="+7 999 999 9999"
      onChange={onChange}
      containerClass={containerClassName}
      required={required}
      invalid={invalid}
      inputClass={styles['input']}
      readOnly={readOnly}
      htmlId={htmlId}
    />
  );
}
