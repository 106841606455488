/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IAccountSchema } from '../../entities/account/AccountSchema';

export type TUpdateBuilderModel = IModel<IUpdateBuilderRequest, TUpdateBuilderResponse>;

export interface IUpdateBuilderRequest {
  /** Email привязанного акканута **/
  accountList: IAccountSchema[];
  /** Комментарий для сотрудников **/
  commentary?: string | null;
  /** Описание **/
  description?: string | null;
  /** Email-ы застройщика **/
  emailList?: string[] | null;
  /** ID **/
  id: number;
  /** URL логотипа **/
  logoUrl?: string | null;
  /** Статус модерации **/
  moderationStatus?: EModerationStatus | null;
  /** Название **/
  name: string;
  /** Телефон **/
  phone?: string | null;
  /** Регионы, в которых работает застройщик **/
  regionList?: number[] | null;
  /** Рабочее время **/
  workTime?: string | null;
}

export type TUpdateBuilderResponse = IUpdateBuilderResponse400 | IUpdateBuilderResponse200 | IUpdateBuilderResponse204;

export interface IUpdateBuilderResponse400 extends IModelResponse<IUpdateBuilderResponseError> {
  statusCode: 400;
}

export interface IUpdateBuilderResponse200 extends IModelResponse<IUpdateBuilderResponse> {
  statusCode: 200;
}

export interface IUpdateBuilderResponse204 extends IModelResponse<{}> {
  statusCode: 204;
}

export interface IUpdateBuilderResponseError {
  errors: any[];
  message: string;
}

export interface IUpdateBuilderResponse {}

export type TResponses = IUpdateBuilderResponseError | IUpdateBuilderResponse | {};

export enum EModerationStatus {
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
}

export interface IMappers<TResponse400, TResponse200, TResponse204> {
  400(response: IUpdateBuilderResponseError): TResponse400;
  200(response: IUpdateBuilderResponse): TResponse200;
  204(): TResponse204;
}
