import * as React from 'react';

import { formatDateTime } from '../../utils/datetime';

import * as styles from './index.css';
import { ChangelogModerationStatusNames, DEFAULT_LOG_COUNT } from '../../utils/constants';
import { ICommonChangelog } from '../../types';
import { Table } from 'react-bootstrap';

interface IProps {
  changelog?: ICommonChangelog[];
}

interface IState {
  showAll: boolean;
}

export class Changelog extends React.PureComponent<IProps, IState> {
  public state = { showAll: false };

  public render() {
    const { changelog = [] } = this.props;
    const { showAll } = this.state;

    if (!changelog.length) {
      return null;
    }

    const showedChangelog = showAll ? changelog : changelog.slice(0, DEFAULT_LOG_COUNT);

    return (
      <div className={styles['container']}>
        <Table striped bordered>
          <tbody>
            <tr>
              <th>Дата изменения</th>
              <th>Пользователь</th>
              <th>Список измененных полей</th>
              <th>Статус</th>
            </tr>
            {showedChangelog.map((item: ICommonChangelog) => (
              <tr key={item.id}>
                <td>{item.created ? formatDateTime(item.created, '+0300') : 'нет данных'}</td>
                <td>{item.userEmail}</td>
                <td>{item.changes || ''}</td>
                <td>{ChangelogModerationStatusNames[item.itemModerationStatus]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!showAll && changelog.length > DEFAULT_LOG_COUNT && (
          <a href="javascript:void(0)" onClick={this.handleShowAllClick}>
            Показать всю историю
          </a>
        )}
      </div>
    );
  }

  private handleShowAllClick = () => {
    this.setState({ showAll: true });
  };
}
