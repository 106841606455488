import { IStyleConfig, mergeStyles } from '@cian/utils';

import { IGalleryItem } from '../../../common/interfaces';
import { Gallery } from '../../gallery';
import { IUpdateFatVillageFeatureSchema } from '../../../repositories/countryside/entities/schemas/UpdateFatVillageFeatureSchema';

export interface IFatFeatureList {
  fatCardEnabled?: boolean;
  fatFeatureList: IUpdateFatVillageFeatureSchema[];
  readOnly?: boolean;
  containerStyle?: IStyleConfig;
  titleStyle?: IStyleConfig;
  onChange(items: IGalleryItem[]): void;
  onDescriptionChange(index: number, description: string): void;
}

export function FatFeatureList({
  containerStyle,
  titleStyle,
  fatCardEnabled,
  fatFeatureList,
  onChange,
  onDescriptionChange,
  readOnly,
}: IFatFeatureList) {
  if (!fatCardEnabled) {
    return null;
  }
  const items: IGalleryItem[] = fatFeatureList.map(({ id, text, imageUrl }) => ({
    description: text,
    id,
    url: imageUrl,
  }));

  return (
    <div {...mergeStyles(containerStyle)}>
      <h2 {...mergeStyles(titleStyle)}>Чем интересен этот поселок (супер премиум особенности)</h2>
      <Gallery
        areInputsDisplayed
        useValidation={false}
        images={items}
        maxCount={10}
        minWidth={210}
        minHeight={140}
        onChange={onChange}
        maxInputLength={40}
        onInputChange={onDescriptionChange}
        readOnly={readOnly}
        required
      />
    </div>
  );
}
