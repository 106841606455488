import { mergeStyles } from '@cian/utils';
import { DISPLAY_TYPE_NAMES } from '../../constants';
import { TFatInfrastructureListItemDisplayType } from '../../types/fat';

import * as styles from './FatNewobjectCategorySetDataPreview.css';
import { Card } from 'react-bootstrap';

export interface IFatInfrastructurePreviewProps {
  title?: string | null;
  description?: string | null;
  iconUrl?: string | null;
  isEnabled?: boolean | null;
  displayType?: TFatInfrastructureListItemDisplayType | null;
  imageUrl?: string | null;
  isInfrastructureList?: boolean;
  onClick(): void;
}

export const FatNewobjectCategorySetDataPreview = ({
  title,
  description,
  iconUrl,
  displayType,
  isInfrastructureList,
  imageUrl,
  isEnabled,
  onClick,
}: IFatInfrastructurePreviewProps) => {
  const previewTitle = [title, !isEnabled && 'Неактивно'].filter(Boolean).join(' | ');

  return (
    <Card
      {...mergeStyles(
        'mb-4',
        styles['container'],
        !isEnabled && styles['container--inactive'],
        isInfrastructureList && styles['container--wide'],
      )}
    >
      <Card.Header className={styles['heading']}>
        <Card.Title className={styles['panel_title']} as="h3">
          {iconUrl && <img src={iconUrl} alt="" />}
          <span className={styles['title']}>{previewTitle}</span>
        </Card.Title>
        <button className={styles['edit_button']} onClick={onClick}>
          <i className="fas fa-pen" />
        </button>
      </Card.Header>
      {description && (
        <Card.Body className={styles['body']}>
          {imageUrl && <img className={styles['body_image']} src={imageUrl} alt="" />}
          {description}
        </Card.Body>
      )}
      {displayType && title && <div className={styles['display_type']}>{DISPLAY_TYPE_NAMES[displayType]}</div>}
    </Card>
  );
};
