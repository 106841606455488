import { IConstructorCallHistoryItemSchema } from 'shared/repositories/countryside-calltracking/entities/constructor/ConstructorCallHistoryItemSchema';
import { ICallHistoryItem } from 'shared/types';

export const prepareConstructorCallHistory = (history: IConstructorCallHistoryItemSchema[]): ICallHistoryItem[] => {
  return history.map(({ callType, changedAt, changerType, userEmail }) => ({
    changeDate: changedAt,
    userName: userEmail,
    userType: changerType,
    value: callType,
  }));
};
