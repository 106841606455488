/* eslint-disable max-lines */
import * as React from 'react';

import { AppContext } from '../../../AppContext';

import { IAdminGetCallsRequest } from '../../../repositories/countryside-calltracking/v1/admin-get-calls';
import { getRawPhone } from '../../../utils/helpers';
import { PhoneInput } from '../../PhoneInput/PhoneInput';
import { SelectSearchField } from '../../select_search_field';
import { TextField } from '../../text_field';

import { SelectField } from '../../SelectField';
import { getValueForTextField } from '../../../utils/formatting';
import { NumberRangeField } from '../../villages/village_form/number_range_field';
import { MAX_PRICE } from '../../../utils/constants';
import {
  CALL_STATUS_DROPDOWN_VALUES,
  CALL_TYPE_BY_BUILDER_FILTER_DROPDOWN_VALUES,
  CALL_TYPE_BY_COMMENT,
  CALL_TYPE_BY_FINAL_FILTER_DROPDOWN_VALUES,
} from '../../../constants/calltracking';

import { BuilderCalltrackingTariffNames, IRegion, CalltrackingTariffNames } from '../../../common/interfaces';
import { MultiselectorField } from '../../multiselector_field';
import { CustomSelectField } from '../../CustomSelectField';
import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { getSelectOptionsFromObject } from '../../../utils/getSelectOptionsFromObject';
import { formatDayPickerDate, getFormattedDate, getShortISODateTime } from '../../../utils/datetime';
import { DatePicker } from '../../DatePicker';

import * as styles from './CalltrackingFilters.css';
import { Button, Col, Row } from 'react-bootstrap';

interface ICalltrackingFormProps {
  filters: IAdminGetCallsRequest;
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  onApplyFilters(filters: IAdminGetCallsRequest): void;
  isCalltrackingManager: boolean;
}

interface ICalltrackingFormState {
  filters: IAdminGetCallsRequest;
}

export class CalltrackingFilters extends React.Component<ICalltrackingFormProps, ICalltrackingFormState> {
  public static contextType = AppContext;
  public state = {
    filters: { ...this.props.filters },
  };

  public render() {
    const {
      filters: {
        builderId,
        builderName,
        callDateFrom,
        callDateTo,
        callId,
        durationFrom,
        durationTo,
        status,
        typeByBuilder,
        finalType,
        villageId,
        villageName,
        regionIdList,
        managerIdList,
        sourceRegion,
        sourcePhone,
        tariffList,
        commentFilter,
      },
    } = this.state;

    const { regionList, managerList, isCalltrackingManager } = this.props;

    const regions = regionList.map(region => ({ value: region.id, label: region.displayName }));
    const managers = [
      { label: 'Без менеджера', value: 0 },
      ...managerList.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
    ];

    const tariffNamesList = isCalltrackingManager ? CalltrackingTariffNames : BuilderCalltrackingTariffNames;

    return (
      <div className={styles['container']}>
        <Row>
          <Col md={2} className="mb-3">
            <TextField value={callId} label="ID звонка" onChange={this.handleNumberFieldCange('callId')} />
          </Col>
          <Col md={2} className="mb-3">
            <TextField value={builderId} label="ID застройщика" onChange={this.handleNumberFieldCange('builderId')} />
          </Col>
          <Col md={3} className="mb-3">
            <TextField
              value={builderName}
              label="Название застройщика"
              onChange={this.handleFieldChange('builderName')}
            />
          </Col>
          <Col md={2} className="mb-3">
            <TextField value={villageId} label="ID посёлка" onChange={this.handleNumberFieldCange('villageId')} />
          </Col>
          <Col md={3} className="mb-3">
            <TextField value={villageName} label="Название посёлка" onChange={this.handleFieldChange('villageName')} />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <SelectField
              value={getValueForTextField(status)}
              values={CALL_STATUS_DROPDOWN_VALUES}
              label="Статус звонка"
              onChange={this.handleFieldChange('status')}
            />
          </Col>
          <Col md={4} className="mb-3">
            <NumberRangeField
              label="Длительность звонка, с"
              minValue={durationFrom || undefined}
              maxValue={durationTo || undefined}
              max={MAX_PRICE}
              onChangeMin={this.handleFieldChange('durationFrom')}
              onChangeMax={this.handleFieldChange('durationTo')}
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={getFormattedDate(callDateFrom)}
              onChange={this.handleCallDateFromChange}
              label="Дата звонка, от"
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={getFormattedDate(callDateTo)}
              onChange={this.handleCallDateToChange}
              label="Дата звонка, до"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-3">
            <MultiselectorField
              label="Регионы КП"
              value={regionIdList || []}
              values={regions}
              onChange={this.handleMultiSelectChange('regionIdList')}
              invalid={false}
              showSelectButtons
            />
          </Col>
          <Col md={3} className="mb-3">
            <SelectSearchField
              label="Регион звонка"
              value={sourceRegion || undefined}
              values={regions}
              onChange={this.handleFieldChange('sourceRegion')}
              noEmpty={false}
            />
          </Col>
          {isCalltrackingManager && (
            <Col md={3} className="mb-3">
              <MultiselectorField
                label="Менеджеры"
                value={managerIdList || []}
                values={managers}
                onChange={this.handleManagerIdListChange}
                invalid={false}
              />
            </Col>
          )}
          <Col md={3} className="mb-3">
            <PhoneInput value={sourcePhone} onChange={this.handlePhoneFieldChange('sourcePhone')} />
          </Col>
        </Row>
        <Row>
          <Col md={2} className="mb-3">
            <CustomSelectField
              value={getValueForTextField(typeByBuilder)}
              values={CALL_TYPE_BY_BUILDER_FILTER_DROPDOWN_VALUES}
              label="Оценка (клиент)"
              onChange={this.handleFieldChange('typeByBuilder')}
            />
          </Col>
          <Col md={2} className="mb-3">
            <CustomSelectField
              value={getValueForTextField(finalType)}
              values={CALL_TYPE_BY_FINAL_FILTER_DROPDOWN_VALUES}
              label="Оценка (итог)"
              onChange={this.handleFieldChange('finalType')}
            />
          </Col>
          <Col md={3} className="mb-3">
            <MultiselectorField
              label="Тариф КП"
              value={tariffList || []}
              values={getSelectOptionsFromObject(tariffNamesList)}
              onChange={this.handleTariffListChange}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <CustomSelectField
              label="Комментарий"
              onChange={this.handleFieldChange('commentFilter')}
              value={getValueForTextField(commentFilter)}
              values={CALL_TYPE_BY_COMMENT}
            />
          </Col>
          <Col md={12} className="text-end">
            <Button variant="primary" className={styles['btn-filter']} onClick={this.handleFilterButtonClick}>
              Отфильтровать
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  private handleCallDateFromChange = (value: Date | null) => {
    const { filters } = this.state;
    const callDateFrom = value instanceof Date ? getShortISODateTime(formatDayPickerDate(value)) : undefined;

    this.setState({
      filters: {
        ...filters,
        callDateFrom,
      },
    });
  };

  private handleCallDateToChange = (value: Date | null) => {
    const { filters } = this.state;
    const callDateTo = value instanceof Date ? getShortISODateTime(formatDayPickerDate(value), true) : undefined;

    this.setState({
      filters: {
        ...filters,
        callDateTo,
      },
    });
  };

  private handleFieldChange = (name: string) => (value: string | number | void) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value || undefined,
      },
    });

  private handlePhoneFieldChange = (name: string) => (value: string) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: getRawPhone(value),
      },
    });

  private handleNumberFieldCange = (name: string) => (value: string) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: parseInt(value, 10) || undefined,
      },
    });

  private setMultiselectFilter = (name: string, value: Array<string | number> | void) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });

  private handleManagerIdListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length > 1) {
      if (value[value.length - 1] === 0) {
        newValue = [0];
      } else if (value.includes(0)) {
        newValue = value.filter(Boolean);
      }
    }

    this.setMultiselectFilter('managerIdList', newValue);
  };

  private handleTariffListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length < 1) {
      newValue = undefined;
    }

    this.setMultiselectFilter('tariffList', newValue);
  };

  private handleMultiSelectChange = (name: string) => (value: Array<string | number> | void) => {
    this.setMultiselectFilter(name, value);
  };

  private handleFilterButtonClick = () =>
    this.props.onApplyFilters({
      ...this.state.filters,
      page: 1,
    });
}
