import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import * as styles from './CalltrackingPopupItem.css';

interface ICalltrackingListPopupItem {
  label: string;
}

export const CalltrackingPopupItem: React.FC<React.PropsWithChildren<ICalltrackingListPopupItem>> = ({
  label,
  children,
}) => (
  <Row className={styles['row']}>
    <Col sm={4}>{label}</Col>
    <Col sm={8}>{children}</Col>
  </Row>
);

CalltrackingPopupItem.displayName = 'CalltrackingPopupItem';
