/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBuilderListModel,
  IGetBuilderListRequest,
  IMappers,
  IGetBuilderListResponse,
  IGetBuilderListResponseError,
  TGetBuilderListResponse,
} from './types';

const defaultConfig: TGetBuilderListModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/get-builder-list/',
  hostType: 'api',
} as TGetBuilderListModel;

function createGetBuilderListModel(parameters: IGetBuilderListRequest): TGetBuilderListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBuilderListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBuilderListRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBuilderList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBuilderListOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetBuilderListResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetBuilderListModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBuilderListResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBuilderListResponseError);
    }
  }

  return { response, statusCode } as TGetBuilderListResponse;
}

export { defaultConfig, createGetBuilderListModel, fetchGetBuilderList };
