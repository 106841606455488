import { combineReducers } from 'redux';
import { cplDataReducer } from '../../reducers/cplDataReducer';
import { cplFiltersReducer } from '../../reducers/cplFiltersReducer';
import { cplHistoryReducer } from '../../reducers/cplHistoryReducer';
import { ICplBuilder, ICplCommonData } from '../../types';
import { ICplSettingsPageState, TReduxActions } from '../../types/redux';
import { createSimpleReducer } from './createSimpleReducer';
import { IManagerForListSchema } from '../../../node/repositories/countryside/entities/schemas/ManagerForListSchema';

export function combineCplSettingsReducers() {
  return combineReducers<ICplSettingsPageState, TReduxActions>({
    builder: createSimpleReducer({} as ICplBuilder),
    roles: createSimpleReducer([]),
    common: createSimpleReducer({} as ICplCommonData),
    deviceType: createSimpleReducer('desktop'),
    cplData: cplDataReducer,
    filters: cplFiltersReducer,
    cplHistory: cplHistoryReducer,
    managerList: createSimpleReducer([] as IManagerForListSchema[]),
  });
}
