import { IRequestErrorBody } from '../types';

export function errorMessageMapperCreator(errors: { [key: string]: string }, defaultMessage: string) {
  return (error: IRequestErrorBody) => {
    if (!error.errors) {
      return defaultMessage;
    }

    const { message } = error.errors[0];
    const errorKey = Object.keys(errors).find(error => !!message.match(new RegExp(error)));

    return errorKey ? message.replace(new RegExp(errorKey), errors[errorKey]) : defaultMessage;
  };
}
