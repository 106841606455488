import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { ESortingDirection, ISortingItems, SortingComponent } from '../../SortingComponent';
import { SelectFeatureItem } from './SelectFeatureItem';
import { FeatureStatusTypes, IFeature, ISelectedFeature, TFeatureModel } from '../../../common/interfaces';
import { SelectFeatureModal } from './SelectFeatureModal';

import * as styles from './SelectFeature.css';

interface ISelectFeatureProps {
  selectedFeatures: ISelectedFeature[];
  onChange(features: ISelectedFeature[]): void;
  possibleFeatureList: IFeature[];
  readOnly?: boolean;
}

interface ISelectFeatureState {
  isModalVisible: boolean;
  featureToEditIndex: number | undefined;
}

export class SelectFeature extends React.Component<ISelectFeatureProps, ISelectFeatureState> {
  public state = {
    featureToEditIndex: undefined,
    isModalVisible: false,
  };

  public render() {
    const { selectedFeatures, possibleFeatureList, onChange, readOnly } = this.props;
    const { featureToEditIndex, isModalVisible } = this.state;

    const filteredFeatures = possibleFeatureList.filter(item => item.status === FeatureStatusTypes.Enabled);
    const arePossibleFeatures = Array.isArray(filteredFeatures) && filteredFeatures.length > 0;
    const isMaxFeaturesCount = selectedFeatures.length >= 10;
    const isContainerFull = !arePossibleFeatures || isMaxFeaturesCount;

    return (
      <div {...mergeStyles(isContainerFull && styles['full'])}>
        <h1>Особенности</h1>
        {!arePossibleFeatures && (
          <p className={styles['danger']}>
            Нельзя добавить новые особенности, потому что они отключены или вовсе не созданы. Чтобы добавить&nbsp;
            новые, включите или создайте их в разделе <a href={'/features'}>«Особенности»</a>.
          </p>
        )}
        {isMaxFeaturesCount && (
          <p className={styles['danger']}>Вы добавили максимум особенностей — 10 штук. Больше нельзя.</p>
        )}
        <div className={styles['features-container']}>
          <SortingComponent
            direction={ESortingDirection.Horizontal}
            list={selectedFeatures}
            items={this.sortingItems}
            itemStyle={styles['feature-wrapper']}
            containerStyle={styles['features-wrapper']}
            onChange={onChange}
            readOnly={readOnly}
          />
          {!readOnly && (
            <label className={styles['label']}>
              <input className={styles['select-input']} onClick={this.handleAdd} />+
            </label>
          )}
        </div>
        {isModalVisible && (
          <SelectFeatureModal
            onConfirm={onChange}
            onClose={this.handleModalClose}
            featureToEditIndex={featureToEditIndex}
            selectedFeatures={selectedFeatures}
            possibleFeatures={filteredFeatures}
            readOnly={readOnly}
          />
        )}
      </div>
    );
  }

  private handleAdd = () => this.setState({ isModalVisible: true });

  private handleEdit = (featureIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    this.setState({
      featureToEditIndex: featureIndex,
      isModalVisible: true,
    });
  };

  private handleRemove = (itemIndex: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const { selectedFeatures } = this.props;

    selectedFeatures.splice(itemIndex, 1);

    this.props.onChange(selectedFeatures);
  };

  private handleModalClose = () => {
    this.setState({
      featureToEditIndex: undefined,
      isModalVisible: false,
    });
  };

  private getFeatureModel = ({ id, description }: ISelectedFeature): TFeatureModel | null => {
    const feature = this.props.possibleFeatureList.find(feature => feature.id === id);
    if (!feature) {
      return null;
    }

    return {
      ...feature,
      description,
    };
  };

  private getItemProps = (index: number) => ({
    onEdit: this.handleEdit(index),
    onRemove: this.handleRemove(index),
    readOnly: this.props.readOnly,
  });

  private get sortingItems(): ISortingItems {
    return this.props.selectedFeatures.reduce(
      (acc, feature, index) => ({
        ...acc,
        [feature.id]: <SelectFeatureItem feature={this.getFeatureModel(feature)} {...this.getItemProps(index)} />,
      }),
      {},
    );
  }
}
