import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import { createGeocodeModel, IGeocodeRequest, IGeocodeResponse } from '../../node/repositories/countryside/v1/geocode';

import { TLocationType } from '../common/interfaces';
import { getFormattedError } from '../utils';

export enum LOCATION_TYPE_IDS {
  DISTRICT = 141,
  SETTLEMENT = 210,
}

export const allowedLocationTypeIds = [LOCATION_TYPE_IDS.DISTRICT, LOCATION_TYPE_IDS.SETTLEMENT];

export interface IGeoCode {
  geoType: TLocationType;
  id: number;
  isLocality: boolean;
  locationTypeId: LOCATION_TYPE_IDS;
}

export interface IGeoCoordinates {
  lat?: number | null;
  lng?: number | null;
}

export interface IGeoCodeResponse {
  isParsed: boolean;
  text: string;
  details: IGeoCode[];
  locationPath: number[];
  geo: IGeoCoordinates;
}

export async function getGeocode(
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IGeocodeRequest,
  timeout?: number,
): Promise<IGeocodeResponse> {
  try {
    const requestModel = createGeocodeModel(parameters);

    const { response, statusCode } = await httpApi.fetch(requestModel, {
      ecnhancersConfig: { timeout },
    });

    if (statusCode !== 200 || 'errors' in response) {
      throw response;
    }

    return response;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getGeocode', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getGeocode' });

      throw formattedError;
    }

    throw error;
  }
}
