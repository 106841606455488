import { useEffect, useState } from 'react';

import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { fetchCallRecord } from '../http';

export interface IUseGetCallURLHook {
  callId?: number | null;
  httpApi: IHttpApi;
  logger: ILogger;
}

export interface ICallRecordState {
  url?: string | null;
  isLoading?: boolean;
}

export const DEFAULT_CALL_RECORD_STATE: ICallRecordState = {
  url: undefined,
};

export function useGetCallRecordHook(params: IUseGetCallURLHook) {
  const { callId, httpApi, logger } = params;

  const [callRecord, setCallRecord] = useState(DEFAULT_CALL_RECORD_STATE);

  useEffect(() => {
    setCallRecord({});
  }, [callId]);

  const getCallRecord = async () => {
    if (!callId) {
      return;
    }

    setCallRecord({ isLoading: true });

    try {
      const { url } = await fetchCallRecord(httpApi, logger, { callId });

      setCallRecord({ url });
    } catch (e) {
      setCallRecord({
        url: null,
      });
    }
  };

  return { callRecord, getCallRecord };
}
