/* eslint-disable max-lines */
import * as React from 'react';
import { IGetBuilderChangelogResponse } from '../../../../node/repositories/countryside/v1/get-builder-changelog';
import { AppContext } from '../../../AppContext';

import { commonChangelogMapBuilder } from '../../../common/helpers';

import { IAlert, IBuilder, IEmail, IRegion, ModerationStatusNames } from '../../../common/interfaces';
import { createBuilder, updateBuilder, uploadImage } from '../../../http';
import { EModerationStatus } from '../../../repositories/countryside/entities/schemas/BuilderForListSchema';
import { ICreateBuilderRequest } from '../../../repositories/countryside/v1/create-builder';
import { IUpdateBuilderRequest } from '../../../repositories/countryside/v1/update-builder';
import { EDIT_BUILDER_ROUTE } from '../../../routes';
import { DEFAULT_ALERT } from '../../../utils/constants';
import { Alert } from '../../Alert';
import { Changelog } from '../../Changelog';
import { IImage, ImageSelector } from '../../image_selector';
import { MultiselectorField } from '../../multiselector_field';
import { SelectField } from '../../SelectField';
import { TextField } from '../../text_field';
import { TextAreaField } from '../../textarea_field';
import { BuilderAccounts } from '../BuilderAccounts';
import { BuilderEmails } from '../BuilderEmails';

import * as styles from './BuilderForm.css';
import { errorMessageMapper } from './helpers';
import { Button, Col, Row } from 'react-bootstrap';
import { PhoneInput } from 'shared/components/PhoneInput/PhoneInput';

interface IProps {
  regionList: IRegion[];
  builder?: IBuilder;
  readOnly?: boolean;
  builderChangelog?: IGetBuilderChangelogResponse;
}

interface IState {
  cianUserList: string;
  form: IBuilder;
  image: IImage;
  imageLoading: boolean;
  alert: IAlert;
  useValidation: boolean;
}

export class BuilderForm extends React.Component<IProps, IState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public constructor(props: IProps) {
    super(props);

    const defaultBuilder: IBuilder = {
      commentary: '',
      description: '',
      id: -1,
      logoUrl: '',
      moderationStatus: EModerationStatus.Draft,
      name: '',
      phone: '',
      regionList: [],
      workTime: '',
      accountList: [],
      emailList: [],
    };

    const { builder = defaultBuilder } = this.props;

    this.state = {
      alert: DEFAULT_ALERT,
      cianUserList: builder.cianUserList ? builder.cianUserList.join(',') : '',
      form: builder,
      image: { url: builder.logoUrl },
      imageLoading: false,
      useValidation: false,
    };
  }

  public componentDidUpdate(_: IProps, prevState: IState) {
    if (!prevState.alert.isVisible && this.state.alert.isVisible) {
      setTimeout(() => {
        this.setState({
          alert: DEFAULT_ALERT,
        });
      }, 2000);
    }
  }

  public render() {
    const isCreation = !this.props.builder;
    const { readOnly, builderChangelog: { builderChangelog = [] } = { builderChangelog: [] } } = this.props;
    const { form, image, imageLoading, alert, useValidation, cianUserList } = this.state;
    const { name, phone, moderationStatus, description, regionList, workTime, commentary } = form;

    const moderationStatuses = Object.keys(ModerationStatusNames).map(key => ({
      value: key,
      label: ModerationStatusNames[key],
    }));
    const regions = this.props.regionList.map(region => ({ value: region.id, label: region.displayName }));
    const title = isCreation ? 'Создание застройщика' : 'Изменение застройщика';

    return (
      <div className={styles['container']}>
        {alert.isVisible && (
          <Alert
            onClick={() =>
              this.setState({
                alert: { ...this.state.alert, isVisible: false },
              })
            }
            text={alert.text}
            type={alert.type}
          />
        )}
        <h4 className="text-center">{title}</h4>
        <Row>
          <Col md={4} className="mb-3">
            <TextField
              value={name}
              maxLength={255}
              label="Название"
              readOnly={readOnly}
              htmlId="builder-name"
              required
              invalid={useValidation && !name}
              onChange={(value: string) => this.handleInputChange('name', value)}
            />
          </Col>
          <Col md={4} className="mb-3">
            <PhoneInput
              value={(phone || '').replace(/^7/, '+7')}
              label="Телефон"
              onChange={(value: string) => this.handleInputChange('phone', value)}
              readOnly={readOnly}
              htmlId="builder-phone"
            />
          </Col>
          <Col md={4} className="mb-3">
            <SelectField
              value={moderationStatus as string}
              values={moderationStatuses}
              label="Статус"
              required
              onChange={(value: string) => this.handleInputChange('moderationStatus', value)}
              readOnly={readOnly}
              id="builder-status"
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <TextAreaField
              value={description || ''}
              label="Описание"
              required
              invalid={useValidation && !description}
              onChange={(value: string) => this.handleInputChange('description', value)}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <MultiselectorField
              value={regionList || []}
              values={regions}
              label="Регионы"
              required
              invalid={useValidation && !(regionList || []).length}
              onChange={(value: number[]) => this.handleInputChange('regionList', value)}
              readOnly={readOnly}
              className="mb-3"
            />
            <TextField
              value={cianUserList}
              maxLength={255}
              label="Cian User ID"
              readOnly
              containerClass="mb-3"
              onChange={this.handleCianUserListChange}
              htmlId="builder-cian-user-id"
            />
            <TextAreaField
              value={workTime || ''}
              inputClass={styles['work_time']}
              maxLength={255}
              label="Рабочее время"
              placeholder="Например, с 9:00 до 18:00"
              onChange={(value: string) => this.handleInputChange('workTime', value)}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <ImageSelector
              value={image}
              onChange={this.handleImageChange}
              minWidth={10}
              minHeight={10}
              readOnly={readOnly}
              label="Логотип"
            />
          </Col>
          <Col md={8} className="mb-3">
            <TextAreaField
              value={commentary ? commentary : ''}
              onChange={(value: string) => this.handleInputChange('commentary', value)}
              placeholder="Не отображается в пользовательской части сайта"
              readOnly={readOnly}
              label="Внутреннее примечание"
              htmlId="builder-inner-note"
            />
          </Col>
        </Row>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Список email-адресов для доступа к КТ</h2>
          <BuilderAccounts accountList={form.accountList} onChange={this.handleAccountListChange} readOnly={readOnly} />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Список личных email-адресов</h2>
          <BuilderEmails emailList={form.emailList} onChange={this.handleEmailListChange} readOnly={readOnly} />
        </div>
        <Row>
          <Col md={12} className="text-end">
            {!readOnly && (
              <Button
                disabled={imageLoading}
                className={styles['save-button']}
                onClick={this.sendBuilder}
                variant="success"
              >
                Сохранить
              </Button>
            )}
          </Col>
        </Row>
        {!!builderChangelog && builderChangelog.length > 0 && (
          <Row className={styles['component-block']}>
            <h2 className={styles['component-block__header']}>История изменения застройщика</h2>
            <Changelog changelog={commonChangelogMapBuilder(builderChangelog)} />
          </Row>
        )}
      </div>
    );
  }

  private sendBuilder = async () => {
    const { image, form } = this.state;
    const { name, description, regionList, phone } = form;
    const { httpApi, logger } = this.context;

    const isCreation = !this.props.builder;

    if (!name || !description || !regionList || !regionList.length) {
      this.setState({ useValidation: true });

      return;
    }

    const builder: IBuilder = {
      ...this.state.form,
      logoUrl: image.url || '',
      phone: (phone || '').replace(/[^0-9]+/g, ''),
    };

    if (isCreation) {
      try {
        const { id } = await createBuilder(httpApi, logger, builder as ICreateBuilderRequest);

        window.location.href = EDIT_BUILDER_ROUTE.replace(':id', id.toString());
      } catch (error) {
        this.setState({
          alert: {
            isVisible: true,
            text: errorMessageMapper(error),
            type: 'danger',
          },
        });
      }
    } else {
      try {
        await updateBuilder(httpApi, logger, builder as IUpdateBuilderRequest);

        this.setState({
          alert: {
            isVisible: true,
            text: 'Застройщик был успешно обновлен',
            type: 'success',
          },
        });
      } catch (error) {
        this.setState({
          alert: {
            isVisible: true,
            text: errorMessageMapper(error),
            type: 'danger',
          },
        });
      }
    }
  };

  private handleInputChange = (name: string, value: null | number | string | number[]) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  };

  private handleImageChange = async (image: IImage) => {
    const { httpApi, logger } = this.context;

    if (image.base64) {
      this.setState({
        imageLoading: true,
      });

      try {
        const value = await uploadImage(httpApi, logger, {
          base64: image.base64,
        });

        this.setState({
          image: value,
          imageLoading: false,
        });
      } catch (error) {
        this.setState({
          image: {},
          imageLoading: false,
        });
      }
    } else {
      this.setState({
        image: {},
        imageLoading: false,
      });
    }
  };

  private handleAccountListChange = (accountList: IEmail[]) =>
    this.setState({
      form: { ...this.state.form, accountList },
    });

  private handleEmailListChange = (emailList: string[]) =>
    this.setState({
      form: { ...this.state.form, emailList },
    });

  private handleCianUserListChange = (value: string) => {
    const cleanValue = value.replace(/[^\d,]/g, '');

    this.setState({ cianUserList: cleanValue });
    this.handleInputChange('cianUserList', this.parseCianUserListFromString(cleanValue));
  };

  private parseCianUserListFromString = (value: string) => {
    if (!value) {
      return null;
    }

    return value.split(',').filter(Boolean).map(Number);
  };
}
