import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { CommuteNames, CommuteTypes, IRoad, IRoadInfo, roadDistanceVillageValues } from '../../../common/interfaces';
import { getCommuteTypeByRegionId } from '../../../utils/geo';
import { IDropdownValue } from '../../SelectField';
import { ISortingItems, SortingComponent } from '../../SortingComponent';
import { VillageRoad } from './VillageRoad';

import * as styles from './VillageRoads.css';
import { Button, Container } from 'react-bootstrap';

const MAX_ROAD_COUNT = 3;

export interface IVillageRoadsProps {
  roadInfoList: IRoadInfo[];
  roads: IRoad[];
  regionId: number;
  readOnly?: boolean;
  onChange(newValue: IRoadInfo[], isDataValid: boolean): void;
  onInit(startValue: IRoadInfo[], isDataValid: boolean): void;
}

interface IVillageRoadsState {
  data: IRoadInfo[];
  isDataValid: boolean;
}

export class VillageRoads extends React.Component<IVillageRoadsProps, IVillageRoadsState> {
  public state = {
    data: this.props.roadInfoList,
    isDataValid: isRoadInfoListValid(this.props.roadInfoList),
  };

  public componentDidMount() {
    const { data, isDataValid } = this.state;

    this.props.onInit(data, isDataValid);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IVillageRoadsProps) {
    this.setState({
      data: nextProps.roadInfoList,
      isDataValid: isRoadInfoListValid(nextProps.roadInfoList),
    });
  }

  public render() {
    const { readOnly } = this.props;
    const { data, isDataValid } = this.state;

    return (
      <Container fluid {...mergeStyles(styles['container'], !isDataValid && styles['container--invalid'])}>
        {!isDataValid && (
          <div className={styles['invalid-message']}>
            Все обязательные поля в этой секции должны быть заполнены, количество шоссе должно быть не более{' '}
            {MAX_ROAD_COUNT}
          </div>
        )}

        <div>
          <ul className={styles['village-roads']}>
            <SortingComponent
              list={this.props.roadInfoList}
              items={this.sortingItems}
              onChange={this.handleRoadsChanged}
              itemStyle={styles['sorting_item']}
              readOnly={readOnly}
            />
          </ul>
          {!readOnly && (
            <Button
              variant="success"
              className={styles['add-btn']}
              type="button"
              onClick={this.addRoadInfoItem}
              disabled={data.length === MAX_ROAD_COUNT}
            >
              Добавить шоссе
            </Button>
          )}
          {data.length === MAX_ROAD_COUNT && (
            <span className={styles['invalid-message']}>Добавлено максимальное количество шоссе</span>
          )}
        </div>
      </Container>
    );
  }

  private getRoadLabel = (road: IRoad): string =>
    road.directionName ? `${road.directionName}: ${road.name}` : road.name;

  private get distanceCityLabel(): string {
    const commuteType = getCommuteTypeByRegionId(this.props.regionId);
    const commuteText = commuteType === CommuteTypes.None ? 'центра' : CommuteNames[commuteType as string];

    return `Расстояние до ${commuteText}, км`;
  }

  private handleDeleteItemClick = (index: number) => () => {
    const { data } = this.state;

    data.splice(index, 1);

    const isDataValid = isRoadInfoListValid(data);
    this.props.onChange(data, isDataValid);

    this.setState({
      data,
      isDataValid,
    });
  };

  private handleRoadChange = (itemIndex: number) => (value: string) => {
    const { data } = this.state;

    data[itemIndex] = {
      ...data[itemIndex],
      roadId: Number(value),
    };

    const isDataValid = isRoadInfoListValid(data);
    this.props.onChange(data, isDataValid);

    this.setState({
      data,
      isDataValid,
    });
  };

  private handleRoadsChanged = (data: IRoadInfo[]) => {
    const isDataValid = isRoadInfoListValid(data);
    this.setState(
      {
        data,
        isDataValid,
      },
      () => this.props.onChange(data, isDataValid),
    );
  };

  private handleDistanceVillageChange = (itemIndex: number) => (value: string) => {
    const { data } = this.state;
    const numberValue = Number(value);

    if (!roadDistanceVillageValues.includes(numberValue)) {
      return;
    }

    data[itemIndex] = {
      ...data[itemIndex],
      distanceVillage: numberValue,
    };

    const isDataValid = isRoadInfoListValid(data);
    this.props.onChange(data, isDataValid);

    this.setState({
      data,
      isDataValid,
    });
  };

  private handleDistanceCityChange = (itemIndex: number) => (value: string) => {
    const { data } = this.state;

    if (!data || value.length > 5) {
      return;
    }
    const distanceCity = parseFloat(value);
    data[itemIndex] = {
      ...data[itemIndex],
      distanceCity: distanceCity > 0 ? distanceCity : 0,
    };

    const isDataValid = isRoadInfoListValid(data);
    this.props.onChange(data, isDataValid);

    this.setState({
      data,
      isDataValid,
    });
  };

  private addRoadInfoItem = () => {
    const { data } = this.state;

    if (data.length >= MAX_ROAD_COUNT) {
      return;
    }

    const newItem = getRoadInfoTemplate();
    const newData = [...data, newItem];

    const isDataValid = isRoadInfoListValid(newData);
    this.props.onChange(newData, isDataValid);

    this.setState({
      data: newData,
      isDataValid,
    });
  };

  private get roadValues(): IDropdownValue[] {
    return this.props.roads.map(road => ({
      value: road.id,
      label: this.getRoadLabel(road),
    }));
  }

  private getItemProps = (index: number) => ({
    roadValues: this.roadValues,
    distanceCityLabel: this.distanceCityLabel,
    onRoadChange: this.handleRoadChange(index),
    onDistanceCityChange: this.handleDistanceCityChange(index),
    onDistanceVillageChange: this.handleDistanceVillageChange(index),
    onRemove: this.handleDeleteItemClick(index),
    readOnly: this.props.readOnly,
  });

  private get sortingItems(): ISortingItems {
    return this.props.roadInfoList.reduce(
      (acc, item, index) => ({
        ...acc,
        [item.roadId]: <VillageRoad item={item} {...this.getItemProps(index)} />,
      }),
      {},
    );
  }
}

export function getRoadInfoTemplate(): IRoadInfo {
  return {
    distanceCity: 0,
    distanceVillage: Math.max(...roadDistanceVillageValues),
    roadId: 0,
  };
}

function isRoadInfoListValid(data: IRoadInfo[]): boolean {
  return data.length <= MAX_ROAD_COUNT && data.every(isRoadInfoValid);
}

function isRoadInfoValid(data: IRoadInfo): boolean {
  return !(!data.roadId || !data.distanceCity || !data.distanceVillage);
}
