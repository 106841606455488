import * as React from 'react';

import { mapTypeToLabel, mapTypeToIcon, mapButtonClass } from '../../constants/calltracking';
import { getMapValue } from '../../utils/formatting';
import { mergeStyles } from '@cian/utils';

import * as styles from './CalltrackingButton.css';
import { Button } from 'react-bootstrap';
import { useEventCallback } from '@cian/react-utils';

type TValue = 'nonTarget' | 'notSpecified' | 'target' | null;

interface ICalltrackingButtonProps {
  value?: TValue;
  isHandheld?: boolean;
  onlyIcon?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement>, value?: TValue): void;
}

export function CalltrackingButton(props: ICalltrackingButtonProps) {
  const { value, isHandheld, onlyIcon, disabled, selected = false, onClick } = props;

  const handleClick = useEventCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e, value);
    }
  });

  return (
    <Button
      tabIndex={selected ? -1 : undefined}
      aria-pressed={selected}
      variant={getMapValue(mapButtonClass, value)}
      {...mergeStyles(
        isHandheld && styles['hh-btn'],
        disabled && styles['btn-disabled'],
        selected && styles['btn-selected'],
      )}
      title={onlyIcon ? getMapValue(mapTypeToLabel, value) : undefined}
      onClick={handleClick}
    >
      {!onlyIcon && getMapValue(mapTypeToLabel, value)}
      <span {...mergeStyles(getMapValue(mapTypeToIcon, value))} />
    </Button>
  );
}
