/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSetPlanModel,
  ISetPlanRequest,
  IMappers,
  ISetPlanResponse,
  ISetPlanResponseError,
  TSetPlanResponse,
} from './types';

const defaultConfig: TSetPlanModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/set-plan/',
  hostType: 'api',
} as TSetPlanModel;

function createSetPlanModel(parameters: ISetPlanRequest): TSetPlanModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISetPlanOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISetPlanRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSetPlan<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISetPlanOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSetPlanResponse> {
  const { response, statusCode } = await httpApi.fetch(createSetPlanModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISetPlanResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISetPlanResponseError);
    }
  }

  return { response, statusCode } as TSetPlanResponse;
}

export { defaultConfig, createSetPlanModel, fetchSetPlan };
