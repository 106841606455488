import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { ISortingItems, SortingComponent } from '../../SortingComponent';

import * as styles from './BuilderEmails.css';
import { BuilderEmailItem } from '../BuilderEmailItem';
import { Button } from 'react-bootstrap';
import { appendTemporaryKey } from 'shared/utils/sortable';
import { ISortable } from 'shared/types/sortable';

interface IBuilderEmailsProps {
  onChange(emailList: string[]): void;
  emailList?: string[] | null;
  readOnly?: boolean;
}

interface IBuilderEmailsState {
  isDataValid: boolean;
  data: ISortable<string>[];
}

export class BuilderEmails extends React.Component<IBuilderEmailsProps, IBuilderEmailsState> {
  public state = {
    data: appendTemporaryKey(this.props.emailList || ['']),
    isDataValid: true,
  };

  public render() {
    const { readOnly } = this.props;
    const { data } = this.state;

    return (
      <div {...mergeStyles(styles['email'])}>
        <SortingComponent
          list={data}
          items={this.sortingItems}
          itemStyle={styles['email-wrapper']}
          onChange={this.onListChange}
          readOnly={readOnly}
        />
        {!readOnly && (
          <Button variant="success" type="button" onClick={this.addEmail}>
            <i className="fas fa-plus" />
          </Button>
        )}
      </div>
    );
  }

  private addEmail = () =>
    this.setState({
      data: [...this.state.data, ...appendTemporaryKey([''])],
    });

  private handleChange = (data: ISortable<string>[]) => {
    this.props.onChange(data.map(({ item }) => item).filter(x => !!x));
  };

  private removeEmail = (index: number) => {
    const data = [...this.state.data];
    data.splice(index, 1);
    this.setState({ data: data.length ? data : appendTemporaryKey(['']) }, () => this.handleChange(data));
  };

  private onEmailChange = (email: string, index: number) => {
    const data = [...this.state.data];
    data[index].item = email;

    this.onListChange(data);
  };

  private onListChange = (data: ISortable<string>[]) => this.setState({ data }, () => this.handleChange(data));

  private get emailItemProps() {
    return {
      onChange: this.onEmailChange,
      onRemove: this.removeEmail,
      readOnly: this.props.readOnly,
    };
  }

  private get sortingItems(): ISortingItems {
    return this.state.data.reduce(
      (acc, { item, uuid }, index) => ({
        ...acc,
        [uuid]: (
          <BuilderEmailItem
            email={item}
            itemIndex={index}
            itemLabel={`Email адрес пользователя ${index + 1}`}
            {...this.emailItemProps}
          />
        ),
      }),
      {},
    );
  }
}
