import { IDropdownValue } from '../components/SelectField';
import { ICplSettingsPageState } from '../types/redux';

export function getDropdownValuesFromManagerList({ managerList }: ICplSettingsPageState): IDropdownValue[] {
  return managerList
    ? managerList.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      }))
    : [];
}
