import {
  EStatus,
  ETypeByBuilder,
  EFinalType,
  IAdminGetCallsRequest,
  ETariffList,
  ECommentFilter,
} from '../repositories/countryside-calltracking/v1/admin-get-calls/types';
import { CALLTRACKING_DOWNLOAD_ROUTE, CALLTRACKING_ROUTE } from '../routes';
import { DEFAULT_CALLTRACKING_FILTERS } from '../constants/calltracking';
import { IVillageFilter, ISort } from '../common/interfaces';
import { getShortDateFromISO } from './datetime';

export const parseToNumber = (text = '') => {
  const parsedNumber = parseInt(text, 10);

  return Number.isFinite(parsedNumber) ? parsedNumber : undefined;
};

export const parseToEnum = <T extends string>(text: string, allowedValues: T[]): T | undefined => {
  return allowedValues.includes(text as T) ? (text as T) : undefined;
};

export const removeTrailingSlash = (text: string) => (text.endsWith('/') ? text.slice(0, -1) : text);

export const parseToEnumList = <T extends string>(text: T, allowedValues: T[]): T[] | undefined => {
  if (!text) {
    return undefined;
  }

  const result = removeTrailingSlash(text)
    .split(',')
    .filter((item: T) => allowedValues.includes(item));

  return result.length ? (result as T[]) : undefined;
};

export const parseToNumbersArray = (text: string): number[] | undefined => {
  if (!text) {
    return undefined;
  }

  const strings = text.split(',');
  const result: number[] = [];

  strings.forEach(element => {
    const parsedNumber = parseToNumber(element);

    if (parsedNumber !== undefined) {
      result.push(parsedNumber);
    }
  });

  return result.length ? result : undefined;
};

export const parseDateTime = (date: string) => (Number(new Date(date)) ? date : undefined);

export const parseFiltersFromQueryString = (query: { [key: string]: string }): IAdminGetCallsRequest => ({
  builderId: parseToNumber(query['builderId']),
  builderName: query['builderName'],
  callDateFrom: parseDateTime(query['callDateFrom']),
  callDateTo: parseDateTime(query['callDateTo']),
  callId: parseToNumber(query['callId']),
  durationFrom: parseToNumber(query['durationFrom']),
  durationTo: parseToNumber(query['durationTo']),
  page: parseToNumber(query['page']) || DEFAULT_CALLTRACKING_FILTERS.page,
  pageSize: parseToNumber(query['pageSize']) || DEFAULT_CALLTRACKING_FILTERS.pageSize,
  regionIdList: parseToNumbersArray(query['regionIdList']),
  managerIdList: parseToNumbersArray(query['managerIdList']),
  status: parseToEnum(query['status'], Object.values(EStatus)),
  typeByBuilder: parseToEnum(query['typeByBuilder'], Object.values(ETypeByBuilder)),
  finalType: parseToEnum(query['finalType'], Object.values(EFinalType)),
  tariffList: parseToEnumList<ETariffList>(query['tariffList'] as ETariffList, Object.values(ETariffList)),
  villageId: parseToNumber(query['villageId']),
  villageName: query['villageName'],
  sourcePhone: query['sourcePhone'],
  sourceRegion: parseToNumber(query['sourceRegion']),
  commentFilter: parseToEnum(query['commentFilter'], Object.values(ECommentFilter)),
});

const incorrectFilter = (value?: string | number | null | boolean | ISort | number[]) => value !== 0 && !value;

export const stringifyFilters = (filters: IAdminGetCallsRequest): string =>
  Object.keys(filters)
    .map((key: keyof IAdminGetCallsRequest) => {
      let value = filters[key];

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      if (incorrectFilter(value) || DEFAULT_CALLTRACKING_FILTERS[key] === value) {
        return null;
      }

      return `${key}=${encodeURIComponent(value as number | string)}`;
    })
    .filter(Boolean)
    .sort()
    .join('&');

export const stringifyVillageFilters = (filters: IVillageFilter): string =>
  Object.keys(filters)
    .map((key: keyof IVillageFilter) => {
      let value = filters[key];

      if (key === 'finishFrom') {
        return `${key}=${getShortDateFromISO(filters.finishFrom)}`;
      }

      if (key === 'finishTo') {
        return `${key}=${getShortDateFromISO(filters.finishTo)}`;
      }

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      if (incorrectFilter(value)) {
        return null;
      }

      return `${key}=${value}`;
    })
    .filter(Boolean)
    .sort()
    .join('&');

export const generateQueryString = (filters: IAdminGetCallsRequest) => {
  const qs = stringifyFilters(filters);

  return qs ? `?${qs}` : '';
};

export const getQueryStringByPage = (filters: IAdminGetCallsRequest) => (page: number) =>
  stringifyFilters({
    ...filters,
    page,
  });

export const changeQueryString = (filters: IAdminGetCallsRequest) =>
  window.history.replaceState({}, document.title, `${CALLTRACKING_ROUTE}/${generateQueryString(filters)}`);

export const getCalltrackingDownloadReportUrl = (filters: IAdminGetCallsRequest) =>
  `${CALLTRACKING_DOWNLOAD_ROUTE.replace(':file', 'report')}/${generateQueryString(filters)}`;
