/* eslint-disable no-case-declarations */
/* eslint-disable max-lines */
import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';
import { getHttpApi } from '@cian/http-api/browser';

import { TDevice } from '@cian/utils';

import {
  IBuilder,
  IBuilderFilter,
  IBuilderListItem,
  IBuilderNameListItem,
  IFeature,
  IGetVillageChangelogResponseSchema,
  IPagination,
  IRegion,
  IRoad,
  IVillage,
  IVillageFilter,
} from '../../../shared/common/interfaces';

import { IContractorCalltrackingListPageState, ICplSettingsPageState } from '../../../shared/types/redux';

import { IRailwayListData } from '../../../shared/http';
import { IVillageForListSchema } from '../../../shared/repositories/countryside/entities/schemas/VillageForListSchema';
import {
  IAdminGetCallsRequest,
  IAdminGetCallsResponse,
} from '../../../shared/repositories/countryside-calltracking/v1/admin-get-calls';
import { IGetBuilderChangelogResponse } from '../../../node/repositories/countryside/v1/get-builder-changelog';
import { EUserRoles } from '../../../shared/utils/permissions';
import { IManagerForListSchema } from '../../../shared/repositories/countryside/entities/schemas/ManagerForListSchema';
import { IFatIcon } from '../../../shared/types/fat';

import { App } from '../../../shared';
import { BuilderForm } from '../../../shared/components/builders/builder_form';
import { Villages } from '../../../shared/components/villages';
import { VillageCreateForm } from '../../../shared/components/villages/village_create';
import { VillageEditForm } from '../../../shared/components/villages/village_edit';
import { Builders } from '../../../shared/components/builders';
import { Calltracking } from '../../../shared/components/Calltracking';

import {
  combineContractorCalltrackingListPageReducers,
  combineCplSettingsReducers,
  createStore,
} from '../../../shared/utils/redux';

import { CplSettingsPage } from '../../../shared/components/CplSettingsPage';
import { ContractorCalltrackingListPage } from '../../../shared/components/ContractorCalltracking/ContractorCalltrackingListPage';

import * as PageRoutes from '../../../shared/routes';

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'whatwg-fetch';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

export function renderOldApplication() {
  const config = getConfig();
  const httpApi = getHttpApi();
  const logger = getLogger();

  const rootElement = document.getElementById('countryside-admin-frontend');

  if (!rootElement) {
    throw new Error('Unable to find element #countryside-admin-frontend');
  }

  let component: React.ReactNode;

  const route = config.get<string>('route');
  const activeMenuItem = config.get<string>('activeMenuItem') || '';

  if (!activeMenuItem && route !== PageRoutes.AUTH_ROUTE) {
    throw new Error('attribute activeMenuItem is required');
  }

  const browserApiUrl = config.getStrict<string>('browserApiUrl');
  const builder = config.get<IBuilder>('builder');
  const builderNames = config.get<IBuilderNameListItem[]>('builderNames');
  const filters = config.get<IVillageFilter | IBuilderFilter>('filters');
  const possibleFeatureList = config.get<IFeature[]>('possibleFeatureList');
  const pagination = config.get<IPagination>('pagination');
  const regionList = config.get<IRegion[]>('regionList');
  const roads = config.get<IRoad[]>('roads');
  const railway = config.get<IRailwayListData>('railway');
  const totalItems = config.get<number>('totalItems') || 0;
  const village = config.get<IVillage>('village');
  const villageList = config.get<IVillageForListSchema[]>('villageList');
  const callsList = config.get<IAdminGetCallsResponse>('callsList');
  const callsFilters = config.get<IAdminGetCallsRequest>('callsFilters') || {};
  const readOnly = !!config.get<boolean>('readOnly');
  const villageChangelog = config.get<IGetVillageChangelogResponseSchema>('villageChangelog');
  const builderChangelog = config.get<IGetBuilderChangelogResponse>('builderChangelog');
  const countrysideRoadRegionsArray = config.get<number[]>('countrysideRoadRegionsArray');
  const countrysideStationRegionsArray = config.get<number[]>('countrysideStationRegionsArray');
  const roles = config.get<EUserRoles[]>('roles') || [];
  const autoplayCplRecordsEnabled = !!config.get<boolean>('autoplayCplRecordsEnabled');
  const managerList = config.get<IManagerForListSchema[]>('managerList');
  const fatIconList = config.get<IFatIcon[]>('fatIconList');
  const calltrackingApiUrl = config.get<string>('countryside-calltracking.browserUrl');
  const deviceType = config.get<TDevice>('deviceType') || 'desktop';
  const fatCardEnabled = !!config.get<boolean>('fatCardEnabled');
  const cplEnabled = !!config.get<boolean>('cplEnabled');

  switch (route) {
    case PageRoutes.EDIT_BUILDER_ROUTE:
      if (!regionList || !builder || !builderChangelog) {
        throw new Error('regionList/builder/builderChangelog are required');
      }

      component = (
        <BuilderForm
          regionList={regionList}
          builder={builder}
          readOnly={readOnly}
          builderChangelog={builderChangelog}
        />
      );
      break;
    case PageRoutes.CREATE_BUILDER_ROUTE:
      if (!regionList) {
        throw new Error('regionList is required');
      }
      if (readOnly) {
        throw new Error('No access to this page');
      }

      component = <BuilderForm regionList={regionList} />;
      break;
    case PageRoutes.VILLAGES_ROUTE:
      if (!filters || !pagination || !regionList || !villageList || !browserApiUrl || !managerList) {
        throw new Error('filters/pagination/regionList/villageList/browserApiUrl/managerList are required');
      }

      component = (
        <Villages
          filter={filters}
          pagination={pagination}
          apiUrl={browserApiUrl}
          regionList={regionList}
          totalItems={totalItems}
          managerList={managerList}
          villageList={villageList}
          readOnly={readOnly}
          roles={roles}
        />
      );
      break;
    case PageRoutes.CREATE_VILLAGE_ROUTE:
      if (!builderNames) {
        throw new Error('builderNames is required');
      }
      if (readOnly) {
        throw new Error('No access to this page');
      }
      if (!managerList) {
        throw new Error('managerList is required');
      }

      component = (
        <VillageCreateForm builderNames={builderNames} managerList={managerList} fatCardEnabled={fatCardEnabled} />
      );
      break;
    case PageRoutes.EDIT_VILLAGE_ROUTE:
      if (
        !builderNames ||
        !village ||
        !possibleFeatureList ||
        !countrysideRoadRegionsArray ||
        !countrysideStationRegionsArray ||
        !villageChangelog ||
        !railway ||
        !roads ||
        !managerList ||
        !fatIconList
      ) {
        throw new Error(
          'builderNames/village/possibleFeatureList/countrysideRoadRegionsArray/' +
            'countrysideStationRegionsArray/villageChangelog/railway/roads/managerList/fatIconList are required',
        );
      }
      component = (
        <VillageEditForm
          builderNames={builderNames}
          managerList={managerList}
          roads={roads}
          railway={railway}
          village={village}
          villageChangelog={villageChangelog}
          possibleFeatureList={possibleFeatureList}
          countrysideRoadRegionsArray={countrysideRoadRegionsArray}
          countrysideStationRegionsArray={countrysideStationRegionsArray}
          readOnly={readOnly}
          fatIconList={fatIconList}
          fatCardEnabled={fatCardEnabled}
          cplEnabled={cplEnabled}
        />
      );
      break;
    case PageRoutes.BUILDERS_ROUTE:
      const builderList = config.get<IBuilderListItem[]>('builderList');
      if (!builderList || !pagination || !filters || !regionList || !totalItems) {
        throw new Error('builderList/pagination/filters/regionList/totalItems are required');
      }

      component = (
        <Builders
          builderList={builderList}
          pagination={pagination}
          filter={filters}
          regionList={regionList}
          totalItems={totalItems}
          readOnly={readOnly}
        />
      );
      break;
    case PageRoutes.CALLTRACKING_ROUTE:
      if (!regionList || !calltrackingApiUrl || !managerList) {
        throw new Error('regionList/calltrackingApiUrl are required');
      }

      component = (
        <Calltracking
          apiUrl={calltrackingApiUrl}
          callsList={callsList}
          callsFilters={callsFilters}
          regionList={regionList}
          managerList={managerList}
          roles={roles}
          deviceType={deviceType}
          autoplayCplRecordsEnabled={autoplayCplRecordsEnabled}
        />
      );
      break;
    case PageRoutes.CPL_SETTINGS_ROUTE:
      const cplSettingsPageState = createStore<ICplSettingsPageState>({
        state: config.getStrict<ICplSettingsPageState>('state'),
        config,
        httpApi,
        logger,
        combinedReducers: combineCplSettingsReducers(),
      });

      component = <CplSettingsPage store={cplSettingsPageState} />;
      break;
    case PageRoutes.CONTRACTOR_CALLTRACKING_ROUTE:
      const contractorCalltrackingState = createStore<IContractorCalltrackingListPageState>({
        state: config.getStrict<IContractorCalltrackingListPageState>('state'),
        config,
        httpApi,
        logger,
        combinedReducers: combineContractorCalltrackingListPageReducers(),
      });

      component = <ContractorCalltrackingListPage store={contractorCalltrackingState} />;
      break;
    default:
      component = <div />;
  }

  hydrateRoot(
    rootElement,
    <App
      activeMenuItem={activeMenuItem}
      httpApi={httpApi}
      logger={logger}
      component={component}
      config={config}
      roles={roles}
    />,
  );
}
