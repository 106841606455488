import { IBuilderChangelogPresenterSchema } from '../../node/repositories/countryside/entities/builder_changelog/BuilderChangelogPresenterSchema';
import { ICommonChangelog } from '../types';
import { IVillageChangelogSchema } from './interfaces';

export function commonChangelogMapBuilder(builderChangelog: IBuilderChangelogPresenterSchema[]): ICommonChangelog[] {
  return builderChangelog.map(item => {
    const { builderId: itemId, builderModerationStatus: itemModerationStatus, ...rest } = item;

    return { ...rest, itemId, itemModerationStatus };
  });
}

export function commonChangelogMapVillage(villageChangelog: IVillageChangelogSchema[]): ICommonChangelog[] {
  return villageChangelog.map(item => {
    const { villageId: itemId, villageModerationStatus: itemModerationStatus, ...rest } = item;

    return { ...rest, itemId, itemModerationStatus };
  });
}
