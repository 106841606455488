/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSetBudgetModel,
  ISetBudgetRequest,
  IMappers,
  ISetBudgetResponse,
  ISetBudgetResponseError,
  TSetBudgetResponse,
} from './types';

const defaultConfig: TSetBudgetModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/set-budget/',
  hostType: 'api',
} as TSetBudgetModel;

function createSetBudgetModel(parameters: ISetBudgetRequest): TSetBudgetModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISetBudgetOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISetBudgetRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSetBudget<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISetBudgetOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSetBudgetResponse> {
  const { response, statusCode } = await httpApi.fetch(createSetBudgetModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISetBudgetResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISetBudgetResponseError);
    }
  }

  return { response, statusCode } as TSetBudgetResponse;
}

export { defaultConfig, createSetBudgetModel, fetchSetBudget };
