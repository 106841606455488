import { prepareCplSettings } from '../../../node/mappers';
import { getCplSettings } from '../../http';
import { ICplSettingsPageState } from '../../types/redux';
import { IThunkActionCreator } from '../../types/thunk';
import { getDisplayError } from '../../utils/getDisplayError';
import { setCplSettingsSelectedVillages } from '../cplFilters';
import { updateCplHistory } from '../cplHistory';
import { setCplData } from './actions';
import { NotificationManager } from '../../utils/showNotification';

// thunk-action для обновления данных CPL

export function updateCplData(isManagerChanged?: boolean): IThunkActionCreator<ICplSettingsPageState> {
  return async (dispatch, getState, { httpApi, logger }) => {
    const {
      common: { builderId },
      filters: { villages, period, managerId },
    } = getState();

    try {
      const rawData = await getCplSettings(
        httpApi,
        logger,
        {
          builderId,
          managerId,
          period,
        },
        true,
      );
      const data = prepareCplSettings(rawData);

      dispatch(setCplData(data));

      const newVillages = data.settings.map(item => item.villageId);

      if (isManagerChanged) {
        dispatch(setCplSettingsSelectedVillages(newVillages));
      } else {
        // Выделение всех новых КП, если с бэка не пришло таких, которые выбраны на текущий момент
        const selectedNewVillages = villages.filter(item => newVillages.includes(item));

        dispatch(setCplSettingsSelectedVillages(selectedNewVillages));
        if (!villages.length && newVillages.length) {
          dispatch(setCplSettingsSelectedVillages(newVillages));
        }
      }

      dispatch(updateCplHistory());
    } catch (e) {
      dispatch(
        setCplData({
          settings: [],
        }),
      );

      const displayError = getDisplayError(e);
      NotificationManager.error(displayError);
    }
  };
}
