import { GenericError, IErrorDetails } from '@cian/peperrors/shared';

export class ResponseError extends GenericError {
  //Ругается что не покрыта ;
  /* istanbul ignore next */
  public constructor(configuration: { domain: string; message?: string; details?: IErrorDetails }) {
    super({
      ...configuration,
      message: configuration.message || 'Неизвестная ошибка',
      type: 'ResponseError',
    });

    Object.setPrototypeOf(this, ResponseError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
