/* eslint-disable react/jsx-no-target-blank */
import { numberToPrettyString } from '@cian/utils';

import { TCplListItem, TChangableCplField } from '../../../types';
import { getCplSettingsToCalltrackingUrl, calculateCplFields, getSummaryOfCplList } from '../../../utils';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { WithTitle } from '../../WIthTitle';
import { EditableField } from '../../EditableField';

import * as styles from './CplSettingsList.css';
import { Table } from 'react-bootstrap';

export interface ICplSettingsListProps {
  list: TCplListItem[];
  canEdit: boolean;
  canEditBudget: boolean;
  canEditPlan: boolean;
  period: IDatePeriod;
  builderId: number | undefined;
  managerId: number | undefined;
  onBlurField(id: number, name: keyof TChangableCplField, value: number | undefined): void;
}

export function CplSettingsList({
  list,
  canEdit,
  canEditBudget,
  canEditPlan,
  period,
  managerId,
  builderId,
  onBlurField,
}: ICplSettingsListProps) {
  if (!list.length) {
    return (
      <div className={styles['container']}>
        <strong>По заданным параметрам нет информации</strong>
      </div>
    );
  }

  const summary = getSummaryOfCplList(list);

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th rowSpan={3}>Клиент</th>
          <th rowSpan={3}>КП</th>
          <th rowSpan={3}>CPL, ₽</th>
          <th colSpan={3}>Звонки (платные)</th>
          <th rowSpan={3}>Звонки (другие)</th>
          <th colSpan={3}>Бюджет, ₽</th>
          <th rowSpan={2}>Менеджер</th>
        </tr>
        <tr>
          <th>План</th>
          <th>Получено</th>
          <th>Остаток</th>
          <th>Всего</th>
          <th>Потрачено</th>
          <th>Остаток</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles['sum_row']}>
          <td>Всего</td>
          <td />
          <td />
          <td>{numberToPrettyString(summary.planSum)}</td>
          <td>
            <a target="_blank" href={getCplSettingsToCalltrackingUrl(builderId, null, period, managerId)}>
              {numberToPrettyString(summary.chargeableCallsCountSum)}
            </a>
          </td>
          <td>{numberToPrettyString(summary.callsBalanceSum)}</td>
          <td>{numberToPrettyString(summary.nonChargeableCallsCountSum)}</td>
          <td>{numberToPrettyString(summary.budgetSum)}</td>
          <td>{numberToPrettyString(summary.budgetSpentSum)}</td>
          <td>{numberToPrettyString(summary.budgetBalanceSum)}</td>
          <td />
        </tr>
        {list.map(item => {
          const { callsBalance, budgetSpent, budgetBalance } = calculateCplFields(item);

          return (
            <tr key={item.id}>
              <td>{item.builderName}</td>
              <td>
                <WithTitle title={`ID: ${item.villageId}`}>{item.villageName}</WithTitle>
              </td>
              <td>
                <EditableField
                  value={item.cpl}
                  canEdit={canEdit}
                  onBlur={value => {
                    onBlurField(item.id, 'cpl', value);
                  }}
                />
              </td>
              <td>
                <EditableField
                  value={item.plan}
                  canEdit={canEditPlan}
                  onBlur={value => {
                    onBlurField(item.id, 'plan', value);
                  }}
                />
              </td>
              <td>
                <a target="_blank" href={getCplSettingsToCalltrackingUrl(item.builderId, item.villageId, period)}>
                  {numberToPrettyString(item.chargeableCallsCount)}
                </a>
              </td>
              <td>{numberToPrettyString(callsBalance)}</td>
              <td>{numberToPrettyString(item.nonChargeableCallsCount)}</td>
              <td>
                <EditableField
                  value={item.budget}
                  canEdit={canEditBudget}
                  onBlur={value => onBlurField(item.id, 'budget', value)}
                />
              </td>
              <td>{numberToPrettyString(budgetSpent)}</td>
              <td>{numberToPrettyString(budgetBalance)}</td>
              <td>
                {item.manager && (
                  <WithTitle title={`${item.manager.firstName} ${item.manager.lastName}`}>
                    {item.manager.email}
                  </WithTitle>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
