import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { IVideo } from '../../../common/interfaces';
import { VillageVideoItem } from './VillageVideoItem';
import { ISortingItems, SortingComponent } from '../../SortingComponent';

import * as styles from './VillageVideos.css';
import { Button } from 'react-bootstrap';
import { ISortable } from 'shared/types/sortable';
import { appendTemporaryKey } from 'shared/utils/sortable';

export interface IVillageVideosProps {
  onChange(videoList: IVideo[]): void;
  videoList: IVideo[];
  readOnly?: boolean;
}

interface IVillageVideosState {
  isDataValid: boolean;
  data: ISortable<IVideo>[];
}

export class VillageVideos extends React.Component<IVillageVideosProps, IVillageVideosState> {
  public state = {
    data: appendTemporaryKey(this.props.videoList || [this.emptyVideo]),
    isDataValid: true,
  };

  private get emptyVideo(): IVideo {
    return { url: '' };
  }

  public render() {
    const { readOnly } = this.props;
    const { data } = this.state;

    return (
      <div {...mergeStyles(styles['video'])}>
        <SortingComponent
          list={data}
          items={this.sortingItems}
          itemStyle={styles['video-wrapper']}
          onChange={this.onListChange}
          readOnly={readOnly}
        />
        {!readOnly && (
          <Button variant="success" type="button" onClick={this.addVideo}>
            <i className="fas fa-plus" />
          </Button>
        )}
      </div>
    );
  }

  private addVideo = () => this.onListChange([...this.state.data, ...appendTemporaryKey([this.emptyVideo])]);

  private removeVideo = (index: number) => {
    const data = [...this.state.data];
    data.splice(index, 1);

    this.onListChange(data.length ? data : appendTemporaryKey([this.emptyVideo]));
  };

  private onUrlChange = (url: string, index: number) => {
    const data = [...this.state.data];
    data[index].item.url = url;

    this.onListChange(data);
  };

  private onListChange = (data: ISortable<IVideo>[]) =>
    this.setState({ data }, () => this.props.onChange(data.map(({ item }) => item).filter(x => !!x.url)));

  private get videoItemProps() {
    return {
      onChange: this.onUrlChange,
      onRemove: this.removeVideo,
      readOnly: this.props.readOnly,
    };
  }

  private get sortingItems(): ISortingItems {
    return this.state.data.reduce(
      (acc, { uuid, item }, index) => ({
        ...acc,
        [uuid]: <VillageVideoItem item={item} itemIndex={index} {...this.videoItemProps} />,
      }),
      {},
    );
  }
}
