import moment from 'moment';

export const DATE_MASK = 'YYYY-MM-DD';
export const DATE_TIME_MASK = DATE_MASK + 'THH:mm:ss';

export const DAY_WITHOUT_ONE_SECOND = 24 * 60 * 60 - 1;

export const formatDateTime = (
  isoDate: string | null | undefined,
  utcOffset?: number | string,
  format: string = 'DD.MM.YYYY HH:mm:ss',
): string => {
  if (isoDate) {
    const offset = utcOffset ? utcOffset : moment.parseZone(isoDate).utcOffset();

    return moment.utc(isoDate).utcOffset(offset).format(format);
  }

  return '';
};

export const dateToISO = (date?: string): string => {
  if (!date) {
    return '';
  }

  return moment(date, 'DD.MM.YYYY').toISOString();
};

export const getShortDateFromISO = (isoDate?: string): string => {
  if (!isoDate) {
    return '';
  }

  return moment(new Date(isoDate)).format('DD.MM.YYYY');
};

export const formatDayPickerDate = (date: Date): string => getShortDateFromISO(date.toISOString());

export const parseDayPickerDate = (date: string): Date | undefined => {
  const momentDate = moment.utc(date, 'DD.MM.YYYY', true);

  return momentDate.isValid() ? momentDate.toDate() : undefined;
};

export const getDateByMask = (dateStr?: string, mask?: string, isSimpleDate?: boolean) => {
  if (!dateStr || !mask) {
    return '';
  }

  const date = isSimpleDate ? moment(dateStr, 'DD.MM.YYYY') : moment(new Date(dateStr));

  return date.format(mask);
};

export const toDate = (date?: string | null) => {
  if (!date) {
    return undefined;
  }
  const parsedDate = moment(new Date(date));

  return parsedDate.isValid() ? parsedDate.toDate() : undefined;
};

export const getISO = (date?: string) => {
  if (!date) {
    return undefined;
  }

  return moment(date).toISOString();
};

export const getShortISODateTime = (date: string, isDateTo?: boolean) => {
  const shortISODate = moment(date, 'DD.MM.YYYY');

  return isDateTo
    ? moment(shortISODate, 'DD.MM.YYYY').add(DAY_WITHOUT_ONE_SECOND, 's').format(DATE_TIME_MASK)
    : shortISODate.format(DATE_TIME_MASK);
};

export const getFormattedDateTime = (date: string | null | undefined) => {
  if (!date) {
    return '';
  }

  return moment(date).format('DD.MM.YYYY HH:mm');
};

export const getFormattedDate = (date: string | null | undefined) => {
  if (!date) {
    return null;
  }
  const formatedDate = moment(date).format('DD.MM.YYYY');

  return parseDayPickerDate(formatedDate);
};

export const formatCalltrackingDateTime = (date: string | null | undefined) => {
  return date ? formatDateTime(date, '-0300', DATE_TIME_MASK) : undefined;
};

export const createFormattedDate = (date?: string | null) =>
  date ? moment(date, DATE_TIME_MASK).format('YYYY-MM-DD') : '';

export const createInputDate = (date?: string, isDateTo?: boolean) => {
  if (!date) {
    return '';
  }

  const momentDate = moment(date, 'YYYY-MM-DD');

  return isDateTo
    ? momentDate.add(DAY_WITHOUT_ONE_SECOND, 's').format(DATE_TIME_MASK)
    : momentDate.format(DATE_TIME_MASK);
};
