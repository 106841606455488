/* eslint-disable react/jsx-no-target-blank */
import { numberToPrettyString } from '@cian/utils';

import { TCplListItem } from '../../../types';
import { getCplSettingsToCalltrackingUrl, getSummaryOfCplList } from '../../../utils';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { BriefLine } from '../../BriefLine';
import { SimpleTable } from '../../SimpleTable';

import * as styles from './CplSummaryInfo.css';

export interface ICplSummaryInfoProps {
  list: TCplListItem[];
  period: IDatePeriod;
  managerId: number | undefined;
  builderId: number | undefined;
}

export function CplSummaryInfo({ list, period, builderId, managerId }: ICplSummaryInfoProps) {
  const {
    planSum,
    chargeableCallsCountSum,
    callsBalanceSum,
    nonChargeableCallsCountSum,
    budgetSum,
    budgetSpentSum,
    budgetBalanceSum,
  } = getSummaryOfCplList(list);

  const chargabeableCallsLink = (
    <a target="_blank" href={getCplSettingsToCalltrackingUrl(builderId, null, period, managerId)}>
      {numberToPrettyString(chargeableCallsCountSum)}
    </a>
  );

  return (
    <div className={styles['container']}>
      <strong className={styles['title']}>Всего</strong>
      <BriefLine title="Звонки (другие)" value={nonChargeableCallsCountSum} />
      <SimpleTable
        title="Звонки (платные)"
        headers={['План', 'Получено', 'Остаток']}
        rows={[[planSum, chargabeableCallsLink, callsBalanceSum]]}
      />
      <SimpleTable
        title="Бюджет, ₽"
        headers={['Всего', 'Потрачено', 'Остаток']}
        rows={[[budgetSum, budgetSpentSum, budgetBalanceSum]]}
      />
    </div>
  );
}
