import { Outside } from 'shared/components/Outside';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { IGeocodeResponse } from '../../../../../node/repositories/countryside/v1/geocode';
import { ELocationType } from '../../../../../node/repositories/countryside/v1/get-village/types';

import { AppContext } from '../../../../AppContext';
import { IRoadInfo, IStationInfo, IVillageAddress } from '../../../../common/interfaces';
import { allowedLocationTypeIds, getAddressTextByGeoCode, getGeocode, IGeoCoordinates } from '../../../../http';
import { isSimilarRegions } from '../../../../utils/geo';
import { generateRoadStationAlerts } from '../../../../utils/helpers';
import { isDefined } from '../../../../utils/helpers/isDefined';
import { AddressMap } from '../AddressMap/AddressMap';

import * as styles from './AddressField.css';

interface IAddressFieldProps {
  onChange(value: IVillageAddress): void;
  address: number;
  locationType: ELocationType;
  coordinates?: IGeoCoordinates;
  regionId: number;
  useValidation?: boolean;
  roadInfoList?: IRoadInfo[] | null;
  stationInfoList?: IStationInfo[] | null;
  readOnly?: boolean;
}

interface IAddressFieldState {
  text: string;
  search: string;
  suggest?: IGeocodeResponse;
  address: number;
  focused: boolean;
  selectedSuggest?: IGeocodeResponse;
  showError: boolean;
}

export class AddressField extends React.Component<IAddressFieldProps, IAddressFieldState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  private timeout: number | null;

  public constructor(props: IAddressFieldProps) {
    super(props);
    const { address } = this.props;

    this.state = {
      address,
      focused: false,
      search: '',
      showError: false,
      text: '',
    };
  }

  public async componentDidMount() {
    const { httpApi, logger } = this.context;
    const { address, locationType } = this.props;
    if (address) {
      try {
        const text = await getAddressTextByGeoCode(httpApi, logger, {
          request: [
            {
              id: address,
              geoType: locationType,
            },
          ],
        });

        this.setState({ text, search: text });
        this.resolveGeoCode(text);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  public render() {
    const { focused, search, selectedSuggest, showError, suggest, text } = this.state;
    const { address, useValidation, coordinates, readOnly } = this.props;

    const areStations = this.hasAttachedStations();
    const areRoads = this.hasAttachedRoads();

    const regionChangeBlocked = this.regionChangeIsBlocked();

    return (
      <Outside onOutside={() => this.setState({ focused: false })}>
        <div className={styles['address-input']}>
          <Form.Label>
            Расположение (с точностью минимум до города)&nbsp;
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            className={useValidation && !address ? styles['invalid'] : undefined}
            value={search}
            onFocus={() => this.setState({ focused: true })}
            onChange={this.handleSearchInput}
            readOnly={readOnly}
          />
          {suggest && focused && (
            <div className={styles['dropdown']}>
              <div className={styles['item']} onClick={() => this.selectSuggest()}>
                {suggest.text}
              </div>
            </div>
          )}
          {text && (
            <div className="text-success">
              Адрес в нашей системе: <strong>{text}</strong>
            </div>
          )}
          {useValidation ||
            (showError && !this.isCitySelected() && (
              <div className="text-danger">Нужно указать адрес с точностью до города</div>
            ))}
          {regionChangeBlocked && (
            <div className={styles['road-station-alert']}>{generateRoadStationAlerts(areStations, areRoads)}</div>
          )}
          {!text && !selectedSuggest && <div className="text-muted">Введите адрес для проверки в нашей системе</div>}
          <AddressMap
            coordinates={coordinates || (selectedSuggest && selectedSuggest.geo) || (suggest && suggest.geo)}
            readOnly={readOnly}
            onCoordinatesChange={coordinates => {
              const suggest = this.state.suggest || this.state.selectedSuggest;
              if (suggest) {
                this.selectSuggest({
                  ...suggest,
                  geo: coordinates,
                });
              }
            }}
            onSearchButtonClick={this.handlerOnSearchButtonClick}
          />
        </div>
      </Outside>
    );
  }

  private handlerOnSearchButtonClick = async ({ lat, lng }: IGeoCoordinates) => {
    await this.resolveGeoCode(`${lng},${lat}`);
    const { suggest } = this.state;
    if (suggest) {
      this.selectSuggest(suggest);
    }
  };

  private isCitySelected(selectedSuggest: IGeocodeResponse | undefined = this.state.selectedSuggest) {
    return (
      selectedSuggest &&
      selectedSuggest.details &&
      selectedSuggest.details.some(
        x => x.isLocality || (isDefined<number>(x.locationTypeId) && allowedLocationTypeIds.includes(x.locationTypeId)),
      )
    );
  }

  private clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  private setSearchTimeout = (searchValue: string, timeout: number = 500) => {
    return window.setTimeout(() => {
      this.resolveGeoCode(searchValue);
    }, timeout);
  };

  private handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value;
    this.setState({ search: searchValue, showError: true });
    this.clearTimeout();

    if (searchValue) {
      this.timeout = this.setSearchTimeout(searchValue);
    } else {
      this.setState({
        selectedSuggest: undefined,
        suggest: undefined,
        text: '',
      });
      this.props.onChange({
        address: 0,
        regionId: this.regionChangeIsBlocked() ? this.props.regionId : 0,
        locationType: ELocationType.Location,
      });
    }
  };

  private selectSuggest = (suggest: IGeocodeResponse | undefined = this.state.suggest) => {
    this.clearTimeout();

    if (suggest && suggest.details && suggest.details.length) {
      const { locationPath, details, text } = suggest;
      const geoDetail = details[suggest.details.length - 1];

      const regionId = (locationPath && locationPath.length && locationPath[locationPath.length - 1]) || 0;
      const regionChangeBlocked = this.regionChangeIsBlocked();
      if (regionChangeBlocked && !isSimilarRegions(regionId, this.props.regionId)) {
        this.setState({
          search: this.state.text,
          suggest: undefined,
        });

        return;
      }
      if (this.isCitySelected(suggest) && geoDetail.id) {
        this.setState({
          address: geoDetail.id,
          search: text || '',
          selectedSuggest: suggest,
          showError: false,
          suggest: undefined,
          text: text || '',
        });
        this.props.onChange({
          address: geoDetail.id,
          locationType: geoDetail.geoType as ELocationType | null | undefined,
          regionId,
          geo: suggest.geo,
        });
      } else {
        this.setState({
          address: 0,
          search: text || '',
          selectedSuggest: suggest,
          suggest: undefined,
          text: '',
        });
        this.props.onChange({
          address: 0,
          regionId: regionChangeBlocked ? regionId : 0,
          locationType: ELocationType.Location,
        });
      }
    }
  };

  private resolveGeoCode = async (searchValue: string) => {
    const { httpApi, logger } = this.context;

    try {
      const response = await getGeocode(httpApi, logger, { request: searchValue });

      if (
        response.isParsed &&
        response.locationPath &&
        (!this.regionChangeIsBlocked() ||
          isSimilarRegions(response.locationPath[response.locationPath.length - 1], this.props.regionId))
      ) {
        this.setState({
          suggest: response,
        });
      } else {
        this.setState({ suggest: undefined });
      }
    } catch (error) {
      this.setState({ suggest: undefined });
    }
  };

  private hasAttachedRoads = () => {
    const { roadInfoList } = this.props;

    return !!roadInfoList && roadInfoList.length > 0;
  };

  private hasAttachedStations = () => {
    const { stationInfoList } = this.props;

    return !!stationInfoList && stationInfoList.length > 0;
  };

  private regionChangeIsBlocked = () => {
    const regionChangeBlocked = this.hasAttachedStations() || this.hasAttachedRoads();

    return regionChangeBlocked;
  };
}
