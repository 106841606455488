/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGeocodeModel,
  IGeocodeRequest,
  IMappers,
  IGeocodeResponse,
  IGeocodeResponseError,
  TGeocodeResponse,
} from './types';

const defaultConfig: TGeocodeModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v1/geocode/',
  hostType: 'api',
} as TGeocodeModel;

function createGeocodeModel(parameters: IGeocodeRequest): TGeocodeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGeocodeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGeocodeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGeocode<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGeocodeOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGeocodeResponse> {
  const { response, statusCode } = await httpApi.fetch(createGeocodeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGeocodeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGeocodeResponseError);
    }
  }

  return { response, statusCode } as TGeocodeResponse;
}

export { defaultConfig, createGeocodeModel, fetchGeocode };
