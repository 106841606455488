import * as React from 'react';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { updateComment } from '../http';
import { ICallListItemSchema } from '../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';
import { NotificationManager } from './showNotification';

export interface IUseUpdateCallCommentHook {
  call: ICallListItemSchema;
  httpApi: IHttpApi;
  logger: ILogger;
  onCommentSave(): void;
}

export function useUpdateCallCommentHook(params: IUseUpdateCallCommentHook) {
  const { call, httpApi, logger, onCommentSave } = params;

  const [isPending, togglePending] = React.useState(false);

  const saveComment = React.useCallback(
    async (value: string) => {
      if (!call || !call.callId) {
        return;
      }

      togglePending(true);
      try {
        await updateComment(httpApi, logger, { callId: call.callId, comment: value });
        NotificationManager.success('Комментарий сохранён');
        onCommentSave();
      } catch (e) {
        NotificationManager.error(e.message);
      } finally {
        togglePending(false);
      }
    },
    [call, httpApi, logger, onCommentSave],
  );

  return { isPending, saveComment };
}
