import { createContext } from './createContext';
import { getConfig } from '@cian/config/browser';
import { renderOldApplication } from '../renderOldApplication';
import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';

export function runApp() {
  const config = getConfig();

  const customContext = config.get<string>('applicationContext.custom');

  if (customContext) {
    const context = createContext();

    const store = createApplicationState(context);

    renderApplication({ context, store });
  } else {
    renderOldApplication();
  }
}
