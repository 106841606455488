import * as React from 'react';
import MediaQuery from 'react-responsive';

import { Outside } from 'shared/components/Outside';
import { TDevice } from '@cian/utils';
import { useSetAlertWithTimeoutHook } from '../../../utils/useSetAlertWithTimeoutHook';

import { CalltrackingListMobile } from '../../CalltrackingListMobile';
import { CalltrackingMobileFilters } from '../../CalltrackingMobileFilters';
import { IUseGetCallsHookResult } from '../../../utils/useGetCallsHook';
import { Pagination } from '../../pagination';
import { getQueryStringByPage } from '../../../utils/filters';
import { setCallType } from '../../../http';
import { EChangerType, EType } from '../../../repositories/countryside-calltracking/v1/admin-set-call-type/types';
import { Alert } from '../../Alert';
import { saveFile } from '../../../../browser/utils/saveFile';
import { getInitialDeviceWidth } from '../../../utils/helpers/getInitialDeviceWidth';
import { CALLTRACKING_ALERT } from '../Calltracking';
import { AppContext } from '../../../AppContext';

import * as styles from './MobileView.css';
import { addScrollFn } from '../../../../browser/utils/addScrollFn';
import { GET_CALLTRACKING_REPORT } from '../../../common/request_path';
import { normalizeCallTrackingFilters } from '../../../utils/helpers';
import { IRegion } from '../../../common/interfaces';
import { errorCalltrackingMessageMapper } from '../helpers';
import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { ERoles } from '../../../repositories/countryside/v2/get-user-role-list';
import { EUserRoles, hasCalltrackingManagerRole } from '../../../utils/permissions';

interface ICalltrackingMobileProps {
  deviceType: TDevice;
  apiUrl?: string;
  callsHookState: IUseGetCallsHookResult;
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  roles: ERoles[];
}

export function CalltrackingMobileView(props: ICalltrackingMobileProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const {
    deviceType,
    apiUrl,
    callsHookState: { callsListState, filters, setFilters, updateFilterValue },
    managerList,
    roles,
    regionList,
  } = props;

  const [alert, setAlert] = useSetAlertWithTimeoutHook(CALLTRACKING_ALERT.HIDDEN);
  const hideAlert = () => setAlert(CALLTRACKING_ALERT.HIDDEN);
  const pageCount = (callsListState && callsListState.totalPages) || 1;
  const isAdmin = roles.includes(EUserRoles.CalltrackingAdmin);
  const canEditBlackList = isAdmin || roles.includes(EUserRoles.CalltrackingBlackList);
  const isCalltrackingManager = hasCalltrackingManagerRole(roles);
  /*
   * Нужен для того, чтобы коллбэк outside-click из попапа выполнялся раньше, чем закрытие алерта,
   * так как аутсайд-клик попапа воспринимает клик по алерту как триггер для закрытия
   */
  const delayedHideAlert = () => setTimeout(hideAlert, 0);

  return (
    <MediaQuery values={{ width: getInitialDeviceWidth(deviceType) }} maxWidth={767}>
      <div className={styles['container']}>
        <div>
          <h2 className={styles['heading_title']}>Звонки</h2>
          <CalltrackingMobileFilters
            regionList={regionList}
            managerList={managerList}
            filters={filters}
            onApplyFilters={setFilters}
            isCalltrackingManager={isCalltrackingManager}
          />
          {alert.isVisible && (
            <Outside onOutside={hideAlert}>
              <Alert onClick={delayedHideAlert} text={alert.text} type={alert.type} />
            </Outside>
          )}
          <CalltrackingListMobile
            callsList={callsListState}
            isAdmin={isAdmin}
            canEditBlackList={canEditBlackList}
            regionList={regionList}
            onSaveReport={saveFile(
              normalizeCallTrackingFilters(filters),
              () => setAlert(CALLTRACKING_ALERT.ERROR_REPORT),
              'report.xlsx',
              apiUrl,
              GET_CALLTRACKING_REPORT,
            )}
            isAlertVisible={alert.isVisible}
            onBlackListStatusChange={() => {
              setFilters({ ...filters });
              setAlert(CALLTRACKING_ALERT.BLACK_LIST_SUCCESS);
            }}
            onCommentSave={() => setFilters({ ...filters })}
            setCalltrackingType={(callId: number, changerType: EChangerType, type: EType) =>
              setCallType(httpApi, logger, { callId, changerType, type })
                .then(() => {
                  setAlert(CALLTRACKING_ALERT.SUCCESS);
                  /* Перезагружаем данные после успешного обновления типа звонка */
                  setFilters({ ...filters });
                })
                .catch(error => {
                  setAlert({
                    ...CALLTRACKING_ALERT.ERROR,
                    text: errorCalltrackingMessageMapper(error),
                  });
                })
            }
          />
          <Pagination
            activePage={filters.page || 1}
            pageSize={filters.pageSize || 0}
            pageCount={pageCount}
            onPageChange={addScrollFn(updateFilterValue('page'))}
            onPageCountChange={value => addScrollFn(updateFilterValue('pageSize'))(Number(value) || undefined)}
            getQueryString={getQueryStringByPage(filters)}
            hideStartEndButtons
            maxPagesToView={4}
          />
        </div>
      </div>
    </MediaQuery>
  );
}
