import {
  fetchUpdateBuilder,
  IUpdateBuilderRequest,
  IUpdateBuilderResponse,
  IUpdateBuilderResponseError,
} from '../repositories/countryside/v2/update-builder';

import { createSimpleRequest } from './createSimpleRequest';

export const updateBuilder = createSimpleRequest<
  IUpdateBuilderRequest,
  IUpdateBuilderResponse,
  IUpdateBuilderResponseError
>('updateBuilder', fetchUpdateBuilder);
