import { mergeStyles, numberToPrettyString } from '@cian/utils';

import { ICplEventDefinition } from '../../../common/interfaces';
import { CplEventDefinitions } from '../../../constants';
import { ICPLSettingsHistoryItemSchema } from '../../../repositories/countryside-calltracking/entities/cpl_settings_history/CPLSettingsHistoryItemSchema';
import { getShortDateFromISO } from '../../../utils/datetime';
import { isLastDayOfMonth } from '../../../utils';

import * as styles from './CplSettingsHistoryList.css';
import { Table } from 'react-bootstrap';

export interface ICplSettingsHistoryListProps {
  list: ICPLSettingsHistoryItemSchema[];
  isAdmin: boolean;
  isMobile?: boolean;
}

export function CplSettingsHistoryList({ list, isAdmin, isMobile }: ICplSettingsHistoryListProps) {
  if (!list.length) {
    return <div>Для выбранного КП нет истории</div>;
  }

  return (
    <Table hover responsive={isMobile} className={styles['table']}>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Событие</th>
          <th>Значение</th>
          <th>Баланс</th>
          {isAdmin && <th>Автор</th>}
        </tr>
      </thead>
      <tbody>
        {list.map(item => {
          const event = CplEventDefinitions[item.event];

          return (
            <tr
              {...mergeStyles(
                styles['item'],
                event.status === 'positive' && styles['item--positive'],
                event.status === 'negative' && styles['item--negative'],
                event.status === 'info' && styles['item--info'],
                isLastDayOfMonth(item, list) && styles['item--lined'],
              )}
              key={`${item.createdAt}_${item.value}`}
            >
              <td>{getShortDateFromISO(item.createdAt)}</td>
              <td className={styles['colored']}>{event.name}</td>
              <td className={styles['colored']}>{renderCplEventStatus(event, item)}</td>
              <td>{!!item.balance && `${numberToPrettyString(Number(item.balance))} ₽`}</td>
              {isAdmin && <td>{item.userEmail}</td>}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export function renderCplEventStatus(event: ICplEventDefinition, item: ICPLSettingsHistoryItemSchema) {
  const formattedValue = numberToPrettyString(Number(item.value));

  if (event.isAmount) {
    return (
      <>
        {event.prefix}
        {formattedValue} ₽
      </>
    );
  }

  return (
    <>
      {formattedValue}
      {item.cpl && (
        <span className={styles['cpl_tip']}>(CPL:&nbsp;{numberToPrettyString(Number(item.cpl))}&nbsp;₽)</span>
      )}
    </>
  );
}
