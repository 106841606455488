import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { checkMinMax } from '../../utils/numbers';

import * as styles from './index.css';
import { Form } from 'react-bootstrap';

interface INumberField {
  value: string;
  min?: number;
  max?: number;
  negative?: boolean;
  onChange(value: number | void): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  containerClass?: string;
  allowFloat?: boolean;
  inputClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  invalid?: boolean;
  readOnly?: boolean;
}

enum ENumberKeycodes {
  E = 69,
  Comma = 188,
  Period = 190,
}

const disallowedFloatKeys = [ENumberKeycodes.E];
const disalloweddKeys = [...disallowedFloatKeys, ENumberKeycodes.Period, ENumberKeycodes.Comma];

const onKeyPressed =
  (allowFloat: boolean = false) =>
  (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const allowDot = value && !value.includes('.');
    const keys = allowFloat && allowDot ? disallowedFloatKeys : disalloweddKeys;
    if (keys.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

export const NumberField = (props: INumberField) => {
  const { max = 999999999, min = 0, allowFloat } = props;
  const handleNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.currentTarget.value.replace(allowFloat ? /[^\d.-]/g : /[^\d-]/g, '');

    let value;
    if (allowFloat) {
      // Ограничиваем количество цифр после запятой у float
      const splitted = result.split('.');
      const croppedNumber = [splitted[0], (splitted[1] || '').slice(0, 6)].join('.');

      value = result ? checkMinMax(Number(croppedNumber), min, max) : undefined;
    } else {
      value = result ? checkMinMax(Number(result), min, max) : undefined;
    }

    props.onChange(value);
  };

  return (
    <Form.Group {...mergeStyles(props.containerClass, styles['number-field'])}>
      {props.label && (
        <Form.Label>
          {props.label}&nbsp;
          {props.required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <Form.Control
        {...mergeStyles(props.inputClass, props.invalid && styles['invalid'])}
        id={props.htmlId || ''}
        value={props.negative ? '-' + props.value.toString() : props.value}
        placeholder={props.placeholder}
        type="number"
        onChange={handleNumberInput}
        onBlur={props.onBlur}
        onKeyDown={onKeyPressed(allowFloat)}
        readOnly={props.readOnly}
      />
    </Form.Group>
  );
};
