import * as React from 'react';
import { createPortal } from 'react-dom';

export interface IPortalProps {
  isHidden?: boolean;
}

export class Portal extends React.Component<React.PropsWithChildren<IPortalProps>> {
  public componentDidMount() {
    document.body.style.overflow = this.props.isHidden ? '' : 'hidden';
  }

  public componentWillUnmount() {
    document.body.style.overflow = '';
  }

  public componentDidUpdate() {
    document.body.style.overflow = this.props.isHidden ? '' : 'hidden';
  }

  public render() {
    const { children } = this.props;

    if (!children) {
      return null;
    }

    return createPortal(this.props.children, document.body);
  }
}
