import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import MaskedInput from 'react-input-mask';

import * as ru from 'date-fns/locale/ru';

import * as styles from './DatePicker.css';
import { Form } from 'react-bootstrap';

registerLocale('ru', ru);

export interface IDatePickerProps {
  selected?: Date | null;
  onChange(date: Date, event: React.ChangeEvent<HTMLInputElement>): void;
  showMonthYearPicker?: boolean;
  placeholderText?: string;
  label?: string;
}

const monthMask = '99/9999';
const dateMask = '99.99.9999';

export function DatePicker({ selected, onChange, showMonthYearPicker, placeholderText, label }: IDatePickerProps) {
  return (
    <div className={styles['day_picker-container']}>
      <Form.Label>{label}</Form.Label>
      <ReactDatePicker
        selected={selected}
        onChange={onChange}
        dateFormat={showMonthYearPicker ? 'MM/yyyy' : 'dd.MM.yyyy'}
        showMonthYearPicker={showMonthYearPicker}
        placeholderText={placeholderText}
        className="form-control"
        locale="ru"
        customInput={<MaskedInput type="text" mask={showMonthYearPicker ? monthMask : dateMask} />}
      />
    </div>
  );
}
