import { EPageTypes } from 'shared/common/types/pageTypes';

export const getActiveItemByPageType = (pageType: EPageTypes) => {
  switch (pageType) {
    case EPageTypes.VillageFeatures:
    case EPageTypes.Villages:
    case EPageTypes.VillageBuilders:
    case EPageTypes.VillageCalltracking:
    case EPageTypes.BuildersCalltracking:
      return pageType;

    case EPageTypes.CreateVillage:
    case EPageTypes.EditVillage:
      return EPageTypes.Villages;

    case EPageTypes.CreateVillageBuilder:
    case EPageTypes.EditVillageBuilder:
      return EPageTypes.VillageBuilders;

    default:
      return;
  }
};
