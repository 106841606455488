import { createSimpleRequest } from './createSimpleRequest';
import {
  fetchGetBuilderChangelog,
  IGetBuilderChangelogRequest,
  IGetBuilderChangelogResponse,
  IGetBuilderChangelogResponseError,
} from '../../node/repositories/countryside/v1/get-builder-changelog';

export const getBuilderChangelog = createSimpleRequest<
  IGetBuilderChangelogRequest,
  IGetBuilderChangelogResponse,
  IGetBuilderChangelogResponseError
>('getBuilderChangelog', fetchGetBuilderChangelog);
