import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import * as styles from './index.css';

export interface IImage {
  url?: string | null;
  base64?: string | null;
}

interface IImageSelectorProps {
  onChange(value: IImage): void;
  loadBtnText?: string;
  value: IImage;
  className?: string;
  minWidth: number;
  minHeight: number;
  maxWidth?: number;
  maxHeight?: number;
  previewWidth?: number | string;
  previewHeight?: number | string;
  accept?: string;
  inlinePreview?: boolean;
  invalid?: boolean;
  readOnly?: boolean;
  label?: string;
  required?: boolean;
}

interface IImageSelectorState {
  currentUrl?: string | null;
  savedUrl?: string | null;
  invalidMaxSize: boolean;
  invalidMinSize: boolean;
}

export class ImageSelector extends React.Component<IImageSelectorProps, IImageSelectorState> {
  public constructor(props: IImageSelectorProps) {
    super(props);

    this.state = {
      currentUrl: props.value.url,
      invalidMaxSize: false,
      invalidMinSize: false,
      savedUrl: props.value.url,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IImageSelectorProps) {
    const { value } = nextProps;

    this.setState({ currentUrl: value.base64 || value.url });
  }

  public render() {
    const {
      className,
      loadBtnText,
      maxWidth,
      minWidth,
      minHeight,
      previewHeight,
      previewWidth,
      accept,
      inlinePreview,
      invalid,
      readOnly,
      label,
      required,
    } = this.props;
    const { currentUrl, invalidMinSize, invalidMaxSize } = this.state;

    return (
      <FormGroup className={className}>
        {label && (
          <FormLabel>
            {label}&nbsp;
            {required && <span className="text-danger">*</span>}
          </FormLabel>
        )}
        <div>
          {currentUrl && !readOnly && (
            <Button
              variant="danger"
              style={{ float: inlinePreview ? 'left' : 'none' }}
              onClick={this.removeImage}
              size="sm"
            >
              Удалить
            </Button>
          )}
          {!currentUrl && !readOnly && (
            <label {...mergeStyles(styles['label'], styles['label-success'])}>
              <input
                type="file"
                accept={accept || 'image/*'}
                className={styles['input']}
                onChange={this.fileChangeHandler}
                readOnly={readOnly}
              />
              {loadBtnText || 'Выбрать'}
            </label>
          )}
          {currentUrl && (
            <div className={inlinePreview ? styles['preview-inline'] : styles['preview']}>
              <img style={{ maxWidth: previewWidth || 200, maxHeight: previewHeight || 200 }} src={currentUrl} />
            </div>
          )}
          {invalidMinSize && (
            <div className="text-danger">Мин. размеры изображения {`${minWidth}х${minHeight}`}&nbsp;px</div>
          )}
          {invalidMaxSize && <div className="text-danger">Макс. ширина изображения {maxWidth}&nbsp;px</div>}
          {invalid && <div className="text-danger">Выберите изображение</div>}
        </div>
      </FormGroup>
    );
  }

  private removeImage = () => {
    this.props.onChange({});

    this.setState({ currentUrl: undefined });
  };

  private fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ invalidMinSize: false, invalidMaxSize: false });
    const file = e.currentTarget.files && e.currentTarget.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64 = String(reader.result);
      const imageElement = new Image();

      imageElement.onload = () => {
        const { minWidth, minHeight, maxWidth } = this.props;

        if (maxWidth && imageElement.width > maxWidth) {
          this.setState({ invalidMaxSize: true });

          return;
        }
        if (imageElement.width >= minWidth && imageElement.height >= minHeight) {
          this.props.onChange({
            base64,
            url: this.state.savedUrl,
          });

          this.setState({ currentUrl: base64 });
        } else {
          this.setState({ invalidMinSize: true });
        }
      };

      imageElement.src = base64;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
}
