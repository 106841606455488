import { mergeStyles } from '@cian/utils';

import { SelectSearchField } from '../../../select_search_field';
import { IDropdownValue, SelectField } from '../../../SelectField';
import { IStationInfo, railwayDistanceVillageDropdownValues } from '../../../../common/interfaces';

import * as styles from '../VillageRailway.css';

export interface IVillageStationProps {
  item: IStationInfo;
  directionValues: IDropdownValue[];
  stationValues: IDropdownValue[];
  terminalInfo: string;
  readOnly?: boolean;
  onDirectionChange(value: string): void;
  onDistanceVillageChange(value: string): void;
  onStationChange(value: string): void;
  onRemove(): void;
}

export const VillageStation = ({
  item,
  directionValues,
  stationValues,
  terminalInfo,
  readOnly,
  onDirectionChange,
  onDistanceVillageChange,
  onStationChange,
  onRemove,
}: IVillageStationProps) => (
  <li className="row">
    <div className="col-md-3">
      <SelectSearchField
        className="form-group"
        value={item.directionId}
        values={directionValues}
        label="Направление"
        required
        onChange={onDirectionChange}
        readOnly={readOnly}
      />
    </div>
    <div className="col-md-3">
      <SelectSearchField
        className="form-group"
        value={item.stationId}
        values={stationValues}
        label="Станция"
        required
        onChange={onStationChange}
        readOnly={readOnly}
      />
    </div>
    <div className="col-md-3">
      <SelectField
        className="form-group"
        readOnly={readOnly}
        value={item.distanceVillage.toString()}
        values={railwayDistanceVillageDropdownValues}
        label="Расстояние от КП, км"
        required
        onChange={onDistanceVillageChange}
      />
    </div>
    <div className="col-md-2">
      <label>Вокзал</label>
      <p className={styles['terminal']}>{terminalInfo}</p>
    </div>
    <div className="col-md-1">
      {!readOnly && (
        <button type="button" onClick={onRemove} {...mergeStyles(styles['delete-btn'], 'btn', 'btn-danger')}>
          <i className="fas fa-times" />
        </button>
      )}
    </div>
  </li>
);
