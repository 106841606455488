/* eslint-disable max-lines, react/jsx-no-target-blank */

import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import {
  IBuilderNameListItem,
  IVillageAddress,
  TVillageCreateModel,
  moderationStatusList,
  monthNameList,
  quarterNameList,
  readinessStatusList,
  villageClassList,
  villageSaleStatusList,
  villageTotalStatusList,
  yearRangeList,
  IVillage,
} from '../../../common/interfaces';
import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { MAX_PRICE, NON_BREAKING_SPACE } from '../../../utils/constants';
import { NumberField } from '../../number_field';
import { SelectField } from '../../SelectField';
import { SelectSearchField } from '../../select_search_field';
import { TextField } from '../../text_field';
import { TextAreaField } from '../../textarea_field';
import { AddressField } from './AddressField';
import { NumberRangeField } from './number_range_field';

import * as styles from './index.css';
import { isUrlValid } from '../../../utils/urlValidator';
import { UserAndOrderIds } from '../UserAndOrderIds';
import { Button, Col, FormLabel, Row } from 'react-bootstrap';
import { CheckboxField } from '../../CheckboxField';
import { PhoneInput } from 'shared/components/PhoneInput/PhoneInput';

interface IProps {
  village: TVillageCreateModel;
  builderNames: IBuilderNameListItem[];
  managerList: IManagerForListSchema[];
  handleFieldChange(name: string, value: string | number | void | boolean): void;
  handleAddressFieldChange(value: IVillageAddress): void;
  handleSaveButtonClick(): void;
  handleShowPhoneChange(value: boolean): void;
  handleIsVisibleChange(value: boolean): void;
  handleCplFieldChange(value: boolean): void;
  useValidation: boolean;
  isCreation?: boolean;
  isSaveButtonDisabled?: boolean;
  readOnly?: boolean;
  fatCardEnabled?: boolean;
  cplEnabled?: boolean;
  hasFreeCpl?: boolean;
  hasCroppedCard?: boolean;
  oldName?: string;
  url?: string | null;
}

export class VillageForm extends React.Component<IProps> {
  public render() {
    const {
      builderNames,
      isCreation,
      village,
      handleFieldChange,
      useValidation,
      isSaveButtonDisabled,
      handleSaveButtonClick,
      handleAddressFieldChange,
      handleShowPhoneChange,
      handleIsVisibleChange,
      handleCplFieldChange,
      readOnly,
      managerList,
      fatCardEnabled,
      cplEnabled,
      hasCroppedCard,
      hasFreeCpl,
      oldName,
      url,
    } = this.props;

    const {
      areaHectare = '',
      builderId,
      displayBuilderPhone,
      description = '',
      finishMonthMax,
      finishMonthMin,
      finishQuarterMax,
      finishQuarterMin,
      finishYearMax,
      finishYearMin,
      floorCountMax,
      floorCountMin,
      hidePrice = false,
      isVisible,
      locationId,
      locationType,
      moderationStatus,
      name,
      priceMax,
      priceMin,
      readyStatus,
      saleStatus,
      siteUrl = '',
      totalStatus,
      villageClass,
      lat,
      lng,
      cianUserId,
      orderId,
      managerId,
      isFat,
      isCpl,
      isCropped,
      isPro,
      freeCpl,
      autodisableOrder,
    } = village;
    const title = `${isCreation ? 'Создание' : 'Изменение'} коттеджного посёлка`;
    const phone = village.phone || '';

    const coordinates = lat && lng ? { lat, lng } : undefined;

    const managers = managerList.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));
    const villageId = isCreation ? undefined : (village as IVillage).id;

    return (
      <div className={styles['container']}>
        <div>
          <h2 className={styles['heading_title']}>
            {title}{' '}
            {!!url && (
              <a href={url} target="_blank">
                {oldName}
              </a>
            )}
          </h2>
          {!readOnly && (
            <Button
              type="button"
              variant="success"
              className={styles['save-btn']}
              disabled={isSaveButtonDisabled}
              onClick={handleSaveButtonClick}
            >
              Сохранить
            </Button>
          )}
        </div>
        <CheckboxField
          label="Скрыть из выдачи"
          value={!isVisible}
          onChange={handleIsVisibleChange}
          disabled={readOnly}
          {...mergeStyles('mb-3', styles['top_inline_item'])}
        />
        <Row>
          {cplEnabled && !!isCpl && (
            <a
              href={`/cpl-settings?builderId=${builderId}&villageId=${villageId}`}
              target="_blank"
              className={styles['top_inline_item']}
            >
              Детализация звонков
            </a>
          )}
        </Row>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6} className="mb-3">
                <TextField
                  value={name}
                  maxLength={255}
                  label="Название"
                  required
                  invalid={useValidation && !name}
                  onChange={(value: string) => handleFieldChange('name', value)}
                  readOnly={readOnly}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  value={siteUrl}
                  maxLength={255}
                  label="Сайт поселка"
                  invalid={useValidation && !isUrlValid(siteUrl)}
                  placeholder="http://example.com"
                  helpText="Адрес должен начинаться с http:// или https://"
                  onChange={(value: string) => handleFieldChange('siteUrl', value)}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <TextAreaField
                  value={description || ''}
                  label="Описание"
                  required
                  invalid={useValidation && !description}
                  onChange={(value: string) => handleFieldChange('description', value)}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <CheckboxField
                  label="Скрыть цену"
                  value={!!hidePrice}
                  onChange={checked => handleFieldChange('hidePrice', checked)}
                  disabled={readOnly}
                />
              </Col>
              <Col md={6} className="mb-3">
                <NumberField
                  value={String(areaHectare)}
                  label="Площадь в гектарах"
                  required
                  invalid={useValidation && !areaHectare}
                  onChange={(value: number) => handleFieldChange('areaHectare', value)}
                  max={999999.999999}
                  allowFloat={true}
                  readOnly={readOnly}
                />
              </Col>
              <Col md={6} className="mb-3">
                <SelectSearchField
                  className="form-group"
                  value={builderId}
                  values={builderNames.map(builder => ({
                    value: builder.id,
                    label: builder.name,
                    muted: builder.moderationStatus !== 'published',
                  }))}
                  label="Застройщик"
                  required
                  onChange={(id: string) => handleFieldChange('builderId', id && Number(id))}
                  readOnly={readOnly}
                  useValidation={useValidation}
                  showValue
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <NumberRangeField
                  label="Диапазон цен за участок, в рублях"
                  useValidation={useValidation}
                  minValue={priceMin ? Number(priceMin.toString()) : undefined}
                  maxValue={priceMax ? Number(priceMax.toString()) : undefined}
                  max={MAX_PRICE}
                  onChangeMin={(min: number) => handleFieldChange('priceMin', min)}
                  onChangeMax={(max: number) => handleFieldChange('priceMax', max)}
                  readOnly={readOnly}
                />
              </Col>
              <Col md={6} className="mb-3">
                <NumberRangeField
                  label="Этажность от и до"
                  useValidation={useValidation}
                  minValue={floorCountMin}
                  maxValue={floorCountMax}
                  min={-99}
                  max={99}
                  onChangeMin={(min: number) => handleFieldChange('floorCountMin', min)}
                  onChangeMax={(max: number) => handleFieldChange('floorCountMax', max)}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <AddressField
                  address={locationId}
                  locationType={locationType}
                  onChange={handleAddressFieldChange}
                  coordinates={coordinates}
                  regionId={village.regionId}
                  useValidation={useValidation}
                  roadInfoList={village.roadInfoList}
                  stationInfoList={village.stationInfoList}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </Col>
          <Col md={4} className="mb-3">
            {!isCreation && (
              <SelectField
                className="mb-3"
                readOnly={readOnly}
                value={moderationStatus}
                values={moderationStatusList}
                label="Статус модерации"
                required
                onChange={(value: string | void) => handleFieldChange('moderationStatus', value)}
              />
            )}
            <SelectField
              className="mb-3"
              readOnly={readOnly}
              value={villageClass}
              values={villageClassList}
              label="Класс поселка"
              required
              onChange={(value: string | void) => handleFieldChange('villageClass', value)}
            />
            <SelectField
              className="mb-3"
              readOnly={readOnly}
              value={saleStatus}
              values={villageSaleStatusList}
              label="Статус продаж"
              required
              onChange={(value: string | void) => handleFieldChange('saleStatus', value)}
            />
            <SelectField
              className="mb-3"
              readOnly={readOnly}
              value={readyStatus}
              values={readinessStatusList}
              label="Статус готовности"
              required
              onChange={(value: string | void) => handleFieldChange('readyStatus', value)}
            />
            <SelectField
              className="mb-3"
              readOnly={readOnly}
              value={totalStatus}
              values={villageTotalStatusList}
              label="Общий статус поселка"
              required
              onChange={(value: string | void) => handleFieldChange('totalStatus', value)}
            />
            <FormLabel>Сроки сдачи от</FormLabel>
            <Row>
              <Col md={4} className="mb-3">
                <SelectField
                  readOnly={readOnly}
                  value={finishYearMin}
                  values={yearRangeList}
                  label="Год"
                  required
                  onChange={(year: string) => handleFieldChange('finishYearMin', Number(year))}
                />
              </Col>
              <Col md={3} className="mb-3">
                <SelectField
                  canEmpty
                  emptyText="—"
                  readOnly={readOnly}
                  value={finishQuarterMin || ''}
                  values={quarterNameList}
                  label="Квартал"
                  onChange={(value: string | void) => handleFieldChange('finishQuarterMin', value)}
                />
              </Col>
              <Col md={5} className="mb-3">
                <SelectField
                  canEmpty
                  emptyText="—"
                  readOnly={readOnly}
                  value={finishMonthMin || ''}
                  values={monthNameList}
                  label="Месяц"
                  onChange={(value: string | void) => handleFieldChange('finishMonthMin', value)}
                />
              </Col>
            </Row>
            <FormLabel>Сроки сдачи до</FormLabel>
            <Row>
              <Col md={4} className="mb-3">
                <SelectField
                  readOnly={readOnly}
                  value={finishYearMax}
                  values={yearRangeList}
                  label="Год"
                  required
                  onChange={(year: string) => handleFieldChange('finishYearMax', Number(year))}
                />
              </Col>
              <Col md={3} className="mb-3">
                <SelectField
                  canEmpty
                  emptyText="—"
                  readOnly={readOnly}
                  value={finishQuarterMax || ''}
                  values={quarterNameList}
                  label="Квартал"
                  onChange={(value: string | void) => handleFieldChange('finishQuarterMax', value)}
                />
              </Col>
              <Col md={5} className="mb-3">
                <SelectField
                  canEmpty
                  emptyText="—"
                  readOnly={readOnly}
                  value={finishMonthMax || ''}
                  values={monthNameList}
                  label="Месяц"
                  onChange={(value: string | void) => handleFieldChange('finishMonthMax', value)}
                />
              </Col>
            </Row>
            <PhoneInput
              value={phone.replace(/^7/, '+7')}
              label="Телефон застройщика"
              onChange={value => handleFieldChange('phone', value || undefined)}
              readOnly={readOnly}
              className="mb-3"
            />
            <CheckboxField
              value={displayBuilderPhone || false}
              onChange={handleShowPhoneChange}
              disabled={readOnly}
              label={`${NON_BREAKING_SPACE}Отображать телефон застройщика`}
              className="mb-3"
            />
            {(cianUserId || orderId) && <UserAndOrderIds cianUserId={cianUserId} orderId={orderId} />}
            <SelectField
              readOnly={readOnly}
              value={managerId || ''}
              values={managers}
              label="Менеджер"
              className="mb-3"
              canEmpty
              onChange={(value: string) => handleFieldChange('managerId', Number(value) || undefined)}
            />
            {fatCardEnabled && (
              <CheckboxField
                label="Супер премиум"
                value={!!isFat}
                onChange={checked => handleFieldChange('isFat', checked)}
                className="mb-3"
              />
            )}
            <CheckboxField
              value={!!isCpl}
              onChange={checked => handleCplFieldChange(checked)}
              label="CPL"
              className="mb-3"
            />
            <CheckboxField
              value={!!autodisableOrder}
              disabled={!isCpl}
              onChange={checked => handleFieldChange('autodisableOrder', checked && !!isCpl)}
              label="Автоотключение заказа"
              className="mb-3"
            />
            {hasFreeCpl && (
              <CheckboxField
                label="Бесплатный CPL"
                value={!!freeCpl}
                onChange={checked => handleFieldChange('freeCpl', checked)}
                className="mb-3"
              />
            )}
            {hasCroppedCard && (
              <CheckboxField
                label="Ручка лидов"
                value={!!isCropped}
                onChange={checked => handleFieldChange('isCropped', checked)}
                className="mb-3"
              />
            )}
            <CheckboxField
              label="Pro"
              value={!!isPro}
              onChange={checked => handleFieldChange('isPro', checked)}
              className="mb-3"
            />
          </Col>
        </Row>
      </div>
    );
  }
}
