import { ICallsPopup, IContractorCallsPlayerStatus } from '../types';

export const INITIAL_CONTRACTOR_POPUP: ICallsPopup = {
  callId: 0,
  isOpen: false,
  history: [],
};

export const INITIAL_PLAYER_STATUS: IContractorCallsPlayerStatus = {
  recordFilePath: undefined,
  isPaused: true,
};

export const DEFAULT_CONTRACTOR_CALLS = {
  totalItems: 0,
  totalPages: 0,
  calls: [],
};
