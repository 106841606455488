/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBuilderAmocrmUrlModel,
  IGetBuilderAmocrmUrlRequest,
  IMappers,
  IGetBuilderAmocrmUrlResponse,
  IGetBuilderAmocrmUrlResponseError,
  TGetBuilderAmocrmUrlResponse,
} from './types';

const defaultConfig: TGetBuilderAmocrmUrlModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v1/get-builder-amocrm-url/',
  hostType: 'api',
} as TGetBuilderAmocrmUrlModel;

function createGetBuilderAmocrmUrlModel(parameters: IGetBuilderAmocrmUrlRequest): TGetBuilderAmocrmUrlModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBuilderAmocrmUrlOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBuilderAmocrmUrlRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBuilderAmocrmUrl<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBuilderAmocrmUrlOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetBuilderAmocrmUrlResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetBuilderAmocrmUrlModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBuilderAmocrmUrlResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBuilderAmocrmUrlResponseError);
    }
  }

  return { response, statusCode } as TGetBuilderAmocrmUrlResponse;
}

export { defaultConfig, createGetBuilderAmocrmUrlModel, fetchGetBuilderAmocrmUrl };
