import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  CanalizationCommunicationDropdownValues,
  ElectricityCommunicationDropdownValues,
  GasCommunicationDropdownValues,
  HeatingCommunicationDropdownValues,
  ICommunicationSet,
  RoadCommunicationDropdownValues,
  WaterSupplyCommunicationDropdownValues,
} from '../../../common/interfaces';
import {
  ECanalization,
  EElectricity,
  EGas,
  EHeating,
  ERoad,
  EWaterSupply,
} from '../../../repositories/countryside/entities/communication/CommunicationSetSchema';
import { MultiselectorField } from '../../multiselector_field';
import { SelectField } from '../../SelectField';

export const getDefaultState = (oldSet: ICommunicationSet): ICommunicationFormState => ({
  data: {
    canalization: oldSet.canalization,
    electricity: oldSet.electricity,
    gas: oldSet.gas || [],
    heating: oldSet.heating || [],
    road: oldSet.road || [],
    waterSupply: oldSet.waterSupply || [],
  },
});

interface ICommunicationFormState {
  data: ICommunicationSet;
}

export interface ICommunicationFormProps {
  onChange(data: ICommunicationSet, isDataValid: boolean): void;
  onInit(initData: ICommunicationSet, isDataValid: boolean): void;
  communicationSet: ICommunicationSet;
  readOnly?: boolean;
}

export class VillageCommunications extends React.Component<ICommunicationFormProps, ICommunicationFormState> {
  public state = getDefaultState(this.props.communicationSet);

  public componentDidMount() {
    const { data } = this.state;

    this.props.onInit(data, true);
  }

  public render() {
    const { readOnly } = this.props;
    const { canalization, electricity, gas, heating, road, waterSupply } = this.state.data;

    return (
      <div>
        <Row>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Газ"
              value={gas || []}
              invalid={false}
              values={GasCommunicationDropdownValues}
              onChange={this.handleGasSelectChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Отопление"
              value={heating || []}
              invalid={false}
              values={HeatingCommunicationDropdownValues}
              onChange={this.handleHeatingSelectChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Дороги"
              value={road || []}
              invalid={false}
              values={RoadCommunicationDropdownValues}
              onChange={this.handleRoadSelectChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <SelectField
              label="Канализация"
              canEmpty
              emptyText="не выбрана"
              value={canalization || ''}
              values={CanalizationCommunicationDropdownValues}
              onChange={this.handleCanalizationSelectChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <SelectField
              label="Электричество"
              canEmpty
              emptyText="не проведено"
              value={electricity || ''}
              values={ElectricityCommunicationDropdownValues}
              onChange={this.handleElectricitySelectChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Водоснабжение"
              value={waterSupply || []}
              invalid={false}
              values={WaterSupplyCommunicationDropdownValues}
              onChange={this.handleWaterSupplySelectChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </div>
    );
  }

  private handleCanalizationSelectChange = (value: ECanalization) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        canalization: !value ? null : value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };

  private handleElectricitySelectChange = (value: EElectricity) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        electricity: !value ? null : value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };

  private handleRoadSelectChange = (value: ERoad[]) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        road: value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };

  private handleWaterSupplySelectChange = (value: EWaterSupply[]) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        waterSupply: value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };

  private handleHeatingSelectChange = (value: EHeating[]) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        heating: value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };

  private handleGasSelectChange = (value: EGas[]) => {
    const { data } = this.state;

    const newState: ICommunicationFormState = {
      data: {
        ...data,
        gas: value,
      },
    };
    this.setState(newState);
    this.props.onChange(newState.data, true);
  };
}
