import { mergeStyles } from '@cian/utils/lib/shared/style';

import { handleUnicodeAndUnprintableChars, THandler } from '../../utils/handle_unicode_and_unprintable_chars';

import * as styles from './index.css';
import { Form } from 'react-bootstrap';

export interface ITextField {
  value: string;
  onChange: THandler;
  containerClass?: string;
  inputClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  invalid?: boolean;
  readOnly?: boolean;
  onBlur?(): void;
  onFocus?(): void;
}

export const TextAreaField = (props: ITextField) => {
  const {
    containerClass,
    htmlId,
    inputClass,
    invalid,
    label,
    maxLength,
    onChange,
    placeholder,
    readOnly,
    required,
    value,
    onBlur,
    onFocus,
  } = props;

  return (
    <Form.Group {...mergeStyles(containerClass, styles['text-field'])}>
      {label && (
        <Form.Label>
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <Form.Control
        as="textarea"
        id={htmlId}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={handleUnicodeAndUnprintableChars(onChange)}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        {...mergeStyles(inputClass, invalid && styles['invalid'])}
      />
    </Form.Group>
  );
};
