import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  IRailwaysSearchRailwaysByDirectionResponse,
  IRailwaysSearchRailwaysByDirectionResponseError,
  TRailwaysSearchRailwaysByDirectionResponse,
  fetchRailwaysSearchRailwaysByDirection,
} from '../../node/repositories/countryside/v1/railways-search-railways-by-direction';

import { IRailwayDirection, IStation } from '../common/interfaces';

import { DC_REGIONS_IDS } from '../utils/geo';
import { getFormattedError } from '../utils';
import { isResponseContainsKey } from './helpers';

export interface IRailwayListData {
  directions: IRailwayDirection[];
  stations: IStation[];
}

export async function getRailwayList(httpApi: IHttpApi, logger: ILogger, regionId: number): Promise<IRailwayListData> {
  try {
    const parameters = { directionRegionIds: [DC_REGIONS_IDS[regionId] || regionId] };
    const { response, statusCode }: TRailwaysSearchRailwaysByDirectionResponse =
      await fetchRailwaysSearchRailwaysByDirection({
        httpApi,
        parameters,
        config: { requestConfig: { headers: [['Content-Type', 'application/json']] } },
      });

    if (
      statusCode === 400 ||
      isResponseContainsKey<
        IRailwaysSearchRailwaysByDirectionResponseError,
        IRailwaysSearchRailwaysByDirectionResponse
      >(response, 'errors')
    ) {
      throw response;
    }

    return {
      directions: response.directions ?? [],
      stations: response.results ?? [],
    };
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getRailwayList', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getRailwayList' });

      throw formattedError;
    }

    throw error;
  }
}
