export enum ERuntimeSettings {
  // platform
  projectName = 'projectName',
  soprApiBaseUrl = 'sopr-api.browserUrl',
  assetsBaseUrl = 'web.manifest.assetsBaseUrl',
  isWebView = 'web.isWebView',
  webPlatform = 'web.platform',
  initialState = 'initialState',
  applicationContextCustom = 'applicationContext.custom',
}
