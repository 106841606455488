import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { ICallHistoryItem, ICallsPopup } from '../../types';

/**
 * Экшены попапа звонка на странице списка звонков строителя (/contractor-calltracking)
 **/

export enum EContractorPopupActions {
  setCallId = 'CONTRACTOR_POPUP_SET_CALL_ID',
  togglePopup = 'CONTRACTOR_POPUP_TOGGLE',
  setStatus = 'CONTRACTOR_POPUP_SET_STATUS',
  setCallHistory = 'CONTRACTOR_POPUP_SET_CALL_HISTORY',
}

export type TSetCallId = ITypedReduxAction<EContractorPopupActions.setCallId, number>;

// Меняет id звонка, открытого в попапе
export const setContractorCallPopupId: (callId?: number) => TSetCallId = actionGenerator<
  EContractorPopupActions.setCallId,
  number
>(EContractorPopupActions.setCallId);

export type TTogglePopup = ITypedReduxAction<EContractorPopupActions.togglePopup, boolean>;

// Управляет видимостью попапа.
export const toggleContractorCallPopup: (isOpen: boolean) => TTogglePopup = actionGenerator<
  EContractorPopupActions.togglePopup,
  boolean
>(EContractorPopupActions.togglePopup);

// Экшен для установки значений
export type TSetContractorCallPopupStatus = ITypedReduxAction<EContractorPopupActions.setStatus, ICallsPopup>;

export const setContractorCallPopupStatus: (payload: ICallsPopup) => TSetContractorCallPopupStatus = actionGenerator<
  EContractorPopupActions.setStatus,
  ICallsPopup
>(EContractorPopupActions.setStatus);

export type TSetCallHistory = ITypedReduxAction<EContractorPopupActions.setCallHistory, ICallHistoryItem[]>;

export const setCallHistory: (payload: ICallHistoryItem[]) => TSetCallHistory = actionGenerator<
  EContractorPopupActions.setCallHistory,
  ICallHistoryItem[]
>(EContractorPopupActions.setCallHistory);
