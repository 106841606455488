import { combineReducers } from 'redux';
import { IContractorCalltrackingListPageState, TReduxActions } from '../../types/redux';
import { createSimpleReducer } from './createSimpleReducer';
import { contractorCallsListDataReducer } from '../../reducers/contractorCallsListDataReducer';
import { contractorCallsListFiltersReducer } from '../../reducers/contractorCallsListFiltersReducer';
import { contractorCallsPopupReducer } from '../../reducers/contractorCallsPopupReducer';
import { contractorCallsPlayerReducer } from '../../reducers/contractorCallsPlayerReducer';

export function combineContractorCalltrackingListPageReducers() {
  return combineReducers<IContractorCalltrackingListPageState, TReduxActions>({
    roles: createSimpleReducer([]),
    regionList: createSimpleReducer([]),
    contractorCallsList: contractorCallsListDataReducer,
    filters: contractorCallsListFiltersReducer,
    callPopup: contractorCallsPopupReducer,
    playerStatus: contractorCallsPlayerReducer,
  });
}
