/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TCreateVillageModel = IModel<ICreateVillageRequest, TCreateVillageResponse>;

export interface ICreateVillageRequest {
  /** Площадь, га **/
  areaHectare: number;
  /** Автоматическое отключение заказа при достижении нулевого баланса **/
  autodisableOrder?: boolean | null;
  /** ID застройщика **/
  builderId: number;
  /** Описание **/
  description?: string | null;
  /** Месяц сдачи до **/
  finishMonthMax?: EFinishMonthMax | null;
  /** Месяц сдачи от **/
  finishMonthMin?: EFinishMonthMin | null;
  /** Квартал сдачи до **/
  finishQuarterMax?: EFinishQuarterMax | null;
  /** Квартал сдачи от **/
  finishQuarterMin?: EFinishQuarterMin | null;
  /** Год сдачи до **/
  finishYearMax: number;
  /** Год сдачи от **/
  finishYearMin: number;
  /** Этажность, до **/
  floorCountMax?: number | null;
  /** Этажность, от **/
  floorCountMin?: number | null;
  /** Признак бесплатности CPL **/
  freeCpl?: boolean | null;
  /** Скрывать цену КП **/
  hidePrice?: boolean | null;
  /** Признак CPL **/
  isCpl?: boolean | null;
  /** Признак "жирности" КП **/
  isFat?: boolean | null;
  /** КП в статусе "Премиум" **/
  isPremium?: boolean | null;
  /** Признак Тариф ПРО **/
  isPro?: boolean | null;
  /** Флаг скрытия КП из выдачи **/
  isVisible?: boolean | null;
  /** Широта **/
  lat?: number | null;
  /** Долгота **/
  lng?: number | null;
  /** ID локации (юр. адрес застройщика) **/
  locationId: number;
  /** Тип локации (юр. адрес застройщика) **/
  locationType: ELocationType;
  /** ID менеджера **/
  managerId?: number | null;
  /** Название **/
  name: string;
  /** Телефон **/
  phone?: string | null;
  /** Цена до, руб **/
  priceMax?: number | null;
  /** Цена от, руб **/
  priceMin?: number | null;
  /** Статус готовности **/
  readyStatus: EReadyStatus;
  /** Регион **/
  regionId: number;
  /** Статус продаж **/
  saleStatus: ESaleStatus;
  /** URL сайта **/
  siteUrl?: string | null;
  /** Общий статус **/
  totalStatus: ETotalStatus;
  /** Класс **/
  villageClass: EVillageClass;
}

export type TCreateVillageResponse = ICreateVillageResponse200 | ICreateVillageResponse400;

export interface ICreateVillageResponse200 extends IModelResponse<ICreateVillageResponse> {
  statusCode: 200;
}

export interface ICreateVillageResponse400 extends IModelResponse<ICreateVillageResponseError> {
  statusCode: 400;
}

export interface ICreateVillageResponse {
  /** Идентификатор объекта в БД **/
  id: number;
}

export interface ICreateVillageResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ICreateVillageResponse | ICreateVillageResponseError;

export enum EFinishMonthMax {
  /** Апрель **/
  April = 'april',
  /** Август **/
  August = 'august',
  /** Декабрь **/
  December = 'december',
  /** Февраль **/
  February = 'february',
  /** Январь **/
  January = 'january',
  /** Июль **/
  July = 'july',
  /** Июнь **/
  June = 'june',
  /** Март **/
  March = 'march',
  /** Май **/
  May = 'may',
  /** Ноябрь **/
  November = 'november',
  /** Октябрь **/
  October = 'october',
  /** Сентябрь **/
  September = 'september',
}

export enum EFinishMonthMin {
  /** Апрель **/
  April = 'april',
  /** Август **/
  August = 'august',
  /** Декабрь **/
  December = 'december',
  /** Февраль **/
  February = 'february',
  /** Январь **/
  January = 'january',
  /** Июль **/
  July = 'july',
  /** Июнь **/
  June = 'june',
  /** Март **/
  March = 'march',
  /** Май **/
  May = 'may',
  /** Ноябрь **/
  November = 'november',
  /** Октябрь **/
  October = 'october',
  /** Сентябрь **/
  September = 'september',
}

export enum EFinishQuarterMax {
  /** I **/
  First = 'first',
  /** IV **/
  Fourth = 'fourth',
  /** II **/
  Second = 'second',
  /** III **/
  Third = 'third',
}

export enum EFinishQuarterMin {
  /** I **/
  First = 'first',
  /** IV **/
  Fourth = 'fourth',
  /** II **/
  Second = 'second',
  /** III **/
  Third = 'third',
}

export enum ELocationType {
  /** Дом / строение **/
  House = 'house',
  /** Город / область **/
  Location = 'location',
  /** Улица / проспект **/
  Street = 'street',
}

export enum EReadyStatus {
  /** Строится **/
  Building = 'building',
  /** Построен **/
  Built = 'built',
  /** Заморожен **/
  Frozen = 'frozen',
  /** Планируется **/
  Planning = 'planning',
}

export enum ESaleStatus {
  /** Завершены **/
  Closed = 'closed',
  /** Открыты **/
  Opened = 'opened',
  /** Ожидание **/
  Pending = 'pending',
}

export enum ETotalStatus {
  /** Активный **/
  Active = 'active',
  /** Не активный **/
  Inactive = 'inactive',
  /** Ожидание **/
  Pending = 'pending',
  /** Реализован **/
  Sold = 'sold',
}

export enum EVillageClass {
  /** Бизнес **/
  Business = 'business',
  /** Комфорт **/
  Comfort = 'comfort',
  /** Эконом **/
  Economy = 'economy',
  /** Элит **/
  Elite = 'elite',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ICreateVillageResponse): TResponse200;
  400(response: ICreateVillageResponseError): TResponse400;
}
