import { getDateByMask } from '../datetime';
import { IVillageFilter } from '../../common/interfaces';
import { normalizeManagerList } from './normalizeManagerList';
import { normalizeModerationList } from './normalizeModerationList';

export function normalizeVillageFilters(filter: IVillageFilter): IVillageFilter {
  return {
    ...filter,
    moderationStatusList: normalizeModerationList(filter.moderationStatusList),
    finishFrom: getDateByMask(filter.finishFrom || undefined, 'MM.YYYY') || undefined,
    finishTo: getDateByMask(filter.finishTo || undefined, 'MM.YYYY') || undefined,
    managerList: normalizeManagerList(filter.managerList),
  };
}
