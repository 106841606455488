import * as React from 'react';

import { IConfig } from '@cian/config/shared';
import { ILogger } from '@cian/logger/shared';
import { IHttpApi } from '@cian/http-api/shared';

import * as pages from '../shared/pageNames';
import * as pageRoutes from '../shared/routes';
import { logComponentError } from './utils/log_component_error';
import { AppContext } from './AppContext';
import {
  hasAccessToCalltracking,
  EUserRoles,
  hasAccessToCountryside,
  hasAccessToContractorService,
} from './utils/permissions';

import './index.css';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { CPL_SETTINGS_ROUTE } from '../shared/routes';
import { logOutEventCreator } from './utils/logOut';

export interface IContext {
  httpApi: IHttpApi;
  logger: ILogger;
  config?: IConfig;
  roles: EUserRoles[];
}

export interface IAppProps {
  component: React.ReactNode;
  activeMenuItem?: string;
  authUrl?: string;
  httpApi: IHttpApi;
  logger: ILogger;
  config?: IConfig;
  isLargePage?: boolean;
  roles?: EUserRoles[];
}

export class App extends React.Component<IAppProps> {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logComponentError({
      error,
      errorInfo,
      logger: this.props.logger,
    });
  }

  public render() {
    const { activeMenuItem, component, config, httpApi, logger, roles = [] } = this.props;

    const activeItem = activeMenuItem || '';
    const showNavigationBar = !!activeMenuItem;

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const context = { config, httpApi, logger, roles };

    const email = config && config.get<string>('email');
    const managerId = config && config.get<number>('managerId');

    return (
      <AppContext.Provider value={context}>
        <Navbar bg="light" className="mb-4 border-bottom">
          <Container>
            <Navbar.Brand href="/">
              <span className="fas fa-home" />
            </Navbar.Brand>
            <Navbar.Collapse role="navigation">
              {showNavigationBar && (
                <Nav className="me-auto">
                  {hasAccessToCountryside(roles) && (
                    <>
                      <Nav.Link href={pageRoutes.FEATURES_ROUTE} active={activeItem.includes(pages.FEATURES)}>
                        Особенности
                      </Nav.Link>
                      <Nav.Link href={pageRoutes.BUILDERS_ROUTE} active={activeItem.includes(pages.BUILDERS)}>
                        Застройщики
                      </Nav.Link>
                      <Nav.Link href={pageRoutes.VILLAGES_ROUTE} active={activeItem.includes(pages.VILLAGES)}>
                        Коттеджные поселки
                      </Nav.Link>
                    </>
                  )}
                  {hasAccessToCalltracking(roles) && (
                    <Nav.Link
                      href={pageRoutes.CALLTRACKING_ROUTE}
                      active={activeItem === pages.CALLTRACKING || activeItem === pageRoutes.CALLTRACKING_ROUTE}
                    >
                      Звонки
                    </Nav.Link>
                  )}
                  {hasAccessToContractorService(roles) && (
                    <Nav.Link
                      href={pageRoutes.CONTRACTOR_CALLTRACKING_ROUTE}
                      active={activeItem === pages.CONTRACTOR_CALLTRACKING}
                    >
                      Звонки строителей
                    </Nav.Link>
                  )}
                </Nav>
              )}
              {email && (
                <Nav>
                  <NavDropdown title={email} id="basic-nav-dropdown">
                    {managerId && (
                      <NavDropdown.Item href={`${CPL_SETTINGS_ROUTE}?managerId=${managerId}`}>
                        Настройки CPL
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item href="javascript:void(0)" onClick={logOutEventCreator(config)}>
                      Выход
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>{component}</Container>
      </AppContext.Provider>
    );
  }
}
