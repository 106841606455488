/* eslint-disable react/jsx-no-target-blank */
import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { CplInfoItemPopupContainer } from '../../../containers/CplInfoItemPopupContainer';

import { TCplListItem } from '../../../types';
import { calculateCplFields, getCplSettingsToCalltrackingUrl } from '../../../utils';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { BriefLine } from '../../BriefLine';
import { SimpleTable } from '../../SimpleTable';

import * as styles from './CplInfoItem.css';

export interface ICplInfoItemProps {
  data: TCplListItem;
  period: IDatePeriod;
}

export class CplInfoItem extends React.PureComponent<ICplInfoItemProps> {
  public state = {
    isPopupOpened: false,
  };

  public render() {
    const {
      data: {
        villageName,
        cpl,
        nonChargeableCallsCount,
        plan,
        chargeableCallsCount,
        budget,
        villageId,
        builderId,
        manager,
      },
      period,
    } = this.props;
    const { isPopupOpened } = this.state;
    const { callsBalance, budgetSpent, budgetBalance } = calculateCplFields(this.props.data);
    const chargabeableCallsLink = (
      <a target="_blank" href={getCplSettingsToCalltrackingUrl(builderId, villageId, period)}>
        {numberToPrettyString(chargeableCallsCount)}
      </a>
    );

    return (
      <div className={styles['container']}>
        <strong className={styles['title']}>КП «{villageName}»</strong>
        <BriefLine title="CPL, ₽" value={cpl} />
        <BriefLine title="Звонки (другие)" value={nonChargeableCallsCount} />
        <SimpleTable
          title="Звонки (платные)"
          headers={['План', 'Получено', 'Остаток']}
          rows={[[plan, chargabeableCallsLink, callsBalance]]}
        />
        <SimpleTable
          title="Бюджет, ₽"
          headers={['Всего', 'Потрачено', 'Остаток']}
          rows={[[budget, budgetSpent, budgetBalance]]}
        />
        <Button type="button" onClick={() => this.setState({ isPopupOpened: true })}>
          Подробнее
        </Button>
        {isPopupOpened && (
          <CplInfoItemPopupContainer
            title={`КП «${villageName}»`}
            managerName={manager ? manager.email : undefined}
            villageId={villageId}
            onClose={() => this.setState({ isPopupOpened: false })}
          />
        )}
      </div>
    );
  }
}
