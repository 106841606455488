/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IVillageForListSchema } from '../../entities/schemas/VillageForListSchema';
import { ISortingSchema } from '../../entities/schemas/SortingSchema';

export type TGetVillageListModel = IModel<IGetVillageListRequest, TGetVillageListResponse>;

export interface IGetVillageListRequest {
  /** ID застройщика **/
  builderId?: number | null;
  /** Название застройщика **/
  builderName?: string | null;
  /** ID пользователя **/
  cianUserId?: number | null;
  /** Отдавать только КП с супер премиум опцией **/
  fatOnly?: boolean | null;
  /** Месяц и год сдачи, от (формат ММ.ГГГГ) **/
  finishFrom?: string | null;
  /** Месяц и год сдачи, до (формат ММ.ГГГГ) **/
  finishTo?: string | null;
  /** ID КП **/
  id?: number | null;
  /** Список ID менеджеров **/
  managerList?: number[] | null;
  /** Статус модерации **/
  moderationStatusList?: EModerationStatusList[] | null;
  /** Название КП **/
  name?: string | null;
  /** Номер заказа **/
  orderId?: number | null;
  /** Номер страницы **/
  p?: number | null;
  /** Размер страницы **/
  pageSize?: number | null;
  /** Статус готовности **/
  readyStatusList?: EReadyStatusList[] | null;
  /** Список ID регионов **/
  regionList?: number[] | null;
  /** Статус продаж **/
  saleStatusList?: ESaleStatusList[] | null;
  /** Сортировка **/
  sort?: ISortingSchema[] | null;
  /** Тариф КП **/
  tariff?: ETariff | null;
  /** Тариф КП **/
  tariffList?: ETariffList[] | null;
  /** Общий статус **/
  totalStatusList?: ETotalStatusList[] | null;
  /** Класс КП **/
  villageClassList?: EVillageClassList[] | null;
  /** Отдавать только КП с неработающими сайтами **/
  withBrokenUrlOnly?: boolean | null;
  /** Отдавать только КП у которых есть непривязанные объявления **/
  withUnboundOffers?: boolean | null;
}

export type TGetVillageListResponse = IGetVillageListResponse200 | IGetVillageListResponse400;

export interface IGetVillageListResponse200 extends IModelResponse<IGetVillageListResponse> {
  statusCode: 200;
}

export interface IGetVillageListResponse400 extends IModelResponse<IGetVillageListResponseError> {
  statusCode: 400;
}

export interface IGetVillageListResponse {
  /** Полное количество найденных записей по запросу (без пагинации) **/
  totalItems?: number | null;
  /** Количество страниц пагинации **/
  totalPages?: number | null;
  /** Список КП **/
  villageList?: IVillageForListSchema[] | null;
}

export interface IGetVillageListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetVillageListResponse | IGetVillageListResponseError;

export enum EModerationStatusList {
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
}

export enum EReadyStatusList {
  /** Строится **/
  Building = 'building',
  /** Построен **/
  Built = 'built',
  /** Заморожен **/
  Frozen = 'frozen',
  /** Планируется **/
  Planning = 'planning',
}

export enum ESaleStatusList {
  /** Завершены **/
  Closed = 'closed',
  /** Открыты **/
  Opened = 'opened',
  /** Ожидание **/
  Pending = 'pending',
}

export enum ETariff {
  /** Basic **/
  Basic = 'basic',
  /** CPL **/
  Cpl = 'cpl',
  /** Бесплатный CPL **/
  FreeCpl = 'freeCpl',
  /** Не подключен **/
  NotSet = 'notSet',
  /** Premium **/
  Premium = 'premium',
}

export enum ETariffList {
  /** Basic **/
  Basic = 'basic',
  /** CPL **/
  Cpl = 'cpl',
  /** Бесплатный CPL **/
  FreeCpl = 'freeCpl',
  /** Не подключен **/
  NotSet = 'notSet',
  /** Premium **/
  Premium = 'premium',
}

export enum ETotalStatusList {
  /** Активный **/
  Active = 'active',
  /** Не активный **/
  Inactive = 'inactive',
  /** Ожидание **/
  Pending = 'pending',
  /** Реализован **/
  Sold = 'sold',
}

export enum EVillageClassList {
  /** Бизнес **/
  Business = 'business',
  /** Комфорт **/
  Comfort = 'comfort',
  /** Эконом **/
  Economy = 'economy',
  /** Элит **/
  Elite = 'elite',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetVillageListResponse): TResponse200;
  400(response: IGetVillageListResponseError): TResponse400;
}
