import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  fetchAdminSetCallType,
  IAdminSetCallTypeRequest,
  TAdminSetCallTypeResponse,
} from '../repositories/countryside-calltracking/v1/admin-set-call-type';
import { getFormattedError } from '../utils';

export async function setCallType(httpApi: IHttpApi, logger: ILogger, parameters: IAdminSetCallTypeRequest) {
  try {
    const { response }: TAdminSetCallTypeResponse = await fetchAdminSetCallType({ httpApi, parameters });

    if (typeof response === 'object' && 'errors' in response) {
      throw response;
    }

    return response;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in setCallType', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in setCallType' });

      throw formattedError;
    }

    throw error;
  }
}
