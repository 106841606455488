import {
  fetchCreateVillage,
  ICreateVillageRequest,
  ICreateVillageResponse,
  ICreateVillageResponseError,
} from '../repositories/countryside/v1/create-village';

import { createSimpleRequest } from './createSimpleRequest';

export const createVillage = createSimpleRequest<
  ICreateVillageRequest,
  ICreateVillageResponse,
  ICreateVillageResponseError
>('createVillage', fetchCreateVillage);
