import { IPagination, IVillageFilter } from '../common/interfaces';
import { stringifyVillageFilters } from './filters';

export function getQueryStringByFilters(pagination: IPagination, filter: IVillageFilter, newPage?: number): string {
  const params = [];

  params.push(`p=${newPage || pagination.p}`);
  if (pagination.pageSize) {
    params.push(`pageSize=${pagination.pageSize}`);
  }

  params.push(stringifyVillageFilters(filter));

  return params.filter(param => param !== '').join('&');
}
