import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  fetchAdminGetCalls,
  IAdminGetCallsRequest,
  IAdminGetCallsResponseError,
  IAdminGetCallsResponse,
  TAdminGetCallsResponse,
} from '../repositories/countryside-calltracking/v1/admin-get-calls';
import { getFormattedError } from '../utils';
import { isResponseContainsKey } from './helpers';
import { isContainsErrorMessage } from '../utils/errorHelpers';
import { CALLTRACKING_BUILDER_NO_ACCESS_MSG, CALLTRACKING_BUILDER_NO_OFFERS_MSG } from '../constants/calltracking';

export async function getCalls(
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IAdminGetCallsRequest,
  ignoreBuilderError?: boolean,
) {
  try {
    const { response }: TAdminGetCallsResponse = await fetchAdminGetCalls({ httpApi, parameters });

    if (!response || isResponseContainsKey<IAdminGetCallsResponseError, IAdminGetCallsResponse>(response, 'errors')) {
      throw response;
    }

    return response;
  } catch (error) {
    const meta = { message: error.message, stackTrace: error.stack };
    if (isContainsErrorMessage(CALLTRACKING_BUILDER_NO_OFFERS_MSG, error)) {
      if (ignoreBuilderError) {
        logger.warning(CALLTRACKING_BUILDER_NO_OFFERS_MSG, meta);

        return null;
      } else {
        /* В дальнейшем планируется переделать на кастомные ошибки peperrors */
        throw new Error(CALLTRACKING_BUILDER_NO_OFFERS_MSG);
      }
    } else if (isContainsErrorMessage(CALLTRACKING_BUILDER_NO_ACCESS_MSG, error)) {
      if (ignoreBuilderError) {
        logger.warning(CALLTRACKING_BUILDER_NO_ACCESS_MSG, meta);

        return null;
      }

      throw new Error(CALLTRACKING_BUILDER_NO_ACCESS_MSG);
    } else if (error instanceof HttpTimeoutError) {
      logger.warning('TimeoutError in getCalls', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getCalls' });

      throw formattedError;
    }

    throw error;
  }
}
