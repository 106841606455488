import * as React from 'react';

import * as styles from './AudioPlayer.css';

export interface IAudioPlayerProps {
  src: string | null | undefined;
  callId: number | null | undefined;
  isPaused?: boolean;
  playOnInit?: boolean;
  onTrackEnd(): void;
  onTogglePlay(): void;
  onTogglePause(): void;
}

export class AudioPlayer extends React.Component<IAudioPlayerProps> {
  private audio = React.createRef<HTMLAudioElement>();

  public componentDidMount() {
    const player = this.audio.current;
    if (!player) {
      return;
    }

    player.addEventListener('ended', this.onTrackEnd);
    player.addEventListener('error', this.onTrackEnd);

    const srcElement = player.querySelector('source');
    if (srcElement) {
      srcElement.addEventListener('error', this.onTrackEnd);
    }

    this.addPlayerStateEvents(player);

    const { src, isPaused, playOnInit } = this.props;

    if (src === null) {
      this.onTrackEnd();
    }

    if (playOnInit && src && !isPaused) {
      player.play();
    }
  }

  public componentWillUnmount() {
    const player = this.audio.current;
    if (!player) {
      return;
    }

    player.removeEventListener('ended', this.onTrackEnd);
    player.removeEventListener('error', this.onTrackEnd);

    const srcElement = player.querySelector('source');
    if (srcElement) {
      srcElement.removeEventListener('error', this.onTrackEnd);
    }

    this.removePlayerStateEvents(player);
  }

  public componentDidUpdate(prevProps: IAudioPlayerProps) {
    const player = this.audio.current;
    const { callId, isPaused, src } = this.props;

    if (player) {
      this.removePlayerStateEvents(player);
    }

    if (src === null) {
      this.onTrackEnd();

      return;
    }

    if (!src && player) {
      player.pause();
    }

    if (!src || !player) {
      return;
    }

    if (callId !== prevProps.callId) {
      player.pause();
      player.load();
      if (!this.props.isPaused) {
        player.play();
      }
    }

    if (!isPaused && player.paused) {
      player.play();
    }

    if (isPaused && !player.paused) {
      player.pause();
    }

    this.addPlayerStateEvents(player);
  }

  public render() {
    const { src, callId } = this.props;

    return (
      <div className={styles['container']} id={`song_${callId}`}>
        <audio ref={this.audio} preload="auto" loop={false} controls>
          <source src={src || ''} type="audio/mp3" />
        </audio>
      </div>
    );
  }

  private onTrackEnd = () => this.props.onTrackEnd();

  private addPlayerStateEvents = (player: HTMLAudioElement) => {
    player.addEventListener('play', this.onTogglePlay);
    player.addEventListener('pause', this.onTogglePause);
  };

  private removePlayerStateEvents = (player: HTMLAudioElement) => {
    player.removeEventListener('play', this.onTogglePlay);
    player.removeEventListener('pause', this.onTogglePause);
  };

  private onTogglePlay = () => this.props.onTogglePlay();

  private onTogglePause = () => {
    setTimeout(() => {
      // Не позволяем стриггерить паузу при смене src
      const audio = this.audio.current;
      if (!audio || !audio.paused) {
        return;
      }

      this.props.onTogglePause();
    });
  };
}
