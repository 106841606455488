import * as React from 'react';
import { Provider } from 'react-redux';

import {
  CplSettingsDesktopViewContainer,
  CplSettingsMobileViewContainer,
} from '../../containers/CplSettingsViewContainer';
import { ICplSettingsPageState, TReduxStore } from '../../types/redux';
import { updateCplSettingsBrowserUrl } from '../../utils/urinator';

export interface ICplSettingsProps {
  store: TReduxStore<ICplSettingsPageState>;
}

export function CplSettingsPage(props: ICplSettingsProps) {
  const { store } = props;

  React.useEffect(() => {
    updateCplSettingsBrowserUrl(store.getState());
  }, []);

  return (
    <Provider store={store}>
      <CplSettingsDesktopViewContainer />
      <CplSettingsMobileViewContainer />
    </Provider>
  );
}
