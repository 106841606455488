/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TRailwaysSearchRailwaysByDirectionModel,
  IRailwaysSearchRailwaysByDirectionRequest,
  IMappers,
  IRailwaysSearchRailwaysByDirectionResponse,
  IRailwaysSearchRailwaysByDirectionResponseError,
  TRailwaysSearchRailwaysByDirectionResponse,
} from './types';

const defaultConfig: TRailwaysSearchRailwaysByDirectionModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/railways/search-railways-by-direction/',
  hostType: 'api',
} as TRailwaysSearchRailwaysByDirectionModel;

function createRailwaysSearchRailwaysByDirectionModel(
  parameters: IRailwaysSearchRailwaysByDirectionRequest,
): TRailwaysSearchRailwaysByDirectionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IRailwaysSearchRailwaysByDirectionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IRailwaysSearchRailwaysByDirectionRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchRailwaysSearchRailwaysByDirection<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IRailwaysSearchRailwaysByDirectionOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TRailwaysSearchRailwaysByDirectionResponse
> {
  const { response, statusCode } = await httpApi.fetch(
    createRailwaysSearchRailwaysByDirectionModel(parameters),
    config,
  );

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IRailwaysSearchRailwaysByDirectionResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IRailwaysSearchRailwaysByDirectionResponseError);
    }
  }

  return { response, statusCode } as TRailwaysSearchRailwaysByDirectionResponse;
}

export { defaultConfig, createRailwaysSearchRailwaysByDirectionModel, fetchRailwaysSearchRailwaysByDirection };
