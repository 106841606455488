import { parseURI } from '@cian/http-api/shared';
import { ICplSettingsPageState } from '../../types/redux';
import { areDatePeriodsEqual } from '../areDatePeriodsEqual';
import { getDatePeriod } from '../getDatePeriod';

export function updateCplSettingsBrowserUrl(state: ICplSettingsPageState) {
  const {
    common: { builderId },
    filters: { period, villages, managerId },
    cplHistory: { selectedVillageId: historyVillageId },
    cplData: { settings },
  } = state;
  const firstVillageId = settings[0] && settings[0].villageId;
  const queryString = [
    builderId && `builderId=${builderId}`,
    managerId && `managerId=${managerId}`,
    !areDatePeriodsEqual(period, getDatePeriod()) && `period=${period.month}.${period.year}`,
    villages.length && `villageIds=${villages.join(',')}`,
    firstVillageId !== historyVillageId && villages.length && `historyVillageId=${historyVillageId}`,
  ]
    .filter(Boolean)
    .join('&');

  const { path } = parseURI(window.location.href);

  window.history.replaceState({}, document.title, `${path}?${queryString}`);
}
