// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IAdminSetConstructorCallTypeRequest,
  TAdminSetConstructorCallTypeModel,
  IMappers,
  TAdminSetConstructorCallTypeResponse,
  IAdminSetConstructorCallTypeResponseError,
} from './types';

export const defaultConfig: TAdminSetConstructorCallTypeModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/set-constructor-call-type/',
  requestType: 'json',
} as TAdminSetConstructorCallTypeModel;

export function createAdminSetConstructorCallTypeModel(
  parameters: IAdminSetConstructorCallTypeRequest,
): TAdminSetConstructorCallTypeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminSetConstructorCallTypeOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
  parameters: IAdminSetConstructorCallTypeRequest;
}

export async function fetchAdminSetConstructorCallType<TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IAdminSetConstructorCallTypeOptions<TResponse400>): Promise<TAdminSetConstructorCallTypeResponse | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createAdminSetConstructorCallTypeModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IAdminSetConstructorCallTypeResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TAdminSetConstructorCallTypeResponse;
}
