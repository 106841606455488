import { prettifyValue } from '../../utils';

import * as styles from './BriefLine.css';

export interface IBriefLineProps {
  title: string;
  value: string | number | null | undefined;
}

export function BriefLine({ title, value }: IBriefLineProps) {
  return (
    <div className={styles['container']}>
      <strong className={styles['title']}>{title}</strong>
      <span className={styles['value']}>{prettifyValue(value)}</span>
    </div>
  );
}
