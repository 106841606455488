import { parseURI } from '@cian/http-api/shared';
import { IContractorCalltrackingListPageState } from '../../types/redux';

export function updateContractorCalltrackingPageBrowserUrl(state: IContractorCalltrackingListPageState) {
  const {
    // contractorId,
    // contractorName,
    // dateFrom,
    // dateTo,
    page,
    pageSize,
    // projectId,
    // projectName,
    // regionId,
    // sourceRegionId,
    // status,
  } = state.filters;

  const queryString = [
    // contractorId && `contractorId=${contractorId}`,
    // contractorName && `contractorName=${contractorName}`,
    // dateFrom && `dateFrom=${encodeURIComponent(dateFrom)}`,
    // dateTo && `dateTo=${encodeURIComponent(dateTo)}`,
    page && `page=${page}`,
    pageSize && `pageSize=${pageSize}`,
    // projectId && `projectId=${projectId}`,
    // projectName && `projectName=${projectName}`,
    // regionId && `regionId=${regionId.join(',')}`,
    // sourceRegionId && `sourceRegionId=${sourceRegionId.join(',')}`,
    // status && `status=${status.join(',')}`,
  ]
    .filter(Boolean)
    .join('&');

  const { path } = parseURI(window.location.href);

  window.history.replaceState({}, document.title, `${path}${queryString.length ? `?${queryString}` : ''}`);
}
