import { errorMessageMapperCreator } from '../../../utils/errorMapperCreator';

const errors: { [key: string]: string } = {
  'builder has no required role': 'У указанного пользователя нет роли "Застройщик КП"',
  'Violate unique key constraint. Duplicate value for builder_cian_user': 'Указанный Cian User ID уже используется',
  'failed to update builder profile, try again': 'При обновлении ЛК застройщика произошла ошибка, попробуйте еще раз',
  'agent does not exists': 'Аккаунт c таким cianUserId не зарегистрирован',
  '(^.+?@.+?\\s)is not registered': 'Аккаунт $1не зарегистрирован',
};

const UNKNOWN_UPDATE_BUILDER_ERROR_MESSAGE = 'При изменении застройщика произошла ошибка';

export const errorMessageMapper = errorMessageMapperCreator(errors, UNKNOWN_UPDATE_BUILDER_ERROR_MESSAGE);
