import * as React from 'react';

import { IGetBuilderListRequest } from '../../../node/repositories/countryside/v1/get-builder-list';
import { AppContext } from '../../AppContext';
import { IAlert, IBuilderFilter, IBuilderListItem, IPagination, IRegion } from '../../common/interfaces';
import { getBuilderAmocrmUrl, getBuilderList, IGetBuildersResponse } from '../../http';
import { BUILDERS_ROUTE, CREATE_BUILDER_ROUTE } from '../../routes';
import { getBuildersQueryStringByFilters, getDateFromISO } from '../../utils';
import { Pagination } from '../pagination';
import { BuilderFilter } from './builder_filter';
import { BuilderList } from './builder_list';
import { DEFAULT_ALERT } from '../../utils/constants';
import { Alert } from '../Alert';
import { getDataFromBuilderResponse } from '../../utils/getDataFromBuilderResponse';
import * as styles from './Builders.css';
import { NotificationContainer, NotificationManager } from '../../utils/showNotification';
import { Button } from 'react-bootstrap';

export interface IBuildersProps {
  builderList: IBuilderListItem[];
  pagination: IPagination;
  filter: IBuilderFilter;
  regionList: IRegion[];
  totalItems: number;
  readOnly?: boolean;
}

export interface IBuildersState {
  builderList: IBuilderListItem[];
  pagination: IPagination;
  filter: IBuilderFilter;
  totalItems: number;
  alert: IAlert;
}

export class Builders extends React.Component<IBuildersProps, IBuildersState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public constructor(props: IBuildersProps) {
    super(props);

    const { builderList, pagination, filter, totalItems } = this.props;

    this.state = {
      alert: DEFAULT_ALERT,
      builderList: builderList || [],
      filter: filter || {},
      pagination: pagination || { pageSize: 20, p: 1 },
      totalItems,
    };
  }

  public componentDidUpdate(_: IBuildersProps, prevState: IBuildersState) {
    if (!prevState.alert.isVisible && this.state.alert.isVisible) {
      setTimeout(() => {
        this.setState({
          alert: DEFAULT_ALERT,
        });
      }, 2000);
    }
  }

  public render() {
    const { regionList, readOnly } = this.props;
    const { builderList, pagination, filter, alert, totalItems } = this.state;

    return (
      <>
        <div className={styles['container']}>
          {alert.isVisible && (
            <Alert
              onClick={() =>
                this.setState({
                  alert: {
                    ...this.state.alert,
                    isVisible: false,
                  },
                })
              }
              text={alert.text}
              type={alert.type}
            />
          )}
          <div>
            <h2 className={styles['heading_title']}>Застройщики</h2>
            {!readOnly && (
              <Button variant="success" href={CREATE_BUILDER_ROUTE}>
                Создать
              </Button>
            )}
          </div>
          <BuilderFilter regionList={regionList} onFilter={this.handleFiltering} filter={filter} />
          <div className={styles['total_count']}>
            <strong>Найдено застройщиков:</strong> {totalItems}
          </div>
          <BuilderList builderList={builderList} handleRedirectToAmo={this.handleRedirectToAmo} />
          <Pagination
            activePage={pagination.p}
            pageSize={pagination.pageSize}
            pageCount={pagination.totalPages || 1}
            onPageChange={this.handlePaginationChange('p')}
            onPageCountChange={this.handlePaginationChange('pageSize')}
            getQueryString={num => `${BUILDERS_ROUTE}/${num}`}
          />
        </div>
        <NotificationContainer />
      </>
    );
  }

  private handleRedirectToAmo = async (id: number) => {
    const { httpApi, logger } = this.context;
    try {
      const response = await getBuilderAmocrmUrl(httpApi, logger, { id });
      if (!(response && response.url)) {
        NotificationManager.error('Застройщик не найден в amoCRM');

        return;
      }

      window.open(response.url);
    } catch (e) {
      NotificationManager.error(e.message);
    }
  };

  private handleFiltering = (filter: IBuilderFilter) => {
    this.setState(
      {
        filter,
      },
      () => {
        this.refreshBuilderList(true);
      },
    );
  };

  private handlePaginationChange = (propName: string) => (value?: number | string) => {
    const shouldResetToFirstPage = propName === 'pageSize';

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          [propName]: value,
        },
      },
      () => {
        this.refreshBuilderList(shouldResetToFirstPage);
      },
    );
  };

  private refreshBuilderList = async (resetToFirstPage?: boolean) => {
    const { httpApi, logger } = this.context;
    const { pagination, filter } = this.state;
    const data = {
      ...filter,
      createdFrom: getDateFromISO(filter.createdFrom),
      createdTo: getDateFromISO(filter.createdTo),
      p: resetToFirstPage ? 1 : pagination.p,
      pageSize: pagination.pageSize,
      updatedFrom: getDateFromISO(filter.updatedFrom),
      updatedTo: getDateFromISO(filter.updatedTo),
    } as IGetBuilderListRequest;

    this.setState(
      {
        builderList: [],
        pagination: {
          ...pagination,
          p: resetToFirstPage ? 1 : pagination.p,
        },
      },
      () => {
        window.history.replaceState(
          {},
          'Список застройщиков коттеджных поселков',
          `${BUILDERS_ROUTE}/${getBuildersQueryStringByFilters(pagination, filter)}`,
        );
      },
    );

    try {
      const response = await getBuilderList(httpApi, logger, data);
      const { builderList, totalItems, totalPages } = getDataFromBuilderResponse(response as IGetBuildersResponse);

      this.setState({
        builderList,
        pagination: {
          ...this.state.pagination,
          totalItems,
          totalPages,
        },
        totalItems,
      });
    } catch (e) {
      this.setState({
        alert: {
          isVisible: true,
          text: 'При обновлении списка застройщиков произошла ошибка',
          type: 'danger',
        },
      });
    }
  };
}
