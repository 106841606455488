/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCommentCallModel,
  ICommentCallRequest,
  IMappers,
  ICommentCallResponse,
  ICommentCallResponseError,
  TCommentCallResponse,
} from './types';

const defaultConfig: TCommentCallModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/comment-call/',
  hostType: 'api',
} as TCommentCallModel;

function createCommentCallModel(parameters: ICommentCallRequest): TCommentCallModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICommentCallOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICommentCallRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCommentCall<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICommentCallOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TCommentCallResponse> {
  const { response, statusCode } = await httpApi.fetch(createCommentCallModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICommentCallResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICommentCallResponseError);
    }
  }

  return { response, statusCode } as TCommentCallResponse;
}

export { defaultConfig, createCommentCallModel, fetchCommentCall };
