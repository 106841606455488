import * as React from 'react';

import { NumberField } from '../number_field';

import * as styles from './EditableField.css';

export interface IEditableFieldProps {
  value: number | undefined;
  canEdit: boolean;
  onChange?(value: number | undefined): void;
  onBlur(value: number | undefined): void;
}

export function EditableField({ value: initialValue, canEdit, onChange, onBlur }: IEditableFieldProps) {
  const [isDirty, setDirtyState] = React.useState(false);
  const [value, setValue] = React.useState(initialValue);

  if (!canEdit) {
    return <div>{value}</div>;
  }

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value && Number(e.currentTarget.value);
    onBlur(val || val === 0 ? val : undefined);
    setDirtyState(false);
  };

  return (
    <div className={styles['container']}>
      <NumberField
        min={0}
        value={String(value)}
        invalid={typeof value === 'undefined'}
        onChange={value => {
          setValue(value as number);
          setDirtyState(true);
          if (onChange) {
            onChange(value || value === 0 ? value : undefined);
          }
        }}
        onBlur={isDirty ? blurHandler : undefined}
      />
    </div>
  );
}
