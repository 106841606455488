import { ERoutes } from 'shared/common/types/routes';
import { EUserRoles } from 'shared/common/types/roles';
import { EPageTypes } from 'shared/common/types/pageTypes';

export const ADMIN_ROLES = [EUserRoles.Admin, EUserRoles.CalltrackingAdmin];

export const ALL_ROLES = Object.values(EUserRoles);

export const COUNTRYSIDE_ROLES = [EUserRoles.ReadOnly, EUserRoles.Admin];

export const BUILDERS_ROLES = [EUserRoles.ContractsAdmin, EUserRoles.ContractsReadOnly];

export const CALLTRACKING_ROLES = [
  EUserRoles.CalltrackingReadOnly,
  EUserRoles.CalltrackingAdmin,
  EUserRoles.CalltrackingBuilder,
];

export const CALLTRACKING_MANAGER_ROLES = [EUserRoles.CalltrackingReadOnly, EUserRoles.CalltrackingAdmin];

const MAP_PAGE_TYPE_TO_ROLES: Partial<Record<EPageTypes, EUserRoles[]>> = {
  [EPageTypes.VillageFeatures]: COUNTRYSIDE_ROLES,
};

// узнаём первый доступный урл для ролей
export function getRedirectPathByRoles(roles: EUserRoles[]) {
  switch (roles[0]) {
    case EUserRoles.ReadOnly:
    case EUserRoles.Admin:
      return ERoutes.Villages;

    case EUserRoles.CalltrackingReadOnly:
    case EUserRoles.CalltrackingAdmin:
    case EUserRoles.CalltrackingBuilder:
      return ERoutes.VillageCalltracking;

    default:
      return ERoutes.Auth;
  }
}

// Проверяем, доступег ли урл переданным ролям
export function hasAccess(pageType: EPageTypes, rolesArray: EUserRoles[]) {
  return rolesArray.some(role => {
    const pageAccessRoles = MAP_PAGE_TYPE_TO_ROLES[pageType];

    return pageAccessRoles && pageAccessRoles.includes(role);
  });
}

// Проверяем есть ли у пользователя хоть одна роль из набора,
export function hasAccessByRoles<T>(userRoles: T[], accessRoles: T[]) {
  return userRoles.some(role => accessRoles.includes(role as T));
}

// Проверяем есть ли у пользователя роль для доступа к звонкам
export function hasAccessToCalltracking(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, CALLTRACKING_ROLES);
}

// Проверяем есть ли у пользователя роль менеджера
export function hasCalltrackingManagerRole(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, CALLTRACKING_MANAGER_ROLES);
}

// Проверяем есть у пользователя роль для доступа к загородке
export function hasAccessToCountryside(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, COUNTRYSIDE_ROLES);
}

// Проверяем есть у пользователя роль для доступа к админке строителей
export function hasAccessToBuilders(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, BUILDERS_ROLES);
}

// Проверяем является-ли пользователь менеджером в строителях
export function hasBuildersManagerRole(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, BUILDERS_ROLES);
}

// Проверяем является-ли пользователь администратором в строителях
export function hasBuildersAdminRole(rolesArray: EUserRoles[]) {
  return rolesArray.includes(EUserRoles.ContractsAdmin);
}

// Проверяем есть ли доступ к админке строителя
export function hasAccessToBuildersAdminPages(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, BUILDERS_ROLES);
}

// Проверяем, является ли пользователь строителем
export function isBuilder(rolesArray: EUserRoles[]) {
  return rolesArray.includes(EUserRoles.Contractor) && !hasAccessByRoles(rolesArray, [EUserRoles.ContractsAdmin]);
}

// Проверяем, есть ли у пользователя доступ к изменению бюджета Cpl
export function hasAccessToCplBudget(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, [...ADMIN_ROLES, EUserRoles.CalltrackingCplBudget]);
}

// Проверяем, есть ли у пользователя доступ к изменению плана Cpl
export function hasAccessToCplPlan(rolesArray: EUserRoles[]) {
  return hasAccessByRoles(rolesArray, [...ADMIN_ROLES, EUserRoles.CalltrackingCplPlan]);
}
