import { CommuteTypes, TCommute } from '../common/interfaces';

export const MOSCOW_REGION_ID = 1;
export const SPB_REGION_ID = 2;
export const MOSCOW_AREA_REGION_ID = 4593;
export const SPB_AREA_REGION_ID = 4588;

export const getCommuteTypeByRegionId = (regionId: number): TCommute => {
  switch (regionId) {
    case MOSCOW_REGION_ID:
    case MOSCOW_AREA_REGION_ID:
      return CommuteTypes.Mkad;
    case SPB_REGION_ID:
    case SPB_AREA_REGION_ID:
      return CommuteTypes.Kad;
    default:
      return CommuteTypes.None;
  }
};

export const DC_REGIONS_IDS: { [key: number]: number } = {
  [MOSCOW_REGION_ID]: MOSCOW_AREA_REGION_ID,
  [SPB_REGION_ID]: SPB_AREA_REGION_ID,
};

const mapRegionsForCompare: { [key: number]: number } = {
  [MOSCOW_REGION_ID]: MOSCOW_AREA_REGION_ID,
  [SPB_REGION_ID]: SPB_AREA_REGION_ID,
};

/**
 * Сравнивает два региона с учетом особенностей слияния некоторых регионов
 * @param regionA {number}
 * @param regionB {number}
 * @return {boolean} true если совпадают и false если нет
 */
export function isSimilarRegions(regionA: number, regionB: number): boolean {
  return (mapRegionsForCompare[regionA] || regionA) === (mapRegionsForCompare[regionB] || regionB);
}
