import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  fetchGetUserRoleList,
  IGetUserRoleListResponseError,
  IGetUserRoleListResponse,
  TGetUserRoleListResponse,
} from '../repositories/countryside/v2/get-user-role-list';
import { getFormattedError } from '../utils';
import { isResponseContainsKey } from './helpers';

interface IParams {
  email: string;
}

export async function getUserRoleList(httpApi: IHttpApi, logger: ILogger, parameters: IParams) {
  try {
    const { response, statusCode }: TGetUserRoleListResponse = await fetchGetUserRoleList({ httpApi, parameters });

    if (
      statusCode === 400 ||
      isResponseContainsKey<IGetUserRoleListResponseError, IGetUserRoleListResponse>(response, 'errors')
    ) {
      //tslint:disable max-line-length
      throw response;
    }

    return response;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getUserRoleList', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getUserRoleList' });

      throw formattedError;
    }

    throw error;
  }
}
