import { TCplListItem } from '../../../types';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { CplInfoItem } from '../CplInfoItem';
import { CplSummaryInfo } from '../CplSummaryInfo';

import * as styles from './CplSettingsMobileList.css';

export interface ICplSettingsMobileListProps {
  list: TCplListItem[];
  period: IDatePeriod;
  managerId: number | undefined;
  builderId: number | undefined;
}

export function CplSettingsMobileList({ list, period, managerId, builderId }: ICplSettingsMobileListProps) {
  if (!list.length) {
    return (
      <div className={styles['container']}>
        <strong>По заданным параметрам нет информации</strong>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <CplSummaryInfo list={list} period={period} managerId={managerId} builderId={builderId} />
      {list.map(item => (
        <CplInfoItem key={item.villageId} data={item} period={period} />
      ))}
    </div>
  );
}
