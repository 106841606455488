import { connect } from 'react-redux';
import { updateCplHistory } from '../../actions/cplHistory';
import { getDropdownValuesFromCplVillageData } from '../../selectors';

import { ICplSettingsPageState } from '../../types/redux';
import { CplSettingsHistory, ICplSettingsHistoryProps } from '../../components/CplSettingsPage/CplSettingsHistory';
import { TTypedThunkDispatch } from '../../types/thunk';
import { hasAnyAdminRole } from '../../utils/hasAnyAdminRole';

export type TStoreProps = Pick<ICplSettingsHistoryProps, 'list' | 'isAdmin' | 'selectedVillageId' | 'villages'>;
export type TDispatchProps = Pick<ICplSettingsHistoryProps, 'onVillageChange'>;

export function mapStateToProps(state: ICplSettingsPageState): TStoreProps {
  const {
    filters: { villages: villagesIds },
    cplHistory: { history, selectedVillageId },
    roles,
  } = state;

  const villages = getDropdownValuesFromCplVillageData(state).filter(({ value }) =>
    villagesIds.includes(Number(value)),
  );

  return {
    list: history,
    isAdmin: hasAnyAdminRole(roles),
    villages,
    selectedVillageId,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    onVillageChange: (value: string) => dispatch(updateCplHistory(Number(value))),
  };
}

export const CplSettingsHistoryContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CplSettingsHistory);
