import { TCplListItem } from '../types';

export function calculateCplFields({ plan, chargeableCallsCount, cpl, balance }: TCplListItem) {
  // Остаток звонков
  const callsBalance = (plan || 0) - chargeableCallsCount;
  // Потрачено бюджета
  const budgetSpent = chargeableCallsCount * (cpl || 0);
  // Остаток бюджета
  const budgetBalance = balance || 0;

  return { callsBalance, budgetSpent, budgetBalance };
}
