import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { Notification } from '../components/Notification';

class NotificationManagerClass {
  public error(message: string, title: string = 'Ошибка') {
    return toast.error(<Notification title={title} message={message} />);
  }
  public success(message: string, title?: string) {
    return toast.success(<Notification title={title} message={message} />);
  }
  public warning(message: string, title?: string) {
    return toast.warn(<Notification title={title} message={message} />);
  }
}

export class NotificationContainer extends React.Component {
  public render() {
    return <ToastContainer />;
  }
}

export const NotificationManager = new NotificationManagerClass();
