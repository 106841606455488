/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetAddressModel,
  IGetAddressRequest,
  IMappers,
  IGetAddressResponse,
  IGetAddressResponseError,
  TGetAddressResponse,
} from './types';

const defaultConfig: TGetAddressModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v2/get-address/',
  hostType: 'api',
} as TGetAddressModel;

function createGetAddressModel(parameters: IGetAddressRequest): TGetAddressModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetAddressOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetAddressRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetAddress<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetAddressOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetAddressResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetAddressModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetAddressResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetAddressResponseError);
    }
  }

  return { response, statusCode } as TGetAddressResponse;
}

export { defaultConfig, createGetAddressModel, fetchGetAddress };
