import * as React from 'react';
import { AppContext } from '../../AppContext';

import { IAlert, IPagination, IRegion, IVillageFilter } from '../../common/interfaces';
import { getVillageList } from '../../http';
import { IGetVillageListRequest, IGetVillageListResponse } from '../../repositories/countryside/v1/get-village-list';
import { CREATE_VILLAGE_ROUTE, VILLAGES_ROUTE } from '../../routes';
import { DEFAULT_ALERT } from '../../utils/constants';
import { Alert } from '../Alert';
import { Pagination } from '../pagination';
import { VillageFilter } from './village_filter';
import { VillageList } from './villages_list';

import * as styles from './index.css';
import { saveFile } from '../../../browser/utils/saveFile';
import { GET_VILLAGE_TIMELINE } from '../../common/request_path';
import { getQueryStringByFilters } from '../../utils/getQueryStringByFilters';
import { normalizeVillageFilters } from '../../utils/helpers/normalizeVillageFilters';
import { getDataFromVillageResponse } from '../../utils/getDataFromVillageResponse';
import { IVillageForListSchema } from '../../repositories/countryside/entities/schemas/VillageForListSchema';
import { IManagerForListSchema } from '../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { ERoles } from '../../repositories/countryside/v2/get-user-role-list';
import { hasCalltrackingManagerRole } from '../../utils/permissions';
import { Button } from 'react-bootstrap';

interface IProps {
  apiUrl: string;
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  villageList: IVillageForListSchema[];
  pagination: IPagination;
  filter: IVillageFilter;
  totalItems: number;
  readOnly?: boolean;
  roles: ERoles[];
}

interface IState {
  alert: IAlert;
  villageList: IVillageForListSchema[];
  pagination: IPagination;
  filter: IVillageFilter;
  totalItems: number;
}

const DEFAULT_PAGINATION = {
  p: 1,
  pageSize: 20,
};

export class Villages extends React.Component<IProps, IState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public constructor(props: IProps) {
    super(props);

    const { villageList, pagination, filter, totalItems } = this.props;

    this.state = {
      alert: DEFAULT_ALERT,
      filter: filter || {},
      pagination: pagination || DEFAULT_PAGINATION,
      villageList: villageList || [],
      totalItems,
    };
  }

  public componentDidUpdate(_: IProps, prevState: IState) {
    if (!prevState.alert.isVisible && this.state.alert.isVisible) {
      setTimeout(() => {
        this.setState({
          alert: DEFAULT_ALERT,
        });
      }, 2000);
    }
  }

  public render() {
    const { managerList, readOnly, regionList, apiUrl, roles } = this.props;

    const { villageList, pagination, filter, alert, totalItems } = this.state;

    const isCalltrackingManager = hasCalltrackingManagerRole(roles);

    return (
      <div className={styles['container']}>
        {alert.isVisible && (
          <Alert
            onClick={() =>
              this.setState({
                alert: { ...this.state.alert, isVisible: false },
              })
            }
            text={alert.text}
            type={alert.type}
          />
        )}
        <div>
          <h2 className={styles['heading_title']}>Коттеджные посёлки</h2>
          {!readOnly && (
            <Button variant="success" href={CREATE_VILLAGE_ROUTE}>
              Создать
            </Button>
          )}
        </div>
        <VillageFilter
          regionList={regionList}
          managerList={managerList}
          onFilter={this.handleFiltering}
          filter={filter}
          isCalltrackingManager={isCalltrackingManager}
        />
        <div className={styles['total_count']}>
          <strong>Найдено посёлков:</strong>&nbsp;{totalItems}
          <Button
            variant="primary"
            className={styles['btn_save']}
            onClick={saveFile(
              normalizeVillageFilters(filter),
              () =>
                this.setState({
                  alert: {
                    isVisible: true,
                    text: 'При загрузке истории произошла ошибка',
                    type: 'danger',
                  },
                }),
              'timeline.xlsx',
              apiUrl,
              GET_VILLAGE_TIMELINE,
            )}
          >
            <i className="far fa-arrow-alt-circle-down" />
            Скачать историю изменений
          </Button>
        </div>
        <VillageList regionList={regionList} villageList={villageList} isCalltrackingManager={isCalltrackingManager} />
        <Pagination
          activePage={pagination.p}
          pageSize={pagination.pageSize}
          pageCount={pagination.totalPages || 1}
          onPageChange={this.handlePaginationChange('p')}
          onPageCountChange={this.handlePaginationChange('pageSize')}
          getQueryString={page => getQueryStringByFilters(pagination, filter, page)}
          extraPageSizes={[1000]}
        />
      </div>
    );
  }

  private handleFiltering = (filter: IVillageFilter) => {
    this.refreshVillageList(true, filter);
  };

  private handlePaginationChange = (propName: string) => (value?: number | string) => {
    const shouldResetToFirstPage = propName === 'pageSize';
    const newPagination = {
      ...this.state.pagination,
      [propName]: value,
    };

    this.refreshVillageList(shouldResetToFirstPage, undefined, newPagination);
  };

  private refreshVillageList = (
    shouldResetToFirstPage?: boolean,
    newFilter?: IVillageFilter,
    newPagination?: IPagination,
  ) => {
    const { httpApi, logger } = this.context;
    const pagination = newPagination || this.state.pagination;
    const filter = newFilter || this.state.filter;

    const data = {
      ...normalizeVillageFilters(filter),
      p: shouldResetToFirstPage ? 1 : pagination.p,
      pageSize: pagination.pageSize,
    };

    window.history.replaceState(
      {},
      'Список коттеджных посёлков',
      `${VILLAGES_ROUTE}?${getQueryStringByFilters(pagination, filter)}`,
    );

    this.setState({
      filter,
      pagination: {
        ...pagination,
        p: shouldResetToFirstPage ? 1 : pagination.p,
      },
      villageList: [],
    });

    getVillageList(httpApi, logger, data as IGetVillageListRequest)
      .then((response: IGetVillageListResponse) => {
        const { villageList, totalItems, totalPages } = getDataFromVillageResponse(response);

        this.setState({
          pagination: {
            ...this.state.pagination,
            totalItems,
            totalPages,
          },
          totalItems,
          villageList,
        });
      })
      .catch(() => {
        this.setState({
          alert: {
            isVisible: true,
            text: 'При обновлении списка коттеджных посёлков произошла ошибка',
            type: 'danger',
          },
        });
      });
  };
}
