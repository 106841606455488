/* eslint-disable import/no-restricted-paths */
import loadable, { LoadableComponent } from '@loadable/component';
import { IApplicationCommonProps } from 'shared/common/types/application';

import { EPageTypes } from 'shared/common/types/pageTypes';
import { ApplicationLayout } from '../ApplicationLayout';

const APP_REGISTRY = {
  [EPageTypes.Auth]: loadable(() => import('shared/auth/containers/AuthContainer'), {
    resolveComponent: ({ AuthContainer }) => AuthContainer,
  }),
  [EPageTypes.VillageFeatures]: loadable(() => import('shared/features/containers/FeaturesContainer'), {
    resolveComponent: ({ FeaturesContainer }) => FeaturesContainer,
  }),
} as Record<EPageTypes, LoadableComponent<{}>>;

/**
 * Корневой компонент приложения, асинхронно загружающий и отдающий нужный App для каждой страницы.
 * Использовать при рендере на сервере и гидрации на клиенте во всех фичах,
 * т.к. от этого зависит работа код-сплиттинга страниц.
 */
export const RootApplicationContainer: React.FC<IApplicationCommonProps> = props => {
  const Application = APP_REGISTRY[props.context.custom.pageType];

  return (
    <ApplicationLayout {...props}>
      <Application />
    </ApplicationLayout>
  );
};
