import {
  fetchUnblockPhone,
  IUnblockPhoneRequest,
  IUnblockPhoneResponse,
  IUnblockPhoneResponseError,
} from '../repositories/countryside-calltracking/v1/unblock-phone';
import { createSimpleRequest } from './createSimpleRequest';

export const unblockPhone = createSimpleRequest<
  IUnblockPhoneRequest,
  IUnblockPhoneResponse,
  IUnblockPhoneResponseError
>('unblockPhone', fetchUnblockPhone);
