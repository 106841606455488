/* eslint-disable max-lines */
import * as React from 'react';

import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import {
  VillagesTariffNames,
  IRegion,
  IVillageFilter,
  VillageClassNames,
  VillageReadinessStatusNames,
  VillageSaleStatusNames,
  VillageTotalStatusNames,
  TModerationStatus,
  FilterModerationStatusNames,
} from '../../../common/interfaces';
import { dateToISO, toDate } from '../../../utils/datetime';
import { MultiselectorField } from '../../multiselector_field';
import { TextField } from '../../text_field';
import { getSelectOptionsFromObject } from '../../../utils/getSelectOptionsFromObject';
import { DatePicker } from '../../DatePicker';

import * as styles from './VillageFilter.css';
import { Button, Col, Form, Row } from 'react-bootstrap';

interface IProps {
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  filter: IVillageFilter;
  onFilter(filters: IVillageFilter): void;
  isCalltrackingManager: boolean;
}

interface IState {
  filters: IVillageFilter;
}

export class VillageFilter extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const filters = { ...this.props.filter };

    this.state = {
      filters: {
        builderName: filters.builderName || '',
        finishFrom: dateToISO(filters.finishFrom),
        finishTo: dateToISO(filters.finishTo),
        id: filters.id,
        managerList: filters.managerList || [],
        moderationStatusList: filters.moderationStatusList || ['published'],
        name: filters.name || '',
        readyStatusList: filters.readyStatusList || [],
        regionList: filters.regionList || [],
        saleStatusList: filters.saleStatusList || [],
        totalStatusList: filters.totalStatusList || [],
        villageClassList: filters.villageClassList || [],
        withBrokenUrlOnly: !!filters.withBrokenUrlOnly,
        tariffList: filters.tariffList || [],
        withUnboundOffers: filters.withUnboundOffers,
        cianUserId: filters.cianUserId,
        orderId: filters.orderId,
        builderId: filters.builderId,
      },
    };
  }

  public render() {
    const {
      builderName = '',
      finishFrom = '',
      finishTo = '',
      id = '',
      managerList = [],
      moderationStatusList = [],
      name = '',
      readyStatusList = [],
      regionList = [],
      saleStatusList = [],
      totalStatusList = [],
      villageClassList = [],
      withBrokenUrlOnly = false,
      tariffList = [],
      withUnboundOffers = false,
      cianUserId = '',
      orderId = '',
      builderId = '',
    } = this.state.filters;

    const regions = this.props.regionList.map(region => ({ value: region.id, label: region.displayName }));
    const managers = [
      { label: 'Без менеджера', value: 0 },
      ...this.props.managerList.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })),
    ];

    return (
      <div className={styles['container']}>
        <Row>
          <Col md={1} className="mb-3">
            <TextField label="ID" value={id} onChange={this.handleIdInputChange} />
          </Col>
          <Col md={3} className="mb-3">
            <TextField label="Посёлок" placeholder="Название" value={name} onChange={this.handleNameInputChange} />
          </Col>
          <Col md={2} className="mb-3">
            <TextField
              label="Застройщик"
              placeholder="Название"
              value={builderName}
              onChange={this.handleBuilderInputChange}
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(finishFrom)}
              onChange={this.handleFinishFromDateChange}
              label="Срок сдачи от"
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(finishTo)}
              onChange={this.handleFinishToDateChange}
              label="Срок сдачи до"
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Общие статусы"
              value={totalStatusList}
              values={getSelectOptionsFromObject(VillageTotalStatusNames)}
              onChange={this.handleMultiSelectChange('totalStatusList')}
              invalid={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Регионы"
              value={regionList}
              values={regions}
              onChange={this.handleMultiSelectChange('regionList')}
              invalid={false}
              showSelectButtons
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Статусы модерации"
              value={moderationStatusList}
              values={getSelectOptionsFromObject(FilterModerationStatusNames, false)}
              onChange={this.handleModerationStatusChange}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Класс КП"
              value={villageClassList}
              values={getSelectOptionsFromObject(VillageClassNames)}
              onChange={this.handleMultiSelectChange('villageClassList')}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Статусы продаж"
              value={saleStatusList}
              values={getSelectOptionsFromObject(VillageSaleStatusNames)}
              onChange={this.handleMultiSelectChange('saleStatusList')}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Статусы готовности"
              value={readyStatusList}
              values={getSelectOptionsFromObject(VillageReadinessStatusNames)}
              onChange={this.handleMultiSelectChange('readyStatusList')}
              invalid={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Менеджеры"
              value={managerList || []}
              values={managers}
              onChange={this.handleManagerIdListChange}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <MultiselectorField
              label="Тариф"
              value={tariffList}
              values={getSelectOptionsFromObject(VillagesTariffNames)}
              onChange={this.handleTariffListChange}
              invalid={false}
            />
          </Col>
          <Col md={2} className="mb-3">
            <TextField label="Номер ЛК" value={cianUserId} onChange={this.handleCianUserIdInputChange} />
          </Col>
          <Col md={2} className="mb-3">
            <TextField label="№ заказа" value={orderId} onChange={this.handleOrderIdInputChange} />
          </Col>
          <Col md={2} className="mb-3">
            <TextField label="ID застройщика" value={builderId} onChange={this.handleBuilderIdInputChange} />
          </Col>
        </Row>
        <Row>
          <Col md={3} className={styles['checkbox']}>
            <Form.Check.Label>
              <Form.Check.Input checked={withBrokenUrlOnly} onChange={this.handleBrokenUrlCheckboxChange} />
              &nbsp; Только с неработающим сайтом
            </Form.Check.Label>
          </Col>
          <Col md={4} className={styles['checkbox']}>
            <Form.Check.Label>
              <Form.Check.Input checked={withUnboundOffers} onChange={this.handleUnboundOffersCheckboxChange} />
              &nbsp; Только с непривязавшимися объектами
            </Form.Check.Label>
          </Col>
          <Col md={5} className="text-end">
            <Button onClick={this.handleFilterButtonClick} className={styles['filter-btn']}>
              Отфильтровать
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  private handleFilterButtonClick = () => {
    const { filters } = this.state;

    const filtered: IVillageFilter = Object.keys(filters).reduce(
      (newFilters: IVillageFilter, key: keyof IVillageFilter) => {
        const value = filters[key];
        const shouldAddIfArray = !(Array.isArray(value) && isTheAllOptionSelected(value as string[]));
        if (typeof value === 'boolean' || (value !== '' && value !== undefined && shouldAddIfArray)) {
          return {
            ...newFilters,
            [key]: value,
          };
        }

        return newFilters;
      },
      {} as IVillageFilter,
    );
    this.props.onFilter(filtered);
  };

  private handleBrokenUrlCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filters: {
        ...this.state.filters,
        withBrokenUrlOnly: e.currentTarget.checked,
      },
    });
  };

  private handleIdInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        id: parseInt(value, 10) || undefined,
      },
    });
  };

  private handleCianUserIdInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        cianUserId: parseInt(value, 10) || undefined,
      },
    });
  };

  private handleBuilderIdInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        builderId: parseInt(value, 10) || undefined,
      },
    });
  };

  private handleOrderIdInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        orderId: parseInt(value, 10) || undefined,
      },
    });
  };

  private handleNameInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        name: value,
      },
    });
  };

  private handleBuilderInputChange = (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        builderName: value,
      },
    });
  };

  private handleFinishFromDateChange = (value?: Date) => {
    const { filters } = this.state;
    const finishFrom = value ? value.toString() : undefined;

    this.setState({
      filters: {
        ...filters,
        finishFrom,
      },
    });
  };

  private handleFinishToDateChange = (value?: Date) => {
    const { filters } = this.state;
    const finishTo = value ? value.toString() : undefined;

    this.setState({
      filters: {
        ...filters,
        finishTo,
      },
    });
  };

  private setMultiselectFilter = (name: string, value: Array<string | number> | void) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });

  private handleManagerIdListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length > 1) {
      if (value[value.length - 1] === 0) {
        newValue = [0];
      } else if (value.includes(0)) {
        newValue = value.filter(Boolean);
      }
    }

    this.setMultiselectFilter('managerList', newValue);
  };

  private handleTariffListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length < 1) {
      newValue = undefined;
    }

    this.setMultiselectFilter('tariffList', newValue);
  };

  private handleMultiSelectChange = (name: string) => (value: Array<string | number> | void) => {
    this.setMultiselectFilter(name, value);
  };

  private handleModerationStatusChange = (newValues: TModerationStatus[]) => {
    const { filters } = this.state;
    let moderationStatusList = newValues;

    if (newValues.length && newValues[newValues.length - 1] === 'all') {
      moderationStatusList = ['all' as TModerationStatus];
    } else {
      moderationStatusList = moderationStatusList.filter(element => element !== 'all');
    }

    this.setState({
      filters: {
        ...filters,
        moderationStatusList,
      },
    });
  };

  private handleUnboundOffersCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filters: {
        ...this.state.filters,
        withUnboundOffers: e.currentTarget.checked,
      },
    });
  };
}

export function isTheAllOptionSelected(array: string[]): boolean {
  return array.length > 0 && array[0] === '';
}
