import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  InfrastructureDropdownValues,
  TVillageInfrastructure,
  VillageInfrastructureNames,
} from '../../common/interfaces';
import { MultiselectorField } from '../multiselector_field';
import { ISortingItems, SortingComponent } from '../SortingComponent';

interface IInfrastructureProps {
  infrastructures: TVillageInfrastructure[];
  onChange(value: TVillageInfrastructure[], isDataValid: boolean): void;
  onInit(value: TVillageInfrastructure[], isDataValid: boolean): void;
  useValidation: boolean;
  readOnly?: boolean;
}

import * as styles from './Infrastructure.css';

export class Infrastructure extends React.Component<IInfrastructureProps> {
  public UNSAFE_componentWillMount() {
    const { infrastructures } = this.props;

    this.props.onInit(infrastructures, Boolean(infrastructures));
  }

  public render() {
    const { infrastructures, useValidation, readOnly } = this.props;

    return (
      <div>
        <Row>
          <Col md={4} className="mb-3">
            <MultiselectorField
              label="Инфраструктура"
              value={infrastructures}
              invalid={useValidation && !infrastructures}
              values={InfrastructureDropdownValues}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        {!readOnly && (
          <Row>
            <SortingComponent
              list={infrastructures}
              items={this.sortingItems}
              containerStyle={styles['infrastructure_container']}
              onChange={this.handleChange}
            />
          </Row>
        )}
      </div>
    );
  }

  private get sortingItems(): ISortingItems {
    return this.props.infrastructures.reduce(
      (acc, value) => ({
        ...acc,
        [value]: VillageInfrastructureNames[value],
      }),
      {},
    );
  }

  private handleChange = (infrastructures: TVillageInfrastructure[]) => {
    this.props.onChange(infrastructures, Boolean(infrastructures));
  };
}
