import { ChangeloModerationStatusTypes, IAlert, ModerationStatusTypes, TAlertType } from '../common/interfaces';

export const NON_BREAKING_SPACE = '\u00A0';

export const DEFAULT_ALERT: IAlert = {
  isVisible: false,
  text: '',
  type: 'success' as TAlertType,
};

export const MAX_PRICE = 99999999999;

export const DEFAULT_LOG_COUNT = 10;

export const INITIAL_PAGINATION_PARAMS = {
  pageSize: 20,
  page: 1,
};

export const ChangelogModerationStatusNames = {
  [ChangeloModerationStatusTypes.New]: 'Новый',
  [ModerationStatusTypes.Draft]: 'Черновик',
  [ModerationStatusTypes.Published]: 'Опубликовано',
  [ModerationStatusTypes.Deleted]: 'Удалено',
};
