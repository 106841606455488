import { IThunkActionCreator } from '../../types/thunk';
import { IContractorCalltrackingListPageState } from '../../types/redux';
import { setCallHistory } from './actions';
import { getConstructorCallHistory } from 'shared/services/getConstructorCallHistory';

export function loadConstructorCallHistory(callId: number): IThunkActionCreator<IContractorCalltrackingListPageState> {
  return async (dispatch, _getState, { httpApi, logger }) => {
    const history = await getConstructorCallHistory(httpApi, logger, { callId });
    dispatch(setCallHistory(history));
  };
}
