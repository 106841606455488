import { ICplBuilder, TCplListItem } from '../../../types';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { CplSettingsMobileList } from '../CplSettingsMobileList';

import * as styles from './CplSettingsMobileInfo.css';

export interface ICplSettingsMobileInfoProps {
  list: TCplListItem[];
  builder?: ICplBuilder;
  managerId: number | undefined;
  period: IDatePeriod;
}

export function CplSettingsMobileInfo({ list, builder, period, managerId }: ICplSettingsMobileInfoProps) {
  return (
    <div className={styles['container']}>
      {builder && builder.commentary && (
        <div className={styles['info_item']}>
          <strong className={styles['info_item_title']}>Комментарий</strong>
          <p className={styles['info_item_description']}>{builder.commentary}</p>
        </div>
      )}
      <CplSettingsMobileList
        list={list}
        period={period}
        managerId={managerId}
        builderId={builder ? builder.id : undefined}
      />
    </div>
  );
}
