import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { DEFAULT_CONTRACTOR_CALLS } from '../constants';
import { ResponseError } from '../errors/shared/responseError';
import {
  fetchAdminGetConstructorCalls,
  IAdminGetConstructorCallsRequest,
  TAdminGetConstructorCallsResponse,
} from '../repositories/countryside-calltracking/v1/admin/get-constructor-calls';
import { TAdminConstructorCalls } from '../types';

export async function getContractorsCalls(
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IAdminGetConstructorCallsRequest,
): Promise<TAdminConstructorCalls> {
  try {
    const { response, statusCode }: TAdminGetConstructorCallsResponse = await fetchAdminGetConstructorCalls({
      httpApi,
      parameters,
    });

    if (statusCode !== 200) {
      throw new ResponseError({
        domain: 'getContractorsCalls',
        message: response.message,
        details: {
          errors: JSON.stringify(response.errors),
        },
      });
    }

    return response;
  } catch (error) {
    logger.error(error);

    return DEFAULT_CONTRACTOR_CALLS;
  }
}
