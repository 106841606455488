import {
  fetchAdminShareCallRecord,
  IAdminShareCallRecordRequest,
  IAdminShareCallRecordResponse,
  IAdminShareCallRecordResponseError,
} from '../repositories/countryside-calltracking/v1/admin-share-call-record';
import { createSimpleRequest } from './createSimpleRequest';

export const fetchCallRecord = createSimpleRequest<
  IAdminShareCallRecordRequest,
  IAdminShareCallRecordResponse,
  IAdminShareCallRecordResponseError
>('fetchCallRecord', fetchAdminShareCallRecord);
