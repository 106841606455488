import { ContractorCallsSuccessStatusNames } from '../../../../common/interfaces';
import { formatDateTime } from '../../../../utils/datetime';
import { prettifySeconds } from '../../../../utils/prettifySeconds';
import { saveFileFromPath } from '../../../../utils/saveFileFromPath';
import * as styles from './ContractorCallsTableRow.css';
import { Button } from 'react-bootstrap';
import { TCallType, TChangerType, TConstructorCall } from '../../../../types';
import { CallTypeToggle } from 'shared/components/CallTypeToggle';

export interface IContractorCallsTableRowProps {
  call: TConstructorCall;
  isPaused: boolean;
  callRegion?: string | null;
  region?: string | null;
  onRowClick(
    call: TConstructorCall,
    isOpen: boolean,
    recordFilePath: string | undefined | null,
    isPaused: boolean,
  ): void;
  onCallTypeChange(callId?: number, changerType?: TChangerType, type?: TCallType): void;
}

export function ContractorCallsTableRow({
  call,
  isPaused,
  onRowClick,
  onCallTypeChange,
}: IContractorCallsTableRowProps) {
  const {
    recordFilePath,
    callId,
    duration,
    date,
    status,
    constructorId,
    sourcePhone,
    calltrackingPhone,
    constructorName,
    finalType,
  } = call;

  return (
    <tr onClick={() => setTimeout(() => onRowClick(call, true, recordFilePath, !isPaused))}>
      <td>{callId}</td>
      <td>{constructorId}</td>
      <td>{constructorName}</td>
      <td>{sourcePhone}</td>
      <td>{calltrackingPhone}</td>
      <td>{formatDateTime(date, '+0300')}</td>
      <td>{prettifySeconds(duration)}</td>
      <td>
        {recordFilePath ? (
          <div className={styles['call_record']}>
            <Button
              variant="success"
              className={styles['play']}
              onClick={e => {
                e.stopPropagation();
                onRowClick(call, false, recordFilePath, isPaused);
              }}
            >
              {isPaused ? <i className="fas fa-pause" /> : <i className="fas fa-play" />}
            </Button>
            <Button onClick={saveFileFromPath(recordFilePath)} title="Скачать">
              <i className="fas fa-download" />
            </Button>
          </div>
        ) : (
          'Запись отсутствует'
        )}
      </td>
      <td>{ContractorCallsSuccessStatusNames[status]}</td>
      <td>
        <CallTypeToggle callId={callId} callType={finalType} setCallType={onCallTypeChange} />
      </td>
    </tr>
  );
}
