/* eslint-disable max-lines */
import { EHouseMaterialList, ELocationType } from '../../node/repositories/countryside/v1/get-village';
import {
  ECanalization,
  EElectricity,
  EGas,
  EHeating,
  ERoad,
  EWaterSupply,
} from '../repositories/countryside/entities/communication/CommunicationSetSchema';
import { IThumbnailSchema } from '../repositories/countryside/entities/entities/ThumbnailSchema';
import { EModerationStatus } from '../repositories/countryside/entities/schemas/BuilderForListSchema';
import { EVillageModerationStatus } from '../repositories/countryside/entities/village_changelog/VillageChangelogSchema';
import { EStatus as EContractorCallsStatus, Override } from '../types';
import { IFatInfrastructureListItem } from '../types/fat';
import { createYearRangeList } from '../utils';
import { generateDropdown } from '../utils/dropdown';
import { IGeoCoordinates } from '../http';
import { IUpdateFatVillageFeatureSchema } from '../repositories/countryside/entities/schemas/UpdateFatVillageFeatureSchema';
import { ETariffList as ECalltrackingTariffList } from '../repositories/countryside-calltracking/v1/admin-get-calls/types';
import { ETariffList as EVillagesTariffList } from '../repositories/countryside/v1/get-village-list/types';
import { ISeoDataSchema } from '../../node/repositories/countryside/entities/seo/SeoDataSchema';

export interface IUserCredentials {
  username: string;
  password: string;
}

export interface IRegion {
  id: number;
  displayName: string;
}

export interface INewEntityResponse {
  id: number;
}

export interface IGalleryItem {
  id?: number | null;
  url?: string | null;
  thumbnailList?: IThumbnailSchema[] | null;
  description?: string | null;
}

export type TAlertType = 'success' | 'danger' | 'warning';

export interface IAlert {
  isVisible: boolean;
  text: string;
  type: TAlertType;
}

export interface IBuilder {
  id?: number | null;
  commentary?: string | null;
  description?: string | null;
  logoUrl?: string | null;
  moderationStatus?: EModerationStatus | null;
  name: string;
  phone?: string | null;
  regionList?: number[] | null;
  workTime?: string | null;
  cianUserList?: number[] | null;
  accountList: IEmail[];
  emailList?: string[] | null;
}

export interface IBuilderFilter {
  createdFrom?: string;
  createdTo?: string;
  id?: number;
  moderationStatus?: EModerationStatus | null;
  name?: string;
  regionList?: number[];
  updatedFrom?: string;
  updatedTo?: string;
}

export interface IBuilderNameListItem {
  id: number;
  name: string;
  moderationStatus?: EModerationStatus | null;
}

export interface IBuilderListItem {
  id: number;
  name: string;
  moderationStatus: EModerationStatus;
  created: string;
  updated: string;
  displayPhone: boolean;
}

export interface IRoad {
  id: number;
  name: string;
  regionId: number;
  directionId?: number | null;
  directionIndex: number;
  directionName?: string | null;
}

export interface IRailwayDirection {
  alternativeNames?: string[] | null;
  id?: number | null;
  // lineFrom — id вокзала
  lineFrom?: number | null;
  name?: string | null;
}

export interface IStation {
  alternativeNames: string[];
  //directions - к каким направлениям относится станция
  directions: number[];
  id: number;
  genitiveName?: string | null;
  name: string;
  prepositionalName?: string | null;
  regionId: number;
  sorterId: number;
}

export interface IGallery {
  imageList?: IPhoto[] | null;
}

export interface IPhoto {
  fullUrl: string;
  thumbnailUrl?: string | null;
}

export interface IVideo {
  url: string;
}

export interface IEmail {
  email: string;
}

export interface IVillageAddress {
  address: number;
  regionId: number;
  locationType?: ELocationType | null;
  geo?: IGeoCoordinates | null;
}

export type TLocationType = 'house' | 'location' | 'street';

export type TVillageObject = 'cottage' | 'landplot' | 'lowrisehouse' | 'townhouse';

export const VillageObjectTypes = {
  Cottage: 'cottage' as TVillageObject,
  Landplot: 'landplot' as TVillageObject,
  Lowrisehouse: 'lowrisehouse' as TVillageObject,
  Townhouse: 'townhouse' as TVillageObject,
};

export const VillageObjectNames = {
  [VillageObjectTypes.Cottage]: 'коттеджи',
  [VillageObjectTypes.Landplot]: 'участки',
  [VillageObjectTypes.Lowrisehouse]: 'малоэтажные дома',
  [VillageObjectTypes.Townhouse]: 'таунхаусы',
};

export interface ICommunicationSet {
  canalization?: ECanalization | null;
  electricity?: EElectricity | null;
  gas?: EGas[] | null;
  heating?: EHeating[] | null;
  road?: ERoad[] | null;
  waterSupply?: EWaterSupply[] | null;
}

export interface INewObjectCategorySet {
  cottage?: INewObjectCategorySetItem | null;
  landplot?: INewObjectCategorySetItem | null;
  lowrisehouse?: INewObjectCategorySetItem | null;
  townhouse?: INewObjectCategorySetItem | null;
}

export interface IVillage {
  id: number;
  infrastructureList: TVillageInfrastructure[];
  fatInfrastructureList?: IFatInfrastructureListItem[] | null;
  name: string;
  description?: string | null;
  builderId: number;
  displayBuilderPhone?: boolean | null;
  fatFeatureList?: IUpdateFatVillageFeatureSchema[] | null;
  siteUrl?: string | null;
  areaHectare: number;
  regionId: number;
  locationId: number;
  locationType: ELocationType;
  saleStatus: TVillageSaleStatus;
  readyStatus: TVillageReadinessStatus;
  totalStatus: TVillageTotalStatus;
  moderationStatus: TModerationStatus;
  villageClass: TVillageClass;
  floorCountMax?: number | null;
  floorCountMin?: number | null;
  featureList: ISelectedFeature[];
  hidePrice?: boolean | null;
  phone?: string | null;
  priceMax?: number | null;
  priceMin?: number | null;
  finishYearMin: number;
  finishQuarterMin?: TQuarter | null;
  finishMonthMin?: TMonth | null;
  finishYearMax: number;
  finishQuarterMax?: TQuarter | null;
  finishMonthMax?: TMonth | null;
  generalPlanUrl?: string | null;
  gallery: IGallery;
  houseMaterialList?: EHouseMaterialList[] | null;
  communicationSet: ICommunicationSet;
  counterSet: ICounterSet;
  roadInfoList?: IRoadInfo[] | null;
  stationInfoList?: IStationInfo[] | null;
  commentary?: string | null;
  newobjectCategorySet: INewObjectCategorySet;
  neighbourhoodList?: IVillageNeighbourhoodItem[] | null;
  videoList?: IVideo[] | null;
  isVisible?: boolean | null;
  lat?: number | null;
  lng?: number | null;
  cianUserId?: number | null;
  orderId?: number | null;
  managerId?: number | null;
  manager?: IManager | null;
  autodisableOrder?: boolean | null;
  isFat?: boolean | null;
  isCpl?: boolean | null;
  isCropped?: boolean | null;
  isPro?: boolean | null;
  freeCpl?: boolean | null;
  seo?: ISeoDataSchema | null;
}

export type TCreateVillage = Override<
  IVillage,
  {
    builderId?: number;
  }
>;

export interface IManager {
  email: string;
  firstName: string;
  id: number;
  isActive: boolean;
  lastName: string;
}

export type TVillageCreateModel = Pick<
  TCreateVillage,
  Exclude<
    keyof TCreateVillage,
    | 'id'
    | 'infrastructureList'
    | 'fatInfrastructureList'
    | 'gallery'
    | 'newobjectCategorySet'
    | 'neighbourhoodList'
    | 'commentary'
    | 'counterSet'
    | 'communicationSet'
    | 'houseMaterialList'
    | 'fatFeatureList'
    | 'featureList'
    | 'generalPlanUrl'
    | 'videoList'
    | 'seo'
  >
>;

export interface IFeature {
  id: number;
  status: TFeatureStatus;
  type: TFeatureType;
  imageUrl: string;
}

export interface ISelectedFeature {
  id: number;
  description: string;
}

export type TFeatureModel = ISelectedFeature & IFeature;

export interface IImage {
  base64: string;
}

export type TModerationStatus = 'draft' | 'published' | 'deleted' | 'new' | 'all';

export const ModerationStatusTypes = {
  Deleted: 'deleted' as TModerationStatus,
  Draft: 'draft' as TModerationStatus,
  Published: 'published' as TModerationStatus,
};

export const ModerationStatusNames = {
  [ModerationStatusTypes.Draft]: 'Черновик',
  [ModerationStatusTypes.Published]: 'Опубликовано',
  [ModerationStatusTypes.Deleted]: 'Удалено',
};

export const FilterModerationStatusTypes = {
  Deleted: 'deleted' as TModerationStatus,
  Draft: 'draft' as TModerationStatus,
  Published: 'published' as TModerationStatus,
  All: 'all' as TModerationStatus,
};

export const FilterModerationStatusNames = {
  [ModerationStatusTypes.Draft]: 'Черновик',
  [ModerationStatusTypes.Published]: 'Опубликовано',
  [ModerationStatusTypes.Deleted]: 'Удалено',
  [FilterModerationStatusTypes.All]: 'Все',
};

export const ModerationStatusIconClassNames = {
  [ModerationStatusTypes.Draft]: 'fas fa-clipboard',
  [ModerationStatusTypes.Published]: 'fas fa-clipboard-check text-success',
  [ModerationStatusTypes.Deleted]: 'fas fa-trash text-danger',
};

export type THouseMaterial = 'brick' | 'monolith' | 'wood' | 'boards' | 'aerocreteBlock' | 'foamConcreteBlock';

export const HouseMaterialTypes = {
  AeroCreteBlock: 'aerocreteBlock' as THouseMaterial,
  Boards: 'boards' as THouseMaterial,
  Brick: 'brick' as THouseMaterial,
  FoamConcreteBlock: 'foamConcreteBlock' as THouseMaterial,
  Monolith: 'monolith' as THouseMaterial,
  Wood: 'wood' as THouseMaterial,
};

export const HouseMaterialNames = {
  [HouseMaterialTypes.AeroCreteBlock]: 'Газобетонный блок',
  [HouseMaterialTypes.Boards]: 'Щитовой',
  [HouseMaterialTypes.Brick]: 'Кирпичный',
  [HouseMaterialTypes.FoamConcreteBlock]: 'Пенобетонный блок',
  [HouseMaterialTypes.Monolith]: 'Монолитный',
  [HouseMaterialTypes.Wood]: 'Деревянный',
};

export type TVillageSaleStatus = 'pending' | 'opened' | 'closed';

export const VillageSaleStatusTypes = {
  Closed: 'closed' as TVillageSaleStatus,
  Opened: 'opened' as TVillageSaleStatus,
  Pending: 'pending' as TVillageSaleStatus,
};

export const VillageSaleStatusNames = {
  [VillageSaleStatusTypes.Pending]: 'Ожидание',
  [VillageSaleStatusTypes.Opened]: 'Открыты',
  [VillageSaleStatusTypes.Closed]: 'Завершены',
};

export type TVillageReadinessStatus = 'planning' | 'building' | 'built' | 'frozen';

export const VillageReadinessStatusTypes = {
  Building: 'building' as TVillageReadinessStatus,
  Built: 'built' as TVillageReadinessStatus,
  Frozen: 'frozen' as TVillageReadinessStatus,
  Planning: 'planning' as TVillageReadinessStatus,
};

export const VillageReadinessStatusNames = {
  [VillageReadinessStatusTypes.Planning]: 'Планируется',
  [VillageReadinessStatusTypes.Building]: 'Строится',
  [VillageReadinessStatusTypes.Built]: 'Построен',
  [VillageReadinessStatusTypes.Frozen]: 'Заморожен',
};

export type TVillageTotalStatus = 'active' | 'pending' | 'inactive' | 'sold';

export const VillageTotalStatusTypes = {
  Active: 'active' as TVillageTotalStatus,
  Inactive: 'inactive' as TVillageTotalStatus,
  Pending: 'pending' as TVillageTotalStatus,
  Sold: 'sold' as TVillageTotalStatus,
};

export const VillageTotalStatusNames = {
  [VillageTotalStatusTypes.Active]: 'Активный',
  [VillageTotalStatusTypes.Pending]: 'Ожидание',
  [VillageTotalStatusTypes.Inactive]: 'Неактивный',
  [VillageTotalStatusTypes.Sold]: 'Реализован',
};

export const VillageTotalStatusIconClassNames = {
  [VillageTotalStatusTypes.Active]: 'far fa-calendar-plus text-success',
  [VillageTotalStatusTypes.Pending]: 'far fa-clock',
  [VillageTotalStatusTypes.Inactive]: 'far fa-calendar-minus text-danger',
  [VillageTotalStatusTypes.Sold]: 'far fa-calendar-check text-success',
};

export type TVillageClass = 'economy' | 'comfort' | 'business' | 'elite';

export const VillageClassTypes = {
  Business: 'business' as TVillageClass,
  Comfort: 'comfort' as TVillageClass,
  Economy: 'economy' as TVillageClass,
  Elite: 'elite' as TVillageClass,
};

export const VillageClassNames = {
  [VillageClassTypes.Economy]: 'Эконом',
  [VillageClassTypes.Comfort]: 'Комфорт',
  [VillageClassTypes.Business]: 'Бизнес',
  [VillageClassTypes.Elite]: 'Элит',
};

export type TVillageInfrastructure =
  | 'playground'
  | 'rest'
  | 'resort'
  | 'cinema'
  | 'theater'
  | 'lake'
  | 'river'
  | 'gulf'
  | 'pond'
  | 'park'
  | 'reserve'
  | 'forest'
  | 'kindergarten'
  | 'school'
  | 'institute'
  | 'studio'
  | 'polyclinic'
  | 'hospital'
  | 'clinic'
  | 'pharmacy'
  | 'veterinaryClinic'
  | 'dogsWalking'
  | 'zoo'
  | 'sportsGround'
  | 'fitness'
  | 'spa'
  | 'pool'
  | 'stadium'
  | 'police'
  | 'fire'
  | 'perimeter'
  | 'video'
  | 'access'
  | 'security'
  | 'concierge'
  | 'guardedParking'
  | 'signaling'
  | 'supermarket'
  | 'shop'
  | 'restaurant'
  | 'shoppingCenter'
  | 'church'
  | 'museum'
  | 'square'
  | 'carService'
  | 'gasStation'
  | 'commercial'
  | 'office'
  | 'selfService'
  | 'parking';

export const VillageInfrastructureTypes = {
  Access: 'access' as TVillageInfrastructure,
  CarService: 'carService' as TVillageInfrastructure,
  Church: 'church' as TVillageInfrastructure,
  Cinema: 'cinema' as TVillageInfrastructure,
  Clinic: 'clinic' as TVillageInfrastructure,
  Commercial: 'commercial' as TVillageInfrastructure,
  Concierge: 'concierge' as TVillageInfrastructure,
  DogsWalking: 'dogsWalking' as TVillageInfrastructure,
  Fire: 'fire' as TVillageInfrastructure,
  Fitness: 'fitness' as TVillageInfrastructure,
  Forest: 'forest' as TVillageInfrastructure,
  GasStation: 'gasStation' as TVillageInfrastructure,
  GuardedParking: 'guardedParking' as TVillageInfrastructure,
  Gulf: 'gulf' as TVillageInfrastructure,
  Hospital: 'hospital' as TVillageInfrastructure,
  Institute: 'institute' as TVillageInfrastructure,
  KinderGarten: 'kindergarten' as TVillageInfrastructure,
  Lake: 'lake' as TVillageInfrastructure,
  Museum: 'museum' as TVillageInfrastructure,
  Office: 'office' as TVillageInfrastructure,
  Park: 'park' as TVillageInfrastructure,
  Parking: 'parking' as TVillageInfrastructure,
  Perimeter: 'perimeter' as TVillageInfrastructure,
  Pharmacy: 'pharmacy' as TVillageInfrastructure,
  Playground: 'playground' as TVillageInfrastructure,
  Police: 'police' as TVillageInfrastructure,
  Polyclinic: 'polyclinic' as TVillageInfrastructure,
  Pond: 'pond' as TVillageInfrastructure,
  Pool: 'pool' as TVillageInfrastructure,
  Reserve: 'reserve' as TVillageInfrastructure,
  Resort: 'resort' as TVillageInfrastructure,
  Rest: 'rest' as TVillageInfrastructure,
  Restaurant: 'restaurant' as TVillageInfrastructure,
  River: 'river' as TVillageInfrastructure,
  School: 'school' as TVillageInfrastructure,
  Security: 'security' as TVillageInfrastructure,
  SelfService: 'selfService' as TVillageInfrastructure,
  Shop: 'shop' as TVillageInfrastructure,
  ShoppingCenter: 'shoppingCenter' as TVillageInfrastructure,
  Signaling: 'signaling' as TVillageInfrastructure,
  Spa: 'spa' as TVillageInfrastructure,
  SportsGround: 'sportsGround' as TVillageInfrastructure,
  Square: 'square' as TVillageInfrastructure,
  Stadium: 'stadium' as TVillageInfrastructure,
  Studio: 'studio' as TVillageInfrastructure,
  Supermarket: 'supermarket' as TVillageInfrastructure,
  Theater: 'theater' as TVillageInfrastructure,
  VeterinaryClinic: 'veterinaryClinic' as TVillageInfrastructure,
  Video: 'video' as TVillageInfrastructure,
  Zoo: 'zoo' as TVillageInfrastructure,
};

export const VillageInfrastructureNames = {
  [VillageInfrastructureTypes.Playground]: 'Детские площадки',
  [VillageInfrastructureTypes.Rest]: 'Места для отдыха',
  [VillageInfrastructureTypes.Resort]: 'Курортная зона',
  [VillageInfrastructureTypes.Cinema]: 'Кино',
  [VillageInfrastructureTypes.Theater]: 'Театр',
  [VillageInfrastructureTypes.Lake]: 'Озеро',
  [VillageInfrastructureTypes.River]: 'Река',
  [VillageInfrastructureTypes.Gulf]: 'Залив',
  [VillageInfrastructureTypes.Pond]: 'Пруд',
  [VillageInfrastructureTypes.Park]: 'Парковая зона',
  [VillageInfrastructureTypes.Reserve]: 'Заповедник',
  [VillageInfrastructureTypes.Forest]: 'Лесная зона',
  [VillageInfrastructureTypes.KinderGarten]: 'Детский сад',
  [VillageInfrastructureTypes.School]: 'Школа',
  [VillageInfrastructureTypes.Institute]: 'Институт',
  [VillageInfrastructureTypes.Studio]: 'Студии',
  [VillageInfrastructureTypes.Polyclinic]: 'Поликлиника',
  [VillageInfrastructureTypes.Hospital]: 'Больница',
  [VillageInfrastructureTypes.Clinic]: 'Медпункт',
  [VillageInfrastructureTypes.Pharmacy]: 'Аптеки',
  [VillageInfrastructureTypes.VeterinaryClinic]: 'Ветеринарная клиника',
  [VillageInfrastructureTypes.DogsWalking]: 'Площадки для выгула собак',
  [VillageInfrastructureTypes.Zoo]: 'Зоопарк',
  [VillageInfrastructureTypes.SportsGround]: 'Спортивные площадки',
  [VillageInfrastructureTypes.Fitness]: 'Фитнес-центр',
  [VillageInfrastructureTypes.Spa]: 'SPA-центр',
  [VillageInfrastructureTypes.Pool]: 'Бассейн',
  [VillageInfrastructureTypes.Stadium]: 'Стадион',
  [VillageInfrastructureTypes.Police]: 'Полиция',
  [VillageInfrastructureTypes.Fire]: 'Пожарная служба',
  [VillageInfrastructureTypes.Perimeter]: 'Огороженный периметр',
  [VillageInfrastructureTypes.Video]: 'Видеонаблюдение',
  [VillageInfrastructureTypes.Access]: 'Пропускная система',
  [VillageInfrastructureTypes.Security]: 'Круглосуточная охрана',
  [VillageInfrastructureTypes.Concierge]: 'Консьерж',
  [VillageInfrastructureTypes.GuardedParking]: 'Охраняемая парковка',
  [VillageInfrastructureTypes.Signaling]: 'Сигнализация',
  [VillageInfrastructureTypes.Supermarket]: 'Супермаркет',
  [VillageInfrastructureTypes.Shop]: 'Магазин',
  [VillageInfrastructureTypes.Restaurant]: 'Рестораны',
  [VillageInfrastructureTypes.ShoppingCenter]: 'Торговый центр',
  [VillageInfrastructureTypes.Church]: 'Церковь',
  [VillageInfrastructureTypes.Museum]: 'Музеи',
  [VillageInfrastructureTypes.Square]: 'Площади',
  [VillageInfrastructureTypes.CarService]: 'Автосервис',
  [VillageInfrastructureTypes.GasStation]: 'АЗС',
  [VillageInfrastructureTypes.Commercial]: 'Коммерческие объекты',
  [VillageInfrastructureTypes.Office]: 'Офисы',
  [VillageInfrastructureTypes.SelfService]: 'Бытовые услуги',
  [VillageInfrastructureTypes.Parking]: 'Парковки',
};

export type TGasCommunications = 'balloon' | 'magistral';

export const GasCommunicationTypes = {
  Balloon: 'balloon' as TGasCommunications,
  Magistral: 'magistral' as TGasCommunications,
};

export const GasCommunicationNames = {
  [GasCommunicationTypes.Balloon]: 'Баллоны',
  [GasCommunicationTypes.Magistral]: 'Магистраль',
};

export type THeatingCommunications = 'boiler' | 'central' | 'electrical';

export const HeatingCommunicationTypes = {
  Boiler: 'boiler' as THeatingCommunications,
  Central: 'central' as THeatingCommunications,
  Electrical: 'electrical' as THeatingCommunications,
};

export const HeatingCommunicationNames = {
  [HeatingCommunicationTypes.Boiler]: 'котел (газ)',
  [HeatingCommunicationTypes.Central]: 'центральное',
  [HeatingCommunicationTypes.Electrical]: 'электрообогрев',
};

export type TCanalizationCommunications = 'local' | 'central' | '';

export const CanalizationCommunicationTypes = {
  Central: 'central' as TCanalizationCommunications,
  Local: 'local' as TCanalizationCommunications,
};

export const CanalizationCommunicationNames = {
  [CanalizationCommunicationTypes.Local]: 'локальная',
  [CanalizationCommunicationTypes.Central]: 'центральная',
};

export type TElectricityCommunications = 'yes' | '';

export const ElectricityCommunicationTypes = {
  Yes: 'yes' as TElectricityCommunications,
};

export const ElectricityCommunicationNames = {
  [ElectricityCommunicationTypes.Yes]: 'есть',
};

export type TRoadCommunications = 'asphaltic' | 'concrete' | 'dirt' | 'trunk' | 'gravel' | 'crushedAsphalt' | 'rubble';

export const RoadCommunicationTypes = {
  Asphaltic: 'asphaltic' as TRoadCommunications,
  Concrete: 'concrete' as TRoadCommunications,
  Dirt: 'dirt' as TRoadCommunications,
  Rubble: 'rubble' as TRoadCommunications,
  Trunk: 'trunk' as TRoadCommunications,
  Gravel: 'gravel' as TRoadCommunications,
  CrushedAsphalt: 'crushedAsphalt' as TRoadCommunications,
};

export const RoadCommunicationNames = {
  [RoadCommunicationTypes.Asphaltic]: 'асфальтовая',
  [RoadCommunicationTypes.Concrete]: 'бетонная',
  [RoadCommunicationTypes.Dirt]: 'грунтовая',
  [RoadCommunicationTypes.Rubble]: 'щебёнка',
  [RoadCommunicationTypes.Trunk]: 'магистральная',
  [RoadCommunicationTypes.Gravel]: 'гравийная',
  [RoadCommunicationTypes.CrushedAsphalt]: 'асфальтовая крошка',
};

export type TWaterSupply = 'central' | 'hole' | 'well';

export const WaterSupplyTypes = {
  Central: 'central' as TWaterSupply,
  Hole: 'hole' as TWaterSupply,
  Well: 'well' as TWaterSupply,
};

export const WaterSupplyCommunicationNames = {
  [WaterSupplyTypes.Central]: 'центральное',
  [WaterSupplyTypes.Hole]: 'скважина',
  [WaterSupplyTypes.Well]: 'колодец',
};

export type TFeatureStatus = 'disabled' | 'enabled';

export const FeatureStatusTypes = {
  Disabled: 'disabled' as TFeatureStatus,
  Enabled: 'enabled' as TFeatureStatus,
};

export type TFeatureType = 'nature' | 'social' | 'location' | 'construction';

export const FeatureTypes = {
  Construction: 'construction' as TFeatureType,
  Location: 'location' as TFeatureType,
  Nature: 'nature' as TFeatureType,
  Social: 'social' as TFeatureType,
};

export const FeatureNames = {
  [FeatureTypes.Construction]: 'Материалы и строительство',
  [FeatureTypes.Location]: 'Расположение',
  [FeatureTypes.Nature]: 'Природа',
  [FeatureTypes.Social]: 'Социальный элемент',
};

export type TMonth =
  | 'april'
  | 'august'
  | 'december'
  | 'february'
  | 'january'
  | 'july'
  | 'june'
  | 'march'
  | 'may'
  | 'november'
  | 'october'
  | 'september';

export const MonthTypes = {
  April: 'april' as TMonth,
  August: 'august' as TMonth,
  December: 'december' as TMonth,
  February: 'february' as TMonth,
  January: 'january' as TMonth,
  July: 'july' as TMonth,
  June: 'june' as TMonth,
  March: 'march' as TMonth,
  May: 'may' as TMonth,
  November: 'november' as TMonth,
  October: 'october' as TMonth,
  September: 'september' as TMonth,
};

export const MonthNames = {
  [MonthTypes.January]: 'Январь',
  [MonthTypes.February]: 'Февраль',
  [MonthTypes.March]: 'Март',
  [MonthTypes.April]: 'Апрель',
  [MonthTypes.May]: 'Май',
  [MonthTypes.June]: 'Июнь',
  [MonthTypes.July]: 'Июль',
  [MonthTypes.August]: 'Август',
  [MonthTypes.September]: 'Сентябрь',
  [MonthTypes.October]: 'Октябрь',
  [MonthTypes.November]: 'Ноябрь',
  [MonthTypes.December]: 'Декабрь',
};

export type TCommute = 'kad' | 'mkad' | null;

export const CommuteTypes = {
  Kad: 'kad' as TCommute,
  Mkad: 'mkad' as TCommute,
  None: null as TCommute,
};

export const CommuteNames = {
  [CommuteTypes.Kad as string]: 'КАД',
  [CommuteTypes.Mkad as string]: 'МКАД',
  [CommuteTypes.None as string]: 'Центр',
};

export interface ICounterSet {
  total?: number | null;
  sold?: number | null;
  underConstruction?: number | null;
  onSaleCottage?: number | null;
  onSaleLandplot?: number | null;
  onSaleLowrisehouse?: number | null;
  onSaleTownhouse?: number | null;
}

export interface IRoadInfo {
  distanceCity: number;
  distanceVillage: number;
  roadId: number;
}

export interface IStationInfo {
  directionId: number;
  distanceVillage: number;
  stationId: number;
}

export const roadDistanceVillageValues = [5, 10, 25];
export const roadDistanceVillageDropdownValues = roadDistanceVillageValues.map(distanceVillage => ({
  label: distanceVillage.toString(),
  value: distanceVillage.toString(),
}));

export const railwayDistanceVillageValues = [1, 5, 10];
export const railwayDistanceVillageDropdownValues = railwayDistanceVillageValues.map(distanceVillage => ({
  label: distanceVillage.toString(),
  value: distanceVillage.toString(),
}));

export interface INewObjectCategorySetItem {
  areaMax?: number | null;
  areaMin?: number | null;
  priceMax?: number | null;
  priceMin?: number | null;
  description?: string | null;
  gallery: IGallery;
  additionalGallery?: {
    imageList?: IPhoto[] | null;
  } | null;
  fatTitle?: string | null;
  fatDescription?: string | null;
  fatIconId?: number | null;
  fatEnabled?: boolean | null;
}

export interface IVillageNeighbourhoodItem {
  description?: string | null;
  imageUrl?: string | null;
}

export const pageSizeValues = [20, 50, 100];

export interface IPagination {
  pageSize: number;
  p: number;
  totalItems?: number;
  totalPages?: number;
}

export interface IBuilderListParams {
  createdFrom?: string;
  createdTo?: string;
  id?: string | number;
  name?: string;
  p: number;
  pageSize: number;
  regionList?: number[];
  updatedFrom?: string;
  updatedTo?: string;
  moderationStatus?: TModerationStatus;
  totalItems?: number;
  totalPages?: number;
}

export interface IVillageSeo {
  transliteratedVillageName: string;
  url: string;
}

export interface IEffectivenessReport {
  created: string;
  reportUrl: string;
}

export type TChangelogModerationStatus = 'draft' | 'published' | 'deleted' | 'new';

export const ChangeloModerationStatusTypes = {
  Deleted: 'deleted' as TChangelogModerationStatus,
  Draft: 'draft' as TChangelogModerationStatus,
  New: 'new' as TChangelogModerationStatus,
  Published: 'published' as TChangelogModerationStatus,
};

export interface IVillageChangelogSchema {
  changes?: string | null;
  created?: string | null;
  id: number;
  userEmail: string;
  villageId: number;
  villageModerationStatus: EVillageModerationStatus;
}

export interface IGetVillageChangelogResponseSchema {
  villageChangelog?: IVillageChangelogSchema[] | null;
}

export interface ISort {
  field?: 'created' | 'updated';
  order?: 'asc' | 'desc';
}

export interface IVillageFilter {
  builderName?: string;
  finishFrom?: string;
  finishTo?: string;
  id?: number;
  managerList?: number[] | null;
  moderationStatusList?: TModerationStatus[];
  name?: string;
  readyStatusList?: TVillageReadinessStatus[];
  regionList?: number[];
  saleStatusList?: TVillageSaleStatus[];
  sort?: ISort;
  totalStatusList?: TVillageTotalStatus[];
  villageClassList?: TVillageClass[];
  totalItems?: number;
  totalPages?: number;
  withBrokenUrlOnly?: boolean;
  tariff?: TTariff;
  tariffList?: TTariff[];
  withUnboundOffers?: boolean;
  cianUserId?: number;
  orderId?: number;
  builderId?: number;
}

export interface IVillageListParams extends IVillageFilter {
  p: string | number;
  pageSize: string | number;
}

export type TNullableModerationStatus = TModerationStatus & '';

export type TQuarter = 'first' | 'second' | 'third' | 'fourth';

export const QuarterTypes = {
  First: 'first' as TQuarter,
  Fourth: 'fourth' as TQuarter,
  Second: 'second' as TQuarter,
  Third: 'third' as TQuarter,
};

export const QuarterNames = {
  [QuarterTypes.First]: 'I',
  [QuarterTypes.Second]: 'II',
  [QuarterTypes.Third]: 'III',
  [QuarterTypes.Fourth]: 'IV',
};

export type TTariff = 'notSet' | 'premium' | 'basic' | 'cpl' | 'freeCpl';

export const BuilderVillagesTariffNames = {
  [EVillagesTariffList.NotSet]: 'Не подключен',
  [EVillagesTariffList.Basic]: 'Basic',
  [EVillagesTariffList.Premium]: 'Premium',
  [EVillagesTariffList.Cpl]: 'CPL',
  [EVillagesTariffList.FreeCpl]: 'CPL',
};

export const VillagesTariffNames = {
  ...BuilderVillagesTariffNames,
  [EVillagesTariffList.FreeCpl]: 'Бесплатный CPL',
};

export const VillagesTariffIconClassNames = {
  [EVillagesTariffList.NotSet]: 'far fa-star',
  [EVillagesTariffList.Basic]: 'fas fa-star-half-alt',
  [EVillagesTariffList.Premium]: 'fas fa-star',
  [EVillagesTariffList.Cpl]: 'fas fa-headphones',
  [EVillagesTariffList.FreeCpl]: 'fas fa-headphones',
};

export const BuilderCalltrackingTariffNames = {
  [ECalltrackingTariffList.NotSet]: 'Не подключен',
  [ECalltrackingTariffList.Basic]: 'Basic',
  [ECalltrackingTariffList.Premium]: 'Premium',
  [ECalltrackingTariffList.Cpl]: 'CPL',
};

export const CalltrackingTariffNames = {
  ...BuilderCalltrackingTariffNames,
  [ECalltrackingTariffList.FreeCpl]: 'Бесплатный CPL',
};

export type TCplEventStatus = 'positive' | 'negative' | 'info';

export interface ICplEventDefinition {
  name: string;
  status?: TCplEventStatus;
  isAmount?: boolean;
  prefix?: string;
}

export const ContractorCallsSuccessStatusNames = {
  [EContractorCallsStatus.Busy]: 'Занято',
  [EContractorCallsStatus.Hgup]: 'Сбросили',
  [EContractorCallsStatus.NoAnswer]: 'Без ответа',
  [EContractorCallsStatus.Success]: 'Успешный',
  [EContractorCallsStatus.TechProblem]: 'Ошибка',
  [EContractorCallsStatus.TurnedOff]: 'Выключен',
  [EContractorCallsStatus.Unknown]: 'Неизвестно',
};

export const quarterNameList = Object.keys(QuarterNames).map(key => ({ value: key, label: QuarterNames[key] }));

export const yearRangeList = createYearRangeList();

export const monthNameList = generateDropdown(MonthNames);

export const readinessStatusList = generateDropdown(VillageReadinessStatusNames);

export const villageSaleStatusList = generateDropdown(VillageSaleStatusNames);

export const moderationStatusList = generateDropdown(ModerationStatusNames);

export const villageClassList = generateDropdown(VillageClassNames);

export const villageTotalStatusList = generateDropdown(VillageTotalStatusNames);

export const GasCommunicationDropdownValues = generateDropdown(GasCommunicationNames);

export const HeatingCommunicationDropdownValues = generateDropdown(HeatingCommunicationNames);

export const CanalizationCommunicationDropdownValues = generateDropdown(CanalizationCommunicationNames);

export const ElectricityCommunicationDropdownValues = generateDropdown(ElectricityCommunicationNames);

export const RoadCommunicationDropdownValues = generateDropdown(RoadCommunicationNames);

export const WaterSupplyCommunicationDropdownValues = generateDropdown(WaterSupplyCommunicationNames);

export const HouseMaterialsDropdownValues = generateDropdown(HouseMaterialNames);

export const InfrastructureDropdownValues = generateDropdown(VillageInfrastructureNames);
