import { ERoles } from '../../../repositories/countryside/v2/get-user-role-list';
import {
  ICallsPopup,
  IContractorCallsPlayerStatus,
  TContractorSelectFieldProps,
  IContractorCallsFilterValues,
  ValueOf,
  TConstructorCall,
  TChangerType,
  TCallType,
} from '../../../types';
import { Container } from '../../Container';
import { ContractorCallsTable } from './ContractorCallsTable';
import { Pagination } from '../../pagination';
import { addScrollFn } from '../../../../browser/utils/addScrollFn';
import { getQueryStringByPage } from '../../../utils/filters';
import { Heading } from '../../Heading';
import { Stats } from '../../Stats';
import { useSetAlertWithTimeoutHook } from 'shared/utils/useSetAlertWithTimeoutHook';
import { IAlert } from 'shared/common/interfaces';
import { useCallback, useContext } from 'react';
import { setConstructorCallType } from 'shared/services/setConstructorCallType';
import { AppContext } from 'shared/AppContext';
import { Outside } from 'shared/components/Outside';
import { Alert } from 'shared/components/Alert';
import { useEventCallback } from '@cian/react-utils';

type TAlertType = 'ERROR' | 'SUCCESS' | 'HIDDEN';
const ALERT_MAP: Record<TAlertType, IAlert> = {
  ERROR: {
    type: 'danger',
    text: 'Произошла ошибка при изменении типа записи',
    isVisible: true,
  },
  SUCCESS: {
    type: 'success',
    text: 'Тип записи успешно изменён',
    isVisible: true,
  },
  HIDDEN: {
    type: 'success',
    text: '',
    isVisible: false,
  },
};

export interface IContractorCalltrackingListProps extends IContractorCallsFilterValues {
  roles: ERoles[];
  // regionList: IDropdownValue[];
  contractorCallsList: TConstructorCall[];
  totalPages: number;
  totalItems: number;
  callPopup: ICallsPopup;
  playerStatus: IContractorCallsPlayerStatus;
  onPageChange(page: number): void;
  onPageSizeChange(pageSize: string): void;
  onIdChange(name: 'contractorId' | 'projectId', value: string | undefined): void;
  onSelectChange(name: keyof TContractorSelectFieldProps, value: ValueOf<TContractorSelectFieldProps>): void;
  // onMultiSelectChange(
  //   name: keyof TContractorMultiselectFieldProps,
  //   value: ValueOf<TContractorMultiselectFieldProps>,
  // ): void;
  onDateChange(name: 'dateTo' | 'dateFrom', value: Date | undefined): void;
  onUpdate(): void;
  onRowClick(call: TConstructorCall, isOpen: boolean, recordFilePath: string | undefined, isPaused: boolean): void;
  onCallTypeChange(): void;
  onSaveReport(): void;
}

export function ContractorCalltrackingList(props: IContractorCalltrackingListProps) {
  const {
    contractorCallsList,
    totalPages,
    totalItems,
    page,
    pageSize,
    // regionList,
    callPopup,
    playerStatus,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onCallTypeChange,
    onSaveReport,
  } = props;
  const { httpApi, logger } = useContext(AppContext);

  const [alert, setAlert] = useSetAlertWithTimeoutHook(ALERT_MAP.HIDDEN);
  const hideAlert = useEventCallback(() => setAlert(ALERT_MAP.HIDDEN));

  const handleCallTypeChange = useCallback(
    async (callId: number, changerType: TChangerType, callType: TCallType) => {
      try {
        await setConstructorCallType(httpApi, logger, { callId, changerType, callType });
        setAlert(ALERT_MAP.SUCCESS);
        onCallTypeChange();
      } catch {
        setAlert(ALERT_MAP.ERROR);
      }
    },
    [httpApi, logger, setAlert, onCallTypeChange],
  );

  return (
    <Container>
      {alert.isVisible && (
        <Outside onOutside={hideAlert}>
          <Alert onClick={hideAlert} text={alert.text} type={alert.type} />
        </Outside>
      )}
      <Heading title="Звонки строителей" />
      {/* 
        Пока убираем фильтры для новой апишки звонков строителей
      <ContractorCallsFilters
        contractorId={contractorId}
        contractorName={contractorName}
        dateFrom={dateFrom}
        dateTo={dateTo}
        projectId={projectId}
        projectName={projectName}
        regionId={regionId}
        sourceRegionId={sourceRegionId}
        status={status}
        regionList={regionList}
        onIdChange={onIdChange}
        onSelectChange={onSelectChange}
        onMultiSelectChange={onMultiSelectChange}
        onDateChange={onDateChange}
        onUpdate={onUpdate}
      /> */}
      <Stats title="Найдено звонков:" totalItems={totalItems} onSaveReport={onSaveReport} />
      <ContractorCallsTable
        contractorCallsList={contractorCallsList}
        // regionList={regionList}
        callPopup={callPopup}
        playerStatus={playerStatus}
        onRowClick={onRowClick}
        onCallTypeChange={handleCallTypeChange}
      />
      <Pagination
        activePage={page}
        pageSize={pageSize}
        pageCount={totalPages}
        onPageChange={addScrollFn(onPageChange)}
        onPageCountChange={value => addScrollFn(onPageSizeChange)(value || '20')}
        getQueryString={getQueryStringByPage({ page, pageSize })}
      />
    </Container>
  );
}
