import { IContractorCalltrackingListPageState } from '../../types/redux';
import { IThunkActionCreator } from '../../types/thunk';
import { setContractorCallsListData } from './actions';
import { getContractorsCalls } from '../../http';
import { updateContractorCalltrackingPageBrowserUrl } from '../../utils/urinator';
import { normalizeContractorCalltrackingFilters } from '../../utils/helpers/normalizeContractorCalltrackingFilters';
import { NotificationManager } from '../../utils/showNotification';

// thunk-action для обновления данных списка звонков строителя

export function updateContractorCallsListData(): IThunkActionCreator<IContractorCalltrackingListPageState> {
  return async (dispatch, getState, { httpApi, logger }) => {
    const { filters } = getState();

    try {
      const data = await getContractorsCalls(httpApi, logger, normalizeContractorCalltrackingFilters(filters));
      dispatch(setContractorCallsListData(data));
      updateContractorCalltrackingPageBrowserUrl(getState());
    } catch (e) {
      dispatch(
        setContractorCallsListData({
          calls: [],
          totalItems: 0,
          totalPages: 0,
        }),
      );

      NotificationManager.error(e.message);
    }
  };
}
