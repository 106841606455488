import { Outside } from 'shared/components/Outside';
import { mergeStyles } from '@cian/utils';
import { PhoneLink } from '../PhoneLink/PhoneLink';
import { Portal } from '../Portal';
import { AudioPlayer } from '../AudioPlayer';
import { CalltrackingPopupItem } from '../CalltrackingPopupItem/CalltrackingPopupItem';
import { prettifySeconds } from '../../utils/prettifySeconds';
import { formatDateTime } from '../../utils/datetime';
import { onEscPressed } from '../../utils/handlers';
import { noop } from '../../utils/noop';
import * as styles from './ContractorCallDesktopPopup.css';
import { ERoles } from '../../repositories/countryside/v2/get-user-role-list/types';
import { Button } from 'react-bootstrap';
import { ICallHistoryItem, TConstructorCall } from 'shared/types';
import { CalltrackingListItemHistory } from '../CalltrackingListItemHistory';

export interface IContractorCallDesktopPopupProps {
  isOpen: boolean;
  call?: TConstructorCall;
  history: ICallHistoryItem[];
  // regionList: IRegion[];
  recordFilePath: string | undefined;
  isPaused: boolean;
  onClose(): void;
  onTrackEnd(): void;
  onTogglePause(isPaused: boolean): void;
  roles: ERoles[];
}

export function ContractorCallDesktopPopup({
  isOpen,
  call,
  history,
  // regionList,
  // roles,
  recordFilePath,
  isPaused,
  onClose,
  onTrackEnd,
  onTogglePause,
}: IContractorCallDesktopPopupProps) {
  if (!call) {
    return null;
  }

  const {
    callId,
    calltrackingPhone,
    // contractorName,
    date,
    destinationPhone,
    duration,
    // projectName,
    // regionId,
    sourcePhone,
    // status,
    // sourceRegionId,
  } = call;

  return (
    <Portal isHidden={!isOpen}>
      <div
        {...mergeStyles(styles['container'], !isOpen && styles['container--hidden'])}
        tabIndex={0}
        onKeyDown={isOpen ? onEscPressed(onClose) : noop}
      >
        <Outside onOutside={isOpen ? onClose : noop}>
          <div
            {...mergeStyles(
              'container',
              styles['outside_container'],
              !!history.length && styles['outside_container--with-history'],
            )}
          >
            <div className={styles['modal']}>
              <div className={styles['modal_header']}>Звонок №{callId}</div>
              <button className={styles['close_button']} onClick={isOpen ? onClose : noop}>
                &times;
              </button>
              <div className={styles['modal_content']}>
                <CalltrackingPopupItem label="Звонок">
                  <div {...mergeStyles(styles['call_container'], !recordFilePath && styles['call_container--hidden'])}>
                    <AudioPlayer
                      src={recordFilePath}
                      playOnInit={!isOpen}
                      callId={callId}
                      isPaused={isPaused}
                      onTrackEnd={isOpen ? noop : onTrackEnd}
                      onTogglePlay={() => onTogglePause(false)}
                      onTogglePause={() => onTogglePause(true)}
                    />
                  </div>
                  {!recordFilePath && <span>Запись отсутствует</span>}
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="">
                  <Button href={recordFilePath}>Получить ссылку на звонок</Button>
                </CalltrackingPopupItem>
                {/* <CalltrackingPopupItem label="Статус">{ContractorCallsSuccessStatusNames[status]}</CalltrackingPopupItem> */}
                <CalltrackingPopupItem label="Дата и время звонка">
                  {formatDateTime(date, '+0300')}
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Длительность">{prettifySeconds(duration)}</CalltrackingPopupItem>
                {/* <CalltrackingPopupItem label="Строитель">{contractorName}</CalltrackingPopupItem>
              <CalltrackingPopupItem label="Проект">{projectName}</CalltrackingPopupItem>
              <CalltrackingPopupItem label="Регион проекта">
                {getRegionNameFromList(regionList, regionId)}
              </CalltrackingPopupItem> */}
                <CalltrackingPopupItem label="Телефон">
                  <PhoneLink phone={sourcePhone} />
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Подменный номер">
                  <PhoneLink phone={calltrackingPhone} />
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Телефон застройщика">
                  <PhoneLink phone={destinationPhone} />
                </CalltrackingPopupItem>
                {/* {sourceRegionId && (
                <CalltrackingPopupItem label="Регион звонившего">
                  {getRegionNameFromList(regionList, sourceRegionId)}
                </CalltrackingPopupItem>
              )} */}
                <CalltrackingListItemHistory history={history} />
              </div>
            </div>
          </div>
        </Outside>
      </div>
    </Portal>
  );
}
