import { withRoles } from '../withRoles';
import { IWithRoles } from '../withRoles/withRoles';
import { CalltrackingButton } from '../CalltrackingButton';
import { TCallType, TChangerType } from 'shared/types';
import { useController } from './hooks';
import { mergeStyles } from '@cian/utils';
import * as styles from './CallTypeToggle.css';

interface ICallTypeToggleProps {
  setCallType(callId?: number, changerType?: TChangerType, type?: TCallType): void;
  callId: number | undefined | null;
  callType: TCallType | undefined | null;
  isEditable?: boolean | null;
}

export function CallTypeToggleComponent(props: ICallTypeToggleProps & IWithRoles) {
  const { callId, callType, roles, setCallType, isEditable = true } = props;
  const { disabled, handleClick } = useController({ isEditable, roles, callId, callType, setCallType });

  return (
    <div className={styles['container']}>
      <div
        {...mergeStyles(styles['btn'], callType === 'target' && styles['btn-selected'])}
        onClick={e => e.stopPropagation()}
      >
        <CalltrackingButton
          value="target"
          disabled={disabled}
          selected={callType === 'target'}
          onClick={handleClick}
          onlyIcon
        />
      </div>
      <div
        {...mergeStyles(styles['btn'], callType === 'nonTarget' && styles['btn-selected'])}
        onClick={e => e.stopPropagation()}
      >
        <CalltrackingButton
          value="nonTarget"
          disabled={disabled}
          selected={callType === 'nonTarget'}
          onClick={handleClick}
          onlyIcon
        />
      </div>
    </div>
  );
}

export const CallTypeToggle = withRoles<ICallTypeToggleProps>(CallTypeToggleComponent);
