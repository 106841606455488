import {
  fetchUploadImage,
  IUploadImageRequest,
  IUploadImageResponse,
  IUploadImageResponseError,
} from '../repositories/countryside/v1/upload-image';

import { createSimpleRequest } from './createSimpleRequest';

export const uploadImage = createSimpleRequest<IUploadImageRequest, IUploadImageResponse, IUploadImageResponseError>(
  'uploadImage',
  fetchUploadImage,
);
