import {
  EContractorCallsListDataActionTypes,
  TSetContractorCallsListData,
} from '../../actions/contractorCallsListData';
import { DEFAULT_CONTRACTOR_CALLS } from '../../constants';
import { TAdminConstructorCalls } from '../../types';

export type TPContractorCallsListDataAction = TSetContractorCallsListData;

export function contractorCallsListDataReducer(
  state: TAdminConstructorCalls = DEFAULT_CONTRACTOR_CALLS,
  action: TPContractorCallsListDataAction,
): TAdminConstructorCalls {
  switch (action.type) {
    case EContractorCallsListDataActionTypes.SetData:
      return action.payload;
    default:
      return state;
  }
}
