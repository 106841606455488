import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { TFeatureModel } from '../../../../common/interfaces';

import * as styles from './SelectFeatureItem.css';

interface ISelectFeatureItemProps {
  feature: TFeatureModel | null;
  readOnly?: boolean;
  onEdit(event: React.MouseEvent<HTMLButtonElement>): void;
  onRemove(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const SelectFeatureItem = ({ feature, readOnly, onEdit, onRemove }: ISelectFeatureItemProps) => {
  if (!feature) {
    return null;
  }

  return (
    <div className={styles['feature-container']}>
      <div className={styles['preview-inline']}>
        <img className={styles['feature-img']} src={feature.imageUrl} />
      </div>
      {!readOnly && (
        <div className={styles['control-block']}>
          <button onClick={onEdit} {...mergeStyles('btn btn-primary btn-xs', styles['edit-btn'])}>
            <i className="fas fa-pen" />
          </button>
          <button onClick={onRemove} {...mergeStyles('btn btn-danger btn-xs', styles['remove-btn'])}>
            <i className="fas fa-times" />
          </button>
        </div>
      )}
      <p className={styles['feature-description']}>{feature.description}</p>
    </div>
  );
};
