import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { IRailwayDirection, IStation, IStationInfo, railwayDistanceVillageValues } from '../../../common/interfaces';
import { IDropdownValue } from '../../SelectField';
import { ISortingItems, SortingComponent } from '../../SortingComponent';
import { VillageStation } from './VillageStation';

import * as styles from './VillageRailway.css';
import { Button, Container } from 'react-bootstrap';

const MAX_STATION_COUNT = 3;

export const getStationInfoTemplate = (): IStationInfo => ({
  directionId: 0,
  distanceVillage: Math.max(...railwayDistanceVillageValues),
  stationId: 0,
});

export interface IVillageRailwayProps {
  stationInfoList: IStationInfo[];
  availableDirections: IRailwayDirection[];
  availableStations: IStation[];
  regionId: number;
  readOnly?: boolean;
  onChange(newValue: IStationInfo[], isDataValid: boolean): void;
  onInit(startValue: IStationInfo[], isDataValid: boolean): void;
}

interface IVillageRailwayState {
  selectedStations: IStationInfo[];
  isDataValid: boolean;
}

export class VillageRailway extends React.Component<IVillageRailwayProps, IVillageRailwayState> {
  public state = {
    isDataValid: isStationInfoListValid(this.props.stationInfoList),
    selectedStations: this.props.stationInfoList,
  };

  public componentDidMount() {
    const { selectedStations, isDataValid } = this.state;

    this.props.onInit(selectedStations, isDataValid);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IVillageRailwayProps) {
    this.setState({
      isDataValid: isStationInfoListValid(nextProps.stationInfoList),
      selectedStations: nextProps.stationInfoList,
    });
  }

  public render() {
    const { readOnly } = this.props;
    const { selectedStations, isDataValid } = this.state;

    return (
      <Container fluid {...mergeStyles(styles['container'], !isDataValid && styles['container--invalid'])}>
        {!isDataValid && (
          <div className={styles['invalid-message']}>
            Все обязательные поля в этой секции должны быть заполнены, количество ж/д станций должно быть не более{' '}
            {MAX_STATION_COUNT}, выбранные станции не должны дублироваться
          </div>
        )}
        <div>
          <ul className={styles['village-stations']}>
            <SortingComponent
              list={this.props.stationInfoList}
              items={this.sortingItems}
              onChange={this.handleStationsChange}
              itemStyle={styles['sorting_item']}
              readOnly={readOnly}
            />
          </ul>
          {!readOnly && (
            <Button
              className={styles['add-btn']}
              variant="success"
              type="button"
              onClick={this.addStationInfoItem}
              disabled={selectedStations.length === MAX_STATION_COUNT}
            >
              Добавить ЖД-станцию
            </Button>
          )}
          {selectedStations.length === MAX_STATION_COUNT && (
            <span className={styles['invalid-message']}>Добавлено максимальное количество жд</span>
          )}
        </div>
      </Container>
    );
  }

  private handleDeleteItemClick = (index: number) => () => {
    const { selectedStations } = this.state;
    selectedStations.splice(index, 1);
    const isDataValid = isStationInfoListValid(selectedStations);
    this.props.onChange(selectedStations, isDataValid);

    this.setState({
      isDataValid,
      selectedStations,
    });
  };

  private getTerminal = (directionId: number) => {
    const { availableDirections, availableStations } = this.props;

    const direction = availableDirections.filter(item => item.id === directionId)[0];
    if (!direction || !direction.lineFrom) {
      return '';
    }

    const station = availableStations.filter(item => item.id === direction.lineFrom)[0];

    return (station && station.name) || '';
  };

  private getDistanceToTerminal = (stationId: number) => {
    const { availableStations } = this.props;

    const station = availableStations.filter(item => item.id === stationId)[0];

    return (station && station.sorterId && `, ${station.sorterId} км`) || '';
  };

  private getTerminalInfo = (directionId: number, stationId: number) =>
    this.getTerminal(directionId) + this.getDistanceToTerminal(stationId);

  private getStationValues = (directionId: number): IDropdownValue[] => {
    const { availableStations } = this.props;

    return directionId
      ? availableStations
          .filter(station => station.directions.includes(directionId))
          .map(i => ({ value: i.id, label: this.getStationLabel(i) }))
      : [];
  };

  private handleDirectionChange = (itemIndex: number) => (value: string) => {
    const { selectedStations } = this.state;
    const directionId = Number(value);
    selectedStations[itemIndex] = {
      ...selectedStations[itemIndex],
      directionId,
      stationId: 0,
    };

    const isDataValid = isStationInfoListValid(selectedStations);
    this.props.onChange(selectedStations, isDataValid);

    this.setState({
      isDataValid,
      selectedStations,
    });
  };

  private handleStationChange = (itemIndex: number) => (value: string) => {
    const { selectedStations } = this.state;

    selectedStations[itemIndex] = {
      ...selectedStations[itemIndex],
      stationId: Number(value),
    };

    const isDataValid = isStationInfoListValid(selectedStations);
    this.props.onChange(selectedStations, isDataValid);

    this.setState({
      isDataValid,
      selectedStations,
    });
  };

  private getDirectionLabel = (direction: IRailwayDirection): string => direction.name || 'Направление без названия';

  private getStationLabel = (station: IStation): string => station.name || 'Станция без названия';

  private handleDistanceVillageChange = (itemIndex: number) => (value: string) => {
    const { selectedStations } = this.state;
    const numberValue = Number(value);

    if (!railwayDistanceVillageValues.includes(numberValue)) {
      return;
    }

    selectedStations[itemIndex] = {
      ...selectedStations[itemIndex],
      distanceVillage: numberValue,
    };

    const isDataValid = isStationInfoListValid(selectedStations);
    this.props.onChange(selectedStations, isDataValid);

    this.setState({
      isDataValid,
      selectedStations,
    });
  };

  private addStationInfoItem = () => {
    const { selectedStations } = this.state;

    if (selectedStations.length >= MAX_STATION_COUNT) {
      return;
    }

    const newItem = getStationInfoTemplate();
    const newData = [...selectedStations, newItem];

    const isDataValid = isStationInfoListValid(newData);
    this.props.onChange(newData, isDataValid);

    this.setState({
      isDataValid,
      selectedStations: newData,
    });
  };

  private handleStationsChange = (selectedStations: IStationInfo[]) => {
    const isDataValid = isStationInfoListValid(selectedStations);
    this.setState(
      {
        isDataValid,
        selectedStations,
      },
      () => this.props.onChange(selectedStations, isDataValid),
    );
  };

  private get directionValues(): IDropdownValue[] {
    return this.props.availableDirections
      .filter(direction => direction.id)
      .map(direction => ({
        value: direction.id as number,
        label: this.getDirectionLabel(direction),
      }));
  }

  private getItemProps = ({ directionId, stationId }: IStationInfo, index: number) => ({
    directionValues: this.directionValues,
    stationValues: this.getStationValues(directionId),
    terminalInfo: this.getTerminalInfo(directionId, stationId),
    onDirectionChange: this.handleDirectionChange(index),
    onDistanceVillageChange: this.handleDistanceVillageChange(index),
    onStationChange: this.handleStationChange(index),
    onRemove: this.handleDeleteItemClick(index),
    readOnly: this.props.readOnly,
  });

  private get sortingItems(): ISortingItems {
    return this.props.stationInfoList.reduce(
      (acc, item, index) => ({
        ...acc,
        [item.stationId]: <VillageStation item={item} {...this.getItemProps(item, index)} />,
      }),
      {},
    );
  }
}

export function isStationInfoListValid(selectedStations: IStationInfo[]): boolean {
  const ids = selectedStations.map(({ stationId }) => stationId);

  return ids.length <= MAX_STATION_COUNT && ids.every(Boolean) && new Set(ids).size === ids.length;
}
