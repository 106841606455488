import { ECplHistoryActionTypes, TSetCplHistory, TSetCplHistoryVillage } from '../../actions/cplHistory';
import { ICplHistory } from '../../types';

export type TCplHistoryAction = TSetCplHistory | TSetCplHistoryVillage;

export function cplHistoryReducer(state: ICplHistory, action: TCplHistoryAction): ICplHistory {
  switch (action.type) {
    case ECplHistoryActionTypes.SetData:
      return {
        ...state,
        ...action.payload,
      };

    case ECplHistoryActionTypes.SetVillage:
      return {
        ...state,
        selectedVillageId: action.payload,
      };

    default:
      return state || null;
  }
}
