import * as React from 'react';

import { loadYmapsApi } from '../../../../utils/helpers';
import { IGeoCoordinates } from '../../../../http';
import { isCoordinatesEqual } from '../../../../utils/isCoordinatesEqual';

import * as styles from './AddressMap.css';

export const COORDINATES_MOSCOW = { lat: 55.75396, lng: 37.620393 };

const INITIAL_ZOOM = 15;
const Y_MAPS_OPTIONS = {
  require: [
    'Map',
    'ObjectManager',
    'templateLayoutFactory',
    'geometry.pixel.Rectangle',
    'Placemark',
    'overlay.Placemark',
    'shape.Rectangle',
    'control.RulerControl',
    'control.FullscreenControl',
    'control.Button',
    'control.ZoomControl',
    'objectManager.addon.objectsBalloon',
  ],
};

interface IAddressMapProps {
  coordinates?: IGeoCoordinates | null;
  onCoordinatesChange(coordinates: IGeoCoordinates): void;
  onSearchButtonClick(coordinates: IGeoCoordinates): void;
  readOnly?: boolean;
}

export class AddressMap extends React.Component<IAddressMapProps> {
  private mapContainer = React.createRef<HTMLDivElement>();
  private map: YMaps.Map;
  private ymaps: YMaps.IYMaps;
  private placemark: YMaps.Placemark;

  public async componentDidMount() {
    const { readOnly } = this.props;
    const { current: mapContainer } = this.mapContainer;
    this.ymaps = await loadYmapsApi(Y_MAPS_OPTIONS);

    if (mapContainer) {
      const { coordinates } = this.props;
      const { lat, lng } = coordinates || COORDINATES_MOSCOW;
      this.map = new this.ymaps.Map(mapContainer, {
        center: [lat as number, lng as number],
        controls: ['fullscreenControl', 'zoomControl'],
        zoom: INITIAL_ZOOM,
      });

      const changeAddressButton = new this.ymaps.control.Button({
        data: {
          content: `<div class="fas fa-search"></div>`,
        },
        options: {
          float: 'right',
          selectOnClick: false,
        },
      });
      changeAddressButton.events.add('click', () => {
        if (this.placemark.geometry) {
          const [lat, lng] = this.placemark.geometry.getCoordinates();
          this.props.onSearchButtonClick({ lat, lng });
        }
      });
      this.map.controls.add(changeAddressButton);

      this.placemark = new this.ymaps.Placemark(this.map.getCenter(), {}, { draggable: !readOnly });
      this.placemark.events.add('dragend', (event: YMaps.IEvent) => {
        const [lat, lng] = event.originalEvent.target.geometry.getCoordinates();
        this.props.onCoordinatesChange({ lat, lng });
      });
      this.map.geoObjects.add(this.placemark);
    }
  }

  public componentDidUpdate(prevProps: IAddressMapProps) {
    const { coordinates } = this.props;
    if (!coordinates || !(this.placemark && this.placemark.geometry)) {
      return;
    }

    /**
     * Устанавливаем новый центр по координатам, только если изменили город в строке поиска
     * поэтому игнорируем изменение координат, произведённые перетаскиванием пина
     */
    const [lat, lng] = this.placemark.geometry.getCoordinates();
    if (!isCoordinatesEqual(coordinates, { lat, lng }) && !isCoordinatesEqual(coordinates, prevProps.coordinates)) {
      const { lat, lng } = coordinates;
      this.map.setCenter([lat as number, lng as number]);
      this.placemark.geometry.setCoordinates([lat as number, lng as number]);
    }
  }

  public render() {
    return <div ref={this.mapContainer} className={styles['map']} />;
  }
}
