import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import {
  fetchGetBuilderList,
  IGetBuilderListRequest,
  IGetBuilderListResponse,
  IGetBuilderListResponseError,
  TGetBuilderListResponse,
} from '../../node/repositories/countryside/v1/get-builder-list';
import { IBuilderListItem } from '../common/interfaces';
import { getFormattedError } from '../utils';

import { isResponseContainsKey } from './helpers';

export interface IGetBuildersResponse {
  totalItems?: number | null;
  totalPages?: number | null;
  builderList?: IBuilderListItem[] | null;
}

export async function getBuilderList(
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IGetBuilderListRequest,
): Promise<IGetBuildersResponse> {
  try {
    const { response, statusCode }: TGetBuilderListResponse = await fetchGetBuilderList({ httpApi, parameters });

    if (
      statusCode === 400 ||
      isResponseContainsKey<IGetBuilderListResponseError, IGetBuilderListResponse>(response, 'errors')
    ) {
      throw response;
    }

    return response as IGetBuildersResponse;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getBuilderList', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getBuilderList' });

      throw formattedError;
    }

    throw error;
  }
}
