import { IAdminGetCallsRequest } from '../../repositories/countryside-calltracking/v1/admin-get-calls';
import { formatCalltrackingDateTime } from '../datetime';
import { normalizeManagerList } from './normalizeManagerList';

export function normalizeCallTrackingFilters(filters: IAdminGetCallsRequest) {
  return {
    ...filters,
    callDateFrom: formatCalltrackingDateTime(filters.callDateFrom),
    callDateTo: formatCalltrackingDateTime(filters.callDateTo),
    managerIdList: normalizeManagerList(filters.managerIdList),
  };
}
