import thunk from 'redux-thunk';
import { compose, applyMiddleware, createStore as _createStore, StoreEnhancer, Reducer, PreloadedState } from 'redux';

import { TReduxActions, TReduxStore } from '../../types/redux';
import { IReduxThunkContext } from '../../types/thunk';
import { isClient } from '../helpers/getEnvironmentInfo';

/**
 * Хелпер для создания Redux стора на основе стейта
 * @param initialState - предзагруженное состояние
 * @param context - context редакса
 * @param reducers - combineReducers для конкретного стора
 */

export function createReduxStore<S>(initialState: S, reducers: Reducer<S, TReduxActions>, context: IReduxThunkContext) {
  const composeEnhancers = (isClient() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const middlewares = [thunk.withExtraArgument(context)];

  const storeEnchancers = composeEnhancers(compose(applyMiddleware(...middlewares))) as unknown as StoreEnhancer<S>;

  const createStore = (): TReduxStore<S> =>
    _createStore<S, TReduxActions, S, {}>(reducers, initialState as unknown as PreloadedState<S>, storeEnchancers);

  return createStore();
}
