export const MAINPAGE_ROUTE = '/';
export const VILLAGES_ROUTE = '/villages';
export const BUILDERS_ROUTE = '/builders';
export const FEATURES_ROUTE = '/features';
export const AUTH_ROUTE = '/auth';
export const CALLTRACKING_ROUTE = '/calltracking';
export const CPL_SETTINGS_ROUTE = '/cpl-settings';
export const CALLTRACKING_DOWNLOAD_ROUTE = '/calltracking/download/:file';
export const CREATE_BUILDER_ROUTE = '/builders/create';
export const EDIT_BUILDER_ROUTE = '/builders/edit/:id';
export const CREATE_VILLAGE_ROUTE = '/villages/create';
export const EDIT_VILLAGE_ROUTE = '/villages/edit/:id';

export const CONTRACTOR_CALLTRACKING_ROUTE = '/contractor-calltracking';

export const KNOWN_ROUTES = [
  MAINPAGE_ROUTE,
  VILLAGES_ROUTE,
  BUILDERS_ROUTE,
  FEATURES_ROUTE,
  AUTH_ROUTE,
  CREATE_BUILDER_ROUTE,
  EDIT_BUILDER_ROUTE,
  CREATE_VILLAGE_ROUTE,
  EDIT_VILLAGE_ROUTE,
  CALLTRACKING_ROUTE,
  CALLTRACKING_DOWNLOAD_ROUTE,
  CPL_SETTINGS_ROUTE,
  CONTRACTOR_CALLTRACKING_ROUTE,
];

export const ROOT_ROUTES = [
  VILLAGES_ROUTE,
  BUILDERS_ROUTE,
  FEATURES_ROUTE,
  CALLTRACKING_ROUTE,
  CONTRACTOR_CALLTRACKING_ROUTE,
];
