import {
  EContractorPopupActions,
  TSetCallId,
  TTogglePopup,
  TSetContractorCallPopupStatus,
  TSetCallHistory,
} from '../../actions/contractorCallPopup';
import { ICallsPopup } from '../../types';
import { INITIAL_CONTRACTOR_POPUP } from '../../constants';

export type TPContractorCallsPopupAction = TSetCallId | TTogglePopup | TSetContractorCallPopupStatus | TSetCallHistory;

export function contractorCallsPopupReducer(
  state: ICallsPopup = INITIAL_CONTRACTOR_POPUP,
  action: TPContractorCallsPopupAction,
): ICallsPopup {
  switch (action.type) {
    case EContractorPopupActions.togglePopup:
      return {
        ...state,
        isOpen: action.payload,
      };
    case EContractorPopupActions.setCallId:
      return {
        ...state,
        callId: action.payload,
      };
    case EContractorPopupActions.setStatus:
      return {
        ...state,
        ...action.payload,
      };
    case EContractorPopupActions.setCallHistory:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
}
