import { IConfig } from '@cian/config/shared';
import { mergePaths } from '@cian/http-api/shared';

export function getCianUserServiceUrl(cianUserId?: number | null, config?: IConfig): string | undefined {
  const serviceUrl = config && config.get<string>('serviceUrl');
  if (!cianUserId || !serviceUrl) {
    return undefined;
  }
  const cianUserServiceUrl = mergePaths(serviceUrl, `/customers/view/${cianUserId}`);

  return cianUserServiceUrl;
}

export function getOrderServiceUrl(oredrId?: number | null, config?: IConfig): string | undefined {
  const serviceUrl = config && config.get<string>('serviceUrl');
  if (!oredrId || !serviceUrl) {
    return undefined;
  }
  const orderServiceUrl = mergePaths(serviceUrl, `/upload/info/${oredrId}`);

  return orderServiceUrl;
}
