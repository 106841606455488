import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import { createGetAddressModel } from '../../node/repositories/countryside/v2/get-address';
import { getFormattedError } from '../utils';

export async function getAddressTextByGeoCode(
  httpApi: IHttpApi,
  logger: ILogger,
  //Кривой интерфейс на уровне бэка, нужно заменить на IGetAddressRequest
  parameters: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
  timeout?: number,
): Promise<string> {
  try {
    const requestModel = createGetAddressModel(parameters);

    const { response, statusCode } = (await httpApi.fetch(requestModel, {
      transformBodyToLegacyString: true,
      ecnhancersConfig: { timeout },
    })) as any; /* eslint-disable-line @typescript-eslint/no-explicit-any */

    if (statusCode !== 200 || 'errors' in response) {
      throw response;
    }

    return response && response[0] ? response[0].text : '';
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getAddressTextByGeoCode', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getAddressTextByGeoCode' });

      throw formattedError;
    }

    throw error;
  }
}
