/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IDetailsSchema } from '../../entities/details/DetailsSchema';
import { IGeoSchema } from '../../entities/geo/GeoSchema';

export type TGeocodeModel = IModel<IGeocodeRequest, TGeocodeResponse>;

export interface IGeocodeRequest {
  /** null **/
  request?: string | null;
  /** источник запроса **/
  source?: ESource | null;
}

export type TGeocodeResponse = IGeocodeResponse200 | IGeocodeResponse400;

export interface IGeocodeResponse200 extends IModelResponse<IGeocodeResponse> {
  statusCode: 200;
}

export interface IGeocodeResponse400 extends IModelResponse<IGeocodeResponseError> {
  statusCode: 400;
}

export interface IGeocodeResponse {
  /** Идентификатор региона тарифной сетки. **/
  billingRegionId?: number | null;
  /** ID страны **/
  countryId?: number | null;
  /** undefined **/
  details?: IDetailsSchema[] | null;
  /** Информация по Гео (широта, долгота). **/
  geo?: IGeoSchema | null;
  /** Если удалось распарсить адрес. **/
  isParsed?: boolean | null;
  /** Идентификаторы всех локаций, от родительского до самого точного
Используется в биллинге **/
  locationPath?: number[] | null;
  /** Результат яндекса после геокодинга. **/
  text?: string | null;
}

export interface IGeocodeResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGeocodeResponse | IGeocodeResponseError;

export enum ESource {
  /**  **/
  AddressSearchFilterService = 'addressSearchFilterService',
  /**  **/
  AnnouncementParser = 'announcementParser',
  /**  **/
  ApiGeoSearchAddress = 'apiGeoSearchAddress',
  /**  **/
  BiEmlsFillOrders = 'biEmlsFillOrders',
  /**  **/
  CheckYandexDefiniteAddress = 'checkYandexDefiniteAddress',
  /**  **/
  EmlsJkImport = 'emlsJkImport',
  /**  **/
  GeoApiGeocode = 'geoApiGeocode',
  /**  **/
  GeoCodeCached = 'geoCodeCached',
  /**  **/
  Import = 'import',
  /**  **/
  ImportEbdTransformationEntry = 'importEbdTransformationEntry',
  /**  **/
  ImportGetGeoCodedResultByQuery = 'importGetGeoCodedResultByQuery',
  /**  **/
  ImportGetYandexGeoRoute = 'importGetYandexGeoRoute',
  /**  **/
  ImportYrlTransformationEntry = 'importYrlTransformationEntry',
  /**  **/
  MachineLearning = 'machineLearning',
  /**  **/
  MapAddress = 'mapAddress',
  /**  **/
  MobileApi = 'mobileApi',
  /**  **/
  Moderation = 'moderation',
  /**  **/
  ProcessGeoCheckItemCommand = 'processGeoCheckItemCommand',
  /**  **/
  Rebind = 'rebind',
  /**  **/
  Rebinding = 'rebinding',
  /**  **/
  RefreshCache = 'refreshCache',
  /**  **/
  Sandbox = 'sandbox',
  /**  **/
  Settler = 'settler',
  /**  **/
  Site = 'site',
  /**  **/
  SiteAdmin = 'siteAdmin',
  /**  **/
  TryAddAddressesAtEmlsJkImport = 'tryAddAddressesAtEmlsJkImport',
  /**  **/
  TryAddAddressesAtRebind = 'tryAddAddressesAtRebind',
  /**  **/
  TryInsert = 'tryInsert',
  /**  **/
  Unknown = 'unknown',
  /**  **/
  UnloadPage = 'unloadPage',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGeocodeResponse): TResponse200;
  400(response: IGeocodeResponseError): TResponse400;
}
