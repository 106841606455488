import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import { getFormattedError } from '../../utils';
import { ResponseError } from 'shared/errors';
import {
  IAdminGetConstructorCallHistoryRequest,
  TAdminGetConstructorCallHistoryResponse,
  fetchAdminGetConstructorCallHistory,
} from 'shared/repositories/countryside-calltracking/v1/admin/get-constructor-call-history';
import { ICallHistoryItem } from 'shared/types';
import { prepareConstructorCallHistory } from 'shared/mappers';

export async function getConstructorCallHistory(
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IAdminGetConstructorCallHistoryRequest,
): Promise<ICallHistoryItem[]> {
  try {
    const { response, statusCode }: TAdminGetConstructorCallHistoryResponse = await fetchAdminGetConstructorCallHistory(
      {
        httpApi,
        parameters,
      },
    );

    if (statusCode !== 200) {
      throw new ResponseError({
        domain: 'getConstructorCallHistory',
        message: response.message || 'Unknown error',
        details: {
          errors: JSON.stringify(response.errors),
        },
      });
    }

    return prepareConstructorCallHistory(response.history);
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.error('TimeoutError in getConstructorCallHistory', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getConstructorCallHistory' });

      throw formattedError;
    }

    return [];
  }
}
