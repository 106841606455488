import { connect } from 'react-redux';
import { setCplHistory, updateCplHistory } from '../../actions/cplHistory';
import { CplInfoItemPopup, ICplInfoItemPopupProps } from '../../components/CplSettingsPage/CplInfoItemPopup';

import { ICplSettingsPageState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { hasAnyAdminRole } from '../../utils/hasAnyAdminRole';

export type TStoreProps = Pick<ICplInfoItemPopupProps, 'historyList' | 'isAdmin'>;
export type TDispatchProps = Pick<ICplInfoItemPopupProps, 'loadHistory' | 'clearHistory'>;

export function mapStateToProps(state: ICplSettingsPageState): TStoreProps {
  const {
    roles,
    cplHistory: { history },
  } = state;

  return {
    historyList: history,
    isAdmin: hasAnyAdminRole(roles),
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    loadHistory: (villageId: number) => dispatch(updateCplHistory(villageId)),
    clearHistory: () =>
      dispatch(
        setCplHistory({
          history: [],
        }),
      ),
  };
}

export const CplInfoItemPopupContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CplInfoItemPopup);
