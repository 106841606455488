import { getConfig } from '@cian/config/browser';

interface ILocalVars {
  ymapsPromise?: Promise<YMaps.IYMaps>;
}

export const localVars: ILocalVars = {};

export function loadYmapsApi(options: YMaps.IReadyOptions): Promise<YMaps.IYMaps> {
  const config = getConfig();
  const apiKey = config.getStrict<string>('countryside.YandexApiKey');

  if (!localVars.ymapsPromise) {
    localVars.ymapsPromise = new Promise((resolve, reject) => {
      if (window.ymaps) {
        resolve(window.ymaps);

        return;
      }

      const e = window.document.createElement('script');
      e.src = `https://enterprise.api-maps.yandex.ru/2.1/?apikey=${apiKey}&lang=ru-RU&load=Map`;
      e.async = true;
      e.defer = true;
      e.onload = () => {
        resolve(window.ymaps);
      };
      e.onerror = () => {
        reject(new Error('Unable to load Yandex Maps API'));
      };
      window.document.body.appendChild(e);
    });
  }

  return localVars.ymapsPromise.then(ymaps => ymaps.ready(options).then(() => ymaps));
}
