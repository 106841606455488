/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ICallListItemSchema } from '../../entities/schemas/CallListItemSchema';

export type TAdminGetCallsModel = IModel<IAdminGetCallsRequest, TAdminGetCallsResponse>;

export interface IAdminGetCallsRequest {
  /** ID застройщика **/
  builderId?: number | null;
  /** Название застройщика **/
  builderName?: string | null;
  /** Дата и время звонка от **/
  callDateFrom?: string | null;
  /** Дата и время звонка до **/
  callDateTo?: string | null;
  /** ID звонка **/
  callId?: number | null;
  /** Фильтр по наличию комментария **/
  commentFilter?: ECommentFilter | null;
  /** Продолжительность звонка от, с **/
  durationFrom?: number | null;
  /** Продолжительность звонка до, с **/
  durationTo?: number | null;
  /** Итоговый тип звонка **/
  finalType?: EFinalType | null;
  /** Список ID менеджеров(Только для админов) **/
  managerIdList?: number[] | null;
  /** Номер страницы **/
  page?: number | null;
  /** Размер страницы **/
  pageSize?: number | null;
  /** Список ID регионов **/
  regionIdList?: number[] | null;
  /** Номер телефона звонящего **/
  sourcePhone?: string | null;
  /** ID региона, откуда совершен звонок **/
  sourceRegion?: number | null;
  /** Статус звонка **/
  status?: EStatus | null;
  tariffList?: ETariffList[] | null;
  /** Тип звонка, установленный застройщиком **/
  typeByBuilder?: ETypeByBuilder | null;
  /** ID коттеджного поселка **/
  villageId?: number | null;
  /** Название коттеджного поселка **/
  villageName?: string | null;
}

export type TAdminGetCallsResponse = IAdminGetCallsResponse200 | IAdminGetCallsResponse400;

export interface IAdminGetCallsResponse200 extends IModelResponse<IAdminGetCallsResponse> {
  statusCode: 200;
}

export interface IAdminGetCallsResponse400 extends IModelResponse<IAdminGetCallsResponseError> {
  statusCode: 400;
}

export interface IAdminGetCallsResponse {
  /** Звонки **/
  calls?: ICallListItemSchema[] | null;
  /** Полное количество найденных записей по запросу (без пагинации) **/
  totalItems?: number | null;
  /** Количество страниц пагинации **/
  totalPages?: number | null;
  /** Общая стоимость всех звонков, попавших под фильтры **/
  totalPrice?: number | null;
}

export interface IAdminGetCallsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IAdminGetCallsResponse | IAdminGetCallsResponseError;

export enum ECommentFilter {
  /** С комментарием **/
  WithComment = 'withComment',
  /** Без комментария **/
  WithoutComment = 'withoutComment',
}

export enum EFinalType {
  /** Нецелевой **/
  NonTarget = 'nonTarget',
  /** Не установлен **/
  NotSpecified = 'notSpecified',
  /** Целевой **/
  Target = 'target',
}

export enum EStatus {
  /** Занято **/
  Busy = 'busy',
  /** Сбросили **/
  Hgup = 'hgup',
  /** Без ответа **/
  NoAnswer = 'noAnswer',
  /** Успешный **/
  Success = 'success',
  /** Техническая ошибка **/
  TechProblem = 'techProblem',
  /** Выключен **/
  TurnedOff = 'turnedOff',
  /** Неизвестно **/
  Unknown = 'unknown',
}

export enum ETariffList {
  /** Basic **/
  Basic = 'basic',
  /** CPL **/
  Cpl = 'cpl',
  /** CPL с отложенной оплатой **/
  FreeCpl = 'freeCpl',
  /** Нет объявлений **/
  NotSet = 'notSet',
  /** Premium **/
  Premium = 'premium',
}

export enum ETypeByBuilder {
  /** Нецелевой **/
  NonTarget = 'nonTarget',
  /** Не установлен **/
  NotSpecified = 'notSpecified',
  /** Целевой **/
  Target = 'target',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IAdminGetCallsResponse): TResponse200;
  400(response: IAdminGetCallsResponseError): TResponse400;
}
