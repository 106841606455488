/* eslint-disable no-useless-escape */
import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { IDropdownValue } from '../SelectField';

import * as styles from './index.css';
import { Form, Dropdown, Button } from 'react-bootstrap';

export interface ISelectSearchFieldProps {
  value: string | number | undefined;
  values: IDropdownValue[];
  label?: string;
  className?: string;
  noEmpty?: boolean;
  required?: boolean;
  readOnly?: boolean;
  showValue?: boolean;
  useValidation?: boolean;
  onChange(value: string | number | undefined): void;
}

interface ISelectSearchFieldState {
  dropdownIsOpen: boolean;
  search: string;
  className?: string;
}

export class SelectSearchField extends React.Component<ISelectSearchFieldProps, ISelectSearchFieldState> {
  public constructor(props: ISelectSearchFieldProps) {
    super(props);

    this.state = {
      dropdownIsOpen: false,
      search: '',
    };
  }

  public render() {
    const { className, useValidation, value, label, required, values, showValue } = this.props;

    const filteredValues = this.filterValues(values);

    return (
      <Form.Group
        {...mergeStyles(
          className,
          styles['selector_search_field'],
          Boolean(useValidation && required && !value) && styles['invalid'],
        )}
      >
        {label && (
          <Form.Label>
            {label}&nbsp;{required && <span className="text-danger">*</span>}
          </Form.Label>
        )}
        <Dropdown
          show={this.state.dropdownIsOpen}
          autoClose="outside"
          onToggle={() => this.setState({ dropdownIsOpen: false })}
        >
          <Dropdown.Toggle
            as={() => (
              <Button
                variant="light"
                className={styles['btn']}
                onClick={() => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })}
              >
                {this.getButtonText()}
                <span {...mergeStyles('caret-down', styles['caret'])} />
              </Button>
            )}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu className={styles['dropdown-menu']}>
            <Dropdown.Item className="mb-2" as="li">
              <Form.Control
                type="text"
                className="form-control"
                onChange={e => this.setState({ search: e.currentTarget.value.replace(/([[\^$.|?*+()])/g, '[$1]') })}
                placeholder="Поиск"
              />
            </Dropdown.Item>
            {filteredValues.map((value, index) => (
              <Dropdown.Item
                key={index}
                {...mergeStyles(styles['item'], this.isSelectedValue(value) && styles['item--active'])}
                as="li"
                onClick={this.handleChange(value)}
              >
                <div {...mergeStyles(styles['label'], value.muted && styles['label--muted'])} data-disable-drag>
                  {value.label}
                  {showValue && <span className={styles['label-id']}> ({value.value})</span>}
                </div>
              </Dropdown.Item>
            ))}
            {!filteredValues.length && (
              <Dropdown.Item className={styles['empty']} as="li">
                Пусто
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
    );
  }

  private filterValues(values: IDropdownValue[]): IDropdownValue[] {
    const pattern = new RegExp(this.state.search, 'ig');

    return values.filter(value => pattern.test(value.label));
  }

  private isSelectedValue(value: IDropdownValue) {
    return this.props.value === value.value;
  }

  private getButtonText() {
    const selectedValues = this.props.values.filter(dropdownValue => this.isSelectedValue(dropdownValue));
    if (selectedValues.length) {
      return selectedValues.map(dropdownValue => dropdownValue.label).join(', ');
    }

    return 'Не выбрано';
  }

  private handleChange =
    ({ value }: IDropdownValue) =>
    () => {
      const { onChange, readOnly, noEmpty } = this.props;
      if (!readOnly) {
        const newValue = !noEmpty && this.props.value === value ? undefined : value;
        onChange(newValue);

        this.setState({ dropdownIsOpen: false });
      }
    };
}
