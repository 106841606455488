/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TGetUserRoleListModel = IModel<IGetUserRoleListRequest, TGetUserRoleListResponse>;

export interface IGetUserRoleListRequest {
  /** Email пользователя **/
  email?: string | null;
}

export type TGetUserRoleListResponse = IGetUserRoleListResponse200 | IGetUserRoleListResponse400;

export interface IGetUserRoleListResponse200 extends IModelResponse<IGetUserRoleListResponse> {
  statusCode: 200;
}

export interface IGetUserRoleListResponse400 extends IModelResponse<IGetUserRoleListResponseError> {
  statusCode: 400;
}

export interface IGetUserRoleListResponse {
  /** ID в таблице Countryside.manager если пользователь - активный менеджер **/
  managerId?: number | null;
  /** Роли пользователя **/
  roles: ERoles[];
}

export interface IGetUserRoleListResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetUserRoleListResponse | IGetUserRoleListResponseError;

export enum ERoles {
  /** Администратор **/
  Admin = 'admin',
  /** Застройщик **/
  Builder = 'builder',
  /** Администратор колтрекинга коттеджных посёлков **/
  CalltrackingAdmin = 'calltrackingAdmin',
  /** Доступ для добавления телефона в черный список **/
  CalltrackingBlackList = 'calltrackingBlackList',
  /** Доступ для застройщика к колтрекингу коттеджных посёлков **/
  CalltrackingBuilder = 'calltrackingBuilder',
  /** Доступ для бюджета на звонки **/
  CalltrackingCplBudget = 'calltrackingCplBudget',
  /** Доступ для установки прогноза по звонкам в КТ **/
  CalltrackingCplPlan = 'calltrackingCplPlan',
  /** Только чтение колтрекинга коттеджных посёлков **/
  CalltrackingReadOnly = 'calltrackingReadOnly',
  /** Доступ для подрядчика **/
  Contractor = 'contractor',
  /** Администратор проектов подряда загородки **/
  ContractsAdmin = 'contractsAdmin',
  /** Только чтение проектов подряда загородки **/
  ContractsReadOnly = 'contractsReadOnly',
  /** Только чтение **/
  ReadOnly = 'readOnly',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetUserRoleListResponse): TResponse200;
  400(response: IGetUserRoleListResponseError): TResponse400;
}
