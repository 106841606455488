export enum EPageTypes {
  Auth = 'auth',
  CplSettings = 'cplSettings',
  VillageFeatures = 'features',
  Villages = 'villages',
  CreateVillage = 'createVillage',
  EditVillage = 'editVillage',
  VillageBuilders = 'villageBuilders',
  CreateVillageBuilder = 'createVillageBuilder',
  EditVillageBuilder = 'editVillageBuilder',
  VillageCalltracking = 'calltracking',
  BuildersCalltracking = 'buildersCalltracking',
}
