import * as React from 'react';

import { AppContext } from '../../AppContext';
import { getDisplayName } from '../../utils/hoc';
import { ERoles } from '../../repositories/countryside/v2/get-user-role-list';

export interface IWithRoles {
  roles: ERoles[];
}

export function withRoles<T>(WrappedComponent: React.ComponentType<T & IWithRoles>) {
  const withRolesComponent = (props: Exclude<T, 'roles'>) => (
    <AppContext.Consumer>{({ roles }) => <WrappedComponent roles={roles || []} {...props} />}</AppContext.Consumer>
  );
  withRolesComponent.displayName = `WithRoles(${getDisplayName(WrappedComponent)})`;

  return withRolesComponent;
}
