/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCreateVillageModel,
  ICreateVillageRequest,
  IMappers,
  ICreateVillageResponse,
  ICreateVillageResponseError,
  TCreateVillageResponse,
} from './types';

const defaultConfig: TCreateVillageModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/create-village/',
  hostType: 'api',
} as TCreateVillageModel;

function createCreateVillageModel(parameters: ICreateVillageRequest): TCreateVillageModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateVillageOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICreateVillageRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCreateVillage<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICreateVillageOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TCreateVillageResponse> {
  const { response, statusCode } = await httpApi.fetch(createCreateVillageModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICreateVillageResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICreateVillageResponseError);
    }
  }

  return { response, statusCode } as TCreateVillageResponse;
}

export { defaultConfig, createCreateVillageModel, fetchCreateVillage };
