import { mergeStyles } from '@cian/utils';
import { pageSizeValues } from '../../common/interfaces';
import { getPagesList } from '../../utils/helpers/getPagesList';
import { SelectField } from '../SelectField';
import { Pagination as PaginationComponent } from 'react-bootstrap';

import * as styles from './pagination.css';

interface IPaginationProps {
  onPageChange(value: number): void;
  onPageCountChange(value?: string): void;
  getQueryString(page: number): void;
  pageSize: number;
  activePage?: number;
  pageCount: number;
  hidePrevNextButtons?: boolean; // Показ кнопок "Вперед", "Назад"
  hideStartEndButtons?: boolean; // Показ кнопок "В начало", "В конец"
  maxPagesToView?: number; // Максимальное число отображаемых страниц
  extraPageSizes?: number[];
}

export const Pagination = (props: IPaginationProps) => {
  const {
    activePage = 1,
    pageCount,
    pageSize,
    onPageCountChange,
    onPageChange,
    getQueryString,
    maxPagesToView,
    extraPageSizes,
  } = props;

  const pagesList = getPagesList(pageCount, activePage, maxPagesToView);

  let pageSizeValuesComplete: number[] = [];

  if (extraPageSizes && extraPageSizes.length > 0) {
    pageSizeValuesComplete = [...pageSizeValues, ...extraPageSizes].sort((a, b) => a - b);
  } else {
    pageSizeValuesComplete = [...pageSizeValues];
  }

  const pageSizeDropdownValues = pageSizeValuesComplete.map(pageSize => ({
    label: pageSize.toString(),
    value: pageSize.toString(),
  }));

  return (
    <div {...mergeStyles('text-center', styles['container'])}>
      <SelectField
        canEmpty={false}
        label="На странице"
        className={styles['select']}
        values={pageSizeDropdownValues}
        value={pageSize}
        onChange={onPageCountChange}
      />
      {pageCount > 1 && (
        <PaginationComponent className={styles['pagination']}>
          {!props.hideStartEndButtons && (
            <PaginationComponent.First onClick={() => onPageChange(1)} disabled={activePage === 1} />
          )}
          {!props.hidePrevNextButtons && (
            <PaginationComponent.Prev
              onClick={() => onPageChange(Math.max(1, activePage - 1))}
              disabled={activePage === 1}
            />
          )}
          {pagesList.map(page => (
            <PaginationComponent.Item
              key={page}
              active={activePage === page}
              href={activePage === page ? undefined : `?${getQueryString(page)}`}
              onClick={event => {
                event.preventDefault();

                if (activePage !== page) {
                  onPageChange(page);
                }
              }}
            >
              {page}
            </PaginationComponent.Item>
          ))}
          {!props.hidePrevNextButtons && (
            <PaginationComponent.Next
              onClick={() => onPageChange(Math.min(pageCount, activePage + 1))}
              disabled={activePage === pageCount}
            />
          )}
          {!props.hideStartEndButtons && (
            <PaginationComponent.Last onClick={() => onPageChange(pageCount)} disabled={activePage === pageCount} />
          )}
        </PaginationComponent>
      )}
    </div>
  );
};
