import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';

import {
  fetchGetVillageList,
  IGetVillageListRequest,
  IGetVillageListResponse,
  IGetVillageListResponseError,
  TGetVillageListResponse,
} from '../repositories/countryside/v1/get-village-list';
import { getFormattedError } from '../utils';

import { isResponseContainsKey } from './helpers';

export async function getVillageList(httpApi: IHttpApi, logger: ILogger, parameters: IGetVillageListRequest) {
  try {
    const { response }: TGetVillageListResponse = await fetchGetVillageList({ httpApi, parameters });

    if (
      isResponseContainsKey<IGetVillageListResponseError, IGetVillageListResponse>(response, 'errors') ||
      !response.villageList
    ) {
      throw response;
    }

    return response;
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.warning('TimeoutError in getVillageList', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in getVillageList' });

      throw formattedError;
    }

    throw error;
  }
}
