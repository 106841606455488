/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetCplSettingsHistoryModel,
  IGetCplSettingsHistoryRequest,
  IMappers,
  IGetCplSettingsHistoryResponse,
  IGetCplSettingsHistoryResponseError,
  TGetCplSettingsHistoryResponse,
} from './types';

const defaultConfig: TGetCplSettingsHistoryModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/get-cpl-settings-history/',
  hostType: 'api',
} as TGetCplSettingsHistoryModel;

function createGetCplSettingsHistoryModel(parameters: IGetCplSettingsHistoryRequest): TGetCplSettingsHistoryModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetCplSettingsHistoryOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetCplSettingsHistoryRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetCplSettingsHistory<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetCplSettingsHistoryOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetCplSettingsHistoryResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetCplSettingsHistoryModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetCplSettingsHistoryResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetCplSettingsHistoryResponseError);
    }
  }

  return { response, statusCode } as TGetCplSettingsHistoryResponse;
}

export { defaultConfig, createGetCplSettingsHistoryModel, fetchGetCplSettingsHistory };
