import { loadableReady } from '@loadable/component';
import { hydrateRoot } from 'react-dom/client';

import { IApplicationContext } from 'shared/common/types/applicationContext';
import { TGenericStore } from 'shared/common/utils/redux/pageTypeToCreateStore';
import { RootApplicationContainer } from 'shared/common/containers/RootApplicationContainer';
import { ERuntimeSettings } from 'shared/common/types/runtimeSettings';

interface IDependencies {
  store: TGenericStore;
  context: IApplicationContext;
}

export async function renderApplication({ store, context }: IDependencies) {
  const projectName = context.config.getStrict<string>(ERuntimeSettings.projectName);
  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  loadableReady(
    () => {
      hydrateRoot(rootElement, <RootApplicationContainer context={context} reduxStore={store} />);
    },
    { namespace: context.custom.pageType },
  );
}
