import { ChangeEvent } from 'react';

// Удаляет мягкий перенос и пробел нулевой ширины из текста, а также заменяет нестандартные дефисы на обычный
export const handleUnicodeAndUnprintableChars = (handleChange: THandler) => {
  return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const replaced = e.currentTarget.value.replace(/([\u200B\u00AD])+/g, '');
    const dashReplased = replaced.replace(/([\u2043\u2010\u2011])+/g, '-');
    handleChange(dashReplased);
  };
};

export type THandler = (val: string) => void;
