import {
  fetchCreateBuilder,
  ICreateBuilderRequest,
  ICreateBuilderResponse,
  ICreateBuilderResponseError,
} from '../repositories/countryside/v2/create-builder';

import { createSimpleRequest } from './createSimpleRequest';

export const createBuilder = createSimpleRequest<
  ICreateBuilderRequest,
  ICreateBuilderResponse,
  ICreateBuilderResponseError
>('createBuilder', fetchCreateBuilder);
