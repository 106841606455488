import { IRegion } from '../common/interfaces';
import { SURROGATE_REGION_NAME_MAP } from '../constants';

export function getRegionNameFromList(regionList: IRegion[], regionId: number) {
  const surrogateRegionName = SURROGATE_REGION_NAME_MAP[regionId];
  if (surrogateRegionName) {
    return surrogateRegionName;
  }

  const region = regionList.find(item => item.id === regionId);

  return region ? region.displayName : null;
}
