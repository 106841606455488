/* eslint-disable max-lines */
import * as React from 'react';
import { mergeStyles, numberToPrettyString } from '@cian/utils';
import { AppContext } from '../../AppContext';
import {
  EFinalType,
  IAdminGetCallsResponse,
} from '../../repositories/countryside-calltracking/v1/admin-get-calls/types';
import { EChangerType } from '../../repositories/countryside-calltracking/v1/admin-set-call-type/types';
import {
  DEFAULT_CALLTRACKING_LIST_PLAYLIST_STATE,
  mapStatusToLabel,
  mapStatusToIcon,
  ERoleType,
} from '../../constants/calltracking';
import { useGetCallHistoryHook } from '../../utils/useGetCallHistoryHook';
import { formatDateTime } from '../../utils/datetime';
import { prettifySeconds } from '../../utils/prettifySeconds';
import { saveFileFromPath } from '../../utils/saveFileFromPath';
import { CalltrackingDesktopPopup } from '../CalltrackingDesktopPopup';
import { CalltrackingTypeField } from '../CalltrackingTypeField';
import { getMapValue } from '../../utils/formatting';
import * as styles from './CalltrackingList.css';
import { IRegion } from '../../common/interfaces';
import { getRegionNameFromList } from '../../utils/getRegionNameFromList';
import { WithTitle } from '../WIthTitle';
import { truncateString } from '../../utils/helpers';
import { CommentIcon } from '../CommentIcon';
import { CalltrackingFinalButton } from '../CalltrackingFinalButton';
import { Button, Table } from 'react-bootstrap';
import { ICallHistoryItem, TCallType, TChangerType } from 'shared/types';

interface ICalltrackingListProps {
  callsList: IAdminGetCallsResponse | null;
  setCalltrackingType(callId: number, changerType: TChangerType, type: TCallType): void;
  onBlackListStatusChange(): void;
  onSaveReport(): void;
  isAlertVisible?: boolean;
  regionList: IRegion[];
  isAdmin: boolean;
  canEditBlackList: boolean;
  autoplayCplRecordsEnabled?: boolean;
  onCommentSave(): void;
}

export interface ICalltrackingListDesktopPopupState {
  callId?: number | null;
  history: ICallHistoryItem[] | null;
  isHidden?: boolean;
}

export const DEFAULT_CALLTRACKING_LIST_POPUP_STATE: ICalltrackingListDesktopPopupState = {
  callId: undefined,
  history: null,
  isHidden: true,
};

export function CalltrackingList({
  callsList,
  setCalltrackingType,
  onSaveReport,
  isAlertVisible,
  regionList,
  isAdmin,
  canEditBlackList,
  autoplayCplRecordsEnabled,
  onBlackListStatusChange,
  onCommentSave,
}: ICalltrackingListProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const [popupState, setPopupState] = React.useState(DEFAULT_CALLTRACKING_LIST_POPUP_STATE);
  const [currentTrack, setCurrentTrack] = React.useState(DEFAULT_CALLTRACKING_LIST_PLAYLIST_STATE);

  React.useEffect(() => {
    setCurrentTrack(DEFAULT_CALLTRACKING_LIST_PLAYLIST_STATE);
  }, [callsList]);

  useGetCallHistoryHook<ICalltrackingListDesktopPopupState>({
    callId: popupState.isHidden ? undefined : popupState.callId,
    httpApi,
    logger,
    popupState,
    setPopupState,
  });

  if (callsList === null || callsList.totalItems === 0 || !callsList.calls) {
    return (
      <div>
        <strong>С заданными параметрами звонков нет</strong>
      </div>
    );
  }
  const { totalItems, calls, totalPrice } = callsList;

  return (
    <div>
      <div className={styles['total_count']}>
        <strong>Найдено звонков:</strong>&nbsp;{totalItems}
        {!!totalPrice && (
          <div className={styles['total_price']}>
            <strong>Общая стоимость:</strong>&nbsp;{numberToPrettyString(Number(totalPrice))}&nbsp;₽
          </div>
        )}
        <Button variant="success" className={styles['btn_save']} onClick={onSaveReport}>
          <i className="far fa-arrow-alt-circle-down" />
          Сохранить отчёт
        </Button>
      </div>
      <div className={styles['table_container']}>
        <Table striped hover>
          <thead>
            <tr>
              <th rowSpan={2}>ID</th>
              <th className={styles['clipped_cell']} rowSpan={2}>
                Клиент
              </th>
              <th className={styles['clipped_cell']} rowSpan={2}>
                КП
              </th>
              <th className={styles['clipped_cell']} rowSpan={2}>
                Регион КП
              </th>
              <th rowSpan={2}>Телефон</th>
              <th rowSpan={2}>Дата звонка</th>
              <th rowSpan={2}>Звонок</th>
              <th rowSpan={2}>Время</th>
              <th rowSpan={2}>Статус</th>
              <th rowSpan={2}>CPL,&nbsp;₽</th>
              <th colSpan={2}>Оценка&nbsp;звонка</th>
            </tr>
            <tr className={styles['type_row']}>
              <th>Клиент</th>
              <th>Итог</th>
            </tr>
          </thead>
          <tbody>
            {calls.map(call => {
              const callRegionName = call.sourceRegion && getRegionNameFromList(regionList, call.sourceRegion);
              const isComment = !!call.comment;

              return (
                <tr
                  key={call.callId as React.Attributes['key']}
                  onClick={() => {
                    setCurrentTrack({
                      recordFilePath: call.recordFilePath,
                      isPaused: !(popupState.callId === call.callId && !currentTrack.isPaused),
                    });

                    setPopupState({
                      ...popupState,
                      callId: call.callId,
                      isHidden: false,
                    });
                  }}
                >
                  {call.isRepeated && (
                    <td>
                      <WithTitle
                        title={`первый звонок, у которого есть повторные${isComment ? ', есть комментарий' : ''}`}
                      >
                        {call.callId}*{isComment && <CommentIcon />}
                      </WithTitle>
                    </td>
                  )}
                  {call.repeatedCallId && (
                    <td>
                      <WithTitle
                        title={`неуникальный с записью ${call.repeatedCallId}${isComment ? ', есть комментарий' : ''}`}
                      >
                        {call.callId}*{isComment && <CommentIcon />}
                      </WithTitle>
                    </td>
                  )}
                  {!call.isRepeated && !call.repeatedCallId && (
                    <td>
                      {isComment ? (
                        <WithTitle title={'Есть комментарий'}>
                          {call.callId}
                          <CommentIcon />
                        </WithTitle>
                      ) : (
                        call.callId
                      )}
                    </td>
                  )}
                  <td className={styles['clipped_cell']} title={`Клиент: ${call.builderName}`}>
                    {truncateString(call.builderName, 25)}
                  </td>
                  <td className={styles['clipped_cell']} title={`КП: ${call.villageName}`}>
                    {truncateString(call.villageName, 25)}
                  </td>
                  <td className={styles['clipped_cell']}>
                    {call.regionId && getRegionNameFromList(regionList, call.regionId)}
                  </td>
                  <td>
                    <span {...mergeStyles(styles['phone'], !!call.blackListInfo && styles['phone--blocked'])}>
                      {call.sourcePhone}
                    </span>
                    {!!callRegionName && <span className={styles['region_name']}>({callRegionName})</span>}
                  </td>
                  <td>{formatDateTime(call.callDate, '+0300')}</td>
                  <td>
                    {call.recordFilePath ? (
                      <div className={styles['call_record']}>
                        <Button
                          variant="success"
                          className={styles['play']}
                          onClick={e => {
                            e.stopPropagation();

                            setPopupState({
                              ...popupState,
                              callId: call.callId,
                              isHidden: true,
                            });

                            setCurrentTrack({
                              recordFilePath: call.recordFilePath,
                              isPaused: popupState.callId === call.callId && !currentTrack.isPaused,
                            });
                          }}
                        >
                          {popupState.callId === call.callId && !currentTrack.isPaused ? (
                            <i className="fas fa-pause" />
                          ) : (
                            <i className="fas fa-play" />
                          )}
                        </Button>
                        <Button variant="primary" onClick={saveFileFromPath(call.recordFilePath)} title="Скачать">
                          <i className="fas fa-download" />
                        </Button>
                      </div>
                    ) : (
                      'Запись отсутствует'
                    )}
                  </td>
                  <td>{prettifySeconds(call.duration)}</td>
                  <td className={styles['icon_cell']}>
                    <span
                      className={getMapValue(mapStatusToIcon, call.status)}
                      title={getMapValue(mapStatusToLabel, call.status)}
                    />
                  </td>
                  <td className={styles['cpl_cell']}>
                    {call.finalType === EFinalType.Target ? (
                      <div className={styles['cpl_cost']}>{call.cpl || 0}</div>
                    ) : (
                      <div className={styles['cpl_cost']}>0</div>
                    )}
                  </td>
                  <td className={styles['icon_cell']}>
                    <CalltrackingTypeField
                      onlyIcon
                      call={call}
                      setCalltrackingType={setCalltrackingType}
                      changerType={EChangerType.Builder}
                      type={ERoleType.Builder}
                    />
                  </td>
                  <td className={styles['icon_cell']}>
                    <CalltrackingFinalButton
                      callId={call.callId}
                      finalType={call.finalType}
                      isEditable={call.isEditableType}
                      setCalltrackingType={setCalltrackingType}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <CalltrackingDesktopPopup
          call={calls.find(({ callId }) => callId === popupState.callId)}
          isAlertVisible={isAlertVisible}
          setCalltrackingType={setCalltrackingType}
          isAdmin={isAdmin}
          canEditBlackList={canEditBlackList}
          onBlackListStatusChange={onBlackListStatusChange}
          regionList={regionList}
          onClose={() => {
            setPopupState({
              ...popupState,
              isHidden: true,
            });
          }}
          history={popupState.history}
          isPaused={currentTrack.isPaused}
          isHidden={popupState.isHidden}
          onTrackEnd={() => {
            const currentCallIndex = calls.findIndex(({ callId }) => callId === popupState.callId);
            const nextCall = calls[currentCallIndex + 1];

            if (autoplayCplRecordsEnabled && currentCallIndex >= 0 && nextCall) {
              setPopupState({
                ...popupState,
                callId: nextCall.callId,
              });
              setCurrentTrack({
                ...currentTrack,
                recordFilePath: nextCall.recordFilePath,
              });
            } else {
              setPopupState(DEFAULT_CALLTRACKING_LIST_POPUP_STATE);
              setCurrentTrack(DEFAULT_CALLTRACKING_LIST_PLAYLIST_STATE);
            }
          }}
          onTogglePlay={() => {
            setCurrentTrack({
              ...currentTrack,
              isPaused: false,
            });
          }}
          onTogglePause={() => {
            setCurrentTrack({
              ...currentTrack,
              isPaused: true,
            });
          }}
          onCommentSave={onCommentSave}
        />
      </div>
    </div>
  );
}
