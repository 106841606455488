import * as React from 'react';
import { ICPLSettingsHistoryItemSchema } from '../../../repositories/countryside-calltracking/entities/cpl_settings_history/CPLSettingsHistoryItemSchema';
import { BasicModal } from '../../BasicModal';
import { BriefLine } from '../../BriefLine';
import { CplSettingsHistoryList } from '../CplSettingsHistoryList';

import * as styles from './CplInfoItemPopup.css';

export interface ICplInfoItemPopupProps {
  title: string;
  managerName: string | null | undefined;
  historyList: ICPLSettingsHistoryItemSchema[];
  isAdmin: boolean;
  villageId: number;
  onClose(): void;
  clearHistory(): void;
  loadHistory(villageId: number): void;
}
export function CplInfoItemPopup({
  title,
  managerName,
  historyList,
  villageId,
  onClose,
  loadHistory,
  clearHistory,
  isAdmin,
}: ICplInfoItemPopupProps) {
  React.useEffect(() => {
    clearHistory();
    loadHistory(villageId);
  }, [villageId]);

  return (
    <BasicModal header={title} onClose={onClose} autoWidth>
      {managerName && <BriefLine title="Менеджер" value={managerName} />}
      <div className={styles['table']}>
        <strong className={styles['table_title']}>История</strong>
        <CplSettingsHistoryList list={historyList} isAdmin={isAdmin} isMobile />
      </div>
    </BasicModal>
  );
}
