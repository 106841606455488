import { connect } from 'react-redux';
import {
  CplSettingsDesktopView,
  ICplSettingsDesktopViewProps,
} from '../../components/CplSettingsPage/CplSettingsDesktopView';
import { CplSettingsMobileView } from '../../components/CplSettingsPage/CplSettingsMobileView';
import { ICplSettingsPageState } from '../../types/redux';

export type TStoreProps = Pick<ICplSettingsDesktopViewProps, 'deviceType'>;

export function mapStateToProps(state: ICplSettingsPageState): TStoreProps {
  return {
    deviceType: state.deviceType,
  };
}

export const CplSettingsDesktopViewContainer = connect<TStoreProps>(mapStateToProps)(CplSettingsDesktopView);

export const CplSettingsMobileViewContainer = connect<TStoreProps>(mapStateToProps)(CplSettingsMobileView);
