import { IStyleConfig, mergeStyles } from '@cian/utils';
import { Spinner } from 'react-bootstrap';
import { mapTypeToLabel, mapUserTypeToLabel } from '../../constants/calltracking';
import { formatDateTime } from '../../utils/datetime';

import * as styles from './CalltrackingListItemHistory.css';
import { ICallHistoryItem } from 'shared/types';

interface ICalltrackingListItemPopupProps {
  history: ICallHistoryItem[] | null;
  tableStyles?: IStyleConfig;
}

export function CalltrackingListItemHistory({ history, tableStyles }: ICalltrackingListItemPopupProps) {
  if (!history) {
    return (
      <div className={styles['history']}>
        <label>История</label>
        <div className={styles['preloader']}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!history.length) {
    return (
      <div className={styles['history']}>
        <label>История</label>
        <div>У звонка нет истории</div>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <label>История</label>
      <div {...mergeStyles(styles['table_container'], tableStyles)}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Пользователь</th>
              <th>Дата изменения</th>
              <th>Тип оценки</th>
              <th>Новое значение</th>
            </tr>
          </thead>
          <tbody>
            {history.map((historyItem, index) => {
              const { userName, changeDate, userType, value } = historyItem;

              return (
                <tr key={index} data-name="CalltrackingListItemHistoryRow">
                  <td>{userName}</td>
                  <td>{formatDateTime(changeDate, '+0300')}</td>
                  <td>{mapUserTypeToLabel[userType]}</td>
                  <td>{mapTypeToLabel[value]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
