/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminSetCallTypeModel,
  IAdminSetCallTypeRequest,
  IMappers,
  IAdminSetCallTypeResponse,
  IAdminSetCallTypeResponseError,
  TAdminSetCallTypeResponse,
} from './types';

const defaultConfig: TAdminSetCallTypeModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/set-call-type/',
  hostType: 'api',
} as TAdminSetCallTypeModel;

function createAdminSetCallTypeModel(parameters: IAdminSetCallTypeRequest): TAdminSetCallTypeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminSetCallTypeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminSetCallTypeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminSetCallType<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminSetCallTypeOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminSetCallTypeResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminSetCallTypeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminSetCallTypeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminSetCallTypeResponseError);
    }
  }

  return { response, statusCode } as TAdminSetCallTypeResponse;
}

export { defaultConfig, createAdminSetCallTypeModel, fetchAdminSetCallType };
