import { IConfig } from '@cian/config/shared';
import { mergePaths } from '@cian/http-api/shared';
import { AUTH_ROUTE } from '../routes';

export const getLogOutUrls = (config?: IConfig): string[] => {
  const authUrl = config && config.get<string>('countrysideLogoutUrl');
  const serviceAuthUrl = config && config.get<string>('countrysideServiceLogoutUrl');
  if (!authUrl || !serviceAuthUrl) {
    return [];
  }
  const logoutCountryside = mergePaths(authUrl, '/api/users/logout');
  const logoutService = mergePaths(serviceAuthUrl, '/users/logoff');

  return [logoutCountryside, logoutService];
};

export const fetchUrl = (url: string) => fetch(url, { method: 'GET', credentials: 'include', mode: 'no-cors' });

export const logOutEventCreator =
  (config?: IConfig, shouldReload: boolean = true) =>
  async () => {
    /* Получаем ссылки на логаут и разлогиниваемся */
    await Promise.all(getLogOutUrls(config).map(fetchUrl));
    if (shouldReload) {
      window.location.assign(AUTH_ROUTE);
    }
  };
