/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetFatIconsModel,
  IGetFatIconsRequest,
  IMappers,
  IGetFatIconsResponse,
  IGetFatIconsResponseError,
  TGetFatIconsResponse,
} from './types';

const defaultConfig: TGetFatIconsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v1/get-fat-icons/',
  hostType: 'api',
} as TGetFatIconsModel;

function createGetFatIconsModel(parameters: IGetFatIconsRequest): TGetFatIconsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetFatIconsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetFatIconsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetFatIcons<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetFatIconsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetFatIconsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetFatIconsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetFatIconsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetFatIconsResponseError);
    }
  }

  return { response, statusCode } as TGetFatIconsResponse;
}

export { defaultConfig, createGetFatIconsModel, fetchGetFatIcons };
