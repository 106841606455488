import { IVideo } from '../../../../common/interfaces';
import { TextField } from '../../../text_field';

import * as styles from '../VillageVideos.css';
import { Button, Col, Row } from 'react-bootstrap';
import { mergeStyles } from '@cian/utils';

export interface IVillageVideoItemProps {
  onChange(value: string, index: number): void;
  onRemove(index: number): void;
  itemIndex: number;
  item: IVideo;
  readOnly?: boolean;
}

export const VillageVideoItem = ({ item, itemIndex, readOnly, onChange, onRemove }: IVillageVideoItemProps) => (
  <Row {...mergeStyles('mb-3', styles['video-row'])}>
    <Col sm={8}>
      <TextField
        label={`Ссылка на видео #${itemIndex + 1}`}
        placeholder="https://www.youtube.com/watch?v=qejuOuErxBM"
        value={item.url}
        onChange={value => onChange(value, itemIndex)}
        maxLength={200}
        readOnly={readOnly}
      />
    </Col>
    <Col sm={4}>
      {!readOnly && (
        <Button type="button" onClick={() => onRemove(itemIndex)} variant="danger">
          <i className="fas fa-times" />
        </Button>
      )}
    </Col>
  </Row>
);
