import { IGetCplSettingsResponse } from '../../repositories/countryside-calltracking/v1/get-cpl-settings';
import { ICplFieldData } from '../../types';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

/* Экшены обработки данных CPL на странице детализации звонков (/cpl-settings) */

export enum ECplDataActionTypes {
  SetData = 'CPL_DATA_SET_DATA',
  SetField = 'CPL_DATA_SET_FIELD',
}

// Экшен для установки КП
export type TSetCplData = ITypedReduxAction<ECplDataActionTypes.SetData, IGetCplSettingsResponse>;
export const setCplData: (payload: IGetCplSettingsResponse) => TSetCplData = actionGenerator<
  ECplDataActionTypes.SetData,
  IGetCplSettingsResponse
>(ECplDataActionTypes.SetData);

// Экшен для обновления полей в таблице
export type TSetCplField = ITypedReduxAction<ECplDataActionTypes.SetField, ICplFieldData>;
export const setCplField: (payload: ICplFieldData) => TSetCplField = actionGenerator<
  ECplDataActionTypes.SetField,
  ICplFieldData
>(ECplDataActionTypes.SetField);
