import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import * as styles from './CustomSelectField.css';
import { Button, Dropdown, Form } from 'react-bootstrap';

export interface ICustomSelectValue {
  value: number | string;
  label?: string;
  iconClass?: string;
}

interface ICustomSelectorFieldProps {
  onChange(value: string | number): void;
  value: string | number;
  values: ICustomSelectValue[];
  label?: string;
  required?: boolean;
  className?: string;
  innerButtonClass?: string;
  disabled?: boolean;
  canEmpty?: boolean;
  emptyText?: string;
  onlyIcon?: boolean;
}

interface ICustomSelectorFieldState {
  dropdownIsOpen: boolean;
  className?: string;
}

export class CustomSelectField extends React.Component<ICustomSelectorFieldProps, ICustomSelectorFieldState> {
  public state = {
    dropdownIsOpen: false,
  };

  public render() {
    const { values, value, label, required, className, innerButtonClass, disabled, canEmpty, emptyText, onlyIcon } =
      this.props;

    const currentItem = values.find(item => item.value === value);

    return (
      <Form.Group {...mergeStyles(className, styles['custom_selector_field'])}>
        <Form.Label>
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </Form.Label>
        <Dropdown show={this.state.dropdownIsOpen} autoClose="outside" onToggle={this.onOutsideClick}>
          <Dropdown.Toggle
            as={() => (
              <Button
                variant="light"
                {...mergeStyles(innerButtonClass, styles['dropdown-btn'], label && styles['uncentered'])}
                disabled={disabled}
                title={(onlyIcon && currentItem && currentItem.label) || undefined}
                onClick={() => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })}
              >
                {onlyIcon ? (
                  <span
                    title={currentItem && currentItem.label}
                    {...mergeStyles(styles['icon'], currentItem && currentItem.iconClass)}
                  />
                ) : (
                  this.renderLabelWithIcon(currentItem)
                )}
                <span {...mergeStyles('caret', styles['caret'])} />
              </Button>
            )}
          />
          {this.state.dropdownIsOpen && (
            <Dropdown.Menu className={styles['dropdown-menu']}>
              {!onlyIcon && canEmpty && <Dropdown.Item as="li">{emptyText || 'не выбрано'}</Dropdown.Item>}
              {this.renderItems(values, onlyIcon)}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </Form.Group>
    );
  }

  private onOutsideClick = () => this.setState({ dropdownIsOpen: false });

  private clickItemHandler = (value: string | number, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.onChange(value);
    this.onOutsideClick();
  };

  private renderLabelWithIcon = (item: ICustomSelectValue | undefined) => {
    if (!item || !(item.label || item.iconClass)) {
      return null;
    }

    return (
      <>
        {item.label && <span className={styles['label']}>{item.label}</span>}
        {item.iconClass && <span {...mergeStyles(item.iconClass, styles['icon'])} />}
      </>
    );
  };

  private renderItems = (values: ICustomSelectValue[], onlyIcon?: boolean) =>
    values.map(item => (
      <Dropdown.Item
        key={item.value}
        className={styles['list-item']}
        title={(onlyIcon && item.label) || undefined}
        onClick={e => this.clickItemHandler(item.value, e)}
        as="li"
      >
        {onlyIcon ? <span className={item.iconClass} /> : this.renderLabelWithIcon(item)}
      </Dropdown.Item>
    ));
}
