import { IApplicationContext } from 'shared/common/types/applicationContext';
import { ERuntimeSettings } from 'shared/common/types/runtimeSettings';
import { TGenericStoreState, mapPageTypeToCreateStoreFunction } from 'shared/common/utils/redux/pageTypeToCreateStore';

export function createApplicationState(context: IApplicationContext) {
  const initialState = context.config.getStrict<TGenericStoreState>(ERuntimeSettings.initialState);

  const createStoreFunction = mapPageTypeToCreateStoreFunction[context.custom.pageType];

  return createStoreFunction(context, initialState, true);
}
