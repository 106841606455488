import { NavBar } from '../../components/NavBar';
import { useApplicationContext } from 'shared/common/utils/applicationContext';
import { logOut } from 'shared/common/utils/logout';
import { ERoutes } from 'shared/common/types/routes';
import { EPageTypes } from 'shared/common/types/pageTypes';
import { FC, MouseEvent, useCallback } from 'react';
import { getActiveItemByPageType } from './utils/getActiveItemByPageType';
import { hasAccessToCalltracking, hasAccessToCountryside, hasAccessToBuilders } from 'shared/common/utils/permissions';

export const NavBarContainer: FC = () => {
  const {
    custom: { roles, email, managerId, pageType },
    config,
  } = useApplicationContext();

  const showEmail = pageType !== EPageTypes.Auth;

  const handleLogout = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();

      logOut(config);
    },
    [config],
  );

  const isCountrysideEnabled = hasAccessToCountryside(roles);
  const isCalltrackingEnabled = hasAccessToCalltracking(roles);
  const isBuildersEnabled = hasAccessToBuilders(roles);

  const cplSettingsUrl = managerId ? `${ERoutes.CplSettings}?managerId=${managerId}` : undefined;

  const activeItem = getActiveItemByPageType(pageType);

  return (
    <NavBar
      hasAccessToCountryside={isCountrysideEnabled}
      hasAccessToCalltracking={isCalltrackingEnabled}
      hasAccessToBuilders={isBuildersEnabled}
      email={showEmail ? email : undefined}
      cplSettingsUrl={cplSettingsUrl}
      onLogOut={handleLogout}
      activeItem={activeItem}
    />
  );
};
