import { INewObjectCategorySetItem } from '../common/interfaces';

export function getObjectSetItemTemplate(): INewObjectCategorySetItem {
  return {
    areaMax: 0,
    areaMin: 0,
    description: '',
    gallery: {
      imageList: [],
    },
    priceMax: 0,
    priceMin: 0,
  };
}
