/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TUpdateVillageModel,
  IUpdateVillageRequest,
  IMappers,
  IUpdateVillageResponse,
  IUpdateVillageResponseError,
  TUpdateVillageResponse,
} from './types';

const defaultConfig: TUpdateVillageModel = {
  apiType: 'public',
  assertStatusCodes: [400, 200, 204],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/update-village/',
  hostType: 'api',
} as TUpdateVillageModel;

function createUpdateVillageModel(parameters: IUpdateVillageRequest): TUpdateVillageModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IUpdateVillageOptions<TResponse400, TResponse200, TResponse204> {
  httpApi: IHttpApi;
  parameters: IUpdateVillageRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400, TResponse200, TResponse204>;
}

async function fetchUpdateVillage<TResponse400, TResponse200, TResponse204>({
  httpApi,
  parameters,
  config,
  mappers,
}: IUpdateVillageOptions<TResponse400, TResponse200, TResponse204>): Promise<
  TResponse400 | TResponse200 | TResponse204 | TUpdateVillageResponse
> {
  const { response, statusCode } = await httpApi.fetch(createUpdateVillageModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IUpdateVillageResponseError);
    }

    if (statusCode === 200) {
      return mappers[200](response as IUpdateVillageResponse);
    }

    if (statusCode === 204) {
      return mappers[204]();
    }
  }

  return { response, statusCode } as TUpdateVillageResponse;
}

export { defaultConfig, createUpdateVillageModel, fetchUpdateVillage };
