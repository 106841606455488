import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { IFeature, ISelectedFeature } from '../../../../common/interfaces';
import { TextField } from '../../../text_field';

import * as styles from './SelectFeatureModal.css';

interface ISelectFeatureModalProps {
  onConfirm(features: ISelectedFeature[]): void;
  onClose(): void;
  possibleFeatures: IFeature[];
  selectedFeatures: ISelectedFeature[];
  featureToEditIndex: number | undefined;
  readOnly?: boolean;
}

interface ISelectFeatureModalState {
  selectedFeature: ISelectedFeature;
  useValidation: boolean;
}

export class SelectFeatureModal extends React.Component<ISelectFeatureModalProps, ISelectFeatureModalState> {
  public constructor(props: ISelectFeatureModalProps) {
    super(props);

    let featureToEdit;
    if (props.featureToEditIndex !== undefined) {
      featureToEdit = (props.selectedFeatures && props.selectedFeatures[props.featureToEditIndex]) || null;
    }

    this.state = {
      selectedFeature: {
        description: (featureToEdit && featureToEdit.description) || '',
        id: (featureToEdit && featureToEdit.id) || -1,
      },
      useValidation: false,
    };
  }

  public render() {
    const { onClose, possibleFeatures, featureToEditIndex, readOnly } = this.props;
    const { selectedFeature, useValidation } = this.state;

    const result = possibleFeatures.map(item => {
      return (
        <div
          {...mergeStyles(item.id === this.state.selectedFeature.id && styles['checked'], styles['feature-container'])}
          key={item.id}
          onClick={() => this.handleFeatureImageClick(item.id)}
        >
          <img src={item.imageUrl} className={styles['feature-img']} title={item.type} />
        </div>
      );
    });

    const modalHeader = featureToEditIndex === undefined ? 'Привязка особенности к посёлку' : 'Изменение особенности';

    return (
      <div className={styles['container']}>
        <div className={styles['overlay']} onClick={onClose} />
        <form className={styles['modal-container']} onSubmit={this.handleModalConfirm}>
          <div className={styles['modal']}>
            <h4 {...mergeStyles('text-center', styles['modal-header'])}>{modalHeader}</h4>
            <div>
              {useValidation && !this.imageIsValid() && (
                <span className={styles['danger']}>Выберите тип особенности</span>
              )}
              <div {...mergeStyles(this.imageIsValid() && styles['nonchecked-features'], styles['features-list'])}>
                {result}
              </div>
              <div>
                <label
                  {...mergeStyles(
                    useValidation && !this.descriptionIsValid() && styles['danger-description'],
                    styles['description-label'],
                  )}
                >
                  Описание особенности (максимум 35 символов)
                </label>
                <TextField
                  value={selectedFeature.description}
                  inputClass={styles['description']}
                  onChange={this.handleDescriptionChange}
                  maxLength={35}
                  required
                  invalid={useValidation && !this.descriptionIsValid()}
                  autoFocus
                  readOnly={readOnly}
                />
              </div>
            </div>
            <div {...mergeStyles('text-center', styles['submit-controls'])}>
              {!readOnly && (
                <button
                  {...mergeStyles(
                    'btn',
                    featureToEditIndex === undefined ? 'btn-success' : 'btn-primary',
                    styles['confirm-btn'],
                  )}
                  type="submit"
                >
                  {featureToEditIndex === undefined ? 'Добавить' : 'Сохранить'}
                </button>
              )}
              <button onClick={onClose} className="btn btn-default">
                Отмена
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  private imageIsValid = () => Boolean(this.state.selectedFeature.id);

  private descriptionIsValid = () => Boolean(this.state.selectedFeature.description.trim());

  private handleFeatureImageClick = (featureId: number) => {
    this.setState({
      selectedFeature: {
        ...this.state.selectedFeature,
        id: featureId,
      },
    });
  };

  private handleDescriptionChange = (description: string) => {
    this.setState({
      selectedFeature: {
        ...this.state.selectedFeature,
        description,
      },
    });
  };

  private handleModalConfirm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { selectedFeature } = this.state;

    this.setState({
      useValidation: true,
    });

    // Checks whether description was fulfilled
    if (!this.descriptionIsValid() || !this.imageIsValid()) {
      return;
    }

    const { selectedFeatures = [], featureToEditIndex } = this.props;

    if (featureToEditIndex === undefined) {
      // Creates feature
      selectedFeatures.push(selectedFeature);
    } else {
      // Edits feature
      selectedFeatures[featureToEditIndex] = selectedFeature;
    }

    this.props.onClose();
    this.props.onConfirm(selectedFeatures);
  };
}
