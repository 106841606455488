/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetCplSettingsModel,
  IGetCplSettingsRequest,
  IMappers,
  IGetCplSettingsResponse,
  IGetCplSettingsResponseError,
  TGetCplSettingsResponse,
} from './types';

const defaultConfig: TGetCplSettingsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/get-cpl-settings/',
  hostType: 'api',
} as TGetCplSettingsModel;

function createGetCplSettingsModel(parameters: IGetCplSettingsRequest): TGetCplSettingsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetCplSettingsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetCplSettingsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetCplSettings<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetCplSettingsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetCplSettingsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetCplSettingsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetCplSettingsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetCplSettingsResponseError);
    }
  }

  return { response, statusCode } as TGetCplSettingsResponse;
}

export { defaultConfig, createGetCplSettingsModel, fetchGetCplSettings };
