import {
  fetchBlockPhone,
  IBlockPhoneRequest,
  IBlockPhoneResponse,
  IBlockPhoneResponseError,
} from '../repositories/countryside-calltracking/v1/block-phone';
import { createSimpleRequest } from './createSimpleRequest';

export const blockPhone = createSimpleRequest<IBlockPhoneRequest, IBlockPhoneResponse, IBlockPhoneResponseError>(
  'blockPhone',
  fetchBlockPhone,
);
