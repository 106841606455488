import { ICplFieldData } from '../../types';
import { ICplSettingsPageState } from '../../types/redux';
import { IThunkActionCreator } from '../../types/thunk';
import { getCplRequest } from '../../utils/getCplRequest';
import { getDisplayError } from '../../utils/getDisplayError';
import { updateCplData } from './updateCplData';
import { NotificationManager } from '../../utils/showNotification';

// thunk-action для обновления полей CPL
export function updateCplField(cplFieldData: ICplFieldData): IThunkActionCreator<ICplSettingsPageState> {
  return async (dispatch, _, { httpApi, logger }) => {
    const { cplSettingsId, value, field } = cplFieldData;

    if (typeof value === 'undefined') {
      NotificationManager.error('Введите корректное число', 'Ошибка заполнения');

      return;
    }

    try {
      const request = getCplRequest(field);

      if (typeof request !== 'function') {
        NotificationManager.error('Поле невозможно изменить');
        logger.error(`Cannot set ${field}: ${value} for CPL: ${cplSettingsId}`);

        return;
      }

      await request(httpApi, logger, {
        [field]: value,
        cplSettingsId,
      });

      dispatch(updateCplData());
    } catch (e) {
      const displayError = getDisplayError(e);
      NotificationManager.error(displayError);
    }
  };
}
