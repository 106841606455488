import {
  IGetCplSettingsRequest,
  IGetCplSettingsResponse,
  IGetCplSettingsResponseError,
  fetchGetCplSettings,
} from '../repositories/countryside-calltracking/v1/get-cpl-settings';
import { createSimpleRequest } from './createSimpleRequest';

export const getCplSettings = createSimpleRequest<
  IGetCplSettingsRequest,
  IGetCplSettingsResponse,
  IGetCplSettingsResponseError
>('getCplSettings', fetchGetCplSettings);
