/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import {
  IRegion,
  VillageTotalStatusNames,
  VillageTotalStatusIconClassNames,
  ModerationStatusNames,
  ModerationStatusIconClassNames,
  VillagesTariffIconClassNames,
  VillagesTariffNames,
  BuilderVillagesTariffNames,
} from '../../../common/interfaces';
import { EDIT_VILLAGE_ROUTE } from '../../../routes';
import { getShortDateFromISO } from '../../../utils/datetime';
import { AppContext } from '../../../AppContext';
import { getCianUserServiceUrl, getOrderServiceUrl } from '../../../utils/getServiceUrl';
import { IVillageForListSchema } from '../../../repositories/countryside/entities/schemas/VillageForListSchema';
import { WrapLink } from '../../WrapLink/WrapLink';

import * as styles from './VillageList.css';
import { getManagerFullName } from '../../../utils/managers';
import { WithTitle } from '../../WIthTitle';
import { Button, Table } from 'react-bootstrap';

interface IVillageListProps {
  villageList: IVillageForListSchema[];
  regionList: IRegion[];
  isCalltrackingManager: boolean;
}

export const VillageList = (props: IVillageListProps) => {
  const { config } = React.useContext(AppContext);
  const { isCalltrackingManager, villageList, regionList } = props;

  return (
    <Table striped hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Название</th>
          <th>Застройщик</th>
          <th>Менеджер</th>
          <th>Регион</th>
          <th>Телефон</th>
          <th>
            <WithTitle title="Статус модерации">
              <i className="far fa-clipboard" />
            </WithTitle>
          </th>
          <th>
            <WithTitle title="Статус поселка">
              <i className="fas fa-flag" />
            </WithTitle>
          </th>
          <th>№&nbsp;ЛК</th>
          <th>Заказ</th>
          <th>Добавлен</th>
          <th>Изменён</th>
          <th>
            <WithTitle title="Телефон">
              <i className="fas fa-phone" />
            </WithTitle>
          </th>
          <th>
            <WithTitle title="Тариф">
              <i className="fas fa-donate" />
            </WithTitle>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {villageList.map(village => (
          <tr key={village.id}>
            <td>{village.id}</td>
            <td>{village.name}</td>
            <td>{village.builderName}</td>
            <td>{getManagerFullName(village.manager)}</td>
            <td>{getRegionById(regionList, village.regionId)}</td>
            <td>
              <span className={styles['phone']}>{village.phone}</span>
            </td>
            <td>
              <WithTitle title={ModerationStatusNames[village.moderationStatus]}>
                <i className={ModerationStatusIconClassNames[village.moderationStatus]} />
              </WithTitle>
            </td>
            <td>
              <WithTitle title={VillageTotalStatusNames[village.totalStatus]}>
                <i className={VillageTotalStatusIconClassNames[village.totalStatus]} />
              </WithTitle>
            </td>
            <td>
              <WrapLink href={getCianUserServiceUrl(village.cianUserId, config)}>{village.cianUserId}</WrapLink>
            </td>
            <td>
              <WrapLink href={getOrderServiceUrl(village.orderId, config)}>{village.orderId}</WrapLink>
            </td>
            <td>{getShortDateFromISO(village.created)}</td>
            <td>{getShortDateFromISO(village.updated)}</td>
            <td>
              <WithTitle title={village.displayBuilderPhone ? 'Отображается' : 'Скрыт'}>
                {village.displayBuilderPhone ? <i className="far fa-eye" /> : <i className="far fa-eye-slash" />}
              </WithTitle>
            </td>
            <td>
              <WithTitle
                title={
                  isCalltrackingManager
                    ? VillagesTariffNames[village.tariff]
                    : BuilderVillagesTariffNames[village.tariff]
                }
              >
                <i className={VillagesTariffIconClassNames[village.tariff]} />
              </WithTitle>
            </td>
            <td className={styles['buttons']}>
              <Button
                href={EDIT_VILLAGE_ROUTE.replace(':id', village.id.toString())}
                target="_blank"
                size="sm"
                variant="primary"
              >
                <i className="fas fa-pen" />
              </Button>
              {village.seo.url && (
                <WithTitle title="Посмотреть на ЦИАН" position="bottom-left">
                  <Button href={village.seo.url} target="_blank" size="sm" variant="light">
                    <i className="far fa-eye" />
                  </Button>
                </WithTitle>
              )}
              {village.effectivenessReport && (
                <WithTitle
                  title={'Отчет об эффективности за ' + getShortDateFromISO(village.effectivenessReport.created)}
                  position="bottom-left"
                >
                  <Button
                    href={village.effectivenessReport.reportUrl}
                    target="_blank"
                    size="sm"
                    variant="info"
                    color="white"
                  >
                    <i className="fas fa-chart-bar" />
                  </Button>
                </WithTitle>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export function getRegionById(regionList: IRegion[], targetId: number) {
  const foundRegion = regionList.find(region => region.id === targetId);

  return foundRegion ? foundRegion.displayName : '';
}
