import { formatCalltrackingDateTime } from '../datetime';
import { IGetCallsRequest } from 'shared/types';

export function normalizeContractorCalltrackingFilters(filters: IGetCallsRequest) {
  return {
    ...filters,
    dateTo: formatCalltrackingDateTime(filters.dateTo),
    dateFrom: formatCalltrackingDateTime(filters.dateFrom),
  };
}
