import { IDatePeriod } from './getDatePeriod';

export function getCplSettingsToCalltrackingUrl(
  builderId: number | null | undefined,
  villageId: number | null,
  period: IDatePeriod,
  managerId?: number | null,
) {
  const month = `0${period.month}`.slice(-2);

  const query = [
    builderId && `builderId=${builderId}`,
    `callDateFrom=${period.year}-${month}-01T00:00`,
    'finalType=target',
    villageId && `villageId=${villageId}`,
    managerId && `managerIdList=${managerId}`,
  ]
    .filter(Boolean)
    .join('&');

  return `/calltracking/?${query}`;
}
