import { useEventCallback } from '@cian/react-utils';
import { ERoleType, TYPE_ROLES_MAP } from 'shared/constants/calltracking';
import { ERoles } from 'shared/repositories/countryside/v2/get-user-role-list';
import { TCallType, TChangerType } from 'shared/types';

interface IParams {
  isEditable?: boolean | null;
  roles: ERoles[];
  callId: number | undefined | null;
  callType: TCallType | undefined | null;
  setCallType(callId?: number, changerType?: TChangerType, type?: TCallType): void;
}

export const useController = ({ isEditable, roles, callId, callType, setCallType }: IParams) => {
  /* роли расположены по приоритету, можно редактировать, если переданный тип роли существует и нет роли приоритетнее */
  const isEditableByRole =
    Object.values(TYPE_ROLES_MAP).find(role => roles.includes(role)) === TYPE_ROLES_MAP[ERoleType.Final];

  const handleClick = useEventCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: TCallType) => {
    e.stopPropagation();
    if (value && value !== callType) {
      setCallType(callId ?? undefined, 'final', value);
    }
  });

  return {
    disabled: !isEditable || !isEditableByRole,
    handleClick,
  };
};
