//tslint:disable max-file-line-count
import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { AppContext } from '../../AppContext';
import { DISPLAY_TYPE_DROPDOWN_VALUES } from '../../constants';
import { uploadImage } from '../../http';
import { IFatIcon, TFatInfrastructureListItemDisplayType } from '../../types/fat';
import { getValueForTextField } from '../../utils/formatting';
import { IImage, ImageSelector } from '../image_selector';
import { SelectField } from '../SelectField';
import { TextField } from '../text_field';
import { TextAreaField } from '../textarea_field';

import * as styles from './FatInfrastructureModal.css';
import { CheckboxField } from '../CheckboxField';
import { Button, FormLabel } from 'react-bootstrap';

export interface IFatInfrastructureModalData {
  title?: string | null;
  description?: string | null;
  displayType?: TFatInfrastructureListItemDisplayType | null;
  imageUrl?: string | null;
  iconId?: number | null;
  isEnabled?: boolean | null;
}

export interface IFatInfrastructureModalProps {
  data: IFatInfrastructureModalData;
  iconList: IFatIcon[];
  header: string;
  isInfrastructureList?: boolean;
  onConfirm(data: IFatInfrastructureModalData): void;
  onClose(): void;
  onRemove?(): void;
}

interface IFatInfrastructureModalState {
  data: IFatInfrastructureModalData;
  useValidation: boolean;
  isImagePending: boolean;
}

export type TFatInfrastructureField = Pick<
  IFatInfrastructureModalData,
  'title' | 'description' | 'iconId' | 'isEnabled' | 'imageUrl' | 'displayType'
>;

export type TFatInfrastructureFieldValue = string | boolean | TFatInfrastructureListItemDisplayType | void;

export class FatInfrastructureModal extends React.Component<
  IFatInfrastructureModalProps,
  IFatInfrastructureModalState
> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public state = {
    data: { ...this.props.data },
    useValidation: false,
    isImagePending: false,
  };

  public render() {
    const { header, onClose, onRemove, iconList, isInfrastructureList } = this.props;
    const {
      data: { title, description, isEnabled, displayType, imageUrl },
      useValidation,
    } = this.state;

    const titleMaxLength = isInfrastructureList ? 100 : 80;
    const descriptionMaxLength = isInfrastructureList ? 450 : 300;

    const result = iconList.map(item => {
      return (
        <div
          {...mergeStyles(item.id === this.state.data.iconId && styles['checked'], styles['infrastructure-container'])}
          key={item.id}
          onClick={() => this.onIconClick(item.id)}
        >
          <img src={item.url} className={styles['infrastructure-img']} alt="" />
        </div>
      );
    });

    const isTitleValid = this.isFieldValid('title');
    const isDescriptionValid = this.isFieldValid('description');
    const isImageUrlValid = this.isFieldValid('imageUrl');

    return (
      <div className={styles['container']}>
        <div className={styles['overlay']} onClick={onClose} />
        <form className={styles['modal-container']} onSubmit={this.handleModalConfirm}>
          <div className={styles['modal']}>
            <h4 {...mergeStyles('text-center', styles['modal-header'])}>{header}</h4>
            <div className={styles['modal-content']}>
              {useValidation && !this.isFieldDefined('iconId') && (
                <span className={styles['danger']}>Выберите иконку инфраструктуры</span>
              )}
              <div
                {...mergeStyles(
                  this.isFieldDefined('iconId') && styles['nonchecked-infrastructures'],
                  styles['infrastructures-list'],
                )}
              >
                {result}
              </div>
              <CheckboxField
                label="Показать на карточке"
                value={!!isEnabled}
                onChange={checked => this.handleFieldChange('isEnabled')(checked)}
              />
              <div>
                <FormLabel {...mergeStyles(useValidation && !isTitleValid && styles['label--danger'], styles['label'])}>
                  Заголовок
                </FormLabel>
                <TextField
                  value={title}
                  inputClass={styles['title']}
                  onChange={this.handleFieldChange('title')}
                  maxLength={titleMaxLength}
                  required
                  invalid={useValidation && !isTitleValid}
                  autoFocus
                />
              </div>
              <div>
                <FormLabel
                  {...mergeStyles(useValidation && !isDescriptionValid && styles['label--danger'], styles['label'])}
                >
                  Описание
                </FormLabel>
                <TextAreaField
                  value={description || ''}
                  inputClass={styles['description']}
                  onChange={this.handleFieldChange('description')}
                  maxLength={descriptionMaxLength}
                  required
                  invalid={useValidation && !isDescriptionValid}
                />
              </div>
              {isInfrastructureList && (
                <>
                  <div>
                    <FormLabel
                      {...mergeStyles(useValidation && !isImageUrlValid && styles['label--danger'], styles['label'])}
                    >
                      Картинка
                    </FormLabel>
                    <ImageSelector
                      value={{ url: imageUrl || undefined }}
                      minWidth={100}
                      minHeight={100}
                      onChange={this.onImageUrlChange}
                    />
                  </div>
                  <div className={styles['display_type']}>
                    <SelectField
                      value={getValueForTextField(displayType)}
                      values={DISPLAY_TYPE_DROPDOWN_VALUES}
                      label="Тип инфраструктуры"
                      onChange={this.handleFieldChange('displayType')}
                    />
                  </div>
                </>
              )}
            </div>
            <div {...mergeStyles('text-center', styles['submit-controls'])}>
              {isInfrastructureList && (
                <Button variant="danger" onClick={onRemove} className="remove_button">
                  Удалить
                </Button>
              )}
              <Button onClick={onClose} variant="outline-secondary">
                Отмена
              </Button>
              <Button className={styles['confirm-btn']} type="submit">
                Сохранить
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  private isFieldDefined = (fieldName: keyof TFatInfrastructureField) => {
    return typeof this.state.data[fieldName] !== 'undefined';
  };

  private isFieldValid = (fieldName: keyof TFatInfrastructureField) => {
    const value = this.state.data[fieldName];

    return typeof value === 'string' ? !!value.trim() : !!value;
  };

  private onIconClick = (iconId: number) => {
    this.setState({
      data: {
        ...this.state.data,
        iconId,
      },
    });
  };

  private handleFieldChange = (fieldName: keyof TFatInfrastructureField) => (value: TFatInfrastructureFieldValue) => {
    this.setState({
      data: {
        ...this.state.data,
        [fieldName]: value,
      },
    });
  };

  private handleModalConfirm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { isInfrastructureList } = this.props;
    const { data } = this.state;

    this.setState({
      useValidation: true,
    });

    // Checks whether description was fulfilled
    if (
      !this.isFieldValid('title') ||
      !this.isFieldValid('description') ||
      !this.isFieldDefined('iconId') ||
      (isInfrastructureList && !this.isFieldDefined('imageUrl'))
    ) {
      return;
    }

    this.props.onConfirm(data);
  };

  private onImageUrlChange = async (image: IImage) => {
    const { httpApi, logger } = this.context;
    const imageFallbackState = {
      data: {
        ...this.state.data,
        imageUrl: undefined,
      },
      isImagePending: false,
    };

    if (image.base64) {
      this.setState({ isImagePending: true });

      try {
        const { url } = await uploadImage(httpApi, logger, {
          base64: image.base64,
        });

        this.setState({
          data: {
            ...this.state.data,
            imageUrl: url,
          },
          isImagePending: false,
        });
      } catch (error) {
        this.setState(imageFallbackState);
      }
    } else {
      this.setState(imageFallbackState);
    }
  };
}
