import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import MaskedInput from 'react-input-mask';
import * as styles from './index.css';
import { Form } from 'react-bootstrap';

export interface IMaskedTextFieldProps {
  value: string;
  onChange(value: string): void;
  containerClass?: string;
  inputClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  invalid?: boolean;
  mask: string | Array<string | RegExp>;
  readOnly?: boolean;
}

export const MaskedTextField = (props: IMaskedTextFieldProps) => (
  <Form.Group {...mergeStyles(props.containerClass, styles['text-field'])}>
    <Form.Label>
      {props.label}&nbsp;
      {props.required && <span className="text-danger">*</span>}
    </Form.Label>
    <MaskedInput
      {...mergeStyles('form-control', props.inputClass, props.invalid && styles['invalid'])}
      id={props.htmlId}
      value={props.value}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      onChange={(e: React.FormEvent<HTMLInputElement>) => props.onChange(e.currentTarget.value)}
      mask={props.mask}
      readOnly={!!props.readOnly}
    />
  </Form.Group>
);
