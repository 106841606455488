import * as React from 'react';
import { AppContext } from '../../AppContext';
import { useUpdateCallCommentHook } from '../../utils/useUpdateCallCommentHook';
import { ICallListItemSchema } from '../../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';
import { TextAreaField } from '../textarea_field';
import { NotificationContainer } from '../../utils/showNotification';

interface ICalltrackingComment {
  call: ICallListItemSchema;
  onCommentSave(): void;
  isAdmin?: boolean;
}

export function CalltrackingComment({ call, onCommentSave, isAdmin }: ICalltrackingComment) {
  const { httpApi, logger } = React.useContext(AppContext);
  const [value, onChange] = React.useState(call.comment || '');
  React.useEffect(() => {
    onChange(call.comment || '');
  }, [call.callId, call.comment]);
  const { isPending, saveComment } = useUpdateCallCommentHook({
    httpApi,
    logger,
    call,
    onCommentSave,
  });

  return (
    <>
      <NotificationContainer />
      <TextAreaField readOnly={!isAdmin} value={value} onChange={onChange} />
      {isAdmin && (
        <button className="btn btn-success" onClick={() => saveComment(value)} disabled={isPending}>
          Сохранить комментарий
        </button>
      )}
    </>
  );
}
