import { connect } from 'react-redux';

import { IContractorCalltrackingListPageState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import {
  IContractorCalltrackingListProps,
  ContractorCalltrackingList,
} from '../../components/ContractorCalltracking/ContractorCalltrackingList/';
import { TConstructorCall, TContractorSelectFieldProps, ValueOf } from '../../types';

import { setContractorCallsFilter } from '../../actions/contractorCallsListFilters';
import { updateContractorCallsListData } from '../../actions/contractorCallsListData';
import { formatDayPickerDate, getShortISODateTime } from '../../utils/datetime';
import { INITIAL_PAGINATION_PARAMS } from '../../utils/constants';
import { loadConstructorCallHistory, setContractorCallPopupStatus } from '../../actions/contractorCallPopup';
import { setContractorPlayerStatus, resetAfterSwitching } from '../../actions/contractorCallsPlayer';
import { saveConstructorCallsReport } from 'shared/actions/contractorCallsReport';

export type TStoreProps = Pick<
  IContractorCalltrackingListProps,
  | 'contractorCallsList'
  // | 'regionList'
  | 'totalPages'
  | 'totalItems'
  | 'roles'
  | 'page'
  | 'pageSize'
  // | 'contractorId'
  // | 'contractorName'
  // | 'dateFrom'
  // | 'dateTo'
  // | 'projectId'
  // | 'projectName'
  // | 'regionId'
  // | 'sourceRegionId'
  // | 'status'
  | 'callPopup'
  | 'playerStatus'
>;

export type TDispatchProps = Pick<
  IContractorCalltrackingListProps,
  | 'onPageChange'
  | 'onPageSizeChange'
  | 'onIdChange'
  | 'onSelectChange'
  // | 'onMultiSelectChange'
  | 'onDateChange'
  | 'onUpdate'
  | 'onRowClick'
  | 'onCallTypeChange'
  | 'onSaveReport'
>;

export function mapStateToProps(state: IContractorCalltrackingListPageState): TStoreProps {
  const {
    contractorCallsList: { calls, totalPages, totalItems },
    callPopup,
    playerStatus,
    filters: {
      page,
      pageSize,
      // contractorId,
      // contractorName,
      // dateFrom,
      // dateTo,
      // projectId,
      // projectName,
      // regionId,
      // sourceRegionId,
      // status,
    },
    roles,
  } = state;

  return {
    contractorCallsList: calls,
    totalPages,
    totalItems,
    roles,
    page: page || INITIAL_PAGINATION_PARAMS.page,
    pageSize: pageSize || INITIAL_PAGINATION_PARAMS.pageSize,
    // contractorId: contractorId || undefined,
    // contractorName: contractorName || undefined,
    // dateFrom: dateFrom || undefined,
    // dateTo: dateTo || undefined,
    // projectId: projectId || undefined,
    // projectName: projectName || undefined,
    // regionId: regionId || [],
    // sourceRegionId: sourceRegionId || [],
    // status: status || [],
    callPopup,
    playerStatus,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    onPageChange: (page: number) => {
      dispatch(setContractorCallsFilter({ name: 'page', value: page }));
      dispatch(updateContractorCallsListData());
    },
    onPageSizeChange: (pageSize: string | number) => {
      dispatch(setContractorCallsFilter({ name: 'pageSize', value: Number(pageSize) }));
      dispatch(setContractorCallsFilter({ name: 'page', value: INITIAL_PAGINATION_PARAMS.page }));
      dispatch(updateContractorCallsListData());
    },
    onIdChange: (name: 'contractorId' | 'projectId', value: string) => {
      dispatch(setContractorCallsFilter({ name, value: parseInt(value, 10) || undefined }));
    },
    onSelectChange: (name: keyof TContractorSelectFieldProps, value: ValueOf<TContractorSelectFieldProps>) => {
      dispatch(setContractorCallsFilter({ name, value: value || undefined }));
    },
    // onMultiSelectChange: (
    //   name: keyof TContractorMultiselectFieldProps,
    //   value: ValueOf<TContractorMultiselectFieldProps>,
    // ) => {
    //   dispatch(setContractorCallsFilter({ name, value: value.length < 1 ? undefined : value }));
    // },
    onDateChange: (name: 'dateTo' | 'dateFrom', value?: Date) => {
      const formattedDate =
        value instanceof Date ? getShortISODateTime(formatDayPickerDate(value), name === 'dateTo') : undefined;

      dispatch(setContractorCallsFilter({ name, value: formattedDate }));
    },
    onUpdate: () => {
      dispatch(setContractorCallsFilter({ name: 'page', value: INITIAL_PAGINATION_PARAMS.page }));
      dispatch(updateContractorCallsListData());
    },
    onRowClick: (call: TConstructorCall, isOpen: boolean, recordFilePath: string | undefined, isPaused: boolean) => {
      const { callId } = call;
      dispatch(resetAfterSwitching(callId));
      dispatch(
        setContractorCallPopupStatus({
          callId,
          isOpen,
          history: [],
        }),
      );
      dispatch(setContractorPlayerStatus({ recordFilePath, isPaused }));
      dispatch(loadConstructorCallHistory(callId));
    },
    onCallTypeChange: () => {
      dispatch(updateContractorCallsListData());
    },
    onSaveReport: () => {
      dispatch(saveConstructorCallsReport());
    },
  };
}

export const ContractorCalltrackingListContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorCalltrackingList);
