/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetUserRoleListModel,
  IGetUserRoleListRequest,
  IMappers,
  IGetUserRoleListResponse,
  IGetUserRoleListResponseError,
  TGetUserRoleListResponse,
} from './types';

const defaultConfig: TGetUserRoleListModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v2/get-user-role-list/',
  hostType: 'api',
  responseType: 'json',
} as TGetUserRoleListModel;

function createGetUserRoleListModel(parameters: IGetUserRoleListRequest): TGetUserRoleListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUserRoleListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUserRoleListRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUserRoleList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUserRoleListOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetUserRoleListResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetUserRoleListModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUserRoleListResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUserRoleListResponseError);
    }
  }

  return { response, statusCode } as TGetUserRoleListResponse;
}

export { defaultConfig, createGetUserRoleListModel, fetchGetUserRoleList };
