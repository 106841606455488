import * as React from 'react';
import { IAlert } from '../common/interfaces';

let timer: number | undefined;

export function useSetAlertWithTimeoutHook(defaultState: IAlert): [IAlert, (alert: IAlert) => void] {
  const [alert, setAlert] = React.useState(defaultState);

  const hideAlert = () => {
    clearTimeout(timer);
    setAlert(defaultState);
  };

  const setAlertWithTimeout = (alert: IAlert) => {
    clearTimeout(timer);

    timer = window.setTimeout(hideAlert, 10000);
    setAlert(alert);
  };

  return [alert, setAlertWithTimeout];
}
