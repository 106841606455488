import * as React from 'react';
import { IBlackListInfoSchema } from '../../repositories/countryside-calltracking/entities/call/BlackListInfoSchema';
import { BasicModal } from '../BasicModal';
import { TextField } from '../text_field';

export interface ICalltrackingBlackListModalProps {
  data: IBlackListInfoSchema | null | undefined;
  onConfirm(data: ICalltrackingBlackListModalData): void;
  onClose(): void;
}

export interface ICalltrackingBlackListModalData {
  cause: string;
}

export function CalltrackingBlackListModal({ onClose, onConfirm, data }: ICalltrackingBlackListModalProps) {
  const [cause, setCause] = React.useState(data ? data.cause : '');

  return (
    <BasicModal
      header="Добавление номера в ЧС"
      onClose={onClose}
      onConfirm={() => {
        if (!cause) {
          return;
        }

        onConfirm({ cause });
      }}
    >
      <TextField value={cause || ''} label="Причина" onChange={setCause} required />
    </BasicModal>
  );
}
