type TDropdownKeysValues = string | number;

interface IIndexableObject {
  [key: string]: TDropdownKeysValues;
  [key: number]: TDropdownKeysValues;
}

export const generateDropdown = (object: IIndexableObject) =>
  Object.keys(object).map(value => ({
    label: String(object[value]),
    value,
  }));

interface IDropdownKeysValuesWithIcons {
  label: string;
  iconClass: string;
}

interface IIndexableObjectWithIcons {
  [key: string]: IDropdownKeysValuesWithIcons;
  [key: number]: IDropdownKeysValuesWithIcons;
}

export const generateDropdownWithIcons = (object: IIndexableObjectWithIcons) =>
  Object.keys(object).map(value => ({
    label: String(object[value].label),
    iconClass: String(object[value].iconClass),
    value,
  }));
