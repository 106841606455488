export interface IDatePeriod {
  year: number;
  month: number;
}

export function getDatePeriod(date: Date = new Date()): IDatePeriod {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
}
