import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { copyTextFromInput } from '../../utils/copyTextFromInput';
import { ICallRecordState } from '../../utils/useGetCallRecordHook';

import * as styles from './CalltrackingAudioLink.css';

interface ICalltrackingAudioLinkProps {
  callRecord: ICallRecordState;
  isMobile?: boolean;
  onLoadButtonClick(): void;
}

export function CalltrackingAudioLink({ callRecord, isMobile, onLoadButtonClick }: ICalltrackingAudioLinkProps) {
  const inputRef = React.useRef(null);

  const { url, isLoading } = callRecord;

  return (
    <div {...mergeStyles(styles['container'], isMobile && styles['container--mobile'])}>
      {!callRecord.url && (
        <button
          {...mergeStyles('btn', 'btn-primary', !isMobile && 'btn-sm', styles['load_button'])}
          type="button"
          onClick={onLoadButtonClick}
          disabled={isLoading}
        >
          Получить ссылку на звонок
        </button>
      )}
      {url && (
        <>
          <input
            {...mergeStyles('form-control', styles['record_field'])}
            ref={inputRef}
            type="text"
            readOnly
            value={url}
            onFocus={e => e.target.select()}
          />
          <button
            {...mergeStyles('btn', 'btn-primary', !isMobile && 'btn-sm', styles['copy_button'])}
            onClick={() => copyTextFromInput(inputRef.current)}
            title="Скопировать"
          >
            <i className="fas fa-copy" />
          </button>
        </>
      )}
      {callRecord.url === null && (
        <span className={styles['load_error']}>Произошла ошибка загрузки. Попробуйте позже</span>
      )}
    </div>
  );
}
