/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ICommunicationSetSchema } from '../../entities/communication/CommunicationSetSchema';
import { IVillageCounterSetSchema } from '../../entities/village_counter/VillageCounterSetSchema';
import { IUpdateFatVillageFeatureSchema } from '../../entities/schemas/UpdateFatVillageFeatureSchema';
import { IFatVillageInfrasturctureSchema } from '../../entities/schemas/FatVillageInfrasturctureSchema';
import { IVillageFeatureSchema } from '../../entities/feature/VillageFeatureSchema';
import { IGallerySchema } from '../../entities/schemas/GallerySchema';
import { INeighbourhoodSchema } from '../../entities/schemas/NeighbourhoodSchema';
import { INewobjectCategorySetSchema } from '../../entities/schemas/NewobjectCategorySetSchema';
import { IRoadInfoSchema } from '../../entities/road_info/RoadInfoSchema';
import { IStationInfoSchema } from '../../entities/station_info/StationInfoSchema';
import { IVideoSchema } from '../../entities/video/VideoSchema';

export type TUpdateVillageModel = IModel<IUpdateVillageRequest, TUpdateVillageResponse>;

export interface IUpdateVillageRequest {
  /** Площадь, га **/
  areaHectare: number;
  /** Автоматическое отключение заказа при достижении нулевого баланса **/
  autodisableOrder?: boolean | null;
  /** ID застройщика **/
  builderId: number;
  /** ID пользователя **/
  cianUserId?: number | null;
  /** Комментарий для сотрудников **/
  commentary?: string | null;
  /** Коммуникации **/
  communicationSet: ICommunicationSetSchema;
  /** Сводка объектов КП **/
  counterSet: IVillageCounterSetSchema;
  /** Email пользователя создавшего КП **/
  creatorEmail?: string | null;
  /** Описание **/
  description?: string | null;
  /** Отображать телефон застройщика **/
  displayBuilderPhone?: boolean | null;
  /** Список жирных особенностей **/
  fatFeatureList?: IUpdateFatVillageFeatureSchema[] | null;
  /** Список жирной инфраструктуры **/
  fatInfrastructureList?: IFatVillageInfrasturctureSchema[] | null;
  /** undefined **/
  featureList: IVillageFeatureSchema[];
  /** Месяц сдачи до **/
  finishMonthMax?: EFinishMonthMax | null;
  /** Месяц сдачи от **/
  finishMonthMin?: EFinishMonthMin | null;
  /** Квартал сдачи до **/
  finishQuarterMax?: EFinishQuarterMax | null;
  /** Квартал сдачи от **/
  finishQuarterMin?: EFinishQuarterMin | null;
  /** Год сдачи до **/
  finishYearMax: number;
  /** Год сдачи от **/
  finishYearMin: number;
  /** Этажность, до **/
  floorCountMax?: number | null;
  /** Этажность, от **/
  floorCountMin?: number | null;
  /** Признак бесплатности CPL **/
  freeCpl?: boolean | null;
  /** Дата первого перехода на бесплатный CPL **/
  freeCplFirstEnabledAt?: string | null;
  /** Главная галерея **/
  gallery: IGallerySchema;
  /** URL изображения генплана **/
  generalPlanUrl?: string | null;
  /** Скрывать цену КП **/
  hidePrice?: boolean | null;
  houseMaterialList?: EHouseMaterialList[] | null;
  /** ID **/
  id: number;
  /** Инфраструктура **/
  infrastructureList: EInfrastructureList[];
  /** Признак CPL **/
  isCpl?: boolean | null;
  /** Признак урезанных блоков КП **/
  isCropped?: boolean | null;
  /** Признак "жирности" КП **/
  isFat?: boolean | null;
  /** КП в статусе "Премиум" **/
  isPremium?: boolean | null;
  /** Признак Тариф ПРО **/
  isPro?: boolean | null;
  /** Флаг скрытия КП из выдачи **/
  isVisible?: boolean | null;
  /** Широта **/
  lat?: number | null;
  /** Долгота **/
  lng?: number | null;
  /** ID локации (юр. адрес застройщика) **/
  locationId: number;
  /** Тип локации (юр. адрес застройщика) **/
  locationType: ELocationType;
  /** ID менеджера **/
  managerId?: number | null;
  /** Статус модерации **/
  moderationStatus: EModerationStatus;
  /** Название **/
  name: string;
  /** Окрестности ПК **/
  neighbourhoodList?: INeighbourhoodSchema[] | null;
  /** Категории объектов **/
  newobjectCategorySet?: INewobjectCategorySetSchema | null;
  /** Дата распубликации объявлений **/
  offersUnpublishedAt?: string | null;
  /** Номер заказа **/
  orderId?: number | null;
  /** Телефон **/
  phone?: string | null;
  /** Цена до, руб **/
  priceMax?: number | null;
  /** Цена от, руб **/
  priceMin?: number | null;
  /** Статус готовности **/
  readyStatus: EReadyStatus;
  /** Регион **/
  regionId: number;
  /** undefined **/
  roadInfoList?: IRoadInfoSchema[] | null;
  /** Статус продаж **/
  saleStatus: ESaleStatus;
  /** URL сайта **/
  siteUrl?: string | null;
  /** undefined **/
  stationInfoList?: IStationInfoSchema[] | null;
  /** Общий статус **/
  totalStatus: ETotalStatus;
  /** undefined **/
  videoList?: IVideoSchema[] | null;
  /** Класс **/
  villageClass: EVillageClass;
}

export type TUpdateVillageResponse = IUpdateVillageResponse400 | IUpdateVillageResponse200 | IUpdateVillageResponse204;

export interface IUpdateVillageResponse400 extends IModelResponse<IUpdateVillageResponseError> {
  statusCode: 400;
}

export interface IUpdateVillageResponse200 extends IModelResponse<IUpdateVillageResponse> {
  statusCode: 200;
}

export interface IUpdateVillageResponse204 extends IModelResponse<{}> {
  statusCode: 204;
}

export interface IUpdateVillageResponseError {
  errors: any[];
  message: string;
}

export interface IUpdateVillageResponse {}

export type TResponses = IUpdateVillageResponseError | IUpdateVillageResponse | {};

export enum EFinishMonthMax {
  /** Апрель **/
  April = 'april',
  /** Август **/
  August = 'august',
  /** Декабрь **/
  December = 'december',
  /** Февраль **/
  February = 'february',
  /** Январь **/
  January = 'january',
  /** Июль **/
  July = 'july',
  /** Июнь **/
  June = 'june',
  /** Март **/
  March = 'march',
  /** Май **/
  May = 'may',
  /** Ноябрь **/
  November = 'november',
  /** Октябрь **/
  October = 'october',
  /** Сентябрь **/
  September = 'september',
}

export enum EFinishMonthMin {
  /** Апрель **/
  April = 'april',
  /** Август **/
  August = 'august',
  /** Декабрь **/
  December = 'december',
  /** Февраль **/
  February = 'february',
  /** Январь **/
  January = 'january',
  /** Июль **/
  July = 'july',
  /** Июнь **/
  June = 'june',
  /** Март **/
  March = 'march',
  /** Май **/
  May = 'may',
  /** Ноябрь **/
  November = 'november',
  /** Октябрь **/
  October = 'october',
  /** Сентябрь **/
  September = 'september',
}

export enum EFinishQuarterMax {
  /** I **/
  First = 'first',
  /** IV **/
  Fourth = 'fourth',
  /** II **/
  Second = 'second',
  /** III **/
  Third = 'third',
}

export enum EFinishQuarterMin {
  /** I **/
  First = 'first',
  /** IV **/
  Fourth = 'fourth',
  /** II **/
  Second = 'second',
  /** III **/
  Third = 'third',
}

export enum EHouseMaterialList {
  /** газобетонный блок **/
  AerocreteBlock = 'aerocreteBlock',
  /** щитовой **/
  Boards = 'boards',
  /** кирпичный **/
  Brick = 'brick',
  /** пенобетонный блок **/
  FoamConcreteBlock = 'foamConcreteBlock',
  /** монолитный **/
  Monolith = 'monolith',
  /** деревянный **/
  Wood = 'wood',
}

export enum EInfrastructureList {
  /** Пропускная система **/
  Access = 'access',
  /** Автосервис **/
  CarService = 'carService',
  /** Церковь **/
  Church = 'church',
  /** Кино **/
  Cinema = 'cinema',
  /** Медпункт **/
  Clinic = 'clinic',
  /** Коммерческий объект **/
  Commercial = 'commercial',
  /** Консьерж **/
  Concierge = 'concierge',
  /** Площадки для выгула собак **/
  DogsWalking = 'dogsWalking',
  /** Пожарная служба **/
  Fire = 'fire',
  /** Фитнес-центр **/
  Fitness = 'fitness',
  /** Лесная зона **/
  Forest = 'forest',
  /** АЗС **/
  GasStation = 'gasStation',
  /** Охраняемая парковка **/
  GuardedParking = 'guardedParking',
  /** Залив **/
  Gulf = 'gulf',
  /** Больница **/
  Hospital = 'hospital',
  /** Институт **/
  Institute = 'institute',
  /** Детский сад **/
  Kindergarten = 'kindergarten',
  /** Озеро **/
  Lake = 'lake',
  /** Музей **/
  Museum = 'museum',
  /** Офис **/
  Office = 'office',
  /** Парковая зона **/
  Park = 'park',
  /** Парковка **/
  Parking = 'parking',
  /** Огороженный периметр **/
  Perimeter = 'perimeter',
  /** Аптека **/
  Pharmacy = 'pharmacy',
  /** Детская площадка **/
  Playground = 'playground',
  /** Полиция **/
  Police = 'police',
  /** Поликлиника **/
  Polyclinic = 'polyclinic',
  /** Пруд **/
  Pond = 'pond',
  /** Бассейн **/
  Pool = 'pool',
  /** Заповедник **/
  Reserve = 'reserve',
  /** Курортная зона **/
  Resort = 'resort',
  /** Место для отдыха **/
  Rest = 'rest',
  /** Ресторан **/
  Restaurant = 'restaurant',
  /** Река **/
  River = 'river',
  /** Школа **/
  School = 'school',
  /** Круглосуточная охрана **/
  Security = 'security',
  /** Пункт бытового самообслуживания **/
  SelfService = 'selfService',
  /** Магазин **/
  Shop = 'shop',
  /** Торговый центр **/
  ShoppingCenter = 'shoppingCenter',
  /** Сигнализация **/
  Signaling = 'signaling',
  /** SPA-центр **/
  Spa = 'spa',
  /** Спортивная площадка **/
  SportsGround = 'sportsGround',
  /** Площадь **/
  Square = 'square',
  /** Стадион **/
  Stadium = 'stadium',
  /** Студия **/
  Studio = 'studio',
  /** Супермаркет **/
  Supermarket = 'supermarket',
  /** Театр **/
  Theater = 'theater',
  /** Ветеринарная клиника **/
  VeterinaryClinic = 'veterinaryClinic',
  /** Видеонаблюдение **/
  Video = 'video',
  /** Зоопарк **/
  Zoo = 'zoo',
}

export enum ELocationType {
  /** Дом / строение **/
  House = 'house',
  /** Город / область **/
  Location = 'location',
  /** Улица / проспект **/
  Street = 'street',
}

export enum EModerationStatus {
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
}

export enum EReadyStatus {
  /** Строится **/
  Building = 'building',
  /** Построен **/
  Built = 'built',
  /** Заморожен **/
  Frozen = 'frozen',
  /** Планируется **/
  Planning = 'planning',
}

export enum ESaleStatus {
  /** Завершены **/
  Closed = 'closed',
  /** Открыты **/
  Opened = 'opened',
  /** Ожидание **/
  Pending = 'pending',
}

export enum ETotalStatus {
  /** Активный **/
  Active = 'active',
  /** Не активный **/
  Inactive = 'inactive',
  /** Ожидание **/
  Pending = 'pending',
  /** Реализован **/
  Sold = 'sold',
}

export enum EVillageClass {
  /** Бизнес **/
  Business = 'business',
  /** Комфорт **/
  Comfort = 'comfort',
  /** Эконом **/
  Economy = 'economy',
  /** Элит **/
  Elite = 'elite',
}

export interface IMappers<TResponse400, TResponse200, TResponse204> {
  400(response: IUpdateVillageResponseError): TResponse400;
  200(response: IUpdateVillageResponse): TResponse200;
  204(): TResponse204;
}
