import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { MONTH_YEAR_MASK_LENGTH } from '../../../constants';
import { getDateByPeriod } from '../../../utils';
import { IDatePeriod } from '../../../utils/getDatePeriod';
import { DatePicker } from '../../DatePicker';
import { MultiselectorField } from '../../multiselector_field';
import { IDropdownValue, SelectField } from '../../SelectField';
import { EUserRoles, hasCalltrackingManagerRole } from '../../../utils/permissions';

import * as styles from './CplSettingsControls.css';

export interface ICplSettingsControlsProps {
  period: IDatePeriod;
  selectedVillageIds: number[];
  managerId?: number;
  villageDropdownValues: IDropdownValue[];
  managerDropdownValues: IDropdownValue[];
  isMobile?: boolean;
  onPeriodChange(value: IDatePeriod): void;
  onVillageDropdownChange(value: number[]): void;
  onManagerDropdownChange(value: string): void;
  roles: EUserRoles[];
}

export function CplSettingsControls({
  period,
  selectedVillageIds,
  managerId,
  villageDropdownValues,
  managerDropdownValues,
  isMobile,
  onVillageDropdownChange,
  onManagerDropdownChange,
  onPeriodChange,
  roles,
}: ICplSettingsControlsProps) {
  const selectedDate = getDateByPeriod(period);

  return (
    <div {...mergeStyles(styles['container'], isMobile && styles['container--mobile'])}>
      <div {...mergeStyles(styles['control'], styles['control--small'])}>
        <DatePicker
          showMonthYearPicker
          selected={selectedDate}
          onChange={(date: Date, event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            if (typeof value !== 'undefined' && value.length !== MONTH_YEAR_MASK_LENGTH) {
              return;
            }

            onPeriodChange({
              year: date.getFullYear(),
              month: date.getMonth() + 1,
            });
          }}
          label="Месяц"
        />
      </div>
      <div className={styles['control']}>
        <MultiselectorField
          value={selectedVillageIds}
          values={villageDropdownValues}
          onChange={onVillageDropdownChange}
          invalid={false}
          showSelectButtons
          label="КП"
        />
      </div>
      {hasCalltrackingManagerRole(roles) && managerId && (
        <div className={styles['control']}>
          <SelectField
            value={managerId}
            values={managerDropdownValues}
            onChange={onManagerDropdownChange}
            label="Менеджер"
          />
        </div>
      )}
    </div>
  );
}
