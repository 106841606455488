import { IBuilderFilter, IPagination } from '../common/interfaces';
import { getDateFromISO } from './getDateFromISO';

export function getBuildersQueryStringByFilters(pagination: IPagination, filter: IBuilderFilter): string {
  const { createdFrom, createdTo, id, name, regionList, updatedFrom, updatedTo, moderationStatus } = filter;
  const params = [];

  params.push(`p=${pagination.p}`);
  if (pagination.pageSize) {
    params.push(`pageSize=${pagination.pageSize}`);
  }
  if (createdFrom) {
    params.push(`createdFrom=${getDateFromISO(createdFrom)}`);
  }
  if (createdTo) {
    params.push(`createdTo=${getDateFromISO(createdTo)}`);
  }
  if (updatedFrom) {
    params.push(`updatedFrom=${getDateFromISO(updatedFrom)}`);
  }
  if (updatedTo) {
    params.push(`updatedTo=${getDateFromISO(updatedTo)}`);
  }
  if (moderationStatus) {
    params.push(`moderationStatus=${moderationStatus}`);
  }
  if (id) {
    params.push(`id=${id}`);
  }
  if (name) {
    params.push(`name=${name}`);
  }
  if (Array.isArray(regionList) && regionList.length) {
    params.push(`regionList=${regionList.join(',')}`);
  }

  return `?${params.join('&')}`;
}
