import { connect } from 'react-redux';
import { updateCplData } from '../../actions/cplData';
import {
  setCplSettingsManagerId,
  setCplSettingsPeriod,
  setCplSettingsSelectedVillages,
} from '../../actions/cplFilters';
import { updateCplHistory } from '../../actions/cplHistory';
import { CplSettingsControls, ICplSettingsControlsProps } from '../../components/CplSettingsPage/CplSettingsControls';
import { ICplSettingsPageState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getDropdownValuesFromCplVillageData } from '../../selectors';
import { IDatePeriod } from '../../utils/getDatePeriod';
import { getDropdownValuesFromManagerList } from '../../selectors/getDropdownValuesFromManagerList';

export type TStoreProps = Pick<
  ICplSettingsControlsProps,
  | 'period'
  | 'selectedVillageIds'
  | 'villageDropdownValues'
  | 'managerId'
  | 'managerDropdownValues'
  | 'isMobile'
  | 'roles'
>;
export type TDispatchProps = Pick<
  ICplSettingsControlsProps,
  'onPeriodChange' | 'onVillageDropdownChange' | 'onManagerDropdownChange'
>;

export function mapStateToProps(state: ICplSettingsPageState): TStoreProps {
  const {
    filters: { period, villages, managerId },
    deviceType,
    roles,
  } = state;

  const villageDropdownValues = getDropdownValuesFromCplVillageData(state);
  const managerDropdownValues = getDropdownValuesFromManagerList(state);

  return {
    period,
    selectedVillageIds: villages,
    managerId,
    villageDropdownValues,
    managerDropdownValues,
    isMobile: deviceType !== 'desktop',
    roles,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    onPeriodChange: (value: IDatePeriod) => {
      dispatch(setCplSettingsPeriod(value));
      dispatch(updateCplData());
    },
    onVillageDropdownChange: (value: number[]) => {
      dispatch(setCplSettingsSelectedVillages(value));
      dispatch(updateCplHistory());
    },
    onManagerDropdownChange: (value: string) => {
      const newmanagerId = Number(value);
      dispatch(setCplSettingsManagerId(newmanagerId));
      dispatch(updateCplData(true));
    },
  };
}

export const CplSettingsControlsContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CplSettingsControls);
