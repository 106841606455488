import { TAdminConstructorCalls } from '../../types';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

/* Экшены обработки данных на странице списка звонков строителя (/contractor-calltracking) */

export enum EContractorCallsListDataActionTypes {
  SetData = 'CONTRACTOR_CALLS_LIST_DATA_SET_DATA',
}

export type TSetContractorCallsListData = ITypedReduxAction<
  EContractorCallsListDataActionTypes.SetData,
  TAdminConstructorCalls
>;

// Экшен для установки списка КП
export const setContractorCallsListData: (payload: TAdminConstructorCalls) => TSetContractorCallsListData =
  actionGenerator<EContractorCallsListDataActionTypes.SetData, TAdminConstructorCalls>(
    EContractorCallsListDataActionTypes.SetData,
  );
