import * as React from 'react';

import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { ICalltrackingBlackListModalData } from '../components/CalltrackingBlackListModal/CalltrackingBlackListModal';
import { blockPhone, unblockPhone } from '../http';
import { ICallListItemSchema } from '../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';

export interface IUseGetCallURLHook {
  call: ICallListItemSchema | null | undefined;
  httpApi: IHttpApi;
  logger: ILogger;
  callback(): void;
}

export enum ECalltrackingBlockState {
  Block = 'block',
  Unblock = 'unblock',
}

export function useCalltrackingBlacklistHook(params: IUseGetCallURLHook) {
  const { call, httpApi, logger, callback } = params;

  const [isBlocked, setBlockedState] = React.useState(!!call && !!call.blackListInfo);
  const [isPending, setPendingState] = React.useState(false);

  React.useEffect(() => {
    setBlockedState(Boolean(call && call.blackListInfo));
  }, [call]);

  const setBlockState = React.useCallback(
    async (action: ECalltrackingBlockState, data?: ICalltrackingBlackListModalData) => {
      if (!call || !call.sourcePhone) {
        return;
      }

      setPendingState(true);

      try {
        if (action === ECalltrackingBlockState.Block && data) {
          await blockPhone(httpApi, logger, {
            sourcePhone: call.sourcePhone,
            cause: data.cause,
          });

          setBlockedState(true);
        } else {
          await unblockPhone(httpApi, logger, {
            sourcePhone: call.sourcePhone,
          });
          setBlockedState(false);
        }

        callback();
        setPendingState(false);
      } catch (e) {
        setPendingState(false);
      }
    },
    [call],
  );

  return { isBlocked, isPending, setBlockState };
}
