import { ICallListItemSchema } from '../../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';
import {
  ERoleType,
  TYPE_ROLES_MAP,
  TYPE_CALL_MAP,
  TYPE_DROPDOWN_WITH_ICONS_MAP,
  mapButtonClass,
} from '../../constants/calltracking';
import { withRoles } from '../withRoles';
import { EChangerType, EType } from '../../repositories/countryside-calltracking/v1/admin-set-call-type/types';
import { IWithRoles } from '../withRoles/withRoles';
import { getMapValue } from '../../utils/formatting';
import { CustomSelectField } from '../CustomSelectField';
import { CalltrackingButton } from '../CalltrackingButton';
import { TCallType, TChangerType } from 'shared/types';

interface ICalltrackingTypeFieldOwnProps {
  type: ERoleType;
  changerType: EChangerType;
  setCalltrackingType(callId?: number | null, changerType?: TChangerType, type?: TCallType): void;
  call: ICallListItemSchema;
  disabled?: boolean;
  onlyIcon?: boolean;
  isHandheld?: boolean;
}

type TCalltrackingTypeFieldProps = ICalltrackingTypeFieldOwnProps & IWithRoles;

export function CalltrackingTypeFieldComponent(props: TCalltrackingTypeFieldProps) {
  const { call, roles, type, setCalltrackingType, changerType, onlyIcon, isHandheld } = props;
  /* роли расположены по приоритету, можно редактировать, если переданный тип роли существует и нет роли приоритетнее */
  const isEditable = Object.values(TYPE_ROLES_MAP).find(role => roles.includes(role)) === TYPE_ROLES_MAP[type];
  const key = TYPE_CALL_MAP[type];
  const value = call[key];

  return isEditable ? (
    <CustomSelectField
      value={value || ''}
      values={TYPE_DROPDOWN_WITH_ICONS_MAP[type]}
      innerButtonClass={getMapValue(mapButtonClass, value)}
      onChange={value => setCalltrackingType(call.callId, changerType, value as EType)}
      onlyIcon={onlyIcon}
      disabled={!call.isEditableType}
    />
  ) : (
    <CalltrackingButton isHandheld={isHandheld} onlyIcon={onlyIcon} value={value} disabled />
  );
}

export const CalltrackingTypeField = withRoles<ICalltrackingTypeFieldOwnProps>(CalltrackingTypeFieldComponent);
