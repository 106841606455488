enum Keycodes {
  Escape = 27,
}

export const onEscPressed =
  (fn?: Function) =>
  <T>(e: React.KeyboardEvent<T>) => {
    if (e.keyCode === Keycodes.Escape && typeof fn === 'function') {
      fn();
    }
  };
