import * as React from 'react';

import { IBuilderFilter, IRegion, ModerationStatusNames } from '../../../common/interfaces';
import { EModerationStatus } from '../../../repositories/countryside/entities/schemas/BuilderForListSchema';
import { dateToISO, toDate } from '../../../utils/datetime';
import { generateDropdown } from '../../../utils/dropdown';
import { MultiselectorField } from '../../multiselector_field';
import { SelectField } from '../../SelectField';
import { TextField } from '../../text_field';
import { DatePicker } from '../../DatePicker';

import * as styles from './BuilderFilter.css';
import { Button, Col, Row } from 'react-bootstrap';

const MODERATION_STATUSES = generateDropdown({
  ...ModerationStatusNames,
  '': 'Все',
});

interface IProps {
  regionList: IRegion[];
  filter: IBuilderFilter;
  onFilter(filters: IBuilderFilter): void;
}

interface IState {
  filters: IBuilderFilter;
}

export class BuilderFilter extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const filters = { ...this.props.filter };

    this.state = {
      filters: {
        createdFrom: dateToISO(filters.createdFrom),
        createdTo: dateToISO(filters.createdTo),
        id: filters.id,
        moderationStatus: filters.moderationStatus || EModerationStatus.Published,
        name: filters.name,
        regionList: filters.regionList,
        updatedFrom: dateToISO(filters.updatedFrom),
        updatedTo: dateToISO(filters.updatedTo),
      },
    };
  }

  public render() {
    const {
      createdFrom = '',
      createdTo = '',
      id = '',
      moderationStatus,
      name = '',
      regionList = [],
      updatedFrom = '',
      updatedTo = '',
    } = this.state.filters;

    const regions = this.props.regionList.map(region => ({ value: region.id, label: region.displayName }));

    return (
      <div className={styles['container']}>
        <Row>
          <Col md={1} className="mb-3">
            <TextField value={id} onChange={this.handleNumberInputChange('id')} label="ID" />
          </Col>
          <Col md={4} className="mb-3">
            <TextField value={name} label="Название" onChange={this.handleInputChange('name')} />
          </Col>
          <Col md={3} className="mb-3">
            <SelectField
              value={moderationStatus as string}
              values={MODERATION_STATUSES}
              label="Статус модерации"
              onChange={this.handleInputChange('moderationStatus')}
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(updatedFrom)}
              onChange={this.handleDateInputChange('updatedFrom')}
              label="Изменен от"
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(updatedTo)}
              onChange={this.handleDateInputChange('updatedTo')}
              label="Изменен до"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <MultiselectorField
              value={regionList}
              values={regions}
              onChange={this.handleInputChange('regionList')}
              invalid={false}
              showSelectButtons
              label="Регионы"
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(createdFrom)}
              onChange={this.handleDateInputChange('createdFrom')}
              label="Добавлен от"
            />
          </Col>
          <Col md={2} className="mb-3">
            <DatePicker
              placeholderText="ДД.ММ.ГГГГ"
              selected={toDate(createdTo)}
              onChange={this.handleDateInputChange('createdTo')}
              label="Добавлен до"
            />
          </Col>
          <Col md={12} className="text-end">
            <Button variant="primary" onClick={this.handleFiltering}>
              Отфильтровать
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  private handleFiltering = () => {
    const { filters } = this.state;

    const filtered = Object.keys(filters)
      .filter((key: keyof IBuilderFilter) => Boolean(filters[key]))
      .reduce((filter: IBuilderFilter, key: keyof IBuilderFilter) => {
        return {
          ...filter,
          [key]: filters[key],
        };
      }, {});

    this.props.onFilter(filtered);
  };

  private handleInputChange = (name: string) => (value: string | Array<string | number> | void) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        [name]: value,
      },
    });
  };

  private handleNumberInputChange = (name: string) => (value: string) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        [name]: parseInt(value, 10) || undefined,
      },
    });
  };

  private handleDateInputChange = (name: string) => (value: Date) => {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        [name]: value ? value.toString() : undefined,
      },
    });
  };
}
