import { mergeStyles } from '@cian/utils';
import * as styles from './PhoneLink.css';

interface IPhoneLinkProps {
  phone?: string | null;
  isBlocked?: boolean;
}

export function PhoneLink({ phone, isBlocked }: IPhoneLinkProps) {
  return phone ? (
    <a {...mergeStyles(isBlocked && styles['blocked'])} href={`tel:${phone}`} rel="noreferrer">
      {phone}
    </a>
  ) : null;
}
