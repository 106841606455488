import {
  ECommentFilter,
  EFinalType,
  EStatus,
  ETypeByBuilder,
  IAdminGetCallsRequest,
} from '../repositories/countryside-calltracking/v1/admin-get-calls/types';
import { generateDropdown, generateDropdownWithIcons } from '../utils/dropdown';
import { ERoles } from '../repositories/countryside/v2/get-user-role-list/types';
import { IDropdownValue } from '../components/SelectField';

export const mapStatusToLabel = {
  busy: 'Занято',
  hgup: 'Сбросили',
  noAnswer: 'Без ответа',
  success: 'Успешный',
  techProblem: 'Техническая ошибка',
  turnedOff: 'Выключен',
  unknown: 'Неизвестно',
};

export const mapStatusToIcon = {
  busy: 'fas fa-times',
  hgup: 'fas fa-times',
  noAnswer: 'fas fa-times',
  success: 'fas fa-check',
  techProblem: 'fas fa-times',
  turnedOff: 'fas fa-times',
  unknown: 'fas fa-times',
};

export const mapTypeToLabel = {
  nonTarget: 'Нецелевой',
  notSpecified: 'Не установлен',
  target: 'Целевой',
};

export const mapTypeToIcon = {
  nonTarget: 'fas fa-thumbs-down',
  notSpecified: 'fas fa-question',
  target: 'fas fa-thumbs-up',
};

export const mapUserTypeToLabel = {
  admin: 'Администратор',
  builder: 'Застройщик',
  final: 'Итог',
};

const CALLTRACKING_FILTERS_STATUS_LABELS_MAP: { [key: string]: string } = {
  [EStatus.Unknown]: 'Неизвестно',
  [EStatus.Busy]: 'Занято',
  [EStatus.Hgup]: 'Сбросили',
  [EStatus.NoAnswer]: 'Без ответа',
  [EStatus.Success]: 'Успешный',
  [EStatus.TechProblem]: 'Техническая ошибка',
  [EStatus.TurnedOff]: 'Выключен',
};

export const CALLTRACKING_FILTERS_TYPE_BY_BUILDER_LABELS_MAP: { [key: string]: string } = {
  [ETypeByBuilder.NonTarget]: 'Нецелевой',
  [ETypeByBuilder.NotSpecified]: 'Не установлен',
  [ETypeByBuilder.Target]: 'Целевой',
};

export const CALLTRACKING_FILTERS_TYPE_BY_FINAL_LABELS_MAP: { [key: string]: string } = {
  [EFinalType.NonTarget]: 'Нецелевой',
  [EFinalType.NotSpecified]: 'Не установлен',
  [EFinalType.Target]: 'Целевой',
};

export interface ISelectWithIcon {
  label: string;
  iconClass: string;
}

export const CALLTRACKING_FILTERS_TYPE_BY_BUILDER_WITH_ICONS_MAP: { [key: string]: ISelectWithIcon } = {
  [ETypeByBuilder.NonTarget]: {
    label: 'Нецелевой',
    iconClass: 'fas fa-thumbs-down',
  },
  [ETypeByBuilder.NotSpecified]: {
    label: 'Не установлен',
    iconClass: 'fas fa-question',
  },
  [ETypeByBuilder.Target]: {
    label: 'Целевой',
    iconClass: 'fas fa-thumbs-up',
  },
};

export const CALLTRACKING_FILTERS_TYPE_BY_FINAL_WITH_ICONS_MAP: { [key: string]: ISelectWithIcon } = {
  [EFinalType.NonTarget]: {
    label: 'Нецелевой',
    iconClass: 'fas fa-thumbs-down',
  },
  [EFinalType.Target]: {
    label: 'Целевой',
    iconClass: 'fas fa-thumbs-up',
  },
};

export const CALLTRACKING_BUTTON_TYPE_BY_FINAL_MAP: { [key: string]: ISelectWithIcon } = {
  [EFinalType.NonTarget]: {
    label: 'Нецелевой',
    iconClass: 'fas fa-thumbs-down',
  },
  [EFinalType.NotSpecified]: {
    label: 'Нецелевой',
    iconClass: 'fas fa-thumbs-down',
  },
  [EFinalType.Target]: {
    label: 'Целевой',
    iconClass: 'fas fa-thumbs-up',
  },
};

export const CALL_STATUS_DROPDOWN_VALUES = [
  { value: '', label: 'Любой' },
  ...generateDropdown(CALLTRACKING_FILTERS_STATUS_LABELS_MAP),
];

export const CALL_TYPE_BY_BUILDER_DROPDOWN_WITH_ICONS_VALUES = generateDropdownWithIcons(
  CALLTRACKING_FILTERS_TYPE_BY_BUILDER_WITH_ICONS_MAP,
);

export const CALL_TYPE_BY_FINAL_DROPDOWN_WITH_ICONS_VALUES = generateDropdownWithIcons(
  CALLTRACKING_FILTERS_TYPE_BY_FINAL_WITH_ICONS_MAP,
);

//здесь нужно оставить так как есть или что-то иное придумать

export const CALL_TYPE_BY_BUILDER_FILTER_DROPDOWN_VALUES = [
  { value: '', label: 'Любой' },
  ...CALL_TYPE_BY_BUILDER_DROPDOWN_WITH_ICONS_VALUES,
];

export const CALL_TYPE_BY_FINAL_FILTER_DROPDOWN_VALUES = [
  { value: '', label: 'Любой' },
  ...CALL_TYPE_BY_FINAL_DROPDOWN_WITH_ICONS_VALUES,
];

export const CALL_TYPE_BY_COMMENT = [
  { value: '', label: 'Любой' },
  { value: ECommentFilter.WithComment, label: 'Есть комментарии' },
  { value: ECommentFilter.WithoutComment, label: 'Без комментариев' },
];

export const DEFAULT_CALLTRACKING_FILTERS: IAdminGetCallsRequest = {
  page: 1,
  pageSize: 20,
};

export interface ICalltrackingListPlayListState {
  recordFilePath?: string | null;
  isPaused?: boolean;
}

export const DEFAULT_CALLTRACKING_LIST_PLAYLIST_STATE: ICalltrackingListPlayListState = {
  recordFilePath: undefined,
  isPaused: true,
};

export const CALLTRACKING_BUILDER_NO_OFFERS_MSG = 'Builder has not offers over 7 days';

export const CALLTRACKING_BUILDER_NO_ACCESS_MSG = 'User having role of builder could not see calls of other builders';

export enum ERoleType {
  Builder = 'builder',
  Final = 'final',
}

export const TYPE_ROLES_MAP: { [key in ERoleType]: ERoles } = {
  [ERoleType.Builder]: ERoles.CalltrackingBuilder,
  [ERoleType.Final]: ERoles.CalltrackingAdmin,
};

export const TYPE_CALL_MAP: { [key in ERoleType]: 'typeByBuilder' | 'finalType' } = {
  [ERoleType.Builder]: 'typeByBuilder',
  [ERoleType.Final]: 'finalType',
};

export const TYPE_DROPDOWN_WITH_ICONS_MAP: { [key in ERoleType]: IDropdownValue[] } = {
  [ERoleType.Builder]: CALL_TYPE_BY_BUILDER_DROPDOWN_WITH_ICONS_VALUES,
  [ERoleType.Final]: CALL_TYPE_BY_FINAL_DROPDOWN_WITH_ICONS_VALUES,
};

export const mapButtonClass = {
  nonTarget: 'danger',
  notSpecified: 'secondary',
  target: 'success',
};
