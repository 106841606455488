import { IHttpApi, IHttpApiFetchConfig, IModelResponse } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import { getFormattedError } from '../utils';
import { isResponseContainsKey } from './helpers';

export interface IHttpApiRequest<TRequestParams> {
  httpApi: IHttpApi;
  parameters: TRequestParams;
  config?: IHttpApiFetchConfig;
}

export const createSimpleRequest =
  <T, S, E extends object>(
    requestName: string,
    request: (request: IHttpApiRequest<T>) => Promise<IModelResponse<S> | IModelResponse<E>>,
  ) =>
  async (httpApi: IHttpApi, logger: ILogger, parameters: T = {} as T, logErrorAsWarning?: boolean) => {
    try {
      const { response, statusCode } = await request({ httpApi, parameters });

      if (statusCode === 400 || isResponseContainsKey<E, S>(response, 'errors')) {
        throw response;
      }

      return response;
    } catch (error) {
      const formattedError = getFormattedError(error);

      if (error instanceof HttpTimeoutError) {
        const meta = { message: error.message, stackTrace: error.stack };
        logger.warning(`TimeoutError in ${requestName}`, meta);

        throw error;
      } else if (logErrorAsWarning && !(error instanceof Error)) {
        const meta = { message: error.message };
        logger.warning(`Error in ${requestName}`, meta);
      } else {
        logger.error(formattedError, { message: `Error in ${requestName}` });
      }

      throw formattedError;
    }
  };
