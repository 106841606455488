/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TUploadImageModel,
  IUploadImageRequest,
  IMappers,
  IUploadImageResponse,
  IUploadImageResponseError,
  TUploadImageResponse,
} from './types';

const defaultConfig: TUploadImageModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/upload-image/',
  hostType: 'api',
} as TUploadImageModel;

function createUploadImageModel(parameters: IUploadImageRequest): TUploadImageModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IUploadImageOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IUploadImageRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchUploadImage<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IUploadImageOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TUploadImageResponse> {
  const { response, statusCode } = await httpApi.fetch(createUploadImageModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IUploadImageResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IUploadImageResponseError);
    }
  }

  return { response, statusCode } as TUploadImageResponse;
}

export { defaultConfig, createUploadImageModel, fetchUploadImage };
