/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBuilderChangelogModel,
  IGetBuilderChangelogRequest,
  IMappers,
  IGetBuilderChangelogResponse,
  IGetBuilderChangelogResponseError,
  TGetBuilderChangelogResponse,
} from './types';

const defaultConfig: TGetBuilderChangelogModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside',
  pathApi: '/v1/get-builder-changelog/',
  hostType: 'api',
} as TGetBuilderChangelogModel;

function createGetBuilderChangelogModel(parameters: IGetBuilderChangelogRequest): TGetBuilderChangelogModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBuilderChangelogOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBuilderChangelogRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBuilderChangelog<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBuilderChangelogOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetBuilderChangelogResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetBuilderChangelogModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBuilderChangelogResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBuilderChangelogResponseError);
    }
  }

  return { response, statusCode } as TGetBuilderChangelogResponse;
}

export { defaultConfig, createGetBuilderChangelogModel, fetchGetBuilderChangelog };
