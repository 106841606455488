import { getCplSettingsHistory } from '../../http';
import { ICplSettingsPageState } from '../../types/redux';
import { IThunkActionCreator } from '../../types/thunk';
import { getDisplayError } from '../../utils/getDisplayError';
import { updateCplSettingsBrowserUrl } from '../../utils/urinator';
import { setCplHistory, setCplHistoryVillage } from './actions';
import { NotificationManager } from '../../utils/showNotification';

// thunk-action для обновления данных CPL
export function updateCplHistory(villageId?: number): IThunkActionCreator<ICplSettingsPageState> {
  return async (dispatch, getState, { httpApi, logger }) => {
    const {
      filters: { villages },
      cplHistory: { selectedVillageId: currentSelectedVillageId },
    } = getState();

    let selectedVillageId = villageId || currentSelectedVillageId;

    if (villages.length && !villages.includes(selectedVillageId)) {
      selectedVillageId = villages[0];
    }

    dispatch(setCplHistoryVillage(selectedVillageId));

    if (!selectedVillageId) {
      updateCplSettingsBrowserUrl(getState());

      return;
    }

    try {
      const data = await getCplSettingsHistory(httpApi, logger, {
        villageId: selectedVillageId,
      });

      dispatch(setCplHistory(data));
      updateCplSettingsBrowserUrl(getState());
    } catch (e) {
      dispatch(
        setCplHistory({
          history: [],
        }),
      );

      const displayError = getDisplayError(e);
      NotificationManager.error(displayError);
    }
  };
}
