import * as React from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';

import { AppContext } from '../../../AppContext';
import { getCianUserServiceUrl, getOrderServiceUrl } from '../../../utils/getServiceUrl';
import { WrapLink } from '../../WrapLink/WrapLink';

interface IUserAndOrderIdsProps {
  cianUserId?: number | null;
  orderId?: number | null;
}

export const UserAndOrderIds = ({ cianUserId, orderId }: IUserAndOrderIdsProps) => {
  const { config } = React.useContext(AppContext);
  const cianUserServiceUrl = getCianUserServiceUrl(cianUserId, config);
  const orderServiceUrl = getOrderServiceUrl(orderId, config);

  return (
    <Row>
      {cianUserId && (
        <Col md={6} className="mb-3">
          <FormLabel>Номер ЛК</FormLabel>
          <br />
          <WrapLink href={cianUserServiceUrl}>{cianUserId}</WrapLink>
        </Col>
      )}
      {orderId && (
        <Col md={6} className="mb-3">
          <FormLabel>Номер заказа</FormLabel>
          <br />
          <WrapLink href={orderServiceUrl}>{orderId}</WrapLink>
        </Col>
      )}
    </Row>
  );
};
