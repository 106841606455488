import {
  fetchGetVillageChangelog,
  IGetVillageChangelogRequest,
  IGetVillageChangelogResponse,
  IGetVillageChangelogResponseError,
} from '../../node/repositories/countryside/v1/get-village-changelog';

import { createSimpleRequest } from './createSimpleRequest';

export const getVillageChangelog = createSimpleRequest<
  IGetVillageChangelogRequest,
  IGetVillageChangelogResponse,
  IGetVillageChangelogResponseError
>('getVillageChangelog', fetchGetVillageChangelog);
