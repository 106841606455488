import * as React from 'react';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { handleUnicodeAndUnprintableChars, THandler } from '../../utils/handle_unicode_and_unprintable_chars';
import { getValueForTextField } from '../../utils/formatting';

import * as styles from './TextField.css';
import { Form } from 'react-bootstrap';

export interface ITextFieldProps {
  value: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
  onChange: THandler;
  inputClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  invalid?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  helpText?: string;
  type?: string;
  containerClass?: string;
}

export const TextField = (props: ITextFieldProps) => {
  const {
    required,
    onChange,
    label,
    invalid,
    inputClass,
    htmlId,
    helpText,
    value,
    containerClass,
    // tslint:disable-next-line
    ...other
  } = props;

  return (
    <Form.Group {...mergeStyles(containerClass, styles['text-field'])}>
      {label && (
        <Form.Label>
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <Form.Control
        {...mergeStyles(inputClass, invalid && styles['invalid'])}
        onChange={handleUnicodeAndUnprintableChars(onChange)}
        id={htmlId}
        value={getValueForTextField(value)}
        {...other}
      />
      {helpText && <div className={styles['tooltip']}>{helpText}</div>}
    </Form.Group>
  );
};
