export function prettifySeconds(duration: number | null | undefined) {
  let hour = 0;
  let min = 0;
  let sec = 0;

  if (duration) {
    if (duration >= 60) {
      min = Math.floor(duration / 60);
      sec = duration % 60;
    } else {
      sec = duration;
    }

    if (min >= 60) {
      hour = Math.floor(min / 60);
      min = min - hour * 60;
    }
  }

  return [hour < 10 ? `0${hour}` : hour, min < 10 ? `0${min}` : min, sec < 10 ? `0${sec}` : sec].join(':');
}
