import { ErrorLogComponent } from '@cian/error-log-component';
import { Container } from 'react-bootstrap';
import { ApplicationContext } from 'shared/common/utils/applicationContext';
import { NavBarContainer } from '../NavBarContainer';
import { IApplicationCommonProps } from 'shared/common/types/application';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

export const ApplicationLayout: FC<PropsWithChildren<IApplicationCommonProps>> = ({
  context,
  children,
  reduxStore,
}) => {
  const { logger } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <Provider store={reduxStore}>
          <NavBarContainer />
          <Container>{children}</Container>
        </Provider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
