import { mergeStyles } from '@cian/utils';

import { IGalleryItem } from '../../../common/interfaces';
import { TextAreaField } from '../../textarea_field';

import * as styles from '../Gallery.css';

export interface IGalleryImageProps {
  item: IGalleryItem;
  onRemove(): void;
  onDescriptionChange(value: string): void;
  areInputsDisplayed?: boolean;
  maxWidth?: number | string;
  maxHeight?: number | string;
  maxInputLength?: number;
  readOnly?: boolean;
}

export const GalleryImage = ({
  areInputsDisplayed,
  item: { url, description = '' },
  onRemove,
  onDescriptionChange,
  maxInputLength,
  maxHeight,
  maxWidth,
  readOnly,
}: IGalleryImageProps) => (
  <div className={styles['image-container']}>
    <div className={styles['preview-inline']}>{url && <img style={{ maxWidth, maxHeight }} src={url} />}</div>
    {!readOnly && (
      <button {...mergeStyles('btn btn-danger btn-xs', styles['remove-btn'])} onClick={onRemove}>
        <i className="fas fa-times" />
      </button>
    )}
    {areInputsDisplayed && (
      <TextAreaField
        value={description || ''}
        onChange={onDescriptionChange}
        placeholder="Описание фотографии"
        maxLength={maxInputLength}
        inputClass={styles['description']}
        required={false}
        containerClass={styles['description-wrapper']}
        readOnly={readOnly}
      />
    )}
  </div>
);
