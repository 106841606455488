import * as React from 'react';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  DragEndEvent,
  UniqueIdentifier,
  PointerSensorOptions,
} from '@dnd-kit/core';

import { IStyleConfig, mergeStyles } from '@cian/utils';

import { reorderList } from '../../utils/dnd';

import * as styles from './SortingComponent.css';

export interface ISortingItems {
  [key: string]: React.ReactNode;
}

interface ISortableElementProps {
  id: UniqueIdentifier;
  value: React.ReactNode;
  itemStyle?: IStyleConfig;
}

interface ISortEnd {
  oldIndex: number;
  newIndex: number;
}

interface ISortableContainerProps {
  items: ISortingItems;
  containerStyle?: IStyleConfig;
  itemStyle?: IStyleConfig;
  onSortEnd: (args: ISortEnd) => void;
  readOnly?: boolean;
}

const SortableItem: React.FC<ISortableElementProps> = ({ id, value, itemStyle }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} {...mergeStyles(styles['dnd-item'], itemStyle, style)}>
      {value}
    </div>
  );
};

const DISABLED_ELEMENTS = ['input', 'textarea', 'select', 'option', 'button', 'i'];

interface ICustomPointerSensorOptions extends PointerSensorOptions {
  isDraggableElement: (element: Element) => boolean;
}

class MyPointerSensor extends PointerSensor {
  public static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: (
        { nativeEvent: event }: React.PointerEvent<Element>,
        { isDraggableElement }: ICustomPointerSensorOptions,
      ) => isDraggableElement(event.target as Element),
    },
  ];
}

const SortableList: React.FC<ISortableContainerProps> = ({ items, itemStyle, containerStyle, onSortEnd, readOnly }) => {
  const identifiers = Object.keys(items);

  const isDraggableElement = React.useCallback(
    (element: Element) => {
      return (
        !readOnly &&
        !DISABLED_ELEMENTS.includes(element.tagName.toLowerCase()) &&
        !element.getAttribute('data-disable-drag')
      );
    },
    [readOnly],
  );

  const sensors = useSensors(useSensor<ICustomPointerSensorOptions>(MyPointerSensor, { isDraggableElement }));

  const getIndex = React.useCallback((id: UniqueIdentifier) => identifiers.findIndex(i => i === id), [identifiers]);

  const handleDragEnd = React.useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const oldIndex = getIndex(active.id);
        const newIndex = getIndex(over.id);

        onSortEnd({
          oldIndex,
          newIndex,
        });
      }
    },
    [getIndex, onSortEnd],
  );

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={identifiers} strategy={verticalListSortingStrategy}>
        <div {...mergeStyles(containerStyle)}>
          {identifiers.map(key => (
            <SortableItem key={key} id={key} value={items[key]} itemStyle={itemStyle} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export enum ESortingDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

interface ISortingComponentProps<T> {
  items: ISortingItems;
  list: T[];
  onChange(value: T[]): void;
  direction?: ESortingDirection;
  containerStyle?: IStyleConfig;
  itemStyle?: IStyleConfig;
  readOnly?: boolean;
}

export function SortingComponent<T>({
  containerStyle,
  direction,
  itemStyle,
  items,
  list,
  onChange,
  readOnly,
}: ISortingComponentProps<T>) {
  const onSortEnd = React.useCallback(
    ({ oldIndex, newIndex }: ISortEnd) => {
      onChange(reorderList(list, oldIndex, newIndex));
    },
    [list, onChange],
  );

  return (
    <SortableList
      items={items}
      onSortEnd={onSortEnd}
      containerStyle={mergeStyles(
        styles['dnd'],
        direction === ESortingDirection.Horizontal && styles['dnd--horizontal'],
        containerStyle,
      )}
      itemStyle={itemStyle}
      readOnly={readOnly}
    />
  );
}
