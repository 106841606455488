import { TCplListItem } from '../types';
import { calculateCplFields } from './calculateCplFields';

export function getSummaryOfCplList(list: TCplListItem[]) {
  const summary = {
    planSum: 0,
    chargeableCallsCountSum: 0,
    callsBalanceSum: 0,
    nonChargeableCallsCountSum: 0,
    budgetSum: 0,
    budgetSpentSum: 0,
    budgetBalanceSum: 0,
  };

  return list.reduce((acc, item) => {
    const { callsBalance, budgetSpent, budgetBalance } = calculateCplFields(item);

    return {
      planSum: acc.planSum + (item.plan || 0),
      chargeableCallsCountSum: acc.chargeableCallsCountSum + item.chargeableCallsCount,
      callsBalanceSum: acc.callsBalanceSum + callsBalance,
      nonChargeableCallsCountSum: acc.nonChargeableCallsCountSum + item.nonChargeableCallsCount,
      budgetSum: acc.budgetSum + (item.budget || 0),
      budgetSpentSum: acc.budgetSpentSum + budgetSpent,
      budgetBalanceSum: acc.budgetBalanceSum + budgetBalance,
    };
  }, summary);
}
