export function getPagesList(pageCount: number, activePage: number, maxPagesToView: number = 7): number[] {
  const array = Array.from(Array(pageCount), (_, index) => index + 1);
  const ceil = Math.ceil(maxPagesToView / 2);
  const firstElementAtLastPageViewIndex = pageCount - maxPagesToView;

  const startIndex =
    activePage <= firstElementAtLastPageViewIndex ? array.indexOf(activePage) - ceil : firstElementAtLastPageViewIndex;

  return array.splice(Math.max(startIndex, 0), maxPagesToView);
}
