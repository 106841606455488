import {
  TCallType,
  TChangerType,
  ICallsPopup,
  IContractorCallsPlayerStatus,
  TConstructorCall,
} from '../../../../types';

import { ContractorCallPopupDesktopContainer } from '../../../../containers/ContractorCallPopupDesktop';
import { ContractorCallsTableRow } from '../ContractorCallsTableRow';
import { Table } from 'react-bootstrap';
import { HEADERS } from './constants';

export interface IContractorCallsTableProps {
  contractorCallsList: TConstructorCall[];
  // regionList: IDropdownValue[];
  callPopup: ICallsPopup;
  playerStatus: IContractorCallsPlayerStatus;
  onRowClick(call: TConstructorCall, isOpen: boolean, recordFilePath: string | undefined, isPaused: boolean): void;
  onCallTypeChange(callId?: number, changerType?: TChangerType, type?: TCallType): void;
}

export function ContractorCallsTable({
  contractorCallsList,
  callPopup,
  playerStatus,
  onRowClick,
  onCallTypeChange,
}: IContractorCallsTableProps) {
  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            {HEADERS.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contractorCallsList.map(call => {
            const { callId } = call;
            const isPaused = callPopup.callId === callId && !playerStatus.isPaused;

            return (
              <ContractorCallsTableRow
                key={callId}
                call={call}
                isPaused={isPaused}
                onRowClick={onRowClick}
                onCallTypeChange={onCallTypeChange}
              />
            );
          })}
        </tbody>
      </Table>
      <ContractorCallPopupDesktopContainer />
    </>
  );
}
