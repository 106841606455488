import * as React from 'react';
import { Provider } from 'react-redux';

import { IContractorCalltrackingListPageState, TReduxStore } from '../../../types/redux';
import { ContractorCalltrackingListContainer } from '../../../containers/ContractorCalltrackingListContainer';

import { updateContractorCalltrackingPageBrowserUrl } from '../../../utils/urinator';
import { NotificationContainer } from '../../../utils/showNotification';

export interface IContractorCalltrackingPageProps {
  store: TReduxStore<IContractorCalltrackingListPageState>;
}

export function ContractorCalltrackingListPage(props: IContractorCalltrackingPageProps) {
  const { store } = props;

  React.useEffect(() => {
    updateContractorCalltrackingPageBrowserUrl(store.getState());
  }, []);

  return (
    <Provider store={store}>
      <ContractorCalltrackingListContainer />
      <NotificationContainer />
    </Provider>
  );
}
