import { ICplEventDefinition } from '../common/interfaces';
import { EEvent } from '../repositories/countryside-calltracking/entities/cpl_settings_history/CPLSettingsHistoryItemSchema';

export const CplEventDefinitions: { [key: string]: ICplEventDefinition } = {
  [EEvent.BudgetReplenishment]: { name: 'Пополнение бюджета', status: 'positive', isAmount: true, prefix: '+' },
  [EEvent.BudgetWriteOff]: { name: 'Списание бюджета', status: 'negative', isAmount: true },
  [EEvent.CallsForecast]: { name: 'Прогноз звонков' },
  [EEvent.CallsReceived]: { name: 'Получено платных звонков' },
  [EEvent.CplChange]: { name: 'Изменение CPL', status: 'info', isAmount: true },
};

export const NOT_FOUND_BUILDER_MSG = 'cannot get builder';
export const NOT_FOUND_MANAGER_MSG = 'cannot get manager';

export const CPL_BUILDER_NO_ACCESS_MSG = 'User having role of builder could not see cpl settings of other builders';

export const mapErrorMessages: { [key: string]: string } = {
  NOT_FOUND_BUILDER_MSG,
  NOT_FOUND_MANAGER_MSG,
  CPL_BUILDER_NO_ACCESS_MSG,
};
