import {
  IGetCplSettingsHistoryRequest,
  IGetCplSettingsHistoryResponse,
  IGetCplSettingsHistoryResponseError,
  fetchGetCplSettingsHistory,
} from '../repositories/countryside-calltracking/v1/get-cpl-settings-history';
import { createSimpleRequest } from './createSimpleRequest';

export const getCplSettingsHistory = createSimpleRequest<
  IGetCplSettingsHistoryRequest,
  IGetCplSettingsHistoryResponse,
  IGetCplSettingsHistoryResponseError
>('getCplSettingsHistory', fetchGetCplSettingsHistory);
