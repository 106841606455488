import { configureStore } from '@reduxjs/toolkit';
import { IApplicationContext } from 'shared/common/types/applicationContext';
import { ERuntimeSettings } from 'shared/common/types/runtimeSettings';

export function createAuthStore(context: IApplicationContext, preloadedState: object, debugEnabled: boolean) {
  const projectName = context.config.getStrict<string>(ERuntimeSettings.projectName);

  return configureStore({
    devTools: debugEnabled ? { name: projectName } : false,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: context,
        },
      }),
    preloadedState,
    reducer: state => state,
  });
}
