import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { HttpTimeoutError } from '@cian/peperrors/shared';
import { getFormattedError } from '../../utils';
import {
  fetchAdminSetConstructorCallType,
  IAdminSetConstructorCallTypeRequest,
  TAdminSetConstructorCallTypeResponse,
} from 'shared/repositories/countryside-calltracking/v1/admin/set-constructor-call-type';
import { TCallType, TChangerType } from 'shared/types';
import { ResponseError } from 'shared/errors';

interface IParams {
  callId: number;
  callType: TCallType;
  changerType: TChangerType;
}

export async function setConstructorCallType(httpApi: IHttpApi, logger: ILogger, parameters: IParams): Promise<void> {
  try {
    const { response, statusCode }: TAdminSetConstructorCallTypeResponse = await fetchAdminSetConstructorCallType({
      httpApi,
      // приводит string к enum
      parameters: parameters as IAdminSetConstructorCallTypeRequest,
    });

    if (statusCode !== 200) {
      throw new ResponseError({
        domain: 'setConstructorCallType',
        message: response.message || 'Unknown error',
        details: {
          errors: JSON.stringify(response.errors),
        },
      });
    }
  } catch (error) {
    if (error instanceof HttpTimeoutError) {
      const meta = { message: error.message, stackTrace: error.stack };
      logger.error('TimeoutError in setConstructorCallType', meta);
    } else {
      const formattedError = getFormattedError(error);
      logger.error(formattedError, { message: 'Error in setConstructorCallType' });

      throw formattedError;
    }
  }
}
