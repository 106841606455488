import * as React from 'react';
import { IStyleConfig, mergeStyles } from '@cian/utils';
import { onEscPressed } from '../../utils/handlers';
import * as styles from './BasicModal.css';
import { Button } from 'react-bootstrap';

export interface IBasicModalProps {
  header: string;
  children: React.ReactNode;
  autoWidth?: boolean;
  modalStyles?: IStyleConfig;
  onConfirm?(): void;
  onClose(): void;
}

export function BasicModal({ onClose, onConfirm, header, children, autoWidth, modalStyles }: IBasicModalProps) {
  return (
    <div
      {...mergeStyles(autoWidth && styles['container--auto_width'], styles['container'])}
      tabIndex={0}
      onKeyDown={onEscPressed(onClose)}
    >
      <div className={styles['overlay']} onClick={onClose} />
      <div className={styles['modal-container']}>
        <div className={styles['modal']}>
          <h4 {...mergeStyles('text-center', styles['modal-header'])}>{header}</h4>
          <div {...mergeStyles(styles['modal-content'], modalStyles)}>{children}</div>
          <div {...mergeStyles('text-center', styles['submit-controls'])}>
            <Button variant="outline-secondary" type="button" onClick={onClose}>
              Отмена
            </Button>
            {onConfirm && (
              <Button type="button" onClick={onConfirm}>
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
