import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { setBudget, setCpl, setPlan } from '../http';
import { ISetBudgetResponse } from '../repositories/countryside-calltracking/v1/set-budget';
import { ISetCplResponse } from '../repositories/countryside-calltracking/v1/set-cpl';
import { ISetPlanResponse } from '../repositories/countryside-calltracking/v1/set-plan';
import { TChangableCplField } from '../types';

export interface ICplRequestParam {
  plan?: number;
  cpl?: number;
  budget?: number;
  cplSettingsId: number;
}

const CPL_FIELD_REQUEST_MAP = {
  budget: setBudget,
  cpl: setCpl,
  plan: setPlan,
};

type RequestFunction = (
  httpApi: IHttpApi,
  logger: ILogger,
  params: ICplRequestParam,
) => Promise<ISetBudgetResponse | ISetCplResponse | ISetPlanResponse>;

export function getCplRequest(field: keyof TChangableCplField): RequestFunction {
  return CPL_FIELD_REQUEST_MAP[field] as RequestFunction;
}
