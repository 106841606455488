import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ELocationType } from '../../../../node/repositories/countryside/v1/get-village/types';

import { AppContext } from '../../../AppContext';

import { IAlert, IBuilderNameListItem, TVillageCreateModel } from '../../../common/interfaces';
import { createVillage } from '../../../http';
import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { ICreateVillageRequest } from '../../../repositories/countryside/v1/create-village';
import { EDIT_VILLAGE_ROUTE } from '../../../routes';
import { DEFAULT_ALERT, MAX_PRICE } from '../../../utils/constants';
import { isUrlValid } from '../../../utils/urlValidator';
import { Alert } from '../../Alert';
import { VillageForm } from '../village_form';

import * as styles from './index.css';

interface IProps {
  builderNames: IBuilderNameListItem[];
  managerList: IManagerForListSchema[];
  fatCardEnabled?: boolean;
}

interface IState {
  form: TVillageCreateModel;
  useValidation: boolean;
  alert: IAlert;
}

export class VillageCreateForm extends React.Component<IProps, IState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public constructor(props: IProps) {
    super(props);

    const currentYear = new Date().getFullYear();

    const defaultVillage: TVillageCreateModel = {
      areaHectare: 0,
      description: '',
      displayBuilderPhone: false,
      finishYearMax: currentYear,
      finishYearMin: currentYear,
      hidePrice: false,
      isVisible: true,
      locationId: 0,
      locationType: ELocationType.House,
      moderationStatus: 'draft',
      name: '',
      readyStatus: 'building',
      regionId: 0,
      saleStatus: 'pending',
      siteUrl: '',
      totalStatus: 'pending',
      villageClass: 'economy',
    };
    this.state = {
      alert: DEFAULT_ALERT,
      form: defaultVillage,
      useValidation: false,
    };
  }

  public componentDidUpdate(_: IProps, prevState: IState) {
    if (!prevState.alert.isVisible && this.state.alert.isVisible) {
      setTimeout(() => {
        this.setState({
          alert: DEFAULT_ALERT,
        });
      }, 2000);
    }
  }

  public render() {
    const { alert, form, useValidation } = this.state;
    const { builderNames, managerList, fatCardEnabled } = this.props;

    return (
      <div>
        <div className={styles['container']}>
          {alert.isVisible && <Alert onClick={this.hideAlert} text={alert.text} type={alert.type} />}
          <VillageForm
            builderNames={builderNames}
            managerList={managerList}
            handleFieldChange={this.handleFieldChange}
            handleAddressFieldChange={this.handleAddressFieldChange}
            handleCplFieldChange={this.handleCplFieldChange}
            village={form}
            useValidation={useValidation}
            isCreation={true}
            handleSaveButtonClick={this.submitVillage}
            handleShowPhoneChange={this.handleShowPhoneChange}
            handleIsVisibleChange={this.handleIsVisibleChange}
            fatCardEnabled={fatCardEnabled}
          />
        </div>
        <Row>
          <Col md={12} className="text-end">
            <Button type="submit" onClick={this.submitVillage} className={styles['save-btn']} variant="success">
              Сохранить
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  private hideAlert = () =>
    this.setState({
      alert: {
        ...this.state.alert,
        isVisible: false,
      },
    });

  private handleFieldChange = (name: string, value: string | number | void) => {
    const { form } = this.state;

    this.setState({
      form: { ...form, [name]: value },
    });
  };

  private handleCplFieldChange = (value: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        isCpl: value,
        autodisableOrder: value && form.autodisableOrder,
      },
    });
  };

  private handleAddressFieldChange = (value: { address: number; regionId: number; locationType: ELocationType }) => {
    const { form } = this.state;
    const { address, regionId, locationType } = value;

    this.setState({
      form: {
        ...form,
        locationId: address,
        regionId,
        locationType: locationType ? (locationType.toLowerCase() as ELocationType) : ELocationType.Location,
      },
    });
  };

  private handleShowPhoneChange = (checked: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        displayBuilderPhone: checked,
      },
    });
  };

  private handleIsVisibleChange = (checked: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        isVisible: !checked,
      },
    });
  };

  private submitVillage = async () => {
    const { form } = this.state;
    const { httpApi, logger } = this.context;

    if (this.isFormValid()) {
      const village: TVillageCreateModel = { ...form };

      try {
        const { id } = await createVillage(httpApi, logger, village as ICreateVillageRequest);
        window.location.href = EDIT_VILLAGE_ROUTE.replace(':id', id.toString());
      } catch (error) {
        this.setState({
          alert: {
            isVisible: true,
            text: 'При создании коттеджного посёлка произошла ошибка',
            type: 'danger',
          },
        });
      }
    } else {
      this.setState({ useValidation: true });
    }
  };

  private isFormValid = () => {
    const { form } = this.state;
    const { name, description, siteUrl, areaHectare, builderId, locationId, finishYearMax, finishYearMin } = form;
    const floorCountMin = form.floorCountMin || -99;
    const floorCountMax = form.floorCountMax || 99;
    const priceMin = form.priceMin || 0;
    const priceMax = form.priceMax || MAX_PRICE;

    return (
      name &&
      description &&
      isUrlValid(siteUrl) &&
      areaHectare &&
      builderId &&
      locationId &&
      finishYearMin <= finishYearMax &&
      floorCountMin <= floorCountMax &&
      priceMin <= priceMax
    );
  };
}
