import * as React from 'react';

import { AppContext } from '../../../AppContext';
import {
  IGalleryItem,
  INewObjectCategorySet,
  INewObjectCategorySetItem,
  TVillageObject,
} from '../../../common/interfaces';
import { uploadImage } from '../../../http';
import { IFatIcon } from '../../../types/fat';
import { getObjectSetItemTemplate, isObjectSetItemValid, isObjectSetValid } from '../../../utils';
import { IFatInfrastructureModalData } from '../../FatInfrastructureModal/FatInfrastructureModal';
import { IImage } from '../../image_selector';
import { VillageObject } from './VillageObject';

import * as styles from './VillageObjects.css';
import { Button, Container } from 'react-bootstrap';

interface IProps {
  onChange(objectSet: INewObjectCategorySet, isDataValid: boolean): void;
  onInit(objectSet: INewObjectCategorySet, isDataValid: boolean): void;
  objectSet: INewObjectCategorySet;
  fatIconList: IFatIcon[];
  readOnly?: boolean;
  fatCardEnabled?: boolean;
}

interface IState {
  isDataValid: boolean;
  data: INewObjectCategorySet;
}

export class VillageObjects extends React.Component<IProps, IState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public state = {
    data: this.props.objectSet,
    isDataValid: isObjectSetValid(this.props.objectSet),
  };

  public componentDidMount() {
    const { data } = this.state;

    this.props.onInit(data, isObjectSetValid(data));
  }

  public render() {
    const { data } = this.state;
    const { readOnly, fatIconList, fatCardEnabled } = this.props;
    const { cottage, landplot, lowrisehouse, townhouse } = data;

    return (
      <Container fluid className={styles['container']}>
        <div className={styles['village-object']}>
          {cottage ? (
            <VillageObject
              title="Коттеджи"
              objectSetItem={cottage}
              isDataValid={isObjectSetItemValid(cottage)}
              handleImageChange={this.handleImageChange('cottage')}
              handleFieldChange={this.handleEntityFieldChange('cottage')}
              handleFatFieldsChange={this.handleEntityFatFieldsChange('cottage')}
              handleDeleteObjectItem={this.setObjectSet('cottage', undefined)}
              handleAdditionalGalleryChange={this.handleAdditionalGalleryChange('cottage')}
              readOnly={readOnly}
              fatIconList={fatIconList}
              fatCardEnabled={fatCardEnabled}
            />
          ) : (
            !readOnly && (
              <Button
                variant="success"
                className={styles['add-btn']}
                type="button"
                onClick={this.setObjectSet('cottage', getObjectSetItemTemplate())}
              >
                Добавить коттеджи
              </Button>
            )
          )}
        </div>
        <div className={styles['village-object']}>
          {landplot ? (
            <VillageObject
              title="Участки"
              objectSetItem={landplot}
              isDataValid={isObjectSetItemValid(landplot)}
              handleImageChange={this.handleImageChange('landplot')}
              handleFieldChange={this.handleEntityFieldChange('landplot')}
              handleFatFieldsChange={this.handleEntityFatFieldsChange('landplot')}
              handleDeleteObjectItem={this.setObjectSet('landplot', undefined)}
              handleAdditionalGalleryChange={this.handleAdditionalGalleryChange('landplot')}
              measureType="сотки"
              readOnly={readOnly}
              fatIconList={fatIconList}
              fatCardEnabled={fatCardEnabled}
            />
          ) : (
            !readOnly && (
              <Button
                variant="success"
                className={styles['add-btn']}
                type="button"
                onClick={this.setObjectSet('landplot', getObjectSetItemTemplate())}
              >
                Добавить участки
              </Button>
            )
          )}
        </div>
        <div className={styles['village-object']}>
          {lowrisehouse ? (
            <VillageObject
              title="Малоэтажные дома"
              objectSetItem={lowrisehouse}
              isDataValid={isObjectSetItemValid(lowrisehouse)}
              handleImageChange={this.handleImageChange('lowrisehouse')}
              handleFieldChange={this.handleEntityFieldChange('lowrisehouse')}
              handleFatFieldsChange={this.handleEntityFatFieldsChange('lowrisehouse')}
              handleDeleteObjectItem={this.setObjectSet('lowrisehouse', undefined)}
              handleAdditionalGalleryChange={this.handleAdditionalGalleryChange('lowrisehouse')}
              readOnly={readOnly}
              fatIconList={fatIconList}
              fatCardEnabled={fatCardEnabled}
            />
          ) : (
            !readOnly && (
              <Button
                variant="success"
                className={styles['add-btn']}
                type="button"
                onClick={this.setObjectSet('lowrisehouse', getObjectSetItemTemplate())}
              >
                Добавить малоэтажные дома
              </Button>
            )
          )}
        </div>
        <div className={styles['village-object']}>
          {townhouse ? (
            <VillageObject
              title="Таунхаусы"
              objectSetItem={townhouse}
              isDataValid={isObjectSetItemValid(townhouse)}
              handleImageChange={this.handleImageChange('townhouse')}
              handleFieldChange={this.handleEntityFieldChange('townhouse')}
              handleFatFieldsChange={this.handleEntityFatFieldsChange('townhouse')}
              handleDeleteObjectItem={this.setObjectSet('townhouse', undefined)}
              handleAdditionalGalleryChange={this.handleAdditionalGalleryChange('townhouse')}
              readOnly={readOnly}
              fatIconList={fatIconList}
              fatCardEnabled={fatCardEnabled}
            />
          ) : (
            !readOnly && (
              <Button
                variant="success"
                className={styles['add-btn']}
                type="button"
                onClick={this.setObjectSet('townhouse', getObjectSetItemTemplate())}
              >
                Добавить таунхаусы
              </Button>
            )
          )}
        </div>
      </Container>
    );
  }

  private handleImageChange = (entity: string) => (image: IImage, index: number) => {
    const { httpApi, logger } = this.context;
    const { data } = this.state;
    const newEntity = Object.assign({}, data[entity as TVillageObject]);
    const { gallery } = newEntity;

    if (!gallery.imageList) {
      return;
    }

    if (image.base64) {
      uploadImage(httpApi, logger, { base64: image.base64 }).then(({ url }) => {
        if (url && gallery.imageList) {
          gallery.imageList[index] = {
            fullUrl: url,
            thumbnailUrl: url,
          };
          const newObjectSet = { ...data, [entity]: newEntity };
          const isDataValid = isObjectSetValid(newObjectSet);
          this.props.onChange(newObjectSet, isDataValid);

          this.setState({
            data: newObjectSet,
            isDataValid,
          });
        }
      });
    } else {
      gallery.imageList[index] = {
        fullUrl: '',
        thumbnailUrl: '',
      };
      const newObjectSet = { ...data, [entity]: newEntity };
      const isDataValid = isObjectSetValid(newObjectSet);
      this.props.onChange(newObjectSet, isDataValid);

      this.setState({
        data: newObjectSet,
        isDataValid,
      });
    }
  };

  private handleEntityFieldChange = (entity: string) => (name: string, value: string | number | void) => {
    const { data } = this.state;
    const newEntity = {
      ...data[entity as TVillageObject],
      [name]: value,
    };
    const newObjectSet = { ...data, [entity]: newEntity };
    const isDataValid = isObjectSetValid(newObjectSet);
    this.props.onChange(newObjectSet, isDataValid);

    this.setState({
      data: newObjectSet,
      isDataValid,
    });
  };

  private handleEntityFatFieldsChange = (entity: string) => (fatData: IFatInfrastructureModalData) => {
    const { data } = this.state;
    const { title: fatTitle, description: fatDescription, iconId: fatIconId, isEnabled: fatEnabled } = fatData;

    const newEntity = {
      ...data[entity as TVillageObject],
      fatTitle,
      fatDescription,
      fatIconId,
      fatEnabled,
    };
    const newObjectSet = { ...data, [entity]: newEntity };
    const isDataValid = isObjectSetValid(newObjectSet);
    this.props.onChange(newObjectSet, isDataValid);

    this.setState({
      data: newObjectSet,
      isDataValid,
    });
  };

  private setObjectSet = (entity: string, value: INewObjectCategorySetItem | undefined) => () => {
    const { data } = this.state;
    const newObjectSet = { ...data, [entity]: value };
    const isDataValid = isObjectSetValid(newObjectSet);
    this.props.onChange(newObjectSet, isDataValid);

    this.setState({
      data: newObjectSet,
      isDataValid,
    });
  };

  private handleAdditionalGalleryChange = (entity: string) => (galleryItems: IGalleryItem[]) => {
    const { data } = this.state;
    const newEntity = {
      ...data[entity as TVillageObject],
      additionalGallery: {
        imageList: galleryItems.map(item => ({
          fullUrl: item.url,
          thumbnailUrl: item.url,
        })),
      },
    };
    const newObjectSet = { ...data, [entity]: newEntity };
    const isDataValid = isObjectSetValid(newObjectSet);

    this.props.onChange(newObjectSet, isDataValid);

    this.setState({
      data: newObjectSet,
      isDataValid,
    });
  };
}
