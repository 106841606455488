import { mergeStyles } from '@cian/utils';

import { IRoadInfo, roadDistanceVillageDropdownValues } from '../../../../common/interfaces';
import { IDropdownValue, SelectField } from '../../../SelectField';
import { SelectSearchField } from '../../../select_search_field';
import { TextField } from '../../../text_field';

import * as styles from '../VillageRoads.css';

export interface IVillageRoadProps {
  distanceCityLabel: string;
  item: IRoadInfo;
  roadValues: IDropdownValue[];
  readOnly?: boolean;
  onRoadChange(value: string): void;
  onDistanceVillageChange(value: string): void;
  onDistanceCityChange(value: string): void;
  onRemove(): void;
}

export const VillageRoad = ({
  distanceCityLabel,
  item,
  readOnly,
  roadValues,
  onDistanceVillageChange,
  onDistanceCityChange,
  onRoadChange,
  onRemove,
}: IVillageRoadProps) => (
  <li className="row">
    <div className="col-md-4">
      <SelectSearchField
        className="form-group"
        value={item.roadId}
        values={roadValues}
        label="Шоссе"
        required
        onChange={onRoadChange}
        readOnly={readOnly}
      />
    </div>
    <div className="col-md-3">
      <SelectField
        className="form-group"
        value={String(item.distanceVillage)}
        values={roadDistanceVillageDropdownValues}
        label="Расстояние от КП, км"
        required
        onChange={onDistanceVillageChange}
        readOnly={readOnly}
      />
    </div>
    <div className="col-md-4">
      <TextField
        label={distanceCityLabel}
        required
        value={String(item.distanceCity)}
        onChange={onDistanceCityChange}
        readOnly={readOnly}
      />
    </div>
    <div className="col-md-1">
      {!readOnly && (
        <button type="button" onClick={onRemove} {...mergeStyles(styles['delete-btn'], 'btn', 'btn-danger')}>
          <i className="fas fa-times" />
        </button>
      )}
    </div>
  </li>
);
