import { ERoles } from '../repositories/countryside/v2/get-user-role-list/types';
import {
  AUTH_ROUTE,
  BUILDERS_ROUTE,
  CALLTRACKING_DOWNLOAD_ROUTE,
  CALLTRACKING_ROUTE,
  CPL_SETTINGS_ROUTE,
  CREATE_BUILDER_ROUTE,
  CREATE_VILLAGE_ROUTE,
  EDIT_BUILDER_ROUTE,
  EDIT_VILLAGE_ROUTE,
  FEATURES_ROUTE,
  MAINPAGE_ROUTE,
  VILLAGES_ROUTE,
} from '../routes';

const mapRolesToPath: { [key: string]: string } = {
  [ERoles.ReadOnly]: VILLAGES_ROUTE,
  [ERoles.Admin]: VILLAGES_ROUTE,
  [ERoles.CalltrackingReadOnly]: CALLTRACKING_ROUTE,
  [ERoles.CalltrackingAdmin]: CALLTRACKING_ROUTE,
  [ERoles.CalltrackingBuilder]: CALLTRACKING_ROUTE,
};

const countrysideRoles = [ERoles.ReadOnly, ERoles.Admin];

const contractorServiceRoles = [ERoles.ContractsAdmin, ERoles.ContractsReadOnly];

const calltrackingRoles = [ERoles.CalltrackingReadOnly, ERoles.CalltrackingAdmin, ERoles.CalltrackingBuilder];

const calltrackingManagerRoles = [ERoles.CalltrackingReadOnly, ERoles.CalltrackingAdmin];

export const ADMIN_ROLES = [ERoles.Admin, ERoles.CalltrackingAdmin];

export const allRoles = [...countrysideRoles, ...calltrackingRoles, ...contractorServiceRoles];

const mapPathToRoles: { [key: string]: ERoles[] } = {
  [MAINPAGE_ROUTE]: allRoles,
  [VILLAGES_ROUTE]: countrysideRoles,
  [BUILDERS_ROUTE]: countrysideRoles,
  [FEATURES_ROUTE]: countrysideRoles,
  [AUTH_ROUTE]: allRoles,
  [CREATE_BUILDER_ROUTE]: [ERoles.Admin],
  [EDIT_BUILDER_ROUTE]: countrysideRoles,
  [CREATE_VILLAGE_ROUTE]: [ERoles.Admin],
  [EDIT_VILLAGE_ROUTE]: countrysideRoles,
  [CALLTRACKING_ROUTE]: calltrackingRoles,
  [CALLTRACKING_DOWNLOAD_ROUTE]: calltrackingRoles,
  [CPL_SETTINGS_ROUTE]: allRoles,
};

// узнаём первый доступный урл для ролей
export function getPathByRoles(roles: ERoles[]) {
  return mapRolesToPath[roles[0]] || AUTH_ROUTE;
}

// Проверяем, доступег ли урл переданным ролям
export function hasAccess(path: string, rolesArray: ERoles[]) {
  return rolesArray.some(role => mapPathToRoles[path].includes(role as ERoles));
}

// Проверяем есть ли у пользователя хоть одна роль из набора,
export function hasAccessByRoles<T>(userRoles: T[], accessRoles: T[]) {
  return userRoles.some(role => accessRoles.includes(role as T));
}

// Проверяем есть ли у пользователя роль для доступа к звонкам
export function hasAccessToCalltracking(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, calltrackingRoles);
}

// Проверяем есть ли у пользователя роль менеджера
export function hasCalltrackingManagerRole(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, calltrackingManagerRoles);
}

// Проверяем есть у пользователя роль для доступа к загородке
export function hasAccessToCountryside(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, countrysideRoles);
}

// Проверяем есть у пользователя роль для доступа к админке строителей
export function hasAccessToContractorService(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, contractorServiceRoles);
}

// Проверяем, является ли пользователь строителем
export function isContractor(rolesArray: ERoles[]) {
  return rolesArray.includes(ERoles.Contractor) && !hasAccessByRoles(rolesArray, [ERoles.ContractsAdmin]);
}

// Проверяем, есть ли у пользователя доступ к изменению бюджета Cpl
export function hasAccessToCplBudget(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, [...ADMIN_ROLES, ERoles.CalltrackingCplBudget]);
}

// Проверяем, есть ли у пользователя доступ к изменению плана Cpl
export function hasAccessToCplPlan(rolesArray: ERoles[]) {
  return hasAccessByRoles(rolesArray, [...ADMIN_ROLES, ERoles.CalltrackingCplPlan]);
}

export { ERoles as EUserRoles } from '../repositories/countryside/v2/get-user-role-list/types';
