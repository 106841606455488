// tslint:disable:max-file-line-count
import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { AppContext } from '../../AppContext';

import { IAdminGetCallsRequest } from '../../repositories/countryside-calltracking/v1/admin-get-calls';
import { getRawPhone } from '../../utils/helpers';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { SelectSearchField } from '../select_search_field';
import { TextField } from '../text_field';

import { DateTimeFieldMobile } from '../DateTimeFieldMobile';
import { SelectField } from '../SelectField';
import { getValueForTextField } from '../../utils/formatting';
import { NumberRangeField } from '../villages/village_form/number_range_field';
import { MAX_PRICE } from '../../utils/constants';
import {
  CALL_STATUS_DROPDOWN_VALUES,
  CALL_TYPE_BY_BUILDER_FILTER_DROPDOWN_VALUES,
  CALL_TYPE_BY_COMMENT,
  CALL_TYPE_BY_FINAL_FILTER_DROPDOWN_VALUES,
} from '../../constants/calltracking';

import * as styles from './CalltrackingMobileFilters.css';
import { BuilderCalltrackingTariffNames, CalltrackingTariffNames, IRegion } from '../../common/interfaces';
import { MultiselectorField } from '../multiselector_field';
import { IManagerForListSchema } from '../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { getSelectOptionsFromObject } from '../../utils/getSelectOptionsFromObject';

interface ICalltrackingFormProps {
  filters: IAdminGetCallsRequest;
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  onApplyFilters(filters: IAdminGetCallsRequest): void;
  isCalltrackingManager: boolean;
}

interface ICalltrackingFormState {
  filters: IAdminGetCallsRequest;
  isOpen: boolean;
}

export class CalltrackingMobileFilters extends React.Component<ICalltrackingFormProps, ICalltrackingFormState> {
  public static contextType = AppContext;
  public state = {
    filters: { ...this.props.filters },
    isOpen: false,
  };

  public render() {
    if (!this.state.isOpen) {
      return (
        <div className={styles['closed_container']}>
          <button {...mergeStyles('btn', 'btn-primary', styles['btn-filter'])} onClick={this.openFilters}>
            Фильтры
          </button>
        </div>
      );
    }
    const {
      filters: {
        builderId,
        builderName,
        callDateFrom,
        callDateTo,
        callId,
        durationFrom,
        durationTo,
        status,
        typeByBuilder,
        finalType,
        villageId,
        villageName,
        regionIdList,
        managerIdList,
        sourceRegion,
        sourcePhone,
        tariffList,
        commentFilter,
      },
    } = this.state;

    const { regionList, managerList, isCalltrackingManager } = this.props;

    const regions = regionList.map(region => ({ value: region.id, label: region.displayName }));
    const managers = [
      { label: 'Без менеджера', value: 0 },
      ...managerList.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
    ];

    const tariffNamesList = isCalltrackingManager ? CalltrackingTariffNames : BuilderCalltrackingTariffNames;

    return (
      <div {...mergeStyles('ct_mobile_filters', styles['container'])}>
        <div className={styles['header']}>
          <span>Уточнить поиск</span>
          <button type="button" {...mergeStyles('close', styles['close'])} onClick={this.closeFilters}>
            &times;
          </button>
        </div>
        <div className={styles['filters']}>
          <TextField value={callId} label="ID звонка" type="tel" onChange={this.handleNumberFieldCange('callId')} />
          <TextField
            value={builderId}
            label="ID застройщика"
            type="tel"
            onChange={this.handleNumberFieldCange('builderId')}
          />
          <TextField
            value={builderName}
            label="Название застройщика"
            onChange={this.handleFieldChange('builderName')}
          />
          <TextField
            value={villageId}
            label="ID посёлка"
            type="tel"
            onChange={this.handleNumberFieldCange('villageId')}
          />
          <TextField value={villageName} label="Название посёлка" onChange={this.handleFieldChange('villageName')} />
          <MultiselectorField
            label="Регионы КП"
            value={regionIdList || []}
            values={regions}
            onChange={this.handleMultiSelectChange('regionIdList')}
            invalid={false}
            showSelectButtons
          />
          <SelectSearchField
            label="Регион звонка"
            value={sourceRegion || undefined}
            values={regions}
            className="form-group"
            onChange={this.handleFieldChange('sourceRegion')}
            noEmpty={false}
          />
          <SelectField
            value={getValueForTextField(status)}
            values={CALL_STATUS_DROPDOWN_VALUES}
            label="Статус звонка"
            onChange={this.handleFieldChange('status')}
          />
          <NumberRangeField
            label="Продолжительность звонка, с"
            minValue={durationFrom || undefined}
            maxValue={durationTo || undefined}
            max={MAX_PRICE}
            onChangeMin={this.handleFieldChange('durationFrom')}
            onChangeMax={this.handleFieldChange('durationTo')}
          />
          <DateTimeFieldMobile
            renderClearButton
            label="Дата и время звонка от"
            value={callDateFrom}
            onChange={this.handleFieldChange('callDateFrom')}
          />
          <DateTimeFieldMobile
            renderClearButton
            label="Дата и время звонка до"
            value={callDateTo}
            onChange={this.handleFieldChange('callDateTo')}
            isDateTo
          />
          {isCalltrackingManager && (
            <MultiselectorField
              label="Менеджеры"
              value={managerIdList || []}
              values={managers}
              onChange={this.handleManagerIdListChange}
              invalid={false}
            />
          )}
          <PhoneInput value={sourcePhone} onChange={this.handlePhoneFieldChange('sourcePhone')} isMobile />
          <MultiselectorField
            label="Тариф КП"
            value={tariffList || []}
            values={getSelectOptionsFromObject(tariffNamesList)}
            onChange={this.handleTariffListChange}
            invalid={false}
          />
          <SelectField
            value={getValueForTextField(typeByBuilder)}
            values={CALL_TYPE_BY_BUILDER_FILTER_DROPDOWN_VALUES}
            label="Тип звонка, установленный застройщиком"
            onChange={this.handleFieldChange('typeByBuilder')}
          />
          <SelectField
            value={getValueForTextField(finalType)}
            values={CALL_TYPE_BY_FINAL_FILTER_DROPDOWN_VALUES}
            label="Итоговый тип звонка"
            onChange={this.handleFieldChange('finalType')}
          />
          <SelectField
            label="Комментарий"
            onChange={this.handleFieldChange('commentFilter')}
            value={getValueForTextField(commentFilter)}
            values={CALL_TYPE_BY_COMMENT}
          />
          <button {...mergeStyles('btn', 'btn-primary', styles['btn-save'])} onClick={this.handleFilterButtonClick}>
            Отфильтровать
          </button>
        </div>
      </div>
    );
  }

  private handleFieldChange = (name: string) => (value: string | number | void) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value || undefined,
      },
    });

  private handlePhoneFieldChange = (name: string) => (value: string) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: getRawPhone(value),
      },
    });

  private handleNumberFieldCange = (name: string) => (value: string) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: parseInt(value, 10) || undefined,
      },
    });

  private setMultiselectFilter = (name: string, value: Array<string | number> | void) =>
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });

  private handleManagerIdListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length > 1) {
      if (value[value.length - 1] === 0) {
        newValue = [0];
      } else if (value.includes(0)) {
        newValue = value.filter(Boolean);
      }
    }

    this.setMultiselectFilter('managerIdList', newValue);
  };

  private handleTariffListChange = (value: Array<string | number> | void) => {
    let newValue = value;

    if (value && value.length < 1) {
      newValue = undefined;
    }

    this.setMultiselectFilter('tariffList', newValue);
  };

  private handleMultiSelectChange = (name: string) => (value: Array<string | number> | void) => {
    this.setMultiselectFilter(name, value);
  };

  private handleFilterButtonClick = () => {
    this.closeFilters();
    this.props.onApplyFilters({
      ...this.state.filters,
      page: 1,
    });
  };

  private openFilters = () => {
    this.setState({ isOpen: true });
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
  };

  private closeFilters = () => {
    this.setState({ isOpen: false });
    document.body.style.overflow = 'visible';
    document.body.style.position = 'initial';
  };
}
