import {
  ISetBudgetRequest,
  ISetBudgetResponse,
  ISetBudgetResponseError,
  fetchSetBudget,
} from '../repositories/countryside-calltracking/v1/set-budget';
import { createSimpleRequest } from './createSimpleRequest';

export const setBudget = createSimpleRequest<ISetBudgetRequest, ISetBudgetResponse, ISetBudgetResponseError>(
  'setBudget',
  fetchSetBudget,
);
