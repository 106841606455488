/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRoadsModel,
  IGetRoadsRequest,
  IMappers,
  IGetRoadsResponse,
  IGetRoadsResponseError,
  TGetRoadsResponse,
} from './types';

const defaultConfig: TGetRoadsModel = {
  apiType: 'private',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v2/get-roads/',
  hostType: 'api',
} as TGetRoadsModel;

function createGetRoadsModel(parameters: IGetRoadsRequest): TGetRoadsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRoadsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRoadsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRoads<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRoadsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetRoadsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetRoadsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRoadsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRoadsResponseError);
    }
  }

  return { response, statusCode } as TGetRoadsResponse;
}

export { defaultConfig, createGetRoadsModel, fetchGetRoads };
