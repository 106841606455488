import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as styles from './Alert.css';

interface IAlertProps {
  onClick?(): void;
  text: string;
  type: 'success' | 'danger' | 'warning';
  topAuto?: boolean;
}

export const Alert = ({ onClick, text, type, topAuto }: IAlertProps) => (
  <div {...mergeStyles(styles['container'], topAuto && { top: 'auto' })} onClick={onClick}>
    <div {...mergeStyles(styles['alert'], styles[type])}>{text}</div>
  </div>
);
