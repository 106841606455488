import { IThunkActionCreator } from '../../types/thunk';
import { NotificationManager } from '../../utils/showNotification';
import { saveFile } from 'browser/utils/saveFile';
import { defaultConfig } from 'shared/repositories/countryside-calltracking/v1/admin/get-constructor-calls-report';

export function saveConstructorCallsReport(): IThunkActionCreator<void> {
  return async (_dispatch, _getState, { config }) => {
    saveFile(
      null,
      () => NotificationManager.error('Ошибка сохранения отчета'),
      'report.xlsx',
      config.getStrict<string>('countryside-calltracking.browserUrl'),
      defaultConfig.pathApi,
    )();
  };
}
