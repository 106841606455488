/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminGetCallsModel,
  IAdminGetCallsRequest,
  IMappers,
  IAdminGetCallsResponse,
  IAdminGetCallsResponseError,
  TAdminGetCallsResponse,
} from './types';

const defaultConfig: TAdminGetCallsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/get-calls/',
  hostType: 'api',
} as TAdminGetCallsModel;

function createAdminGetCallsModel(parameters: IAdminGetCallsRequest): TAdminGetCallsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminGetCallsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminGetCallsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminGetCalls<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminGetCallsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TAdminGetCallsResponse> {
  const { response, statusCode } = await httpApi.fetch(createAdminGetCallsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminGetCallsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminGetCallsResponseError);
    }
  }

  return { response, statusCode } as TAdminGetCallsResponse;
}

export { defaultConfig, createAdminGetCallsModel, fetchAdminGetCalls };
