import { saveAs } from 'file-saver';

export function saveFileFromPath(filePath: string) {
  return (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    const pathArr = filePath.split('/');
    saveAs(filePath, pathArr[pathArr.length - 1]);
  };
}
