import { connect } from 'react-redux';

import { setCplField, updateCplField } from '../../actions/cplData';
import { CplSettingsMobileInfo } from '../../components/CplSettingsPage/CplSettingsMobileInfo';
import { ICplSettingsPageState } from '../../types/redux';
import { CplSettingsInfo, ICplSettingsInfoProps } from '../../components/CplSettingsPage/CplSettingsInfo';
import { TTypedThunkDispatch } from '../../types/thunk';
import { isCurrentPeriod } from '../../utils/isCurrentPeriod';
import { hasAccessToCplBudget, hasAccessToCplPlan } from '../../utils/permissions';
import { hasAnyAdminRole } from '../../utils/hasAnyAdminRole';

export type TStoreProps = Pick<
  ICplSettingsInfoProps,
  'list' | 'builder' | 'canEdit' | 'period' | 'managerId' | 'canEditBudget' | 'canEditPlan'
>;
export type TDispatchProps = Pick<ICplSettingsInfoProps, 'onBlurField'>;

export function mapStateToProps(state: ICplSettingsPageState): TStoreProps {
  const {
    cplData: { settings },
    filters: { villages, period, managerId },
    builder,
    roles,
  } = state;

  const list = settings.filter(item => villages.includes(item.villageId));

  return {
    list,
    builder,
    period,
    managerId,
    canEditBudget: hasAccessToCplBudget(roles) && isCurrentPeriod(period),
    canEditPlan: hasAccessToCplPlan(roles) && isCurrentPeriod(period),
    canEdit: hasAnyAdminRole(roles) && isCurrentPeriod(period),
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    onBlurField: (cplSettingsId, field, value) => {
      dispatch(
        setCplField({
          cplSettingsId,
          value,
          field,
        }),
      );

      dispatch(
        updateCplField({
          cplSettingsId,
          value,
          field,
        }),
      );
    },
  };
}

export const CplSettingsInfoContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CplSettingsInfo);

export const CplSettingsMobileInfoContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CplSettingsMobileInfo);
