import * as React from 'react';
import * as styles from './Container.css';

interface IContainerProps {
  title?: string;
  noBorder?: boolean;
  children: React.ReactNode;
}

export function Container({ title, children }: IContainerProps) {
  return (
    <div className={styles['container']}>
      {title && <h2 className={styles['title']}>{title}</h2>}
      {children}
    </div>
  );
}
