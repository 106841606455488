/* eslint-disable no-useless-escape */
import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { IDropdownValue } from '../SelectField';

import * as styles from './MultiselectorField.css';
import { Button, Dropdown, Form } from 'react-bootstrap';

interface IMultiselectorFieldProps {
  onChange(value: (string | number)[]): void;
  value: (string | number)[];
  values: IDropdownValue[];
  label?: string;
  className?: string;
  noEmpty?: boolean;
  required?: boolean;
  disabled?: boolean;
  invalid: boolean;
  readOnly?: boolean;
  showSelectButtons?: boolean;
}

interface IMultiselectorFieldState {
  dropdownIsOpen: boolean;
  search: string;
  className?: string;
}

export class MultiselectorField extends React.Component<IMultiselectorFieldProps, IMultiselectorFieldState> {
  public state = {
    dropdownIsOpen: false,
    search: '',
  };

  public render() {
    const { className, invalid, label, readOnly, required, showSelectButtons } = this.props;

    const values = this.filterValues(this.props.values);

    return (
      <Form.Group {...mergeStyles(className, styles['multiselector-field'], invalid && styles['invalid'])}>
        <Form.Label>
          {label}&nbsp;{required && <span className="text-danger">*</span>}
        </Form.Label>
        <Dropdown
          show={this.state.dropdownIsOpen}
          autoClose="outside"
          onToggle={() => this.setState({ dropdownIsOpen: false })}
        >
          <Dropdown.Toggle
            as={() => (
              <Button
                variant="light"
                disabled={this.props.disabled}
                className={styles['dropdown-btn']}
                onClick={() => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })}
              >
                {this.getButtonText()}
                <span {...mergeStyles('caret-down', styles['caret'])} />
              </Button>
            )}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu className={styles['dropdown-menu']}>
            <Dropdown.Item className="mb-2" as="li">
              <Form.Control
                type="text"
                className="form-control"
                onChange={e => this.setState({ search: e.currentTarget.value.replace(/([[\^$.|?*+()])/g, '[$1]') })}
                placeholder="Поиск"
              />
            </Dropdown.Item>
            {showSelectButtons && (
              <>
                <Dropdown.Item className={styles['select_all']} as="li">
                  <Form.Check.Label className={styles['label']}>
                    <Form.Check.Input
                      type="checkbox"
                      checked={this.areAllValuesSelected}
                      onClick={this.areAllValuesSelected ? this.deselectAll : this.selectAll}
                      readOnly={readOnly}
                    />
                    &nbsp;
                    {this.areAllValuesSelected ? 'Снять все' : 'Выбрать все'}
                  </Form.Check.Label>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            {values.map((value, index) => (
              <Dropdown.Item key={index} className={styles['list-item']} as="li">
                <Form.Check.Label className={styles['label']}>
                  <Form.Check.Input
                    type="checkbox"
                    checked={this.isSelectedValue(value.value)}
                    onClick={() => this.toggleValue(value.value)}
                    readOnly={readOnly}
                  />
                  &nbsp;
                  {value.label}
                </Form.Check.Label>
              </Dropdown.Item>
            ))}
            {!values.length && (
              <Dropdown.Item className={styles['list-item']} as="li">
                Пусто
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
    );
  }

  private filterValues(values: IDropdownValue[]): IDropdownValue[] {
    const pattern = new RegExp(this.state.search, 'ig');

    return values.filter(value => pattern.test(value.label));
  }

  private getButtonText() {
    const selectedValues = this.props.values.filter(dropdownValue => this.isSelectedValue(dropdownValue.value));
    if (selectedValues.length) {
      return selectedValues.map(dropdownValue => dropdownValue.label).join(', ');
    }

    return 'Не выбрано';
  }

  private isSelectedValue(value: string | number) {
    return this.props.value.includes(value);
  }

  private toggleValue(value: string | number) {
    if (this.props.readOnly) {
      return;
    }
    if (this.isSelectedValue(value)) {
      if (this.props.noEmpty && this.props.value.length === 1) {
        return;
      }

      this.props.onChange(this.props.value.filter(select => select !== value));
    } else {
      this.props.onChange([...this.props.value, value]);
    }
  }

  private get allValues() {
    return this.props.values.map(({ value }) => value);
  }

  private get areAllValuesSelected() {
    return this.props.values.length === this.props.value.length;
  }

  private selectAll = () => this.props.onChange(this.allValues);

  private deselectAll = () => this.props.onChange([]);
}
