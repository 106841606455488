import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { IApplicationContext, IApplicationContextCustom } from 'shared/common/types/applicationContext';

export const createContext = (): IApplicationContext => {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
  };
};
