import { TDevice } from '@cian/utils';

export function getInitialDeviceWidth(deviceType: TDevice): number {
  switch (deviceType) {
    case 'phone':
      return 320;
    case 'tablet':
      return 768;
    case 'desktop':
    default:
      return 1025;
  }
}
