import { IBuilderListItem, ModerationStatusNames } from '../../../common/interfaces';
import { EDIT_BUILDER_ROUTE } from '../../../routes';
import { formatDateTime } from '../../../utils/datetime';
import { WithTitle } from '../../WIthTitle';

import * as styles from './BuilderList.css';
import { Button, Table } from 'react-bootstrap';

interface IBuilderListProps {
  builderList: IBuilderListItem[];
  handleRedirectToAmo(id: number): void;
}

export const BuilderList = (props: IBuilderListProps) => (
  <Table striped hover>
    <thead>
      <tr>
        <th>ID</th>
        <th>Название</th>
        <th>Статус</th>
        <th>Добавлен</th>
        <th>Изменен</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {props.builderList.map(builder => (
        <tr key={builder.id}>
          <td>{builder.id}</td>
          <td>{builder.name}</td>
          <td>{ModerationStatusNames[builder.moderationStatus]}</td>
          <td>{formatDateTime(builder.created)}</td>
          <td>{formatDateTime(builder.updated)}</td>
          <td>
            <Button
              variant="primary"
              size="sm"
              href={EDIT_BUILDER_ROUTE.replace(':id', builder.id.toString())}
              title="Редактировать"
              rel="noreferrer"
            >
              <i className="fas fa-pen" />
            </Button>
            <WithTitle title="Посмотреть на amoCRM" position="bottom-left">
              <Button
                variant="light"
                size="sm"
                title="Редактировать"
                onClick={() => props.handleRedirectToAmo(builder.id)}
                className={styles['button']}
              >
                <i className="far fa-eye" />
              </Button>
            </WithTitle>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);
