import { ERoleType, TYPE_ROLES_MAP } from '../../constants/calltracking';
import { withRoles } from '../withRoles';
import { IWithRoles } from '../withRoles/withRoles';
import { CalltrackingButton } from '../CalltrackingButton';
import { TCallType, TChangerType } from 'shared/types';

interface ICalltrackingFinalButtonProps {
  setCalltrackingType(callId?: number, changerType?: TChangerType, type?: TCallType): void;
  callId: number | undefined | null;
  finalType: TCallType | undefined | null;
  isHandheld?: boolean;
  isEditable?: boolean | null;
}

export function CalltrackingFinalButtonComponent(props: ICalltrackingFinalButtonProps & IWithRoles) {
  const { callId, finalType, roles, setCalltrackingType, isHandheld, isEditable = true } = props;
  /* роли расположены по приоритету, можно редактировать, если переданный тип роли существует и нет роли приоритетнее */
  const isEditableByRole =
    Object.values(TYPE_ROLES_MAP).find(role => roles.includes(role)) === TYPE_ROLES_MAP[ERoleType.Final];
  const value = finalType === 'target' ? finalType : 'nonTarget';

  return (
    <CalltrackingButton
      isHandheld={isHandheld}
      value={value}
      disabled={!isEditable || !isEditableByRole}
      onClick={event => {
        event.stopPropagation();
        const newValue = value === 'target' ? 'nonTarget' : 'target';
        setCalltrackingType(callId || undefined, 'final', newValue);
      }}
      onlyIcon
    />
  );
}

export const CalltrackingFinalButton = withRoles<ICalltrackingFinalButtonProps>(CalltrackingFinalButtonComponent);
