import { IGetVillageListResponse } from '../repositories/countryside/v1/get-village-list';

type TNonNullableResponse = Required<{ [K in keyof IGetVillageListResponse]: NonNullable<IGetVillageListResponse[K]> }>;

export const getDataFromVillageResponse = ({
  totalItems,
  totalPages,
  villageList,
}: IGetVillageListResponse): TNonNullableResponse => ({
  totalItems: totalItems || 0,
  totalPages: totalPages || 0,
  villageList: villageList || [],
});
