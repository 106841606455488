import { ECplDataActionTypes, TSetCplField, TSetCplData } from '../../actions/cplData';
import { ICplData } from '../../types';

export type TCplDataAction = TSetCplData | TSetCplField;

export function cplDataReducer(state: ICplData, action: TCplDataAction): ICplData {
  switch (action.type) {
    case ECplDataActionTypes.SetData:
      return { ...action.payload };

    case ECplDataActionTypes.SetField:
      // eslint-disable-next-line no-case-declarations
      const { cplSettingsId, value, field } = action.payload;

      return {
        ...state,
        settings: state.settings.map(item => {
          return item.id === cplSettingsId ? { ...item, [field]: value } : item;
        }),
      };

    default:
      return state || null;
  }
}
