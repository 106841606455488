import { mergeStyles } from '@cian/utils';
import * as styles from './CheckboxField.css';
import { Form } from 'react-bootstrap';

export interface ICheckboxFieldProps {
  value: boolean;
  className?: string;
  inputClass?: string;
  htmlId?: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
  helpText?: string;
  onChange(value: boolean): void;
  disabled?: boolean;
}

export const CheckboxField = (props: ICheckboxFieldProps) => {
  const { required, onChange, label, inputClass, className, helpText, value, disabled } = props;

  return (
    <Form.Group {...mergeStyles(className, styles['checkbox-field'])}>
      <Form.Check>
        <Form.Check.Label>
          <Form.Check.Input
            {...mergeStyles(styles['input'], inputClass)}
            onChange={e => onChange((e.currentTarget as HTMLInputElement).checked)}
            type="checkbox"
            checked={value}
            disabled={disabled}
          />
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </Form.Check.Label>
      </Form.Check>
      {helpText && <div className={styles['tooltip']}>{helpText}</div>}
    </Form.Group>
  );
};
