import * as styles from './styles.css';

interface INotificationProps {
  title: string | undefined;
  message: string;
}

export const Notification = ({ title, message }: INotificationProps) => (
  <div className={styles['container']}>
    {title && <div className={styles['title']}>{title}</div>}
    <div className={styles['message']}>{message}</div>
  </div>
);
