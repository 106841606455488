import { mergeStyles } from '@cian/utils';
import { IBlackListInfoSchema } from '../../repositories/countryside-calltracking/entities/call/BlackListInfoSchema';
import { formatDateTime } from '../../utils/datetime';

import * as styles from './CalltrackingBlackListInfo.css';

interface ICalltrackingBlackListInfoProps {
  data: IBlackListInfoSchema | null | undefined;
  isMobile?: boolean;
}

export function CalltrackingBlackListInfo({ data, isMobile }: ICalltrackingBlackListInfoProps) {
  if (!data) {
    return null;
  }
  const { cause, reportDate, reporterEmail } = data;

  const formattedDate = formatDateTime(reportDate, '+0300');

  return (
    <div {...mergeStyles(styles['container'], isMobile && styles['container--mobile'])}>
      <span className={styles['icon']}>
        <i className="fas fa-ban" />
      </span>
      Номер добавлен в чёрный список&nbsp;
      {!!formattedDate && <strong className={styles['date']}>{formattedDate}&nbsp;</strong>}
      {!!reporterEmail && <strong className={styles['email']}>{reporterEmail}&nbsp;</strong>}
      {!!cause && (
        <>
          по причине:&nbsp;
          <strong className={styles['reason']}>{cause}</strong>
        </>
      )}
    </div>
  );
}
