import * as React from 'react';
import * as styles from './WithTitle.css';
import { mergeStyles } from '@cian/utils/lib/shared/style';

type TPosition = 'bottom-right' | 'bottom-left';

interface IWithTitleProps {
  title: string;
  position?: TPosition;
}

export function WithTitle({ children, position, title }: React.PropsWithChildren<IWithTitleProps>) {
  return (
    <div className={styles['with-title']}>
      {children}
      <div {...mergeStyles(styles['title'], position ? styles[position] : styles['bottom-right'])}>{title}</div>
    </div>
  );
}
