// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface ICPLSettingsHistoryItemSchema {
  /** Баланс **/
  balance?: number;
  /** CPL **/
  cpl?: number;
  /** Дата и время, когда произошло событие **/
  createdAt: string;
  /** Событие **/
  event: EEvent;
  /** Email пользователя, сделавшего изменение **/
  userEmail?: string;
  /** Значение **/
  value: number;
}
export enum EEvent {
  /** Пополнение бюджета **/
  'BudgetReplenishment' = 'budgetReplenishment',
  /** Списание бюджета **/
  'BudgetWriteOff' = 'budgetWriteOff',
  /** Прогноз звонков **/
  'CallsForecast' = 'callsForecast',
  /** Получено платных звонков **/
  'CallsReceived' = 'callsReceived',
  /** Изменение CPL **/
  'CplChange' = 'cplChange',
}
