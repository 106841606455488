import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { Outside } from 'shared/components/Outside';
import { AppContext } from '../../AppContext';
import { IRegion } from '../../common/interfaces';

import { ICallListItemSchema } from '../../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';
import { ECalltrackingBlockState, useCalltrackingBlacklistHook } from '../../utils/useCalltrackingBlacklistHook';
import { getRegionNameFromList } from '../../utils/getRegionNameFromList';
import { noop } from '../../utils/noop';
import { prettifySeconds } from '../../utils/prettifySeconds';
import { useGetCallRecordHook } from '../../utils/useGetCallRecordHook';
import { AudioPlayer } from '../AudioPlayer';
import { CalltrackingAudioLink } from '../CalltrackingAudioLink';
import { CalltrackingBlackListInfo } from '../CalltrackingBlackListInfo';
import { CalltrackingBlackListModal } from '../CalltrackingBlackListModal';
import { CalltrackingListItemHistory } from '../CalltrackingListItemHistory';
import { CalltrackingPopupItem } from '../CalltrackingPopupItem/CalltrackingPopupItem';
import { PhoneLink } from '../PhoneLink/PhoneLink';
import { Portal } from '../Portal';
import { onEscPressed } from '../../utils/handlers';

import * as styles from './CalltrackingDesktopPopup.css';
import { CalltrackingComment } from '../CalltrackingComment/';
import { formatDateTime } from '../../utils/datetime';
import { ICallHistoryItem, TCallType, TChangerType } from 'shared/types';

interface ICalltrackingListItemPopupProps {
  call: ICallListItemSchema | undefined | null;
  isAlertVisible?: boolean;
  history: ICallHistoryItem[] | null;
  isPaused?: boolean;
  isHidden?: boolean;
  isAdmin?: boolean;
  canEditBlackList?: boolean;
  regionList: IRegion[];
  setCalltrackingType?(callId: number, changerType: TChangerType, type: TCallType): void;
  onClose(): void;
  onTrackEnd(): void;
  onTogglePlay(): void;
  onTogglePause(): void;
  onBlackListStatusChange(): void;
  onCommentSave(): void;
}

export function CalltrackingDesktopPopup({
  call,
  isAlertVisible,
  onClose,
  onTrackEnd,
  history,
  isPaused,
  isHidden,
  isAdmin,
  canEditBlackList,
  regionList,
  onTogglePlay,
  onTogglePause,
  onBlackListStatusChange,
  onCommentSave,
}: ICalltrackingListItemPopupProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const { callRecord, getCallRecord } = useGetCallRecordHook({
    callId: call ? call.callId : null,
    httpApi,
    logger,
  });
  const [isBlackListModalVisible, setBlackListModalVisibility] = React.useState(false);
  const { isBlocked, isPending, setBlockState } = useCalltrackingBlacklistHook({
    call,
    httpApi,
    logger,
    callback: onBlackListStatusChange,
  });

  if (!call) {
    return null;
  }

  const hideBlackListPopup = () => setTimeout(() => setBlackListModalVisibility(false));

  const {
    callId,
    recordFilePath,
    duration,
    sourcePhone,
    sourceRegion,
    calltrackingPhone,
    destinationPhone,
    localDate,
    regionId,
  } = call;
  const callRegionName = sourceRegion && getRegionNameFromList(regionList, sourceRegion);
  const villageRegionName = regionId && getRegionNameFromList(regionList, regionId);

  return (
    <Portal isHidden={isHidden}>
      <div
        {...mergeStyles(styles['container'], isHidden && styles['container--hidden'])}
        tabIndex={0}
        onKeyDown={onEscPressed(onClose)}
      >
        <Outside onOutside={isAlertVisible || isBlackListModalVisible ? noop : onClose}>
          <div
            {...mergeStyles(
              'container',
              styles['outside_container'],
              !!history && !!history.length && styles['outside_container--with-history'],
            )}
          >
            <div className={styles['modal']}>
              <div className={styles['modal_header']}>
                Звонок №{callId}
                <button className={styles['close_button']} onClick={onClose}>
                  &times;
                </button>
              </div>
              <div className={styles['modal_content']}>
                <CalltrackingPopupItem label="Звонок">
                  <div {...mergeStyles(styles['call_container'], !recordFilePath && styles['call_container--hidden'])}>
                    <AudioPlayer
                      src={recordFilePath}
                      callId={callId}
                      isPaused={isPaused}
                      onTrackEnd={isHidden ? onTrackEnd : noop}
                      onTogglePlay={onTogglePlay}
                      onTogglePause={onTogglePause}
                    />
                    <CalltrackingAudioLink callRecord={callRecord} onLoadButtonClick={getCallRecord} />
                  </div>
                  {!recordFilePath && <span>Запись отсутствует</span>}
                </CalltrackingPopupItem>
                {localDate && (
                  <CalltrackingPopupItem label="Местное время звонка">
                    {formatDateTime(localDate)}
                    {villageRegionName && <span className={styles['region_name']}>{villageRegionName}</span>}
                  </CalltrackingPopupItem>
                )}
                <CalltrackingPopupItem label="Длительность">{prettifySeconds(duration)}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="Телефон">
                  <PhoneLink phone={sourcePhone} isBlocked={isBlocked} />
                  {callRegionName && <span className={styles['region_name']}>({callRegionName})</span>}
                </CalltrackingPopupItem>
                {calltrackingPhone && (
                  <CalltrackingPopupItem label="Подменный телефон">
                    <PhoneLink phone={calltrackingPhone} />
                  </CalltrackingPopupItem>
                )}
                {destinationPhone && (
                  <CalltrackingPopupItem label="Телефон застройщика">
                    <PhoneLink phone={destinationPhone} />
                  </CalltrackingPopupItem>
                )}
                <CalltrackingPopupItem label="Комментарий" />
                <CalltrackingComment isAdmin={isAdmin} call={call} onCommentSave={onCommentSave} />
                <CalltrackingListItemHistory history={history} />
              </div>
              <div className={styles['modal_footer']}>
                {isBlocked && (
                  <div className={styles['blacklist_info']}>
                    <CalltrackingBlackListInfo data={call.blackListInfo} />
                  </div>
                )}
                <div className={styles['modal_footer_buttons']}>
                  {canEditBlackList && (
                    <div className={styles['btn_footer']}>
                      {isBlocked ? (
                        <button
                          className="btn btn-success"
                          disabled={isPending}
                          onClick={() => setBlockState(ECalltrackingBlockState.Unblock)}
                        >
                          Удалить из ЧС
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger"
                          disabled={isPending}
                          onClick={() => setBlackListModalVisibility(true)}
                        >
                          Добавить в ЧС
                        </button>
                      )}
                    </div>
                  )}
                  <button className="btn btn-danger" onClick={onClose} autoFocus>
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
            {isBlackListModalVisible && (
              <CalltrackingBlackListModal
                data={call.blackListInfo}
                onConfirm={data => {
                  setBlockState(ECalltrackingBlockState.Block, data);
                  hideBlackListPopup();
                }}
                onClose={hideBlackListPopup}
              />
            )}
          </div>
        </Outside>
      </div>
    </Portal>
  );
}
