import { isClient } from '../../shared/utils/helpers/getEnvironmentInfo';

export const addScrollFn =
  <T, S>(cb: (arg: T) => S) =>
  (arg: T) => {
    if (isClient()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    return cb(arg);
  };
