import { Reducer } from 'redux';
import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { TReduxActions } from '../../types/redux';
import { IReduxThunkContext } from '../../types/thunk';
import { createReduxStore } from './createReduxStore';

export interface ICreateStoreParams<T> {
  state: T;
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
  combinedReducers: Reducer<T, TReduxActions>;
}

export function createStore<T>({ state, config, httpApi, logger, combinedReducers }: ICreateStoreParams<T>) {
  const context: IReduxThunkContext = {
    config,
    httpApi,
    logger,
  };

  return createReduxStore(state, combinedReducers, context);
}
