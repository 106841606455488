import { saveAs } from 'file-saver';
import { mergePaths } from '@cian/http-api/shared';
import { IAdminGetCallsRequest } from '../../shared/repositories/countryside-calltracking/v1/admin-get-calls';
import { IVillageFilter } from '../../shared/common/interfaces';

export interface IStatusHandler {
  status: number;
  handlerFn: Function;
}

export const saveFile =
  (
    filters: IAdminGetCallsRequest | IVillageFilter | null,
    onFail: Function,
    defaultFilename: string,
    apiUrl?: string,
    path?: string,
    statusHandlers?: IStatusHandler[],
  ) =>
  async () => {
    if (!apiUrl || !path) {
      return;
    }
    try {
      const url = mergePaths(apiUrl, path);
      const result = await fetch(url, {
        method: 'POST',
        body: filters ? JSON.stringify(filters) : undefined,
        /* добавляем куки к запросу */
        credentials: 'include',
      });

      const filteredHandler = statusHandlers && statusHandlers.find(handler => handler.status === result.status);

      if (filteredHandler) {
        filteredHandler.handlerFn();

        return;
      }

      if (result.status >= 400) {
        throw new Error(`Error. Server respond with ${result.status} status`);
      }

      const contentDisposition = result.headers.get('Content-Disposition');
      const filenameRegExpArray = contentDisposition && /filename="(.*)"/.exec(contentDisposition);
      const filename = (filenameRegExpArray && filenameRegExpArray[1]) || defaultFilename;
      saveAs(await result.blob(), filename);
    } catch (e) {
      onFail(e);
    }
  };
