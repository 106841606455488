import * as styles from './Stats.css';
import { Button } from 'react-bootstrap';

export interface IStatsProps {
  totalItems: number;
  title: string;
  onSaveReport(): void;
}

export function Stats({ totalItems, title, onSaveReport }: IStatsProps) {
  return (
    <div className={styles['total_count']}>
      <strong>{title}</strong>&nbsp;{totalItems}
      <Button variant="success" className={styles['save-report-btn']} onClick={onSaveReport}>
        <i className="far fa-arrow-alt-circle-down" />
        Сохранить отчёт
      </Button>
    </div>
  );
}
