import { Dispatch, SetStateAction, useEffect } from 'react';

import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { getCallHistory } from '../http';

interface IUseGetCallHistoryHook<T> {
  callId: number | null | undefined;
  httpApi: IHttpApi;
  logger: ILogger;
  popupState: T;
  setPopupState: Dispatch<SetStateAction<T>>;
}

export function useGetCallHistoryHook<T>(params: IUseGetCallHistoryHook<T>) {
  const { callId, httpApi, logger, popupState, setPopupState } = params;

  useEffect(() => {
    if (!callId) {
      return;
    } //tslint:disable-line return-undefined

    getCallHistory(httpApi, logger, { callId })
      .then(response => {
        setPopupState({
          ...popupState,
          history: response.history,
        });
      })
      .catch(() => {
        setPopupState({
          ...popupState,
          history: [],
        });
      });
  }, [callId]);
}
