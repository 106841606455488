/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TAdminSetCallTypeModel = IModel<IAdminSetCallTypeRequest, TAdminSetCallTypeResponse>;

export interface IAdminSetCallTypeRequest {
  /** ID звонка **/
  callId: number;
  /** Кто устанавливает тип звонка **/
  changerType?: EChangerType | null;
  /** Тип звонка **/
  type: EType;
}

export type TAdminSetCallTypeResponse = IAdminSetCallTypeResponse200 | IAdminSetCallTypeResponse400;

export interface IAdminSetCallTypeResponse200 extends IModelResponse<IAdminSetCallTypeResponse> {
  statusCode: 200;
}

export interface IAdminSetCallTypeResponse400 extends IModelResponse<IAdminSetCallTypeResponseError> {
  statusCode: 400;
}

export interface IAdminSetCallTypeResponse {}

export interface IAdminSetCallTypeResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IAdminSetCallTypeResponse | IAdminSetCallTypeResponseError;

export enum EChangerType {
  /** Тип звонка изменен застройщиком **/
  Builder = 'builder',
  /** Итоговый тип звонка (всегда изменяется админом **/
  Final = 'final',
}

export enum EType {
  /** Нецелевой **/
  NonTarget = 'nonTarget',
  /** Не установлен **/
  NotSpecified = 'notSpecified',
  /** Целевой **/
  Target = 'target',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IAdminSetCallTypeResponse): TResponse200;
  400(response: IAdminSetCallTypeResponseError): TResponse400;
}
