import { mergeStyles } from '@cian/utils';

import * as styles from './DateTimeFieldMobile.css';
import { createFormattedDate, createInputDate } from '../../utils/datetime';

interface IDateFieldProps {
  value?: string | null;
  label?: string;
  containerClass?: string;
  renderClearButton?: boolean;
  onChange(day: string): void;
  isDateTo?: boolean;
}

export const DateTimeFieldMobile = ({
  containerClass,
  label,
  value,
  onChange,
  renderClearButton,
  isDateTo,
}: IDateFieldProps) => (
  <div {...mergeStyles(styles['container'], containerClass)}>
    <label>{label}</label>
    <input
      type="date"
      value={createFormattedDate(value)}
      onChange={e => onChange(createInputDate(e.currentTarget.value, isDateTo))}
    />
    {renderClearButton && (
      <button type="button" onClick={() => onChange('')} {...mergeStyles('btn', 'btn-danger', styles['clear-button'])}>
        <i className="fas fa-times" />
      </button>
    )}
  </div>
);
