import { CombinedState, EnhancedStore, PreloadedState } from '@reduxjs/toolkit';

import { EPageTypes } from 'shared/common/types/pageTypes';
import { IApplicationContext } from 'shared/common/types/applicationContext';
import { createAuthStore } from 'shared/auth/redux';
import { createFeaturesStore } from 'shared/features/redux';

export const mapPageTypeToCreateStoreFunction = {
  [EPageTypes.Auth]: createAuthStore,
  [EPageTypes.VillageFeatures]: createFeaturesStore,
} as Record<EPageTypes, TCreateStoreFunctionSignature>;

export type TCreateStoreFunctionSignature = (
  context: IApplicationContext,
  preloadedState: PreloadedState<CombinedState<unknown>>,
  debugEnabled: boolean,
) => EnhancedStore;

export type TGenericStore<PAGE_TYPE extends EPageTypes = EPageTypes> = ReturnType<
  (typeof mapPageTypeToCreateStoreFunction)[PAGE_TYPE]
>;

export type TGenericStoreState<PAGE_TYPE extends EPageTypes = EPageTypes> = ReturnType<
  TGenericStore<PAGE_TYPE>['getState']
>;
