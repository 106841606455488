import {
  fetchGetManagerList,
  IGetManagerListRequest,
  IGetManagerListResponse,
  IGetManagerListResponseError,
} from '../repositories/countryside/v1/get-manager-list';

import { createSimpleRequest } from './createSimpleRequest';

export const getManagerList = createSimpleRequest<
  IGetManagerListRequest,
  IGetManagerListResponse,
  IGetManagerListResponseError
>('getManagerList', fetchGetManagerList);
