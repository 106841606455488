import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { IContractorCallsPlayerStatus } from '../../types';

/* Экшены для плеера в колтрекинге строителей (/contractor-calltracking) */

export enum EContractorPlayerActionTypes {
  SetFilePath = 'CONTRACTOR_PLAYER_SET_FILE_PATH',
  SetPause = 'CONTRACTOR_PLAYER_SET_PAUSE',
  SetStatus = 'CONTRACTOR_PLAYER_SET_STATUS',
}

// Экшен для установки пути аудиофайла
export type TSetContractorPlayerFilePath = ITypedReduxAction<
  EContractorPlayerActionTypes.SetFilePath,
  string | undefined
>;

export const setContractorPlayerFilePath: (payload: string | undefined) => TSetContractorPlayerFilePath =
  actionGenerator<EContractorPlayerActionTypes.SetFilePath, string | undefined>(
    EContractorPlayerActionTypes.SetFilePath,
  );

// Экшен для установки паузы
export type TSetContractorPlayerPause = ITypedReduxAction<EContractorPlayerActionTypes.SetPause, boolean>;

export const setContractorPlayerPause: (payload: boolean) => TSetContractorPlayerPause = actionGenerator<
  EContractorPlayerActionTypes.SetPause,
  boolean
>(EContractorPlayerActionTypes.SetPause);

// Экшен для установки значений
export type TSetContractorPlayerStatus = ITypedReduxAction<
  EContractorPlayerActionTypes.SetStatus,
  IContractorCallsPlayerStatus
>;

export const setContractorPlayerStatus: (payload: IContractorCallsPlayerStatus) => TSetContractorPlayerStatus =
  actionGenerator<EContractorPlayerActionTypes.SetStatus, IContractorCallsPlayerStatus>(
    EContractorPlayerActionTypes.SetStatus,
  );
