import { isDefined } from '../../../../utils/helpers/isDefined';

import { NumberField } from '../../../number_field';

import * as styles from './index.css';
import { Col, Form, Row } from 'react-bootstrap';
import { mergeStyles } from '@cian/utils';

export interface INumberRangeFieldProps {
  minValue?: number | null;
  maxValue?: number | null;
  containerClass?: string;
  htmlId?: string;
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  required?: boolean;
  useValidation?: boolean;
  allowFloat?: boolean;
  readOnly?: boolean;
  invalid?: boolean;
  onChangeMin(value?: number): void;
  onChangeMax(value?: number): void;
}

export const NumberRangeField = (props: INumberRangeFieldProps) => {
  const {
    allowFloat,
    useValidation,
    label,
    min = 0,
    max = 999999999,
    required,
    minValue,
    maxValue,
    onChangeMin,
    onChangeMax,
    readOnly,
    invalid,
    containerClass,
  } = props;
  let isInvalid = useValidation && (min > (maxValue || minValue || 0) || max < (maxValue || minValue || 0));
  isInvalid = invalid || isInvalid || Boolean(maxValue && minValue && maxValue < minValue);
  const maxValueField = isDefined<number>(maxValue) && Number.isFinite(maxValue) ? maxValue.toString() : '';
  const minValueField = isDefined<number>(minValue) && Number.isFinite(minValue) ? minValue.toString() : '';

  return (
    <Form.Group {...mergeStyles(containerClass, styles['text-field'])}>
      {label && (
        <Form.Label>
          {label}&nbsp;
          {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      <Row>
        <Col md={6}>
          <NumberField
            value={minValueField}
            invalid={isInvalid}
            placeholder="От"
            min={min}
            max={max}
            allowFloat={allowFloat}
            onChange={onChangeMin}
            readOnly={readOnly}
          />
        </Col>
        <Col md={6}>
          <NumberField
            value={maxValueField}
            invalid={isInvalid}
            placeholder="До"
            min={min}
            max={max}
            allowFloat={allowFloat}
            onChange={onChangeMax}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};
