import { ICPLSettingsHistoryItemSchema } from '../../../repositories/countryside-calltracking/entities/cpl_settings_history/CPLSettingsHistoryItemSchema';
import { IDropdownValue, SelectField } from '../../SelectField';
import { CplSettingsHistoryList } from '../CplSettingsHistoryList';

import * as styles from './CplSettingsHistory.css';

export interface ICplSettingsHistoryProps {
  list: ICPLSettingsHistoryItemSchema[];
  selectedVillageId: number;
  villages: IDropdownValue[];
  isAdmin: boolean;
  onVillageChange(value: string): void;
}

export function CplSettingsHistory({
  list,
  isAdmin,
  selectedVillageId,
  villages,
  onVillageChange,
}: ICplSettingsHistoryProps) {
  if (!villages.length) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <h2 className={styles['title']}>История</h2>
        <div className={styles['village_select']}>
          <SelectField value={selectedVillageId} values={villages} onChange={onVillageChange} />
        </div>
      </div>
      <CplSettingsHistoryList list={list} isAdmin={isAdmin} />
    </div>
  );
}
