export enum ERoutes {
  Auth = '/auth',
  CplSettings = '/cpl-settings',
  VillageFeatures = '/features',
  Villages = '/villages',
  CreateVillage = '/villages/create',
  EditVillage = '/villages/edit/:id',
  VillageBuilders = '/builders',
  CreateVillageBuilder = '/builders/create',
  EditVillageBuilder = '/builders/edit/:id',
  VillageCalltracking = '/calltracking',
  BuildersCalltracking = '/contractor-calltracking',
}
