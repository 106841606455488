import { ICPLSettingsHistoryItemSchema } from '../repositories/countryside-calltracking/entities/cpl_settings_history/CPLSettingsHistoryItemSchema';
import { formatDateTime } from './datetime';

export function isLastDayOfMonth(item: ICPLSettingsHistoryItemSchema, list: ICPLSettingsHistoryItemSchema[]): boolean {
  const itemMonth = Number(formatDateTime(item.createdAt, '+0000', 'MM'));
  const itemIndex = list.indexOf(item);
  const nextItem = list[itemIndex + 1];

  if (!nextItem) {
    return false;
  }

  const nextItemMonth = Number(formatDateTime(nextItem.createdAt, '+0000', 'MM'));

  return nextItemMonth !== itemMonth;
}
