export const GET_FEATURE_LIST = '/v1/get-feature-list/';
export const UPLOAD_IMAGE = '/v1/upload-image/';
export const CREATE_FEATURE = '/v1/create-feature/';
export const CREATE_VILLAGE = '/v1/create-village/';
export const UPDATE_FEATURE = '/v1/update-feature/';
export const GET_USER_ROLE_LIST = '/v2/get-user-role-list/';
export const GET_REGION_LIST = '/v1/get-regions/';
export const CREATE_BUILDER = '/v1/create-builder/';
export const GET_BUILDER = '/v1/get-builder/';
export const GET_BUILDER_LIST = '/v1/get-builder-list/';
export const UPDATE_BUILDER = '/v1/update-builder/';
export const GET_VILLAGE_LIST = '/v1/get-village-list/';
export const GET_BUILDER_NAME_LIST = '/v1/get-builder-name-list/';
export const GET_GEOCODE = '/v1/geocode/';
export const GET_DETAILS_BY_GEOCODE = '/v2/get-address/';
export const GET_VILLAGE = '/v1/get-village/';
export const UPDATE_VILLAGE = '/v1/update-village/';
export const GET_ROAD_LIST = '/v2/get-roads/';
export const GET_STATION_LIST = '/v1/railways/search-railways-by-direction/';
export const GET_VILLAGE_CHANGELOG = '/v1/get-village-changelog/';
export const GET_CALLTRACKING_REPORT = '/v1/admin/get-calls-report/';
export const GET_CALLTRACKING_CALL_RECORD = '/v1/admin/get-call-record/';
export const GET_VILLAGE_TIMELINE = '/v1/get-village-timeline/';
