import { createSlice } from '@reduxjs/toolkit';
import { TFeature } from 'shared/features/types/featureList';

const initialState: TFeature[] = [];

const featureListSlice = createSlice({
  initialState,
  name: 'featureList',
  reducers: {},
});

export const { reducer: featureListReducer } = featureListSlice;
