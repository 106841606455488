import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/utils';
import { AppContext } from '../../AppContext';

import { IGalleryItem } from '../../common/interfaces';
import { uploadImage } from '../../http';
import { LoadingIndicator } from '../loading_indicator';
import { ISortingItems, SortingComponent } from '../SortingComponent';
import { GalleryImage } from './GalleryImage';

import * as styles from './Gallery.css';
import { FormLabel } from 'react-bootstrap';
import { appendTemporaryKey } from 'shared/utils/sortable';
import { ISortable } from 'shared/types/sortable';

export interface IGalleryProps {
  areInputsDisplayed?: boolean;
  images: IGalleryItem[];
  minWidth: number;
  minHeight?: number;
  maxInputLength?: number;
  onChange(urls: IGalleryItem[]): void;
  previewWidth?: number | string;
  previewHeight?: number | string;
  useValidation: boolean;
  onInputChange?(index: number, text: string): void;
  minCount?: number;
  maxCount?: number;
  required?: boolean;
  readOnly?: boolean;
  withThumbs?: boolean;
  itemStyles?: IStyleConfig;
  label?: string;
  className?: string;
}

interface IGalleryState {
  invalidMinHeight: boolean;
  invalidMinSize: boolean;
  uploadingCount: number;
  images: ISortable<IGalleryItem>[];
}

export class Gallery extends React.Component<IGalleryProps, IGalleryState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public state = {
    invalidMinHeight: false,
    invalidMinSize: false,
    uploadingCount: 0,
    images: appendTemporaryKey(this.props.images),
  };

  public componentDidUpdate(prevProps: Readonly<IGalleryProps>): void {
    /** При изменении описания айтема, нужно изменить описание у этого айтема в стейте */
    if (prevProps.images !== this.props.images && prevProps.images.length === this.props.images.length) {
      const { images } = this.props;

      this.setState(prevState => ({
        ...prevState,
        images: this.state.images.map((image, index) => ({
          ...image,
          item: {
            ...image.item,
            description:
              image.item.description !== images[index].description ? images[index].description : image.item.description,
          },
        })),
      }));
    }
  }

  public render() {
    const {
      areInputsDisplayed,
      useValidation,
      minWidth,
      minHeight = 400,
      minCount,
      maxCount = 999,
      required,
      readOnly,
      itemStyles,
      label,
      className,
    } = this.props;

    const { invalidMinSize, invalidMinHeight, uploadingCount, images } = this.state;

    return (
      <div {...mergeStyles(className, styles['container'])}>
        {label && (
          <FormLabel>
            {label}&nbsp;
            {required && <span className="text-danger">*</span>}
          </FormLabel>
        )}
        <div className={styles['images-container']}>
          <SortingComponent
            list={images}
            items={this.sortingItems}
            containerStyle={mergeStyles(
              styles['sorting_container'],
              !areInputsDisplayed && styles['sorting_container--inline'],
            )}
            itemStyle={mergeStyles(styles['sorting_item'], itemStyles)}
            onChange={this.onImagesChange}
            readOnly={readOnly}
          />
          {uploadingCount === 0 && !readOnly && maxCount > images.length && (
            <label className={styles['label']}>
              <input
                type="file"
                accept="image/*"
                className={styles['select-input']}
                multiple
                onChange={this.fileChangeHandler}
              />
              +
            </label>
          )}
          {uploadingCount > 0 && (
            <div className={styles['loading-indicator-wrapper']}>
              <LoadingIndicator />
            </div>
          )}
        </div>
        {invalidMinSize && <div className="text-danger">Минимальная ширина изображения {minWidth}&nbsp;px</div>}
        {invalidMinHeight && <div className="text-danger">Минимальная высота изображения {minHeight}&nbsp;px</div>}
        {useValidation && required && !images.length && <div className="text-danger">Выберите изображение</div>}
        {useValidation && minCount && images.length < minCount && (
          <div className="text-danger">Минимальное количество изображений {minCount}</div>
        )}
        {useValidation && maxCount && images.length > maxCount && (
          <div className="text-danger">Максимальное количество изображений {maxCount}</div>
        )}
      </div>
    );
  }

  private removeImage = (index: number) => () => {
    const { images } = this.state;

    images.splice(index, 1);

    this.onImagesChange(images);
  };

  private fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ invalidMinSize: false, invalidMinHeight: false });
    const files = e.currentTarget.files || [];
    const { minWidth, minHeight } = this.props;

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64 = String(reader.result);
        const imageElement = new Image();

        imageElement.onload = () => {
          if (imageElement.width < minWidth) {
            this.setState({ invalidMinSize: true });
          } else if (minHeight && imageElement.height < minHeight) {
            this.setState({ invalidMinHeight: true });
          } else {
            this.processImage(base64);
          }
        };

        imageElement.src = base64;
      };

      reader.readAsDataURL(files[i]);
    }
  };

  private reduceUploadingCount = () => {
    if (this.state.uploadingCount > 0) {
      this.setState({ uploadingCount: this.state.uploadingCount - 1 });
    }
  };

  private onDescriptionChange = (index: number) => (value: string) => {
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange(index, value);
    }
  };

  private processImage = async (base64: string) => {
    const { withThumbs } = this.props;
    const { httpApi, logger } = this.context;
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });

    try {
      const { url, thumbnails } = await uploadImage(httpApi, logger, { base64 });

      if (url) {
        this.onImagesChange([
          ...this.state.images,
          ...appendTemporaryKey([
            {
              url,
              thumbnailList: withThumbs ? thumbnails : undefined,
            },
          ]),
        ]);
      }
      this.reduceUploadingCount();
    } catch (error) {
      this.reduceUploadingCount();
    }
  };

  private getItemProps = (index: number) => {
    const { areInputsDisplayed, maxInputLength, previewHeight = 200, previewWidth, readOnly } = this.props;

    return {
      item: this.state.images[index].item,
      areInputsDisplayed,
      maxInputLength,
      maxHeight: previewHeight,
      maxWidth: previewWidth,
      onDescriptionChange: this.onDescriptionChange(index),
      onRemove: this.removeImage(index),
      readOnly,
    };
  };

  private onImagesChange = (images: ISortable<IGalleryItem>[]) =>
    this.setState({ images }, () => this.props.onChange(images.map(({ item }) => item)));

  private get sortingItems(): ISortingItems {
    return this.state.images.reduce(
      (acc, { uuid }, index) => ({
        ...acc,
        [uuid]: <GalleryImage {...this.getItemProps(index)} />,
      }),
      {},
    ) as ISortingItems;
  }
}
