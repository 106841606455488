/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetVillageListModel,
  IGetVillageListRequest,
  IMappers,
  IGetVillageListResponse,
  IGetVillageListResponseError,
  TGetVillageListResponse,
} from './types';

const defaultConfig: TGetVillageListModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v1/get-village-list/',
  hostType: 'api',
} as TGetVillageListModel;

function createGetVillageListModel(parameters: IGetVillageListRequest): TGetVillageListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetVillageListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetVillageListRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetVillageList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetVillageListOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetVillageListResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetVillageListModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetVillageListResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetVillageListResponseError);
    }
  }

  return { response, statusCode } as TGetVillageListResponse;
}

export { defaultConfig, createGetVillageListModel, fetchGetVillageList };
