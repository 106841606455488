export const HEADERS = [
  'ID звонка',
  'ID строителя',
  'Строитель',
  'Номер абонента',
  'Подменный номер',
  'Дата и время звонка',
  'Длительность звонка',
  'Запись звонка',
  'Статус',
  'Итог',
];
