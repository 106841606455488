import {
  fetchGetBuilderAmocrmUrl,
  IGetBuilderAmocrmUrlRequest,
  IGetBuilderAmocrmUrlResponse,
  IGetBuilderAmocrmUrlResponseError,
} from '../repositories/countryside/v1/get-builder-amocrm-url';

import { createSimpleRequest } from './createSimpleRequest';

export const getBuilderAmocrmUrl = createSimpleRequest<
  IGetBuilderAmocrmUrlRequest,
  IGetBuilderAmocrmUrlResponse,
  IGetBuilderAmocrmUrlResponseError
>('getBuilderAmocrmUrl', fetchGetBuilderAmocrmUrl);
