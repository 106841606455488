export interface IGetCallsRequest {
  /** ID подрядчика **/
  contractorId?: number;
  /** Название подрядчика **/
  contractorName?: string;
  /** С какого времени искать звонки **/
  dateFrom?: string;
  /** По какое время искать звонки **/
  dateTo?: string;
  /** Страница выдачи **/
  page?: number;
  /** Размер страницы **/
  pageSize?: number;
  /** ID проекта **/
  projectId?: number;
  /** Название проекта **/
  projectName?: string;
  /** ID регионов проектов **/
  regionId?: number[];
  /** Регион абонента **/
  sourceRegionId?: number[];
  /** Статус звонка **/
  status?: EStatus[];
}

export enum EStatus {
  /** Занято **/
  Busy = 'busy',
  /** Сбросили **/
  Hgup = 'hgup',
  /** Без ответа **/
  NoAnswer = 'noAnswer',
  /** Успешный **/
  Success = 'success',
  /** Техническая ошибка **/
  TechProblem = 'techProblem',
  /** Выключен **/
  TurnedOff = 'turnedOff',
  /** Неизвестно **/
  Unknown = 'unknown',
}
