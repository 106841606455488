import * as React from 'react';

import * as styles from './Heading.css';

interface IHeadingProps {
  title: string;
  children?: React.ReactNode;
}

export function Heading({ title, children }: IHeadingProps) {
  return (
    <div className={styles['heading']}>
      <h2 className={styles['heading_title']}>{title}</h2>
      {children}
    </div>
  );
}
