import { Table } from 'react-bootstrap';
import { prettifyValue } from '../../utils';

import * as styles from './SimpleTable.css';

export type TSimpleTableValue = JSX.Element | number | string | null | undefined;

export interface ISimpleTableProps {
  headers: string[];
  title?: string;
  rows: TSimpleTableValue[][];
}

export function SimpleTable({ headers, rows, title }: ISimpleTableProps) {
  return (
    <div className={styles['container']}>
      {title && <strong className={styles['title']}>{title}</strong>}
      <Table responsive>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((value, index) => (
                <td key={index}>{prettifyValue(value)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
