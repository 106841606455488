import { Col, Row } from 'react-bootstrap';

import { HouseMaterialsDropdownValues } from '../../../common/interfaces';
import { EHouseMaterialList } from '../../../repositories/countryside/v1/update-village';
import { MultiselectorField } from '../../multiselector_field';

interface IHouseMaterialsProps {
  materials: EHouseMaterialList[];
  readOnly?: boolean;
  onChange(value: EHouseMaterialList[]): void;
}

export const HouseMaterials = ({ materials, readOnly, onChange }: IHouseMaterialsProps) => (
  <div>
    <Row>
      <Col md={4} className="mb-3">
        <MultiselectorField
          label="Материалы домов"
          value={materials}
          invalid={false}
          values={HouseMaterialsDropdownValues}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Col>
    </Row>
  </div>
);
