import { createSimpleRequest } from './createSimpleRequest';
import {
  fetchCommentCall,
  ICommentCallRequest,
  ICommentCallResponse,
  ICommentCallResponseError,
} from '../repositories/countryside-calltracking/v1/comment-call';

export const updateComment = createSimpleRequest<ICommentCallRequest, ICommentCallResponse, ICommentCallResponseError>(
  'blockPhone',
  fetchCommentCall,
);
