import { Outside } from 'shared/components/Outside';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import { AppContext } from '../../AppContext';
import { IRegion } from '../../common/interfaces';
import { ERoleType, mapStatusToLabel } from '../../constants/calltracking';
import { ICallListItemSchema } from '../../repositories/countryside-calltracking/entities/schemas/CallListItemSchema';
import { EFinalType } from '../../repositories/countryside-calltracking/v1/admin-get-calls/types';
import { EChangerType } from '../../repositories/countryside-calltracking/v1/admin-set-call-type/types';
import { formatDateTime } from '../../utils/datetime';
import { getRegionNameFromList } from '../../utils/getRegionNameFromList';
import { noop } from '../../utils/noop';
import { prettifySeconds } from '../../utils/prettifySeconds';
import { saveFileFromPath } from '../../utils/saveFileFromPath';
import { useGetCallRecordHook } from '../../utils/useGetCallRecordHook';
import { useCalltrackingBlacklistHook, ECalltrackingBlockState } from '../../utils/useCalltrackingBlacklistHook';
import { CalltrackingAudioLink } from '../CalltrackingAudioLink';
import { CalltrackingBlackListInfo } from '../CalltrackingBlackListInfo';
import { CalltrackingBlackListModal } from '../CalltrackingBlackListModal';
import { CalltrackingListItemHistory } from '../CalltrackingListItemHistory';
import { CalltrackingPopupItem } from '../CalltrackingPopupItem/CalltrackingPopupItem';
import { Portal } from '../Portal';
import { CalltrackingTypeField } from '../CalltrackingTypeField';
import { getMapValue } from '../../utils/formatting';
import { PhoneLink } from '../PhoneLink/PhoneLink';
import { WithTitle } from '../WIthTitle';
import { CalltrackingComment } from '../CalltrackingComment';
import { CalltrackingFinalButton } from '../CalltrackingFinalButton';
import * as styles from './CalltrackingListItemPopup.css';
import { ICallHistoryItem, TCallType, TChangerType } from 'shared/types';

interface ICalltrackingListItemPopupProps {
  call: ICallListItemSchema | undefined | null;
  isAlertVisible?: boolean;
  history: ICallHistoryItem[] | null;
  isAdmin: boolean;
  canEditBlackList: boolean;
  regionList: IRegion[];
  setCalltrackingType(callId: number, changerType: TChangerType, type: TCallType): void;
  onClose(): void;
  onBlackListStatusChange(): void;
  onCommentSave(): void;
}

export function CalltrackingListItemPopup({
  call,
  setCalltrackingType,
  isAlertVisible,
  history,
  onClose,
  isAdmin,
  canEditBlackList,
  regionList,
  onBlackListStatusChange,
  onCommentSave,
}: ICalltrackingListItemPopupProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const { callRecord, getCallRecord } = useGetCallRecordHook({
    callId: call ? call.callId : null,
    httpApi,
    logger,
  });

  const [isBlackListModalVisible, setBlackListModalVisibility] = React.useState(false);
  const { isBlocked, isPending, setBlockState } = useCalltrackingBlacklistHook({
    call,
    httpApi,
    logger,
    callback: onBlackListStatusChange,
  });

  if (!call) {
    return null;
  }

  const {
    callId,
    builderName,
    villageName,
    callDate,
    recordFilePath,
    duration,
    sourcePhone,
    sourceRegion,
    status,
    calltrackingPhone,
    destinationPhone,
    localDate,
    regionId,
  } = call;
  const callRegionName = sourceRegion && getRegionNameFromList(regionList, sourceRegion);
  const villageRegionName = regionId && getRegionNameFromList(regionList, regionId);

  return (
    <Portal>
      <div className={styles['container']}>
        <Outside onOutside={isAlertVisible || isBlackListModalVisible ? noop : onClose}>
          <div className={styles['outside_container']}>
            <div {...mergeStyles(styles['modal'], 'container-fluid')}>
              <div className={styles['modal_header']}>
                Звонок №{callId}
                <button className={styles['close_button']} onClick={onClose}>
                  &times;
                </button>
              </div>
              <div className={styles['modal_content']}>
                <CalltrackingPopupItem label="ID звонка">{callId}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="Застройщик">{builderName}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="Коттеджный посёлок">{villageName}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="Телефон">
                  <PhoneLink phone={sourcePhone} isBlocked={isBlocked} />
                  {callRegionName && <span className={styles['region_name']}>({callRegionName})</span>}
                  {isBlocked && <CalltrackingBlackListInfo data={call.blackListInfo} isMobile />}
                </CalltrackingPopupItem>
                {calltrackingPhone && (
                  <CalltrackingPopupItem label="Подменный телефон">
                    <PhoneLink phone={calltrackingPhone} />
                  </CalltrackingPopupItem>
                )}
                {destinationPhone && (
                  <CalltrackingPopupItem label="Телефон застройщика">
                    <PhoneLink phone={destinationPhone} />
                  </CalltrackingPopupItem>
                )}
                <CalltrackingPopupItem label="Дата звонка (МСК)">
                  {formatDateTime(callDate, '+0300')}
                </CalltrackingPopupItem>
                {localDate && (
                  <CalltrackingPopupItem label="Местное время звонка">
                    {formatDateTime(localDate)}
                    {villageRegionName && <span className={styles['region_name']}>{villageRegionName}</span>}
                  </CalltrackingPopupItem>
                )}
                <CalltrackingPopupItem label="Звонок">
                  {recordFilePath ? (
                    <>
                      <div>
                        <audio src={recordFilePath} controls preload="none" />
                        <button
                          {...mergeStyles('btn', 'btn-primary', styles['download_button'])}
                          onClick={saveFileFromPath(recordFilePath)}
                          title="Скачать"
                        >
                          <i className="fas fa-download" />
                        </button>
                      </div>
                      <CalltrackingAudioLink callRecord={callRecord} onLoadButtonClick={getCallRecord} isMobile />
                    </>
                  ) : (
                    'Запись отсутствует'
                  )}
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Длительность">{prettifySeconds(duration)}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="Статус">{getMapValue(mapStatusToLabel, status)}</CalltrackingPopupItem>
                <CalltrackingPopupItem label="CPL,&nbsp;₽">
                  {call.finalType === EFinalType.Target ? (
                    call.cpl
                  ) : (
                    <WithTitle title={`Стоимость целевого звонка: ${call.cpl}`}>0</WithTitle>
                  )}
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Тип от застройщика">
                  <CalltrackingTypeField
                    isHandheld
                    call={call}
                    setCalltrackingType={setCalltrackingType}
                    changerType={EChangerType.Builder}
                    type={ERoleType.Builder}
                  />
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Итоговый тип">
                  <CalltrackingFinalButton
                    isHandheld
                    callId={call.callId}
                    finalType={call.finalType}
                    isEditable={call.isEditableType}
                    setCalltrackingType={setCalltrackingType}
                  />
                </CalltrackingPopupItem>
                <CalltrackingPopupItem label="Комментарий" />
                <CalltrackingComment onCommentSave={onCommentSave} call={call} isAdmin={isAdmin} />
                <CalltrackingListItemHistory history={history} tableStyles={styles['history_table']} />
              </div>
              <div className={styles['modal_footer']}>
                {canEditBlackList && (
                  <div className={styles['black_list_btn']}>
                    {isBlocked ? (
                      <button
                        className="btn btn-success"
                        disabled={isPending}
                        onClick={() => setBlockState(ECalltrackingBlockState.Unblock)}
                      >
                        Удалить из ЧС
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger"
                        disabled={isPending}
                        onClick={() => setBlackListModalVisibility(true)}
                      >
                        Добавить в ЧС
                      </button>
                    )}
                  </div>
                )}
                <button className="btn btn-danger" onClick={onClose}>
                  Закрыть
                </button>
              </div>
            </div>
            {isBlackListModalVisible && (
              <CalltrackingBlackListModal
                data={call.blackListInfo}
                onConfirm={data => {
                  setBlockState(ECalltrackingBlockState.Block, data);
                  setBlackListModalVisibility(false);
                }}
                onClose={() => {
                  setTimeout(() => setBlackListModalVisibility(false));
                }}
              />
            )}
          </div>
        </Outside>
      </div>
    </Portal>
  );
}
