/* eslint-disable max-lines */
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import { ELocationType } from '../../../../node/repositories/countryside/v1/get-village/types';
import { IGetVillageChangelogResponse } from '../../../../node/repositories/countryside/v1/get-village-changelog';
import { AppContext } from '../../../AppContext';
import { commonChangelogMapVillage } from '../../../common/helpers';

import {
  IAlert,
  IBuilderNameListItem,
  ICommunicationSet,
  ICounterSet,
  IFeature,
  IGalleryItem,
  INewObjectCategorySet,
  IRailwayDirection,
  IRoad,
  IRoadInfo,
  ISelectedFeature,
  IStation,
  IStationInfo,
  IVideo,
  IVillage,
  IVillageAddress,
  TVillageInfrastructure,
} from '../../../common/interfaces';
import { getRailwayList, getRoadList, IRailwayListData, updateVillage, uploadImage } from '../../../http';
import { IManagerForListSchema } from '../../../repositories/countryside/entities/schemas/ManagerForListSchema';
import { EHouseMaterialList, IUpdateVillageRequest } from '../../../repositories/countryside/v1/update-village';
import { IFatIcon, IFatInfrastructureListItem } from '../../../types/fat';
import { getFormattedNewobjectCategorySet } from '../../../utils';
import { DEFAULT_ALERT, MAX_PRICE } from '../../../utils/constants';
import { isUrlValid } from '../../../utils/urlValidator';
import { Alert } from '../../Alert';
import { Changelog } from '../../Changelog';
import { SelectFeature } from '../../features/SelectFeature';
import { Gallery } from '../../gallery';
import { IImage, ImageSelector } from '../../image_selector';
import { Infrastructure } from '../../infrastructure';
import { TextAreaField } from '../../textarea_field';
import { FatFeatureList } from '../FatFeatureList';
import { FatInfrastructureList } from '../FatInfrastructureList';
import { Neighbourhood } from '../Neighbourhood';
import { VillageForm } from '../village_form';
import { HouseMaterials } from '../village_house_materials';
import { VillageObjectsReport } from '../village_objects_report';
import { VillageCommunications } from '../VillageCommunications';
import { VillageObjects } from '../VillageObjects';
import { VillageRailway } from '../VillageRailway';
import { VillageRoads } from '../VillageRoads';
import { VillageVideos } from '../VillageVideos';

import * as styles from './index.css';
import { Button, Col, Row } from 'react-bootstrap';

interface IProps {
  builderNames: IBuilderNameListItem[];
  managerList: IManagerForListSchema[];
  fatIconList: IFatIcon[];
  roads: IRoad[];
  village: IVillage;
  possibleFeatureList: IFeature[];
  countrysideRoadRegionsArray: number[];
  railway: IRailwayListData;
  countrysideStationRegionsArray: number[];
  villageChangelog: IGetVillageChangelogResponse;
  readOnly?: boolean;
  fatCardEnabled?: boolean;
  cplEnabled?: boolean;
}

interface IState {
  availableStations: IStation[];
  availableDirections: IRailwayDirection[];
  form: IVillage;
  generalPlan: IImage;
  isImagePending: boolean;
  useValidation: boolean;
  alert: IAlert;
  areVillageDistancesValid: boolean;
  areVillageCommunicationsValid: boolean;
  isCategorySetValid: boolean;
  isCounterSetValid: boolean;
  isInfrastructureValid: boolean;
  isRoadInfoListValid: boolean;
  isStationInfoListValid: boolean;
  roads: IRoad[];
  shouldRenderNewRoads: boolean;
  shouldRenderNewStations: boolean;
}

interface IExtraHandlerParams {
  [key: string]: Partial<IVillage>;
}

const FAT_CARD_MIN_PHOTOS_COUNT = 3;

export class VillageEditForm extends React.Component<IProps, IState> {
  public static contextType = AppContext;
  public context: React.ContextType<typeof AppContext>;
  public constructor(props: IProps) {
    super(props);
    const { village, countrysideRoadRegionsArray, countrysideStationRegionsArray, roads, railway } = this.props;

    this.state = {
      alert: DEFAULT_ALERT,
      areVillageCommunicationsValid: true,
      areVillageDistancesValid: true,
      availableDirections: railway.directions,
      availableStations: railway.stations,
      form: {
        ...village,
        managerId: village.manager ? village.manager.id : undefined,
      },
      generalPlan: { url: village.generalPlanUrl },
      isCategorySetValid: true,
      isCounterSetValid: true,
      isImagePending: false,
      isInfrastructureValid: true,
      isRoadInfoListValid: true,
      isStationInfoListValid: true,
      roads,
      shouldRenderNewRoads: countrysideRoadRegionsArray.includes(village.regionId),
      shouldRenderNewStations: countrysideStationRegionsArray.includes(village.regionId),
      useValidation: false,
    };
  }

  public extraHandlerParams: IExtraHandlerParams = {
    isCropped: {
      isFat: false,
    },
    isFat: {
      isCropped: false,
    },
  };

  public componentDidUpdate(_: IProps, prevState: IState) {
    if (!prevState.alert.isVisible && this.state.alert.isVisible) {
      setTimeout(() => {
        this.setState({
          alert: DEFAULT_ALERT,
        });
      }, 2000);
    }
  }

  public render() {
    const {
      alert,
      form,
      useValidation,
      generalPlan,
      isImagePending,
      roads,
      availableDirections,
      availableStations,
      shouldRenderNewRoads,
      shouldRenderNewStations,
    } = this.state;
    const { commentary, isFat } = form;
    const {
      builderNames,
      managerList,
      fatIconList,
      villageChangelog: { villageChangelog = [] },
      readOnly,
      fatCardEnabled,
      cplEnabled,
      village: { name: oldName, seo },
    } = this.props;
    const url = seo && seo.url;

    return (
      <div>
        <div className={styles['component-block']}>
          {alert.isVisible && <Alert onClick={this.hideAlert} text={alert.text} type={alert.type} />}
          <VillageForm
            builderNames={builderNames}
            managerList={managerList}
            handleFieldChange={this.handleFieldChange}
            handleAddressFieldChange={this.handleAddressFieldChange}
            handleCplFieldChange={this.handleCplFieldChange}
            village={form}
            useValidation={useValidation}
            isCreation={false}
            handleSaveButtonClick={this.submitVillage}
            isSaveButtonDisabled={isImagePending}
            handleShowPhoneChange={this.handleShowPhoneChange}
            handleIsVisibleChange={this.handleIsVisibleChange}
            readOnly={readOnly}
            fatCardEnabled={fatCardEnabled}
            cplEnabled={cplEnabled}
            oldName={oldName}
            url={url}
            hasCroppedCard
            hasFreeCpl
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Внутреннее примечание</h2>
          <Row>
            <Col md={8} className="mb-3">
              <TextAreaField
                value={commentary ? commentary : ''}
                onChange={(value: string) => this.handleCommentaryChange(value)}
                placeholder="Не отображается в пользовательской части сайта"
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Типы объектов</h2>
          <VillageObjects
            objectSet={form.newobjectCategorySet}
            onChange={this.handleObjectSetChange}
            onInit={this.handleObjectSetInit}
            readOnly={readOnly}
            fatCardEnabled={fatCardEnabled}
            fatIconList={fatIconList}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Сводка объектов</h2>
          <VillageObjectsReport
            counterSet={form.counterSet || {}}
            useValidation={useValidation}
            onChange={this.handleCounterSetChange}
            onInit={this.handleCounterSetInit}
            readOnly={readOnly}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Ген план</h2>
          <ImageSelector
            value={generalPlan}
            minWidth={100}
            minHeight={100}
            onChange={this.handleGeneralPlanChange}
            readOnly={readOnly}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Изображения коттеджного посёлка</h2>
          <Gallery
            images={
              form.gallery && form.gallery.imageList
                ? form.gallery.imageList.map(image => ({ url: image.fullUrl }))
                : []
            }
            minWidth={640}
            onChange={this.handleMainGalleryChange}
            useValidation={useValidation}
            required
            minCount={isFat ? FAT_CARD_MIN_PHOTOS_COUNT : undefined}
            readOnly={readOnly}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Ссылки на видео</h2>
          <VillageVideos videoList={form.videoList || []} onChange={this.handleVideoListChange} readOnly={readOnly} />
        </div>
        {shouldRenderNewRoads && (
          <div className={styles['component-block']}>
            <h2 className={styles['component-block__header']}>Расположение относительно шоссе</h2>
            <VillageRoads
              roadInfoList={form.roadInfoList || []}
              regionId={form.regionId}
              roads={roads}
              onChange={this.handleRoadInfoListChange}
              onInit={this.handleRoadInfoListInit}
              readOnly={readOnly}
            />
          </div>
        )}
        {shouldRenderNewStations && (
          <div className={styles['component-block']}>
            <h2 className={styles['component-block__header']}>Расположение относительно ЖД-станций</h2>
            <VillageRailway
              stationInfoList={form.stationInfoList || []}
              availableStations={availableStations}
              availableDirections={availableDirections}
              regionId={form.regionId}
              onChange={this.handleStationInfoListChange}
              onInit={this.handleStationInfoListInit}
              readOnly={readOnly}
            />
          </div>
        )}
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Коммуникации</h2>
          <VillageCommunications
            communicationSet={form.communicationSet}
            onChange={this.handleCommunicationsChange}
            onInit={this.handleCommunicationsInit}
            readOnly={readOnly}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Окрестности</h2>
          <Neighbourhood
            neighborhoodList={form.neighbourhoodList || []}
            onChange={this.handleNeighbourhoodChange}
            onDescriptionChange={this.handleNeighbourhoodDescriptionChange}
            readOnly={readOnly}
          />
        </div>
        <FatFeatureList
          fatFeatureList={form.fatFeatureList || []}
          fatCardEnabled={fatCardEnabled}
          onChange={this.handleFatFeatureListChange}
          onDescriptionChange={this.handleFatFeatureTextChange}
          readOnly={readOnly}
        />
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Материалы домов</h2>
          <HouseMaterials
            materials={form.houseMaterialList || []}
            onChange={this.handleHouseMaterialsChange}
            readOnly={readOnly}
          />
        </div>
        <div className={styles['component-block']}>
          <h2 className={styles['component-block__header']}>Инфраструктура</h2>
          <Infrastructure
            infrastructures={form.infrastructureList}
            onInit={this.handleInfrastructureInit}
            onChange={this.handleInfrastructureChange}
            useValidation={useValidation}
            readOnly={readOnly}
          />
        </div>
        {fatCardEnabled && (
          <div className={styles['component-block']}>
            <h2 className={styles['component-block__header']}>Супер премиум инфраструктура</h2>
            <FatInfrastructureList
              list={form.fatInfrastructureList || []}
              onChange={this.handleFatInfrastructureListChange}
              fatIconList={fatIconList}
            />
          </div>
        )}
        <div className={styles['component-block']}>
          <SelectFeature
            selectedFeatures={form.featureList}
            possibleFeatureList={this.props.possibleFeatureList}
            onChange={this.handleFeaturesChange}
            readOnly={readOnly}
          />
        </div>
        <Row className={styles['village-edit__footer']}>
          <Col md={12} className="text-end">
            {!readOnly && (
              <Button
                variant="success"
                className={styles['save-btn']}
                type="submit"
                disabled={isImagePending}
                onClick={this.submitVillage}
              >
                Сохранить
              </Button>
            )}
          </Col>
        </Row>
        {villageChangelog && villageChangelog.length > 0 && (
          <div {...mergeStyles('row', styles['component-block'])}>
            <h2 className={styles['component-block__header']}>История изменения посёлка</h2>
            <Changelog changelog={commonChangelogMapVillage(villageChangelog)} />
          </div>
        )}
      </div>
    );
  }

  private handleShowPhoneChange = (checked: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        displayBuilderPhone: checked,
      },
    });
  };

  private handleIsVisibleChange = (checked: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        isVisible: !checked,
      },
    });
  };

  private handleFatFeatureListChange = (galleryItems: IGalleryItem[]) =>
    this.setState({
      form: {
        ...this.state.form,
        fatFeatureList: galleryItems.map((item, ordering) => ({
          id: item.id,
          text: item.description || '',
          imageUrl: item.url || '',
          ordering,
        })),
      },
    });

  private handleFatFeatureTextChange = (itemIndex: number, text: string) => {
    const { form } = this.state;
    if (!form.fatFeatureList) {
      return;
    }
    const fatFeatureList = form.fatFeatureList.slice();

    fatFeatureList[itemIndex].text = text;

    this.setState({
      form: {
        ...form,
        fatFeatureList,
      },
    });
  };

  private handleNeighbourhoodChange = (galleryItems: IGalleryItem[]) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        neighbourhoodList: galleryItems.map(item => ({
          description: item.description || '',
          imageUrl: item.url,
        })),
      },
    });
  };

  private handleNeighbourhoodDescriptionChange = (itemIndex: number, description: string) => {
    const { form } = this.state;
    const { neighbourhoodList } = form;

    const list = neighbourhoodList || [];

    list[itemIndex].description = description;

    this.setState({
      form: {
        ...form,
        neighbourhoodList: list,
      },
    });
  };

  private handleFeaturesChange = (features: ISelectedFeature[]) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        featureList: features,
      },
    });
  };

  private handleObjectSetChange = (categorySet: INewObjectCategorySet, isCategorySetValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        newobjectCategorySet: categorySet,
      },
      isCategorySetValid,
    });
  };

  private handleHouseMaterialsChange = (houseMaterials: EHouseMaterialList[]) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        houseMaterialList: houseMaterials,
      },
    });
  };

  private handleInfrastructureInit = (infrastructures: TVillageInfrastructure[], isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        infrastructureList: infrastructures,
      },
      isInfrastructureValid: isDataValid,
    });
  };

  private handleInfrastructureChange = (infrastructures: TVillageInfrastructure[], isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        infrastructureList: infrastructures,
      },
      isInfrastructureValid: isDataValid,
    });
  };

  private handleObjectSetInit = (categorySet: INewObjectCategorySet, isCategorySetValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        newobjectCategorySet: categorySet,
      },
      isCategorySetValid,
    });
  };

  private handleCounterSetChange = (counterSet: ICounterSet, isCounterSetValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        counterSet,
      },
      isCounterSetValid,
    });
  };

  private handleCounterSetInit = (counterSet: ICounterSet, isCounterSetValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        counterSet,
      },
      isCounterSetValid,
    });
  };

  private handleGeneralPlanChange = async (image: IImage) => {
    const { httpApi, logger } = this.context;
    const genPlanFallbackState = {
      form: {
        ...this.state.form,
        generalPlanUrl: undefined,
      },
      generalPlan: {},
      isImagePending: false,
    };

    if (image.base64) {
      this.setState({ isImagePending: true });

      try {
        const value = await uploadImage(httpApi, logger, {
          base64: image.base64,
        });

        this.setState({
          form: {
            ...this.state.form,
            generalPlanUrl: value.url,
          },
          generalPlan: value,
          isImagePending: false,
        });
      } catch (error) {
        this.setState(genPlanFallbackState);
      }
    } else {
      this.setState(genPlanFallbackState);
    }
  };

  private handleCommunicationsChange = (communicationSet: ICommunicationSet, isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      areVillageCommunicationsValid: isDataValid,
      form: {
        ...form,
        communicationSet,
      },
    });
  };

  private handleCommunicationsInit = (communicationSet: ICommunicationSet, isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      areVillageCommunicationsValid: isDataValid,
      form: {
        ...form,
        communicationSet,
      },
    });
  };

  private handleAddressFieldChange = async (value: IVillageAddress) => {
    const { form } = this.state;
    const { httpApi, logger } = this.context;
    const { address, regionId, locationType, geo } = value;

    const shouldRenderNewRoads = this.props.countrysideRoadRegionsArray.includes(regionId);
    const shouldRenderNewStations = this.props.countrysideStationRegionsArray.includes(regionId);

    const roads = await (shouldRenderNewRoads ? getRoadList(httpApi, logger, regionId) : Promise.resolve([]));

    const { directions, stations } = await (shouldRenderNewStations
      ? getRailwayList(httpApi, logger, regionId)
      : Promise.resolve({ stations: [], directions: [] }));

    this.setState({
      availableDirections: directions,
      availableStations: stations,
      form: {
        ...form,
        ...geo,
        locationId: address,
        locationType: locationType ? (locationType.toLowerCase() as ELocationType) : ELocationType.Location,
        regionId,
      },
      roads,
      shouldRenderNewRoads,
      shouldRenderNewStations,
    });
  };

  private handleRoadInfoListInit = (roadInfoList: IRoadInfo[], isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        roadInfoList,
      },
      isRoadInfoListValid: isDataValid,
    });
  };

  private handleRoadInfoListChange = (roadInfoList: IRoadInfo[], isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        roadInfoList,
      },
      isRoadInfoListValid: isDataValid,
    });
  };

  private handleStationInfoListInit = (stationInfoList: IStationInfo[], isDataValid: boolean) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        stationInfoList,
      },
      isStationInfoListValid: isDataValid,
    });
  };

  private handleStationInfoListChange = (stationInfoList: IStationInfo[], isDataValid: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        stationInfoList,
      },
      isStationInfoListValid: isDataValid,
    });
  };

  private handleMainGalleryChange = (galleryItems: IGalleryItem[]) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        gallery: {
          imageList: galleryItems.map(item => ({
            fullUrl: item.url || '',
            thumbnailUrl: item.url || '',
          })),
        },
      },
    });
  };

  private handleCommentaryChange = (commentary: string) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        commentary,
      },
    });
  };

  private hideAlert = () =>
    this.setState({
      alert: {
        ...this.state.alert,
        isVisible: false,
      },
    });

  private handleVideoListChange = (videoList: IVideo[]) =>
    this.setState({
      form: { ...this.state.form, videoList },
    });

  private handleFatInfrastructureListChange = (list: IFatInfrastructureListItem[]) => {
    const filteredList = list.filter(el => !!el.title);

    this.setState({
      form: {
        ...this.state.form,
        fatInfrastructureList: filteredList,
      },
    });
  };

  private handleCplFieldChange = (value: boolean) => {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        isCpl: value,
        autodisableOrder: value && form.autodisableOrder,
      },
    });
  };

  private handleFieldChange = (name: string, value: string | number | void | boolean) => {
    const { form } = this.state;

    const extraParams = this.getExtraHandlerParams(name, value);

    this.setState({
      form: {
        ...form,
        ...extraParams,
        [name]: value,
      },
    });
  };

  private getExtraHandlerParams = (name: string, value: string | number | void | boolean) => {
    return value ? this.extraHandlerParams[name] : undefined;
  };

  private submitVillage = async () => {
    const {
      form,
      areVillageDistancesValid,
      areVillageCommunicationsValid,
      isCounterSetValid,
      isCategorySetValid,
      isInfrastructureValid,
      isRoadInfoListValid,
      isStationInfoListValid,
    } = this.state;

    const { gallery, isFat } = form;
    const { httpApi, logger } = this.context;

    const imageList = gallery.imageList || [];
    const fatGalleryValidation = isFat ? imageList.length >= FAT_CARD_MIN_PHOTOS_COUNT : !!imageList.length;
    const isGalleryValid = Array.isArray(gallery.imageList) && fatGalleryValidation;

    if (
      !this.isCreateFormValid() ||
      !areVillageDistancesValid ||
      !isCounterSetValid ||
      !isCategorySetValid ||
      !areVillageCommunicationsValid ||
      !isGalleryValid ||
      !isInfrastructureValid ||
      !isRoadInfoListValid ||
      !isStationInfoListValid
    ) {
      this.setState({
        useValidation: true,
        alert: {
          isVisible: true,
          text: 'Заполнены не все обязательные поля',
          type: 'danger',
        },
      });

      return;
    }
    const village: IVillage = {
      ...form,
      newobjectCategorySet: getFormattedNewobjectCategorySet(form.newobjectCategorySet),
    };

    try {
      await updateVillage(httpApi, logger, village as IUpdateVillageRequest);

      this.setState({
        alert: {
          isVisible: true,
          text: 'Коттеджный посёлок был успешно обновлен',
          type: 'success',
        },
      });
    } catch (error) {
      this.setState({
        alert: {
          isVisible: true,
          text: 'При обновлении коттеджного посёлка произошла ошибка',
          type: 'danger',
        },
      });
    }
  };

  private isCreateFormValid = () => {
    const { form } = this.state;
    const {
      name,
      siteUrl,
      description,
      areaHectare,
      builderId,
      locationId,
      finishYearMax,
      finishYearMin,
      floorCountMin = -99,
      floorCountMax = 99,
      priceMin = 0,
      priceMax = MAX_PRICE,
    } = form;

    return (
      name &&
      description &&
      isUrlValid(siteUrl) &&
      areaHectare &&
      builderId &&
      locationId &&
      areNumberFieldsValid(floorCountMin, floorCountMax) &&
      areNumberFieldsValid(finishYearMin, finishYearMax) &&
      areNumberFieldsValid(priceMin, priceMax)
    );
  };
}

function areNumberFieldsValid(field1: number | string | null, field2: number | string | null): boolean {
  if (field1 === null || field2 === null) {
    return true;
  }

  const [field1AsNumber, field2AsNumber] = [Number(field1), Number(field2)];

  return field1AsNumber <= field2AsNumber;
}
