import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { IContractorCallsFilterValues, ValueOf } from '../../types';

/* Экшены фильтров на странице списка звонков строителя (/contractor-calltracking) */

export enum EContractorCallsListFiltersActionTypes {
  SetFilterValue = 'CONTRACTOR_CALLS_LIST_SET_FILTER_VALUE',
}

export interface ISetContractorCallsFilterPayload {
  name: keyof IContractorCallsFilterValues;
  value: ValueOf<IContractorCallsFilterValues>;
}

export type TSetFilterValue = ITypedReduxAction<
  EContractorCallsListFiltersActionTypes.SetFilterValue,
  ISetContractorCallsFilterPayload
>;

// Экшен для установки фильтров страницы
export const setContractorCallsFilter: (payload: ISetContractorCallsFilterPayload) => TSetFilterValue = actionGenerator<
  EContractorCallsListFiltersActionTypes.SetFilterValue,
  ISetContractorCallsFilterPayload
>(EContractorCallsListFiltersActionTypes.SetFilterValue);
