import {
  ISetPlanRequest,
  ISetPlanResponse,
  ISetPlanResponseError,
  fetchSetPlan,
} from '../repositories/countryside-calltracking/v1/set-plan';
import { createSimpleRequest } from './createSimpleRequest';

export const setPlan = createSimpleRequest<ISetPlanRequest, ISetPlanResponse, ISetPlanResponseError>(
  'setPlan',
  fetchSetPlan,
);
