/* eslint-disable */
//tslint:disable

export interface IBuilderForListSchema {
  /** Дата создания **/
  created?: string | null;
  /** ID **/
  id: number;
  /** Статус модерации **/
  moderationStatus?: EModerationStatus | null;
  /** Название **/
  name: string;
  /** Дата последнего изменения **/
  updated?: string | null;
}

export enum EModerationStatus {
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
}
