import {
  fetchGetFatIcons,
  IGetFatIconsRequest,
  IGetFatIconsResponse,
  IGetFatIconsResponseError,
} from '../repositories/countryside/v1/get-fat-icons';

import { createSimpleRequest } from './createSimpleRequest';

export const getFatIcons = createSimpleRequest<IGetFatIconsRequest, IGetFatIconsResponse, IGetFatIconsResponseError>(
  'getFatIcons',
  fetchGetFatIcons,
);
