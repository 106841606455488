import { TDevice } from '@cian/utils';
import MediaQuery from 'react-responsive';

import { CplSettingsControlsContainer } from '../../../containers/CplSettingsControlsContainer';
import { CplSettingsMobileInfoContainer } from '../../../containers/CplSettingsInfoContainer';
import { getInitialDeviceWidth } from '../../../utils/helpers/getInitialDeviceWidth';
import { NotificationContainer } from '../../../utils/showNotification';

import * as styles from './CplSettingsMobileView.css';

export interface ICplSettingsMobileViewProps {
  deviceType: TDevice;
}

export function CplSettingsMobileView({ deviceType }: ICplSettingsMobileViewProps) {
  return (
    <MediaQuery values={{ width: getInitialDeviceWidth(deviceType) }} maxWidth={1024}>
      <div className={styles['container']}>
        <h2 className={styles['title']}>Настройки и детализация CPL</h2>
        <CplSettingsControlsContainer />
        <CplSettingsMobileInfoContainer />
        <NotificationContainer />
      </div>
    </MediaQuery>
  );
}
