import { connect } from 'react-redux';
import {
  ContractorCallDesktopPopup,
  IContractorCallDesktopPopupProps,
} from '../../components/ContractorCallDesktopPopup';
import { IContractorCalltrackingListPageState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { setContractorCallPopupId, toggleContractorCallPopup } from '../../actions/contractorCallPopup';
import { setContractorPlayerPause, setContractorPlayerStatus } from '../../actions/contractorCallsPlayer';

import { INITIAL_CONTRACTOR_POPUP, INITIAL_PLAYER_STATUS } from '../../constants';

export type TStoreProps = Pick<
  IContractorCallDesktopPopupProps,
  'call' | 'isOpen' | 'roles' | 'recordFilePath' | 'isPaused' | 'history'
>;

export type TDispatchProps = Pick<IContractorCallDesktopPopupProps, 'onClose' | 'onTrackEnd' | 'onTogglePause'>;

export function mapStateToProps(state: IContractorCalltrackingListPageState): TStoreProps {
  const {
    contractorCallsList: { calls },
    playerStatus: { recordFilePath, isPaused },
    callPopup: { isOpen, callId, history },
    // regionList,
    roles,
  } = state;
  const call = calls.find(call => call.callId === callId);

  return {
    roles,
    // regionList,
    isOpen,
    recordFilePath,
    isPaused,
    call,
    history,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    onClose: () => {
      dispatch(toggleContractorCallPopup(false));
    },
    onTrackEnd: () => {
      dispatch(setContractorPlayerStatus({ ...INITIAL_PLAYER_STATUS }));
      dispatch(setContractorCallPopupId(INITIAL_CONTRACTOR_POPUP.callId));
    },
    onTogglePause: (isPaused: boolean) => {
      dispatch(setContractorPlayerPause(isPaused));
    },
  };
}

export const ContractorCallPopupDesktopContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorCallDesktopPopup);
