/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ICreateAccountSchema } from '../../entities/schemas/CreateAccountSchema';

export type TCreateBuilderModel = IModel<ICreateBuilderRequest, TCreateBuilderResponse>;

export interface ICreateBuilderRequest {
  /** Список email-ов для доступа к загородной админке КТ **/
  accountList?: ICreateAccountSchema[] | null;
  /** Комментарий для сотрудников **/
  commentary?: string | null;
  /** Email пользователя создавшего застройщика **/
  creatorEmail?: string | null;
  /** Описание **/
  description?: string | null;
  /** Email-ы застройщика **/
  emailList?: string[] | null;
  /** URL логотипа **/
  logoUrl?: string | null;
  /** Статус модерации **/
  moderationStatus?: EModerationStatus | null;
  /** Название **/
  name: string;
  /** Телефон **/
  phone?: string | null;
  /** Регионы, в которых работает застройщик **/
  regionList?: number[] | null;
  /** Рабочее время **/
  workTime?: string | null;
}

export type TCreateBuilderResponse = ICreateBuilderResponse200 | ICreateBuilderResponse400;

export interface ICreateBuilderResponse200 extends IModelResponse<ICreateBuilderResponse> {
  statusCode: 200;
}

export interface ICreateBuilderResponse400 extends IModelResponse<ICreateBuilderResponseError> {
  statusCode: 400;
}

export interface ICreateBuilderResponse {
  /** Идентификатор объекта в БД **/
  id: number;
}

export interface ICreateBuilderResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ICreateBuilderResponse | ICreateBuilderResponseError;

export enum EModerationStatus {
  /** Удален **/
  Deleted = 'deleted',
  /** Черновик **/
  Draft = 'draft',
  /** Новый **/
  New = 'new',
  /** Опубликован **/
  Published = 'published',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ICreateBuilderResponse): TResponse200;
  400(response: ICreateBuilderResponseError): TResponse400;
}
