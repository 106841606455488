/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCreateBuilderModel,
  ICreateBuilderRequest,
  IMappers,
  ICreateBuilderResponse,
  ICreateBuilderResponseError,
  TCreateBuilderResponse,
} from './types';

const defaultConfig: TCreateBuilderModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside',
  pathApi: '/v2/create-builder/',
  hostType: 'api',
} as TCreateBuilderModel;

function createCreateBuilderModel(parameters: ICreateBuilderRequest): TCreateBuilderModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateBuilderOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICreateBuilderRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCreateBuilder<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICreateBuilderOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TCreateBuilderResponse> {
  const { response, statusCode } = await httpApi.fetch(createCreateBuilderModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICreateBuilderResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICreateBuilderResponseError);
    }
  }

  return { response, statusCode } as TCreateBuilderResponse;
}

export { defaultConfig, createCreateBuilderModel, fetchCreateBuilder };
