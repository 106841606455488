export enum MonthFormat {
  SHORT = 'short',
  LONG = 'long',
}

export const DEFAULT_MONTH_PICKER_CONFIG = {
  i18n: {
    dateFormat: { ru: 'MM/YYYY' },
    monthFormat: MonthFormat.LONG,
  },
  lang: 'ru',
  inputProps: {
    className: 'form-control',
  },
};
