import { ITypedReduxAction } from '../../types/redux/actionType';
import { IDatePeriod } from '../../utils/getDatePeriod';
import { actionGenerator } from '../../utils/redux/actionGenerator';

/* Экшены фильтров на странице детализации звонков (/cpl-settings) */

export enum ECplFiltersActionTypes {
  SetPeriod = 'CPL_FILTERS_SET_PERIOD',
  SetSelectedVillages = 'CPL_FILTERS_SET_SELECTED_VILLAGES',
  SetManagerId = 'CPL_FIlTERS_SET_MANAGER_ID',
}

export type TSetPeriod = ITypedReduxAction<ECplFiltersActionTypes.SetPeriod, IDatePeriod>;

// Экшен для установки месяца
export const setCplSettingsPeriod: (payload: IDatePeriod) => TSetPeriod = actionGenerator<
  ECplFiltersActionTypes.SetPeriod,
  IDatePeriod
>(ECplFiltersActionTypes.SetPeriod);

export type TSetVillage = ITypedReduxAction<ECplFiltersActionTypes.SetSelectedVillages, number[]>;

// Экшен для установки КП
export const setCplSettingsSelectedVillages: (payload: number[]) => TSetVillage = actionGenerator<
  ECplFiltersActionTypes.SetSelectedVillages,
  number[]
>(ECplFiltersActionTypes.SetSelectedVillages);

export type TSetManagerId = ITypedReduxAction<ECplFiltersActionTypes.SetManagerId, number>;

// Экшен для менеджера КП
export const setCplSettingsManagerId: (payload: number) => TSetManagerId = actionGenerator<
  ECplFiltersActionTypes.SetManagerId,
  number
>(ECplFiltersActionTypes.SetManagerId);
