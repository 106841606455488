import { useCallback, useEffect, useState } from 'react';

import {
  IAdminGetCallsRequest,
  IAdminGetCallsResponse,
} from '../repositories/countryside-calltracking/v1/admin-get-calls';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { getCalls } from '../http';
import { changeQueryString } from './filters';
import { normalizeCallTrackingFilters } from './helpers';
import { CALLTRACKING_BUILDER_NO_OFFERS_MSG } from '../constants/calltracking';

interface IUseGetCallsHook {
  callsList: IAdminGetCallsResponse | null;
  httpApi: IHttpApi;
  logger: ILogger;
  callsFilters: IAdminGetCallsRequest;
}

type TUpdateFilterValue = (name: string) => (value?: number | string) => void;

export interface IUseGetCallsHookResult {
  callsListState: IAdminGetCallsResponse | null;
  filters: IAdminGetCallsRequest;
  setFilters: React.Dispatch<React.SetStateAction<IAdminGetCallsRequest>>;
  updateFilterValue: TUpdateFilterValue;
  hasBuilderError?: boolean;
}

export const updateFilterValueCreator =
  (filters: IAdminGetCallsRequest, cb: Function): TUpdateFilterValue =>
  (name: string) =>
  (value?: number | string) =>
    cb({ ...filters, page: 1, [name]: value });

export function useGetCallsHook(params: IUseGetCallsHook): IUseGetCallsHookResult {
  const { callsList, httpApi, logger, callsFilters } = params;

  const [filters, setFilters] = useState(callsFilters);
  const [callsListState, setCallListData] = useState(callsList);
  const [hasBuilderError, setBuilderError] = useState(false);
  const updateFilterValue = useCallback(updateFilterValueCreator(filters, setFilters), [filters]);
  const normalizedFilters = normalizeCallTrackingFilters(filters);
  useEffect(() => {
    changeQueryString(filters);
    getCalls(httpApi, logger, normalizedFilters)
      .then(setCallListData)
      .catch(error => {
        setBuilderError(error.message === CALLTRACKING_BUILDER_NO_OFFERS_MSG);
        setCallListData(null);
      });
  }, [filters]);

  return {
    callsListState,
    filters,
    hasBuilderError,
    setFilters,
    updateFilterValue,
  };
}
