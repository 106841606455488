import {
  TSetContractorPlayerFilePath,
  TSetContractorPlayerPause,
  TSetContractorPlayerStatus,
  EContractorPlayerActionTypes,
} from '../../actions/contractorCallsPlayer';
import { IContractorCallsPlayerStatus } from '../../types';
import { INITIAL_PLAYER_STATUS } from '../../constants';

export type TPContractorCallsPlayerAction =
  | TSetContractorPlayerFilePath
  | TSetContractorPlayerPause
  | TSetContractorPlayerStatus;

export function contractorCallsPlayerReducer(
  state: IContractorCallsPlayerStatus = INITIAL_PLAYER_STATUS,
  action: TPContractorCallsPlayerAction,
): IContractorCallsPlayerStatus {
  switch (action.type) {
    case EContractorPlayerActionTypes.SetFilePath:
      return {
        ...state,
        recordFilePath: action.payload,
      };

    case EContractorPlayerActionTypes.SetPause:
      return {
        ...state,
        isPaused: action.payload,
      };

    case EContractorPlayerActionTypes.SetStatus:
      // eslint-disable-next-line no-case-declarations
      const { isPaused, recordFilePath } = action.payload;

      return {
        ...state,
        isPaused,
        recordFilePath,
      };
    default:
      return state;
  }
}
