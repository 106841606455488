import * as React from 'react';
import { mergeStyles, numberToPrettyString } from '@cian/utils';
import { AppContext } from '../../AppContext';
import { IRegion } from '../../common/interfaces';
import { IAdminGetCallsResponse } from '../../repositories/countryside-calltracking/v1/admin-get-calls';
import { formatDateTime } from '../../utils/datetime';
import { useGetCallHistoryHook } from '../../utils/useGetCallHistoryHook';
import { CalltrackingListItemPopup } from '../CalltrackingListItemPopup';
import * as styles from './CalltrackingListMobile.css';
import { CommentIcon } from '../CommentIcon';
import { ICallHistoryItem, TCallType, TChangerType } from 'shared/types';

export interface ICalltrackingListMobileProps {
  callsList: IAdminGetCallsResponse | null;
  isAlertVisible?: boolean;
  isAdmin: boolean;
  canEditBlackList: boolean;
  regionList: IRegion[];
  autoplayCplRecordsEnabled?: boolean;
  onSaveReport(): void;
  setCalltrackingType(callId: number, changerType: TChangerType, type: TCallType): void;
  onBlackListStatusChange(): void;
  onCommentSave(): void;
}

export interface ICalltrackingListMobilePopupState {
  callId?: number | null;
  history: ICallHistoryItem[] | null;
}

export const DEFAULT_CALLTRACKING_LIST_MOBILE_POPUP_STATE: ICalltrackingListMobilePopupState = {
  callId: undefined,
  history: null,
};

export function CalltrackingListMobile({
  callsList,
  setCalltrackingType,
  isAlertVisible,
  onSaveReport,
  isAdmin,
  canEditBlackList,
  regionList,
  onBlackListStatusChange,
  onCommentSave,
}: ICalltrackingListMobileProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const [popupState, setPopupState] = React.useState(DEFAULT_CALLTRACKING_LIST_MOBILE_POPUP_STATE);
  useGetCallHistoryHook<ICalltrackingListMobilePopupState>({
    callId: popupState.callId,
    httpApi,
    logger,
    popupState,
    setPopupState,
  });

  if (callsList === null || callsList.totalItems === 0 || !callsList.calls) {
    return (
      <div>
        <strong>С заданными параметрами звонков нет</strong>
      </div>
    );
  }
  const { totalItems, calls, totalPrice } = callsList;

  const popupCall = calls.find(call => call.callId === popupState.callId);

  return (
    <div>
      <div className={styles['total_count']}>
        <span>
          <strong>Найдено звонков:</strong>&nbsp;{totalItems}
        </span>
        {totalPrice && (
          <span>
            <strong>Общая стоимость:</strong>&nbsp;{numberToPrettyString(Number(totalPrice))}&nbsp;₽
          </span>
        )}
        <button {...mergeStyles('btn', 'btn-success', styles['btn_save'])} onClick={onSaveReport}>
          <i className="far fa-arrow-alt-circle-down" />
          Сохранить отчёт
        </button>
      </div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Застройщик</th>
            <th>КП</th>
            <th>Дата звонка</th>
          </tr>
        </thead>
        <tbody>
          {calls.map(call => (
            <tr
              key={call.callId as React.Attributes['key']}
              onClick={() =>
                setPopupState({
                  ...popupState,
                  callId: call.callId,
                })
              }
            >
              <td>
                {call.callId}
                {!!call.comment && <CommentIcon />}
              </td>
              <td>{call.builderName}</td>
              <td>{call.villageName}</td>
              <td>{formatDateTime(call.callDate, '+0300')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <CalltrackingListItemPopup
        call={popupCall}
        isAlertVisible={isAlertVisible}
        isAdmin={isAdmin}
        canEditBlackList={canEditBlackList}
        setCalltrackingType={setCalltrackingType}
        history={popupState.history}
        regionList={regionList}
        onClose={() => setPopupState(DEFAULT_CALLTRACKING_LIST_MOBILE_POPUP_STATE)}
        onBlackListStatusChange={() => {
          onBlackListStatusChange();
          setPopupState(DEFAULT_CALLTRACKING_LIST_MOBILE_POPUP_STATE);
        }}
        onCommentSave={onCommentSave}
      />
    </div>
  );
}
