import { TDevice } from '@cian/utils';
import MediaQuery from 'react-responsive';

import { CplSettingsControlsContainer } from '../../../containers/CplSettingsControlsContainer';
import { CplSettingsHistoryContainer } from '../../../containers/CplSettingsHistoryContainer';
import { CplSettingsInfoContainer } from '../../../containers/CplSettingsInfoContainer';
import { getInitialDeviceWidth } from '../../../utils/helpers/getInitialDeviceWidth';
import { NotificationContainer } from '../../../utils/showNotification';

import * as styles from './CplSettingsDesktopView.css';

export interface ICplSettingsDesktopViewProps {
  deviceType: TDevice;
}

export function CplSettingsDesktopView({ deviceType }: ICplSettingsDesktopViewProps) {
  return (
    <MediaQuery values={{ width: getInitialDeviceWidth(deviceType) }} minWidth={1025}>
      <div className={styles['container']}>
        <h2 className={styles['title']}>Настройки и детализация CPL</h2>
        <CplSettingsControlsContainer />
        <CplSettingsInfoContainer />
        <CplSettingsHistoryContainer />
        <NotificationContainer />
      </div>
    </MediaQuery>
  );
}
