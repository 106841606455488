import {
  fetchAdminGetCallHistory,
  IAdminGetCallHistoryRequest,
  IAdminGetCallHistoryResponse,
  IAdminGetCallHistoryResponseError,
} from '../repositories/countryside-calltracking/v1/admin-get-call-history';

import { createSimpleRequest } from './createSimpleRequest';

export const getCallHistory = createSimpleRequest<
  IAdminGetCallHistoryRequest,
  IAdminGetCallHistoryResponse,
  IAdminGetCallHistoryResponseError
>('getCallHistory', fetchAdminGetCallHistory);
