import { TextField } from '../../text_field';

import * as styles from './BuilderEmailItem.css';
import { Button, Col, Row } from 'react-bootstrap';
import { mergeStyles } from '@cian/utils';

interface IBuilderEmailsItemProps {
  onChange(value: string, index: number): void;
  onRemove(index: number): void;
  itemIndex: number;
  itemLabel?: string;
  email: string;
  readOnly?: boolean;
}

export const BuilderEmailItem = ({
  email,
  itemLabel,
  itemIndex,
  readOnly,
  onChange,
  onRemove,
}: IBuilderEmailsItemProps) => (
  <Row {...mergeStyles('mb-3', styles['email-row'])}>
    <Col sm={8}>
      <TextField
        label={itemLabel}
        placeholder="user@example.com"
        value={email}
        onChange={value => onChange(value, itemIndex)}
        maxLength={200}
        readOnly={readOnly}
      />
    </Col>
    <Col sm={4}>
      {!readOnly && (
        <Button variant="danger" type="button" onClick={() => onRemove(itemIndex)}>
          <i className="fas fa-times" />
        </Button>
      )}
    </Col>
  </Row>
);
