import { ECplFiltersActionTypes, TSetManagerId, TSetPeriod, TSetVillage } from '../../actions/cplFilters';
import { ICplFilters } from '../../types';

export type TCplFiltersAction = TSetPeriod | TSetVillage | TSetManagerId;

export function cplFiltersReducer(state: ICplFilters, action: TCplFiltersAction) {
  switch (action.type) {
    case ECplFiltersActionTypes.SetPeriod:
      return {
        ...state,
        period: action.payload,
      };

    case ECplFiltersActionTypes.SetSelectedVillages:
      return {
        ...state,
        villages: action.payload,
      };

    case ECplFiltersActionTypes.SetManagerId:
      return {
        ...state,
        managerId: action.payload,
      };

    default:
      return state || null;
  }
}
