/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminShareCallRecordModel,
  IAdminShareCallRecordRequest,
  IMappers,
  IAdminShareCallRecordResponse,
  IAdminShareCallRecordResponseError,
  TAdminShareCallRecordResponse,
} from './types';

const defaultConfig: TAdminShareCallRecordModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/share-call-record/',
  hostType: 'api',
} as TAdminShareCallRecordModel;

function createAdminShareCallRecordModel(parameters: IAdminShareCallRecordRequest): TAdminShareCallRecordModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminShareCallRecordOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminShareCallRecordRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminShareCallRecord<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminShareCallRecordOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminShareCallRecordResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminShareCallRecordModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminShareCallRecordResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminShareCallRecordResponseError);
    }
  }

  return { response, statusCode } as TAdminShareCallRecordResponse;
}

export { defaultConfig, createAdminShareCallRecordModel, fetchAdminShareCallRecord };
