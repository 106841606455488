import { TCplHistoryData } from '../../types';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ECplHistoryActionTypes {
  SetData = 'CPL_HISTORY_SET_DATA',
  SetVillage = 'CPL_HISTORY_SET_VILLAGE',
}

// Экшен для установки истории
export type TSetCplHistory = ITypedReduxAction<ECplHistoryActionTypes.SetData, TCplHistoryData>;
export const setCplHistory: (payload: TCplHistoryData) => TSetCplHistory = actionGenerator<
  ECplHistoryActionTypes.SetData,
  TCplHistoryData
>(ECplHistoryActionTypes.SetData);

// Экшен для смены текущего КП
export type TSetCplHistoryVillage = ITypedReduxAction<ECplHistoryActionTypes.SetVillage, number>;
export const setCplHistoryVillage: (payload: number) => TSetCplHistoryVillage = actionGenerator<
  ECplHistoryActionTypes.SetVillage,
  number
>(ECplHistoryActionTypes.SetVillage);
