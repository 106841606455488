import { EContractorCallsListFiltersActionTypes, TSetFilterValue } from '../../actions/contractorCallsListFilters';
import { IGetCallsRequest } from 'shared/types';

export type TPContractorCallsListFiltersAction = TSetFilterValue;

export function contractorCallsListFiltersReducer(
  state: IGetCallsRequest,
  action: TPContractorCallsListFiltersAction,
): IGetCallsRequest {
  switch (action.type) {
    case EContractorCallsListFiltersActionTypes.SetFilterValue:
      // eslint-disable-next-line no-case-declarations
      const { name, value } = action.payload;

      return {
        ...state,
        [name]: value,
      };

    default:
      return state || null;
  }
}
