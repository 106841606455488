import * as React from 'react';

import { IGalleryItem, IVillageNeighbourhoodItem } from '../../../common/interfaces';
import { Gallery } from '../../gallery';

export interface INeighbourhoodProps {
  neighborhoodList: IVillageNeighbourhoodItem[];
  readOnly?: boolean;
  onChange(items: IGalleryItem[]): void;
  onDescriptionChange(index: number, description: string): void;
}

export class Neighbourhood extends React.Component<INeighbourhoodProps> {
  public render() {
    const { neighborhoodList, onChange, onDescriptionChange, readOnly } = this.props;

    const items: IGalleryItem[] = neighborhoodList.map(({ description, imageUrl }) => ({
      description,
      url: imageUrl,
    }));

    return (
      <Gallery
        areInputsDisplayed
        useValidation={false}
        images={items}
        minWidth={640}
        minHeight={400}
        onChange={onChange}
        maxInputLength={280}
        onInputChange={onDescriptionChange}
        readOnly={readOnly}
        required
      />
    );
  }
}
