import { INewObjectCategorySetItem } from '../common/interfaces';
import { isImageListValid } from './isImageListValid';
import { isNumberRangeValid } from './isNumberRangeValid';

export function isObjectSetItemValid(data?: INewObjectCategorySetItem | null): boolean {
  if (!data) {
    return false;
  }
  const { areaMax, areaMin, priceMax, priceMin, description, gallery, additionalGallery } = data;
  const isGalleryValid = isImageListValid(gallery.imageList || []);
  const isAdditionalGalleryValid =
    additionalGallery &&
    additionalGallery.imageList &&
    additionalGallery.imageList.length &&
    additionalGallery.imageList.length <= 20;

  const oneOfGalleryIsValid = isGalleryValid || isAdditionalGalleryValid;

  return Boolean(
    isNumberRangeValid(Number(areaMin), Number(areaMax)) &&
      isNumberRangeValid(Number(priceMin), Number(priceMax)) &&
      description &&
      oneOfGalleryIsValid,
  );
}
