import * as React from 'react';

import { IGalleryItem, INewObjectCategorySetItem } from '../../../../common/interfaces';
import { IFatIcon } from '../../../../types/fat';
import { MAX_PRICE } from '../../../../utils/constants';
import { FatInfrastructureModal } from '../../../FatInfrastructureModal';
import { IFatInfrastructureModalData } from '../../../FatInfrastructureModal/FatInfrastructureModal';
import { FatNewobjectCategorySetDataPreview } from '../../../FatNewobjectCategorySetDataPreview';
import { IImage, ImageSelector } from '../../../image_selector';
import { TextAreaField } from '../../../textarea_field';
import { NumberRangeField } from '../../village_form/number_range_field';
import { Gallery } from '../../../gallery';

import * as styles from './VillageObject.css';
import { Button, Col, Container, FormLabel, Row } from 'react-bootstrap';

export interface IVillageObjectProps {
  objectSetItem: INewObjectCategorySetItem;
  title: string;
  fatIconList: IFatIcon[];
  isDataValid?: boolean;
  measureType?: string;
  readOnly?: boolean;
  fatCardEnabled?: boolean;
  handleImageChange(image: IImage, index: number): void;
  handleFieldChange(name: string, value: string | number | void): void;
  handleFatFieldsChange(data: IFatInfrastructureModalData): void;
  handleDeleteObjectItem(): void;
  handleAdditionalGalleryChange(galleryItems: IGalleryItem[]): void;
}

export const VillageObject = (props: IVillageObjectProps) => {
  const {
    isDataValid,
    objectSetItem,
    handleFieldChange,
    handleFatFieldsChange,
    handleImageChange,
    handleDeleteObjectItem,
    handleAdditionalGalleryChange,
    title,
    measureType = 'м²',
    readOnly,
    fatIconList,
    fatCardEnabled,
  } = props;
  const [fatPopupState, changeFatPopupState] = React.useState({ isOpened: false });
  const {
    areaMax,
    areaMin,
    gallery,
    priceMax,
    priceMin,
    description,
    additionalGallery,
    fatTitle,
    fatDescription,
    fatIconId,
    fatEnabled,
  } = objectSetItem;

  const fatData = {
    title: fatTitle,
    description: fatDescription,
    iconId: fatIconId,
    isEnabled: fatEnabled,
  };

  const additionalGalleryIsValid =
    additionalGallery && !!additionalGallery.imageList && additionalGallery.imageList.length;
  const createFieldHandler = (name: string) => (value: string | number | void) => handleFieldChange(name, value);
  const createImageHandler = (index: number) => (image: IImage) => handleImageChange(image, index);
  const fatIconData = fatIconList.find(icon => icon.id === fatIconId);
  const galleryImageList = gallery.imageList || [];

  return (
    <Container fluid className={!isDataValid ? styles['container--invalid'] : undefined}>
      <Row>
        <Col md={6}>
          <h3>{title}</h3>
        </Col>
        <Col md={6} className="text-end mt-auto">
          {!readOnly && (
            <Button type="button" onClick={handleDeleteObjectItem} variant="danger">
              <i className="fas fa-times" />
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <NumberRangeField
            label="Диапазон цен за объект, в рублях"
            required
            minValue={priceMin ? Number(priceMin.toString()) : undefined}
            maxValue={priceMax ? Number(priceMax.toString()) : undefined}
            max={MAX_PRICE}
            onChangeMin={createFieldHandler('priceMin')}
            onChangeMax={createFieldHandler('priceMax')}
            readOnly={readOnly}
            containerClass="mb-3"
          />
          <NumberRangeField
            label={`Площадь объектов,  ${measureType}`}
            required
            minValue={areaMin ? Number(areaMin.toString()) : undefined}
            maxValue={areaMax ? Number(areaMax.toString()) : undefined}
            max={999999.999999}
            allowFloat={true}
            onChangeMin={createFieldHandler('areaMin')}
            onChangeMax={createFieldHandler('areaMax')}
            readOnly={readOnly}
            containerClass="mb-3"
          />
          <Row>
            <Col md={6} className="mb-3">
              <FormLabel>
                Прямоугольное изображение&nbsp;
                {!additionalGalleryIsValid && <span className="text-danger">*</span>}
              </FormLabel>
              <ImageSelector
                value={{ url: galleryImageList[0] ? galleryImageList[0].fullUrl : '' }}
                onChange={createImageHandler(0)}
                minWidth={480}
                minHeight={1}
                maxWidth={960}
                invalid={!(galleryImageList[0] || additionalGalleryIsValid)}
                readOnly={readOnly}
              />
            </Col>
            <Col md={6} className="mb-3">
              <FormLabel>
                Квадратное изображение&nbsp;
                {!additionalGalleryIsValid && <span className="text-danger">*</span>}
              </FormLabel>
              <ImageSelector
                value={{ url: galleryImageList[1] ? galleryImageList[1].fullUrl : '' }}
                onChange={createImageHandler(1)}
                minWidth={232}
                minHeight={1}
                maxWidth={480}
                invalid={!(galleryImageList[1] || additionalGalleryIsValid)}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <TextAreaField
            value={description || ''}
            label="Описание"
            required
            onChange={createFieldHandler('description')}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-3">
          <h2>Галерея</h2>
          <Gallery
            images={
              additionalGallery && additionalGallery.imageList
                ? additionalGallery.imageList.map(image => ({ url: image.fullUrl }))
                : []
            }
            minWidth={830}
            minHeight={320}
            onChange={handleAdditionalGalleryChange}
            useValidation
            maxCount={20}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {fatCardEnabled && (
        <Row>
          <Col md={12}>
            <h2>Супер премиум</h2>
            <FatNewobjectCategorySetDataPreview
              {...fatData}
              iconUrl={fatIconData ? fatIconData.url : undefined}
              onClick={() =>
                changeFatPopupState({
                  isOpened: true,
                })
              }
            />
            {fatPopupState.isOpened && (
              <FatInfrastructureModal
                data={fatData}
                iconList={fatIconList}
                header="Информация о типе недвижимости"
                onClose={() => {
                  changeFatPopupState({
                    isOpened: false,
                  });
                }}
                onConfirm={data => {
                  handleFatFieldsChange(data);

                  changeFatPopupState({
                    isOpened: false,
                  });
                }}
              />
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};
