import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { ICounterSet } from '../../../common/interfaces';
import { isDefined } from '../../../utils/helpers/isDefined';
import { NumberField } from '../../number_field';

const MIN_ITEM_COUNT = 0;
const MAX_ITEM_COUNT = 9999;

const getDefaultCounterSet = (propsData: ICounterSet): ICounterSet => ({
  onSaleCottage: propsData.onSaleCottage || MIN_ITEM_COUNT,
  onSaleLandplot: propsData.onSaleLandplot || MIN_ITEM_COUNT,
  onSaleLowrisehouse: propsData.onSaleLowrisehouse || MIN_ITEM_COUNT,
  onSaleTownhouse: propsData.onSaleLowrisehouse || MIN_ITEM_COUNT,
  sold: propsData.sold || MIN_ITEM_COUNT,
  total: propsData.total || MIN_ITEM_COUNT,
  underConstruction: propsData.underConstruction || MIN_ITEM_COUNT,
});

const getStringValue = (value?: number | null) => (!isDefined<number>(value) ? '' : value.toString());

const validateForm = (data: Partial<ICounterSet>) => Object.values(data).every(value => value !== undefined);

interface IProps {
  counterSet: ICounterSet;
  onChange(newValue: ICounterSet, isDataValid: boolean): void;
  onInit(newValue: ICounterSet, isDataValid: boolean): void;
  useValidation: boolean;
  readOnly?: boolean;
}

interface IState {
  data: ICounterSet;
  isDataValid: boolean;
}

export class VillageObjectsReport extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const data = getDefaultCounterSet(this.props.counterSet);

    this.state = {
      data,
      isDataValid: validateForm(data),
    };
  }

  public componentDidMount() {
    const { data, isDataValid } = this.state;

    this.props.onInit(data, isDataValid);
  }

  public render() {
    const { useValidation, readOnly } = this.props;
    const { data } = this.state;
    const { total, sold, onSaleLowrisehouse, onSaleLandplot, onSaleCottage, onSaleTownhouse, underConstruction } = data;

    return (
      <div>
        <Row>
          <Col md={4} className="mb-3">
            <NumberField
              label="Всего объектов"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(total)}
              required
              onChange={this.handleTotalFieldChange}
              invalid={useValidation && total === undefined}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <NumberField
              label="Объектов куплено"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(sold)}
              required
              onChange={this.handleSoldFieldChange}
              invalid={useValidation && sold === undefined}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <NumberField
              label="Объектов строится"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(underConstruction)}
              required
              invalid={useValidation && underConstruction === undefined}
              onChange={this.handleUnderConstructionFieldChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <NumberField
              label="Коттеджей в продаже"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(onSaleCottage)}
              required
              invalid={useValidation && onSaleCottage === undefined}
              onChange={this.handleOnSaleCottageFieldChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <NumberField
              label="Таунхаусов в продаже"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(onSaleTownhouse)}
              required
              invalid={useValidation && onSaleTownhouse === undefined}
              onChange={this.handleOnSaleTownhouseFieldChange}
              readOnly={readOnly}
            />
          </Col>
          <Col md={4} className="mb-3">
            <NumberField
              label="Малоэтажек в продаже"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(onSaleLowrisehouse)}
              required
              invalid={useValidation && onSaleLowrisehouse === undefined}
              onChange={this.handleOnSaleLowrisehouseFieldChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <NumberField
              label="Участков в продаже"
              min={MIN_ITEM_COUNT}
              max={MAX_ITEM_COUNT}
              value={getStringValue(onSaleLandplot)}
              required
              invalid={useValidation && onSaleLandplot === undefined}
              onChange={this.handleOnSaleLandplotFieldChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </div>
    );
  }

  private handleFieldChange = (key: string, value: number | undefined) => {
    const { data, isDataValid } = this.state;

    const newState: IState = {
      data: {
        ...data,
        [key]: value,
      },
      isDataValid,
    };

    newState.isDataValid = validateForm(newState.data);

    this.setState(newState);
    this.props.onChange(newState.data as ICounterSet, newState.isDataValid);
  };

  private handleTotalFieldChange = (total: number | undefined) => {
    this.handleFieldChange('total', total);
  };

  private handleSoldFieldChange = (sold: number | undefined) => {
    this.handleFieldChange('sold', sold);
  };

  private handleUnderConstructionFieldChange = (underConstruction: number | undefined) => {
    this.handleFieldChange('underConstruction', underConstruction);
  };

  private handleOnSaleCottageFieldChange = (onSaleCottage: number | undefined) => {
    this.handleFieldChange('onSaleCottage', onSaleCottage);
  };

  private handleOnSaleTownhouseFieldChange = (onSaleTownhouse: number | undefined) => {
    this.handleFieldChange('onSaleTownhouse', onSaleTownhouse);
  };

  private handleOnSaleLowrisehouseFieldChange = (onSaleLowrisehouse: number | undefined) => {
    this.handleFieldChange('onSaleLowrisehouse', onSaleLowrisehouse);
  };

  private handleOnSaleLandplotFieldChange = (onSaleLandplot: number | undefined) => {
    this.handleFieldChange('onSaleLandplot', onSaleLandplot);
  };
}
