import * as React from 'react';

interface IWrapLinkProps {
  href?: string;
}

export const WrapLink: React.FC<React.PropsWithChildren<IWrapLinkProps>> = props =>
  props.href ? <a {...props} rel="noreferrer" /> : props.children;

WrapLink.displayName = 'WrapLink';
