/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAdminGetCallHistoryModel,
  IAdminGetCallHistoryRequest,
  IMappers,
  IAdminGetCallHistoryResponse,
  IAdminGetCallHistoryResponseError,
  TAdminGetCallHistoryResponse,
} from './types';

const defaultConfig: TAdminGetCallHistoryModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/admin/get-call-history/',
  hostType: 'api',
} as TAdminGetCallHistoryModel;

function createAdminGetCallHistoryModel(parameters: IAdminGetCallHistoryRequest): TAdminGetCallHistoryModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAdminGetCallHistoryOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAdminGetCallHistoryRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchAdminGetCallHistory<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IAdminGetCallHistoryOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAdminGetCallHistoryResponse
> {
  const { response, statusCode } = await httpApi.fetch(createAdminGetCallHistoryModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IAdminGetCallHistoryResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IAdminGetCallHistoryResponseError);
    }
  }

  return { response, statusCode } as TAdminGetCallHistoryResponse;
}

export { defaultConfig, createAdminGetCallHistoryModel, fetchAdminGetCallHistory };
