/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TUnblockPhoneModel,
  IUnblockPhoneRequest,
  IMappers,
  IUnblockPhoneResponse,
  IUnblockPhoneResponseError,
  TUnblockPhoneResponse,
} from './types';

const defaultConfig: TUnblockPhoneModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'countryside-calltracking',
  pathApi: '/v1/unblock-phone/',
  hostType: 'api',
} as TUnblockPhoneModel;

function createUnblockPhoneModel(parameters: IUnblockPhoneRequest): TUnblockPhoneModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IUnblockPhoneOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IUnblockPhoneRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchUnblockPhone<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IUnblockPhoneOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TUnblockPhoneResponse> {
  const { response, statusCode } = await httpApi.fetch(createUnblockPhoneModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IUnblockPhoneResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IUnblockPhoneResponseError);
    }
  }

  return { response, statusCode } as TUnblockPhoneResponse;
}

export { defaultConfig, createUnblockPhoneModel, fetchUnblockPhone };
