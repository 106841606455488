import * as React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

import { ERoutes } from 'shared/common/types/routes';
import { EPageTypes } from 'shared/common/types/pageTypes';

interface INavBarProps {
  hasAccessToCountryside: boolean;
  activeItem?: string;
  hasAccessToCalltracking: boolean;
  hasAccessToBuilders: boolean;
  email?: string;
  cplSettingsUrl?: string;
  onLogOut(e: React.MouseEvent<HTMLElement>): void;
}

export const NavBar: React.FC<INavBarProps> = ({
  hasAccessToCountryside,
  activeItem,
  hasAccessToCalltracking,
  hasAccessToBuilders,
  email,
  cplSettingsUrl,
  onLogOut,
}) => {
  const showNavigationBar = !!activeItem;

  return (
    <Navbar bg="light" className="mb-4 border-bottom">
      <Container>
        <Navbar.Brand href="/">
          <span className="fas fa-home" />
        </Navbar.Brand>
        <Navbar.Collapse role="navigation">
          {showNavigationBar && (
            <Nav className="me-auto">
              {hasAccessToCountryside && (
                <>
                  <Nav.Link href={ERoutes.VillageFeatures} active={activeItem === EPageTypes.VillageFeatures}>
                    Особенности
                  </Nav.Link>
                  <Nav.Link href={ERoutes.VillageBuilders} active={activeItem === EPageTypes.VillageBuilders}>
                    Застройщики
                  </Nav.Link>
                  <Nav.Link href={ERoutes.Villages} active={activeItem === EPageTypes.Villages}>
                    Коттеджные поселки
                  </Nav.Link>
                </>
              )}
              {hasAccessToCalltracking && (
                <Nav.Link href={ERoutes.VillageCalltracking} active={activeItem === EPageTypes.VillageCalltracking}>
                  Звонки
                </Nav.Link>
              )}
              {hasAccessToBuilders && (
                <Nav.Link href={ERoutes.BuildersCalltracking} active={activeItem === EPageTypes.BuildersCalltracking}>
                  Звонки строителей
                </Nav.Link>
              )}
            </Nav>
          )}
          {email && (
            <Nav>
              <NavDropdown title={email} id="basic-nav-dropdown">
                {cplSettingsUrl && <NavDropdown.Item href={cplSettingsUrl}>Настройки CPL</NavDropdown.Item>}
                <NavDropdown.Item onClick={onLogOut}>Выход</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
