import * as React from 'react';

import { TDevice } from '@cian/utils';

import {
  IAdminGetCallsRequest,
  IAdminGetCallsResponse,
} from '../../repositories/countryside-calltracking/v1/admin-get-calls';
import { EUserRoles } from '../../utils/permissions';
import { CalltrackingDesktopView } from './DesktopView';
import { CalltrackingMobileView } from './MobileView';
import { CalltrackingTabletView } from './TabletView';
import { IAlert, IRegion } from '../../common/interfaces';
import { useGetCallsHook } from '../../utils/useGetCallsHook';
import { AppContext } from '../../AppContext';
import { Alert } from '../Alert';
import { IManagerForListSchema } from '../../repositories/countryside/entities/schemas/ManagerForListSchema';

interface ICalltrackingProps {
  callsList: IAdminGetCallsResponse | null;
  callsFilters: IAdminGetCallsRequest;
  roles: EUserRoles[];
  regionList: IRegion[];
  managerList: IManagerForListSchema[];
  apiUrl?: string;
  deviceType: TDevice;
  autoplayCplRecordsEnabled?: boolean;
}

export const CALLTRACKING_ALERT: { [key: string]: IAlert } = {
  ERROR_BUILDER: {
    type: 'danger',
    text: 'Доступ к звонкам ограничен.\n\nОбратитесь к вашему менеджеру, чтобы восстановить доступ',
    isVisible: true,
  },
  ERROR: {
    type: 'danger',
    text: 'Произошла ошибка при изменении типа записи',
    isVisible: true,
  },
  ERROR_REPORT: {
    type: 'danger',
    text: 'Произошла ошибка при загрузке отчёта',
    isVisible: true,
  },
  SUCCESS: {
    type: 'success',
    text: 'Тип записи успешно изменён',
    isVisible: true,
  },
  BLACK_LIST_SUCCESS: {
    type: 'success',
    text: 'Статус в ЧС успешно обновлен',
    isVisible: true,
  },
  HIDDEN: {
    type: 'success',
    text: '',
    isVisible: false,
  },
};

export function Calltracking(props: ICalltrackingProps) {
  const { httpApi, logger } = React.useContext(AppContext);
  const { callsFilters, callsList } = props;
  const hookState = useGetCallsHook({
    callsList,
    httpApi,
    logger,
    callsFilters,
  });

  if (hookState.hasBuilderError) {
    return <Alert {...CALLTRACKING_ALERT.ERROR_BUILDER} topAuto />;
  }

  return (
    <>
      <CalltrackingMobileView callsHookState={hookState} {...props} />
      <CalltrackingTabletView callsHookState={hookState} {...props} />
      <CalltrackingDesktopView callsHookState={hookState} {...props} />
    </>
  );
}
